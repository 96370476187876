import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-simple',
  templateUrl: './toggle-simple.component.html',
  styleUrls: ['./toggle-simple.component.css']
})
export class ToggleSimpleComponent implements OnInit {
  @Input() index:any;
  @Input() number:any;
  @Input() name:any;
  constructor() { }

  ngOnInit() {
  
  }

  
}
