import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-room-edit-category',
    templateUrl: './room-edit-category.component.html',
    styleUrls: ['./room-edit-category.component.css']
})
export class RoomEditCategoryComponent implements OnInit {

    constructor() { }

    ngOnInit() {}


}
