import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import {ToastrService} from 'ngx-toastr';
import {BookingService} from '../../../../services/bookings/booking.service';
import {AuthUser} from '../../../../models/auth-user.model';
import {environment} from '../../../../../environments/environment';
import {AppService} from '../../../../services/app/app.service';
import {Router} from '@angular/router';
import {AppConfig} from '../../../../models/app.model';
declare var $: any;
import { isDevMode } from '@angular/core';

@Component({
    selector: 'app-booking-checkout',
    templateUrl: './booking-checkout.component.html',
    styleUrls: ['./booking-checkout.component.css']
})
export class BookingCheckoutComponent implements OnInit {
    @Input() loaded: boolean;
    @Input() success: boolean;
    @Input() mode = 'selection';
    @Input() product: any;
    @Input() booking: any;
    @Input() selection: any;
    @Input() bookingId: any;
    @Input() queryParam: any = {};
    @Input() authUser: AuthUser;

    @Output() successChange = new EventEmitter<boolean>();
    @Output() bookingChange = new EventEmitter<any>();
    public payPalConfig?: IPayPalConfig;
    showSuccess: boolean;
    fundingSource: any;
    loading: boolean;
    private pricing: any;
    private submittedPayment: boolean;
    private currency: string;
    private supportedPaypalCurrencies = [
        'USD',
        'EUR'
    ];
    constructor(private toastr: ToastrService,
                private bookingService: BookingService,
                private appService: AppService,
                private router: Router) { }

    ngOnInit() {
        if (!this.checkUser()) {
            this.router.navigate(['/search'], {queryParams: this.queryParam});
            return;
        }

        this.appService.appConfigSubject.subscribe(
            (config: AppConfig) => {

                if (config.currency) {
                    if (!this.supportedPaypalCurrencies.includes(config.currency)) {
                        this.currency = 'EUR';
                    } else {
                        this.currency = config.currency;
                    }
                }

                if (this.mode === 'selection' && this.product) {

                    this.pricing =  {
                        currency: this.product.pricing.currency,
                        total: Number(parseFloat(this.product.pricing.totalHt).toFixed(2)),
                        cleaning_fees: Number(parseFloat(this.product.pricing.cleaning_fees).toFixed(2)),
                        fees: Number(parseFloat(this.product.pricing.fees).toFixed(2)),
                        amount: Number(parseFloat(this.product.pricing.total).toFixed(2)),
                        discount: Number(parseFloat(this.product.pricing.discount).toFixed(2)),
                        vat_fees: Number(parseFloat(this.product.pricing.vta_fees).toFixed(2)),
                        from: this.selection.date_start,
                        to: this.selection.date_end,
                        adults: this.selection.adults,
                        children: this.selection.children,
                        babies: this.selection.babies
                    };
                }

                if (this.mode === 'demand' && this.booking) {
                    this.pricing =  {
                        currency: this.booking.currency,
                        total: Number(parseFloat(this.booking.total_tenant_ht).toFixed(2)),
                        cleaning_fees: Number(parseFloat(this.booking.base_tenant_cleaning_fees).toFixed(2)),
                        fees: Number(parseFloat(this.booking.tenant_fees).toFixed(2)),
                        amount: Number(parseFloat(this.booking.total).toFixed(2)),
                        discount: Number(parseFloat(this.booking.discount).toFixed(2)),
                        vat_fees: Number(parseFloat(this.booking.vta_fees).toFixed(2)),
                        from: this.booking.date_start,
                        to: this.booking.date_end,
                        adults: this.booking.adults,
                        children: this.booking.children,
                        babies: this.booking.babies
                    };
                }

                if (this.product && this.pricing) {
                    this.initConfig();
                }
            }
        );

        this.appService.emitAppConfig();

        this.loadScripts();

        this.successChange.subscribe(
            (val) => {
                this.success = val;
            }
        );

        this.bookingChange.subscribe(
            (booking) => {
                this.booking = booking;
            }
        );


    }

    isDev() {
        return isDevMode();
    }
    private initConfig(): void {
        this.payPalConfig = {
            currency: this.currency,
            clientId: environment.payPalClientId,
            createOrderOnClient: (data) => <ICreateOrderRequest> {
                intent: 'CAPTURE',
                purchase_units: [
                    {
                        custom_id: 'APA-' + this.bookingId,
                        description: this.product.name,
                        amount: {
                            currency_code: this.currency,
                            value: this.appService.convertPrice(this.pricing.amount , this.pricing.currency, this.currency),
                            breakdown: {
                                item_total: {
                                    currency_code: this.currency,
                                    value: this.appService.convertPrice(this.pricing.amount, this.pricing.currency, this.currency),
                                },
                            }
                        },
                        items: [
                            {
                                name: this.product.name,
                                quantity: '1',
                                category: 'PHYSICAL_GOODS',
                                unit_amount: {
                                    currency_code: this.currency,
                                    value: this.appService.convertPrice(this.pricing.amount, this.pricing.currency, this.currency),
                                },
                            }
                        ]
                    }
                ]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                layout: 'vertical',
                size: 'medium'
            },
            onApprove: (data, actions) => {
                actions.order.get().then(details => {
                    this.loading = true;
                });
            },
            onClientAuthorization: (data) => {
                this.showSuccess = true;
                this.queryParam.orderId = data.id;
                this.queryParam.isInstantBooking = 1;
                this.saveBooking();
            },
            onCancel: (data, actions) => {
                this.fundingSource = null;
                this.queryParam.fundingSource = null;
            },
            onError: err => {
                this.toastr.error('Désolé une erreur s\'est produite réessayez plus tard');
            },
            onClick: (data, actions) => {
                this.fundingSource = data.fundingSource;
            },
        };
    }

    saveBooking() {
        this.loading = true;
        this.queryParam.fundingSource = this.fundingSource;
        const savedBookingHandler = (booking: any) => {
            this.bookingChange.emit(booking);
            this.successChange.emit(true);
            this.loading = false;
        };
        const failedBookingHandler = () => {
            this.loading = false;
            this.toastr.error('Désolé, une erreur s\'est produite');
        };

        if (this.mode === 'selection') {
            this.bookingService.saveUserSelectionBooking(this.authUser.id, this.selection.id, this.queryParam)
                .then(savedBookingHandler, failedBookingHandler);
        }

        if (this.mode === 'demand') {
            this.bookingService.saveClientDemandReservation(this.authUser.id, this.bookingId, this.queryParam)
                .then(savedBookingHandler, failedBookingHandler);
        }
    }

    openFormStripe() {
        this.loading = true;
        this.fundingSource = 'stripe';
        const handler = (window as any).StripeCheckout.configure({
            key: environment.keyStripe,
            locale: 'fr-FR',
            token: async token => {
                this.submittedPayment = true;
                this.queryParam.stripeToken = token.id;
                this.queryParam.email = token.email;
                this.queryParam.isInstantBooking = this.product.is_instant_booking;
                this.saveBooking();
            }
        });

        const convertedTotal = Number(
            this.appService.convertPrice(
                this.pricing.total,
                this.pricing.currency
            )
        );

        const total = Math.round(convertedTotal);

        handler.open({
            name: 'Shapart',
            description: 'Payer ma réservation',
            currency: this.appService.appConfig.currency,
            amount: total,
            closed: () => {
                if (!this.submittedPayment) {
                    this.loading = false;
                    this.fundingSource = null;
                }
            }
        });

    }

    checkUser(): boolean {
        return this.product.user_id !== this.authUser.id && this.product.manager.id !== this.authUser.id;
    }

    loadScripts() {
        if (!window.document.getElementById('stripe-script')) {
            const s = window.document.createElement('script');
            s.id = 'stripe-script';
            s.type = 'text/javascript';
            s.src = 'https://checkout.stripe.com/checkout.js';
            window.document.body.appendChild(s);
        }
        function toggleChevron(e) {
            $(e.target)
                .prev('.card-header')
                .find('i.indicator')
                .toggleClass('ti-angle-down ti-angle-right');

        }
        $('.accordion_2').on('hidden.bs.collapse shown.bs.collapse', toggleChevron);
    }

}
