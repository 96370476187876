import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../../../services/auth/account.service';
import {ToastrService} from 'ngx-toastr';
import {User} from '../../../models/user';

@Component({
  selector: 'app-login-shapart',
  templateUrl: './login-shapart.component.html',
  styleUrls: ['./login-shapart.component.css']
})
export class LoginShapartComponent implements OnInit {
    redirectTo: string;

    loginForm: FormGroup;
    loading = false;
    errors: any;
    formErrors;

  constructor(private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private accountService: AccountService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
      this.initForm();
      this.redirectTo = this.route.snapshot.queryParams.redirect_to || '/';
  }

    initForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.min(6)]],
        });
    }

    doLogin() {
        if (!this.loginForm.invalid) {
            const email = this.loginForm.get('email').value;
            const password = this.loginForm.get('password').value;
            this.loading = true;

            this.accountService.login(email, password).then(
                (data: User) => {
                    this.loading = false;
                    this.router.navigateByUrl(this.redirectTo).then(
                        () => {
                            document.getElementsByTagName('BODY')[0].removeAttribute('id');

                            this.toastr.success('Bonjour ' + data.lastname);
                        }
                    );

                },

                (error) => {
                    this.loading = false;

                    this.errors = error.error.error;
                }
            );
        } else {

            this.formErrors = this.loginForm.controls;
            return;
        }


    }

}
