import {Component, OnDestroy, OnInit} from '@angular/core';
import {ProductsService} from '../../../../services/products/products.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {SuggestedUser} from '../../../../models/user';

@Component({
    selector: 'app-property-room-owner',
    templateUrl: './property-room-owner.component.html',
    styleUrls: ['./property-room-owner.component.css'],
})

export class PropertyRoomOwnerComponent implements OnInit, OnDestroy {
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    accountTypeChangeSubscription: Subscription;
    managerTypeChangeSubscription: Subscription;
    propertyForm: FormGroup;
    errors: any;
    product: any;
    loading: boolean;
    loaded: boolean;
    productId: number;
    submitted;
    account_type;
    manager_type;
    selectedUser: SuggestedUser = null;
    invitation = null;
    contract = null;
    owner: AuthUser;
    agent: AuthUser;
    isOptionsDisabled = false;

    constructor(private productService: ProductsService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder,
                private toastr: ToastrService) { }

    ngOnInit() {

        this.productId = this.route.parent.parent.snapshot.params.id;

        this.initForms();

        this.accountTypeChangeSubscription = this.getAccountTypeChangeSubscription();
        this.managerTypeChangeSubscription = this.getManagerTypeSubscription();

        this.propertyForm.controls.is_options_disabled.valueChanges.subscribe(
            value => {
                this.isOptionsDisabled = value;
                if (value) {
                    if (this.accountTypeChangeSubscription) {
                        this.accountTypeChangeSubscription.unsubscribe();
                    }

                    if (this.managerTypeChangeSubscription) {
                        this.managerTypeChangeSubscription.unsubscribe();
                    }

                } else {
                    this.accountTypeChangeSubscription = this.getAccountTypeChangeSubscription();
                    this.managerTypeChangeSubscription = this.getManagerTypeSubscription();
                }
            }
        );

        this.propertyForm.controls.is_options_disabled.setValue(false);

        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {
                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {

                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;

                                        if (!this.propertyForm.get('account_type').value) {
                                            this.propertyForm.controls.account_type.setValue(product.user_role);
                                        }
                                        if (product.invitation) {
                                            this.contract = product.contract;

                                            this.propertyForm.controls.manager_type.setValue(product.invitation.invitee_role);
                                        }
                                        this.loading = false;
                                        this.loaded = true;
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                (error) => {
                                    console.log(error);
                                    this.router.navigate(['/rooms/new']);
                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();
        }

    }

    checkAgency() {
        return !this.authUser.agency || !this.authUser.agency.verified || this.isOptionsDisabled ? true : null;
    }


    getAccountTypeChangeSubscription() {
        return this.propertyForm.controls.account_type.valueChanges.subscribe(
            val => {

                setTimeout(
                    () => {
                        if (val === 'agent' && this.checkAgency()) {
                            return;
                        }

                        if (val !== this.account_type) {
                            this.submitted = false;
                            this.account_type = val;
                        }

                    }, 500
                );
            }
        );
    }

    getManagerTypeSubscription() {
        return this.propertyForm.controls.manager_type.valueChanges.subscribe(
            val => {
                this.submitted = false;
                this.manager_type = val;
            }
        );
    }

    next() {
        return this.productService.getNextStep();
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    doSubmit() {

        this.submitted = true;

        if (this.propertyForm.invalid) {
            return;
        }

        if (!this.isFormValid()) {
            if (this.account_type === 'owner' && this.manager_type === 'agent') {
                this.toastr.error('Vous devez ajouter l\'agent de ce bien');
            } else if (this.account_type === 'agent') {
                this.toastr.error('Vous devez ajouter le propriétaire de ce bien');
            } else if (this.contract) {
                this.toastr.error('Vous devez signer le contract avant de continuer');
            }
            return;
        }

        if (this.account_type === 'agent' || (this.account_type === 'owner' && this.manager_type === 'agent')) {
            this.router.navigate([this.next()]);

        } else if (this.account_type === 'tenant' || (this.account_type === 'owner' && this.manager_type === 'owner')) {
            this.loading = true;
            const formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('user_role', this.account_type);

            this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
                (product: any) => {
                    this.product = product;
                    this.loading = true;
                    this.router.navigate([this.next()]);
                },
                () =>  {
                    this.loading = false;
                }
            );

        }
    }

    initForms() {
        this.propertyForm  = this.fb.group({
            account_type: ['', [Validators.required]],
            manager_type: [''],
            is_options_disabled: ['']
        });
    }

    isFormValid() {

        if (this.contract) {
            return this.contract.signed;
        }

        if (this.account_type === 'owner' && this.manager_type === 'agent') {
            return this.selectedUser && this.selectedUser.userType === 'agent' && this.selectedUser.status === 'accepted';
        }

        if (this.account_type === 'agent') {
            return this.selectedUser && this.selectedUser.userType === 'owner' && this.selectedUser.status === 'accepted';
        }

        return true;
    }

    get f() {
        return this.propertyForm.controls;
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
