import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-schedule-room',
    templateUrl: './schedule-room.component.html',
    styleUrls: ['./schedule-room.component.css']
})
export class ScheduleRoomComponent implements OnInit {

    constructor() {}

    ngOnInit() {}



}
