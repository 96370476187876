import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SocialUser, User} from '../../models/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {AccountService} from '../../services/auth/account.service';
import {SocialLoginService} from '../../services/auth/social-login.service';
import {AuthService} from '../../services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user/user.service';
import {ResponseMessage} from '../../models/response-message.model';
import {SocialAccountUser} from '../../models/social-account-user.model';

declare var $: any;
@Component({
    selector: 'app-auth-shapart',
    templateUrl: './auth-shapart.component.html',
    styleUrls: ['./auth-shapart.component.css']
})
export class AuthShapartComponent implements OnInit, AfterViewInit {
    @Input() pageTitle: string;
    @Input() action: string;

    socialRegisterForm: FormGroup;
    formErrors;

    response;
    socialUser = new SocialUser();
    socialLoggingIn = false;
    registerMethod;

    error: any;
    errors: any;
    loading = false;

    signUpByEmail = false;
    redirectTo = '/';
    socialErrorMessage = 'Désolé une erreur s\'est produite';

    constructor(private toastr: ToastrService,
                private router: Router,
                private route: ActivatedRoute,
                private title: Title,
                private authService: AuthService,
                private accountService: AccountService,
                private userService: UserService,
                private socialAccountService: SocialLoginService,
                private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.redirectTo = this.route.snapshot.queryParams.redirect_to || '/';
        this.registerMethod = this.route.snapshot.queryParams.register_method;

        if (this.registerMethod && this.registerMethod === 'email') {

            this.signUpByEmail = true;
        }

        this.title.setTitle(this.pageTitle);
        this.initForm();
    }

    initForm() {

        this.socialRegisterForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            accept_terms: [false, [Validators.requiredTrue]]
        });

    }

    showEmailSignUpForm() {
        this.signUpByEmail = true;
        this.registerMethod = 'email';
    }

/*
    socialSignIn(socialProvider: string) {

        let socialPlatformProvider;

        if (socialProvider === 'facebook') {

            socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;

        } else if (socialProvider === 'google') {

            socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        }

        this.OAuth.signIn(socialPlatformProvider).then(
            socialUser => {

                if (socialUser) {
                    this.socialLoggingIn = true;

                    this.socialUser = socialUser;

                    this.saveResponse(socialUser, socialPlatformProvider);
                }

            },

            error => {
                this.socialLoggingIn = false;

                this.toastr.error(this.socialErrorMessage);
                console.log(error);

            }
        );
    }
*/

    saveResponse(socialUser: SocialUser, provider) {

        this.socialAccountService.saveResponse(socialUser, provider).then(
            (res: ResponseMessage) => {

                const data: SocialAccountUser = res.data;

                if (data.status === 'account.register') {

                    this.doSocialRegister(res.data);

                }

                if (data.status === 'account.login') {

                    this.authService.storeValidToken(data.token, 'password');

                    this.userService.getUserInformations().then(
                        (userData) => {
                            this.router.navigateByUrl(this.redirectTo).then(

                                () => {
                                    this.toastr.success('Vous êtes connecté avec votre compte ' + provider);
                                }
                            );

                        },
                        (error) => {
                            this.socialLoggingIn = false;

                            this.toastr.error(this.socialErrorMessage);

                            console.log(error);
                        }
                    );
                }

            }
        );

    }

    showLoginForm() {

        this.socialLoggingIn = false;

        this.socialUser = null;
    }

    showRegisterForm() {

        this.socialLoggingIn = false;
        this.socialUser = null;

    }

    doSocialRegister(data: SocialAccountUser) {

        if (this.socialUser) {
            const formData = new FormData();

            const password = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            formData.append('name', data.user.name);
            formData.append('lastname', data.user.surname);
            formData.append('email', data.user.email);
            formData.append('photo', this.socialUser.image);
            formData.append('password', password);
            formData.append('redirect_uri', window.location.origin);
            formData.append('social_register', 'true');
            formData.append('provider_id', this.socialUser.id);
            formData.append('provider', this.socialUser.provider);
            formData.append('register_type', 'client');
            formData.append('has_professional_info', 'false');

            this.userService.createUser(formData).then(

                () => {

                    this.accountService.login(data.user.email, password).then(
                        () => {

                            this.router.navigateByUrl(this.redirectTo);
                        },
                        (err) => {
                            this.socialLoggingIn = false;

                            this.toastr.error(this.socialErrorMessage);
                            console.log(err);

                        }
                    );

                }, err => {

                    this.toastr.error(this.socialErrorMessage);

                    this.socialLoggingIn = false;

                    console.log(err);

                }
            );
        }
    }

    ngAfterViewInit() {

        $('body').attr('id', 'login_bg');

    }

}
