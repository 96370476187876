import { Component, OnInit } from '@angular/core';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { Title } from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import { Search } from 'src/app/models/search';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from 'src/app/services/search/search.service';
@Component({
  selector: 'app-result-search-without-map',
  templateUrl: './result-search-without-map.component.html',
  styleUrls: ['./result-search-without-map.component.css']
})
export class ResultSearchWithoutMapComponent implements OnInit {
  result_search = "header map_view menu_fixed";
  categoriesSubscription: Subscription;
  categories: any;
  searchs: Search;
  results: any;
  constructor(private categoriesService: CategoriesService,
    private title: Title,
    private route: ActivatedRoute,
    private serviceSearch: SearchService)
   { 
    this.searchs={} as Search;
   }

 
  ngOnInit() {
    this.route.queryParamMap
    .subscribe(params => {
      this.searchs.all=params.get('all');
      this.resultSearch(this.searchs);
    });
    this.title.setTitle('Shapart');
    this.categoriesSubscription = this.categoriesService.forwardedProductByCategoriesSubject.subscribe(
        (categories: any) => {
            this.categories = categories;
            console.log(this.categories);
        }
    );
    this.categoriesService.getForwardedProductByCategories();
    this.categoriesService.emitForwardedProductByCategories();
}


resultSearch(data) {

  this.serviceSearch.searchProduct(data).then(data => {

    this.results = data;
    console.log('resultat de la recherche', this.results);
  }, err => {
   
    console.log('erreur resultat de la recherche', err);
  });
}

}
