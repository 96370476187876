import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
@Component({
  selector: 'app-products-agent',
  templateUrl: './products-agent.component.html',
  styleUrls: ['./products-agent.component.css']
})
export class ProductsAgentComponent implements OnInit {
  breadcrumb=breadcrumb.products_agent;
  constructor() { }

  ngOnInit() {
  }

}
