import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import MapOptions = google.maps.MapOptions;
import {Subscription} from 'rxjs';
import {SearchService} from '../../../services/search/search.service';
import {SearchModel} from '../../../models/search';
import {ApiResponse} from '../../../models/response.model';
import {AppConfig} from '../../../models/app.model';
import {AppService} from '../../../services/app/app.service';
import {Marker} from '../../../models/product.model';
import {MapsAPILoader} from '@agm/core';
declare var MarkerClusterer: any;
declare var InfoBox: any;
declare var $: any;


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
    markers = [];

    markersData = {
        Marker: []
    };

    mapOptions: MapOptions;

    loading = true;
    data: ApiResponse;
    searchs: any = {};

    searchSubscription: Subscription;
    appConfigSubscription: Subscription;
    currency: string;
    currentPage: any = 1;
    perPage: number;
    private mapObject: google.maps.Map<HTMLElement | null>;
    private mapData: ApiResponse;
    private mapLoading: boolean;

    constructor(private searchService: SearchService,
                private appService: AppService,
                private mapsAPILoader: MapsAPILoader) {
        this.perPage = this.searchService.perPage;

    }

    ngOnInit() {
        this.mapsAPILoader.load().then(
            () => {
                this.mapOptions = {
                    zoom: 13,
                    // center: new google.maps.LatLng(48.865633, 2.321236),
                    mapTypeId: google.maps.MapTypeId.TERRAIN,

                    mapTypeControl: false,
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                        position: google.maps.ControlPosition.LEFT_CENTER
                    },
                    panControl: false,
                    panControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT
                    },
                    zoomControl: true,
                    fullscreenControl: true,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.LARGE,
                        position: google.maps.ControlPosition.RIGHT_BOTTOM
                    },
                    fullscreenControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM
                    },
                    scrollwheel: false,
                    scaleControl: false,
                    scaleControlOptions: {
                        position: google.maps.ControlPosition.TOP_LEFT
                    },
                    streetViewControl: false,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    styles: [
                        {
                            "featureType": "administrative.country",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.province",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.locality",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.neighborhood",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.landcover",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural.terrain",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.attraction",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.government",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.medical",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.place_of_worship",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.school",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.sports_complex",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station.airport",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station.bus",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station.rail",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        }
                    ]
                } as MapOptions;
                this.createScripts();

                this.initMap();
            }
        );
    }

    getDetailQueryParams() {
        const queryParams = this.searchs;
        delete queryParams.page;
        delete queryParams.per_page;
        return queryParams;
    }

    getDetailsQueryParamsAsString(): string {

        let queryParamsString = '?';
        const queryParams: any = this.getDetailQueryParams();

        if (queryParams) {
            for (const param in queryParams) {
                if (queryParams.hasOwnProperty(param)) {
                    queryParamsString += param + '=' + queryParams[param] + '&' ;
                }
            }
        }

        return queryParamsString.slice(0, -1);
    }

    transformCurrency(room) {
        return this.appService.transformCurrency(
            room.dailly_price,
            room.currency,
            null,
            true,
            room.price_unit
        );
    }

    transformCalculatedCurrency(room) {
        return this.appService.transformCurrency(
            room.pricing.total,
            room.pricing.currency,
            this.appService.appConfig.currency,
            false,
        );
    }

    initMap() {
        this.mapObject = new google.maps.Map(document.getElementById('map'));
    }

    checkMapPosition(room) {
        return !this.mapLoading && typeof room.mapPosition !== 'undefined';
    }


    getMarkersData() {
        const markers: Marker[] = [];

        if (this.mapData && this.mapData.total > 0) {
            this.mapData.data.forEach(
                room => {
                    const markerRoom: Marker = {
                        type_point: room.address,
                        name: room.name,
                        location_latitude: room.latitude,
                        location_longitude: room.longitude,
                        map_image_url: room.front_image,
                        rate: 'Superb | 7.5',
                        name_point: room.adress,
                        get_directions_start_address: '',
                        url_point: '/detail-products/' + room.id + this.getDetailsQueryParamsAsString(),
                        daily_price: this.transformCurrency(room),
                        dailly_price: this.appService.transformCurrency(room.dailly_price, room.currency),
                        total: room.pricing ? this.transformCalculatedCurrency(room) : null,
                        currency: room.currency
                    };

                    markers.push(markerRoom);
                }
            );
        }
        return markers;

    }

    setMarkers() {
        if (this.searchs.latitude && this.searchs.longitude && this.searchs.address) {

            this.mapOptions.center = new google.maps.LatLng(parseFloat(this.searchs.latitude), parseFloat(this.searchs.longitude));
            this.mapObject.setOptions(this.mapOptions);

            let marker;
            const markers = [];
            const mapObject = this.mapObject;
            this.markersData.Marker = this.getMarkersData();

            for (const key in this.markersData) {
                this.markersData[key].forEach( (item) => {
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(item.location_latitude, item.location_longitude),
                        map: mapObject,
                        label: item.dailly_price
                    });

                    if ('undefined' === typeof markers[key])
                        markers[key] = [];
                    markers[key].push(marker);
                    google.maps.event.addListener(marker, 'click', ( function() {
                        closeInfoBox();
                        getInfoBox(item).open(mapObject, this);
                        mapObject.setCenter(new google.maps.LatLng(parseFloat(item.location_latitude),
                            parseFloat(item.location_longitude)));
                    }));

                });

                new MarkerClusterer(mapObject, markers[key]);

            }

            this.hideAllMarkers();

            this.markers = markers;

        }


        function closeInfoBox() {
            $('div.infoBox').remove();
        }

        function getInfoBox(item: Marker) {

            let content = '<span class="price_marker"><strong>' + item.daily_price + '</strong></span>';

            if (item.total) {
                content += '<span class="price_marker"><strong>' + item.total + '</strong> au total</span>';
            }
            return new InfoBox({
                content:
                '<div class="marker_info" id="marker_info">' +
                '<img width="240" height="140" src="' + item.map_image_url + '" alt=""/>' +
                '<span class="marker_info_span">' +
                '<span class="infobox_rate">' + item.rate + '</span>' +
                '<h3>' + item.name + '</h3>' +
                '<em>' + item.type_point + '</em>' +
                content +
                '<a target="_blank" href="' + item.url_point + '" class="btn_infobox_detail"></a>' +
                '</span>' +
                '</div>',
                disableAutoPan: false,
                maxWidth: 0,
                pixelOffset: new google.maps.Size(10, 92),
                closeBoxMargin: '',
                closeBoxURL: 'assets/img/close_infobox.png',
                isHidden: false,
                alignBottom: true,
                pane: 'floatPane',
                enableEventPropagation: true
            });
        }
    }

    ngAfterViewInit() {
        // form search

        $('a.search_map').on('click', function(e) {
            e.preventDefault();
            $('.search_map_wp').slideToggle('fast');
        });


        this.searchSubscription = this.searchService.searchSubject.subscribe(
            (searchModel: SearchModel) => {

                this.loading = searchModel.loading;
                this.mapLoading = searchModel.loadingMap;

                this.data = searchModel.data;
                this.mapData = searchModel.mapData;

                if (this.mapObject && this.searchs) {
                    this.setMarkers();
                }

                this.appConfigSubscription = this.appService.appConfigSubject.subscribe(
                    (config: AppConfig) => {
                        if (config) {
                            const currentCurrency = this.currency;
                            this.currency = config.currency;

                            if (config.currency !== currentCurrency && this.markersData.Marker.length > 0) {
                                this.setMarkers();
                            }

                        }
                    }
                );

                this.appService.emitAppConfig();
            }
        );

        this.searchService.emitSearchModel();


    }

    hideAllMarkers() {
        $('div.infoBox').remove();
        for (const key in this.markers) {
            this.markers[key].forEach(function (marker) {
                marker.setMap(null);
            });
        }
    }

    onHtmlClick(event, key) {
        event.preventDefault();
        google.maps.event.trigger(this.markers["Marker"][key], 'click');
    }

    createScripts() {

        const markerclusterer = document.createElement('script');
        markerclusterer.src = 'assets/site/template/html/js/markerclusterer.js';
        document.body.append(markerclusterer);

        const infobox = document.createElement('script');
        infobox.src = 'assets/site/template/html/js/infobox.js';
        document.body.append(infobox);


    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }

        if (this.appConfigSubscription) {
            this.appConfigSubscription.unsubscribe();
        }
    }

}


