import {Injectable, NgZone} from '@angular/core';
import {GeocoderResult, GeocoderStatus, MapsAPILoader} from '@agm/core';
import {Subject} from 'rxjs';
import {MapCord} from '../../models/app.model';

@Injectable({
    providedIn: 'root'
})

export class AgmService {
    geoCoder: google.maps.Geocoder;
    cord = new MapCord();
    mapCordSubject = new Subject<MapCord>();

    constructor(private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone) { }

    emitMapCord() {
        this.mapCordSubject.next(this.cord);
    }

    placeAutoComplete(searchElementRef: any, searchControl: string = null) {

        this.mapsAPILoader.load().then(() => {
            const autoComplete = new google.maps.places.Autocomplete(searchElementRef, {
                types: ['geocode', 'establishment']
            });
            autoComplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = autoComplete.getPlace();

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    this.fillLMapCords(place, searchControl);

                });
            });
        });
    }

    setCurrentPosition(searchControl: string = null) {

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.geoCoder = new google.maps.Geocoder;
                // new google.maps.
                this.geoCoder.geocode({ 'location': { lat: position.coords.latitude, lng: position.coords.longitude } },
                    (results, status) => {
                        this.cord.status = status;
                        // console.log(status);
                        if (status === 'OK') {
                            // console.log('resultat', results[0]);
                            if (results[0]) {
                                this.fillLMapCords(results[0] as GeocoderResult, searchControl);
                            } else {
                                window.alert('No results found');
                            }
                        } else {
                            window.alert('Geocoder failed due to: ' + status);
                        }
                    });
            });

        }
    }

    fillLMapCords(place: google.maps.places.PlaceResult | GeocoderResult, searchControl: string = null) {

        if (place.address_components) {
            this.cord = new MapCord();
            this.cord.isLoading = true;
            this.cord.searchControl = searchControl;
            this.cord.adress = place.formatted_address;
            this.cord.longitude = place.geometry.location.lng();
            this.cord.latitude = place.geometry.location.lat();

            // get town
            for (let i = 0; i < place.address_components.length; i++) {
                for (let b = 0; b < place.address_components[i].types.length; b++) {
                    if (place.address_components[i].types[b] == 'locality') {
                        this.cord.city = place.address_components[i].long_name;
                    }
                }
            }
            // get country
            for (let i = 0; i < place.address_components.length; i++) {
                for (let b = 0; b < place.address_components[i].types.length; b++) {
                    if (place.address_components[i].types[b] == 'country') {
                        this.cord.country = place.address_components[i].short_name;
                    }
                }
            }

            // get postal_code
            for (let i = 0; i < place.address_components.length; i++) {
                for (let b = 0; b < place.address_components[i].types.length; b++) {
                    if (place.address_components[i].types[b] == 'postal_code') {
                        this.cord.postalCode = place.address_components[i].short_name;
                    }
                }
            }

            this.cord.status = GeocoderStatus.OK;
            this.cord.isLoading = false;
            this.emitMapCord();

        }
    }




}
