import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import * as filter from '../../json/filter/filter.json';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable.agencies.json';
import {IPagination} from '../../model/pagination';
@Component({
  selector: 'app-list-agencies',
  templateUrl: './list-agencies.component.html',
  styleUrls: ['./list-agencies.component.css']
})
export class ListAgenciesComponent implements OnInit {
  data:any;
  breadcrumb=breadcrumb.agencies;
  response:any;
  loaded:boolean;
  data_json=datatable.agencies;
  filter=filter.agencies;
  queryParams: any = {
    per_page: 15,
    page: 1
};
  adminId: any;
  paramsPage: IPagination;

  constructor(public adminService:AdminService, private route: ActivatedRoute) { 
    this.paramsPage = {} as IPagination;
  }


  ngOnInit() {
   
    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
          
     this.getParams();
    });
   }
  getParams() {
    this.route.queryParams.subscribe(
      params => {
        this.loaded=false;
        if (params.page) {     
            this.queryParams.page = params.page;
        }
        if(params){
           this.getAllAgencies(this.adminId,location.search);
        }else{
        
          this.getAllAgencies(this.adminId);
        }
      });
  }

  private getAllAgencies(id,status?:string):void{
    this.adminService.getAllAgencies(id,status).then((response:any)=>{
      this.response = response.data;
        this.data=response.data ? response.data.data : [];
        this.paramsPage.currentPage = response.data? response.data.current_page:0;
        this.paramsPage.totalItems =  response.data?response.data.total:0;
        this.paramsPage.currentPage =  response.data?response.data.current_page:0;
        this.paramsPage.itemsPerPage = response.data? response.data.per_page:0;
        this.paramsPage.id = "server";
      this.loaded=true;
    },err=>{
      console.log('erreur ',err);
    
    })
  }


  getPage(page) {

    this.getAllAgencies(this.adminId, "?page=" + page);
  }

 

}
