import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.css']
})
export class ProductDocumentsComponent implements OnInit {
  @Input() documents :any;
  url: any;
  type: any;
  url_pdf: any;

  data: any;
  url_doc: any;
  img: any;
  type_doc=[];
  constructor(  private modalService: NgbModal,
                private sanitizer: DomSanitizer) { }

  ngOnInit() {
  //  console.log('documents',this.documents);
   if( this.documents.find(element=> element.title=="property_title")){
    this.type_doc['property_title']= {title:'Titre de propriéte', type:"tp",value:this.documents.find(element=> element.title=="property_title")} ;
   }
   if(this.documents.find(element=> element.title=="user_identity_image_verso")){
    this.type_doc['user_identity_image_verso']={title:'Verso', type:"ji",value: this.documents.find(element=> element.title=="user_identity_image_verso")} ;
   }

   if( this.documents.find(element=> element.title=="user_identity_image_recto")){
    this.type_doc['user_identity_image_recto']={title:'Recto', type:"ji",value: this.documents.find(element=> element.title=="user_identity_image_recto")} ;
   }
   if(this.documents.find(element=> element.title=="gas_receipt")){
    this.type_doc['gas_receipt']= {title:'facture de gaz', type:"ja",value: this.documents.find(element=> element.title=="gas_receipt")} ;
   }

   if(this.documents.find(element=> element.title=="user_passport_image")){
    this.type_doc['user_passport_image']= {title:'Passport', type:"ji",value: this.documents.find(element=> element.title=="user_passport_image")} ;
   }
   if(this.documents.find(element=> element.title=="electricity_receipt"))
   this.type_doc['electricity_receipt']= {title:'facture d\'éléctricité', type:"ja",value: this.documents.find(element=> element.title=="electricity_receipt")} ;
   
  
   if(this.documents.find(element=> element.title=="user_snapped_photo"))
   this.type_doc['user_snapped_photo']= {title:'Photo instantanée', type:"pi",value: this.documents.find(element=> element.title=="user_snapped_photo")} ;

   console.log('doc',this.documents);
   
  }

  // openDoc(e,content_doc){
  //   this.type= this.getExtensionFile(e.value.url);
  //   this.data=e;
  //   if(this.type=='doc'|| this.type=='docx'){
  //     let  urls="https://docs.google.com/gview?url="+e.value.url+"&embedded=true";
  //     this.url_doc=this.sanitizer.bypassSecurityTrustResourceUrl(urls);
  //     this.img=null;
  //     this.url_pdf=null;
  //   } else if(this.type=="pdf"){
  //     this.url_pdf=this.sanitizer.bypassSecurityTrustResourceUrl(e.value.url);
  //     this.img=null;
  //     this.url_doc=null;
  //   }else if(this.type=="png" ||this.type=="pjg" || this.type=="jpeg" ){
  //       this.img=e.value.url;
  //       this.url_pdf=null;
  //       this.url_doc=null;
  //   }
  //   this.modalService.open(content_doc,{centered:true,size:'xl'});
   
   
    
  // }


  // getExtensionFile(file:string){
  //   var basename = file.split(/[\\/]/).pop(),  // extract file name from full path ...                                     // (supports `\\` and `/` separators)
  //       pos = basename.lastIndexOf('.');       // get last position of `.`
  //   if (basename === '' || pos < 1)            // if file name is empty or ...
  //       return "";                             //  `.` not found (-1) or comes first (0)
  //   return basename.slice(pos + 1);  

  // }
}
