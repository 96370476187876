import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-contract-room-model',
    templateUrl: './contract-room-model.component.html',
    styleUrls: ['./contract-room-model.component.css']
})
export class ContractRoomModelComponent implements OnInit {

    @Input() product: any;
    @Input() owner: any;
    @Input() agent: any;

    constructor() { }

    ngOnInit() {
    }

}
