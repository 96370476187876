import { Component, OnInit } from '@angular/core';
declare var $ :any;
@Component({
  selector: 'app-admin-template',
  templateUrl: './admin-template.component.html',
  styleUrls: ['./admin-template.component.css']
})
export class AdminTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    import('src/assets/site/template/html/admin_section/vendor/bootstrap/js/bootstrap.bundle.min.js');
    import('src/assets/site/template/html/admin_section/js/admin.js');
    // import('src/assets/js/jquery.selectbox-0.2.js'); 
   
   
  }
}
