import { Injectable, OnDestroy } from '@angular/core';
import {environment} from '../../../environments/environment'
import { Observable, Subject, Subscription } from 'rxjs';
import  Mail  from '../class/mail';
import { AdminAccountService } from './admin.account.service';
import { HttpAdminService } from './http-admin.service';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AdminService implements OnDestroy{
 
  infoSubscription: Subscription;
  currentAdmin: any;
  nameApp=environment.appName;
  private subject = new Subject<any>();
  admin: any;
  constructor(private httpAdminService: HttpAdminService,private adminAccountService:AdminAccountService
    ,private title:Title ) {}

    sendBreadcrumb(data) {
      this.subject.next(data);
    }

    setTitle(title){
      this.title.setTitle(title+'/'+environment.appName);
    }
    
    
    
    getBreadcrumb(): Observable<any> {
      return this.subject.asObservable();
    }
    public get $admin() {
      return new Promise((resolve,reject)=>{
       this.infoSubscription = this.adminAccountService.getInfo().subscribe(
         (info:any)=>{
           if(info){
             this.admin=info;
             resolve( info);
           } 
         },err=>{
           reject(err);
         });
      })
     }

  ngOnDestroy(): void {
    this.infoSubscription.unsubscribe();
  }
  private actionBack:boolean;
// a supprimer
  public getActionBack(){
    return this.actionBack;
  }
  public setActionBack(action:boolean){
    this.actionBack=action;
  }


getAllAdmins(params?) {
  if(params){
    return this.httpAdminService.get('admins'+params);
  }else{
    return this.httpAdminService.get('admins');
  }
 
}
saveNewAdmin(admin){
  return this.httpAdminService.post('admins',admin);
}
getOneAdmin(id){
  return this.httpAdminService.get('admins/'+id);
}
updateAdmin(data,id){
  return this.httpAdminService.post('admins/'+id,data);
}
updateRoleAdmin(data,id){
  return this.httpAdminService.post('admins/'+id+'/roles',data);
}

//------------------------payement--------------------------
getAllPayementRefunded(idAdmin, params?) {
  if(params){
    return this.httpAdminService.get('admins/'+idAdmin+'payments?type_payment=refund'+params);
  }else{
    return this.httpAdminService.get('admins/'+idAdmin+'/payments?type_payment=refund');
  }
 
}

getOnePayementRefunded(idAdmin,Idpayment) {
  return this.httpAdminService.get('admins/'+idAdmin+'/payments/'+Idpayment);
 
}

getAllPayementSuccess(idAdmin, params?) {
  if(params){
    return this.httpAdminService.get('admins/'+idAdmin+'payments?type_payment=transfer'+params);
  }else{
    console.log('iiiiii')
    return this.httpAdminService.get('admins/'+idAdmin+'/payments?type_payment=transfer');
  }
 
}

getOnePayementSuccess(idAdmin,Idpayment) {
  return this.httpAdminService.get('admins/'+idAdmin+'/payments/'+Idpayment);
 
}



  // upload image
  public uploadImage(data: FormData) {
    return this.httpAdminService.post('users/upload-image', data);
}

// -----------Routes pour les owners-----------
getAllOwners(AdminId, params?:string){
  if(params){
    return this.httpAdminService.get('admins/'+AdminId+'/owners'+params);
  }else
  return this.httpAdminService.get('admins/'+AdminId+'/owners?sort_by_desc=created_at');
}

getOneOwner(adminId, ownerId) {
  return this.httpAdminService.get('admins/'+adminId+'/owners/'+ownerId);
}

// -----------Routes pour les clients-----------
getAllCustomer(params?:string){
  if(params){
    return this.httpAdminService.get('admins/'+this.admin.id+'/clients'+params);
  }else
  return this.httpAdminService.get('admins/'+this.admin.id+'/clients?sort_by_desc=created_at');
}
getOneCustomer(adminId, clientId) {
  return this.httpAdminService.get('admins/'+adminId+'/clients/'+clientId);
}

putOneCustomer(adminId, clientId,data) {
  return this.httpAdminService.post('admins/'+adminId+'/clients/'+clientId,data);
}

// -----------Routes pour les travelers-----------

getAllTravelers(AdminId){
  return this.httpAdminService.get('admins/'+AdminId+'/travelers');
}

getOneTraveler(adminId, clientId) {
  return this.httpAdminService.get('admins/'+adminId+'/travelers/'+clientId);
}


// -----------Routes pour les landlord-----------

getAllLandlords(AdminId,params?){
 if(params){
  return this.httpAdminService.get('admins/'+AdminId+'/datas'+params);
 }else{
  return this.httpAdminService.get('admins/'+AdminId+'/datas');
 }
}


// -----------Routes pour les companies-----------

getAllCompanies(AdminId){
  return this.httpAdminService.get('admins/'+AdminId+'/companies?sort_by_desc=total_reservations');
}



getFilterCustomers(filter){
  return this.httpAdminService.post('admins/filter-clients',filter);
}


// ---------------Routes pour les products--------

getAllProducts(params?:string){
  if(params){
    return this.httpAdminService.get("admins/"+this.admin.id+"/products"+params);
  }else
  return this.httpAdminService.get('admins/'+this.admin.id+'/products?sort_by_desc=created_at&published=1');
}


getOneProduct(adminId,productId){
  return this.httpAdminService.get('admins/'+adminId+"/products/"+productId);
}
updateProductAdmin(adminId,productId,data){
  return this.httpAdminService.post('admins/'+adminId+'/products/'+productId,data);
}

getFilterProducts(filter){
  return this.httpAdminService.post('admins/filter-products',filter);
}


// ---------------Routes pour les claims--------

getOneClaim(claimId){
  return this.httpAdminService.get('admins/'+this.admin.id+"/claims/"+claimId);
}

getAllClaims(){
  return this.httpAdminService.get('admins/'+this.admin.id+'/claims?sort_by_desc=created_at');
}

postClaims(data){
  return this.httpAdminService.post('admins/'+this.admin.id+'/claims',data);
}

// routes pour gerer les roles
getAllRoles(params?){
  if(params){
    return this.httpAdminService.get('roles'+params);
  }else{
    return this.httpAdminService.get('roles');
  }
 
}
addRole(data){
  return this.httpAdminService.post('roles',data);
}
addRoleOperations(data){
  return this.httpAdminService.post('roles/'+data.role_id+'/operations',data);
}
addRoleFunctionalities(data){
  return this.httpAdminService.post('roles/'+data.role_id+'/functionalities',data);
}
getRole(id){
 return  this.httpAdminService.get('roles/'+id);
}

// routes pour gerer les operation
getAllOperations(){
  return this.httpAdminService.get('operations');
}




// ---------------Routes pour les agents--------

getAllAgents(params?:string){
  if(params){
    return this.httpAdminService.get('agents'+params);
  }else return this.httpAdminService.get('agents');
 
}

getOneAgent(id){
  return this.httpAdminService.get('admins/'+this.admin.id+'/agents/'+id);
}



// --------------------------Envoyer un mail--------------------

sendMail(mail:Mail){
  console.log('Email',mail);

   return this.httpAdminService.post('url',mail);
}












//--------------------------booking-----------------------
getAllReservations(adminId,params?:string){
  if(params){
    return this.httpAdminService.get("admins/"+adminId+"/reservations"+params);
  }else
  return this.httpAdminService.get("admins/"+adminId+"/reservations");
}

getReservation(adminId,paymentId){
  return this.httpAdminService.get('admins/'+adminId+'/reservations/'+paymentId);
}




//--------------------------agencies-----------------------
getAllAgencies(adminId,params?:string){
  if(params){
    return this.httpAdminService.get("admins/"+adminId+"/agencies"+params);
  }else
  return this.httpAdminService.get('admins/'+adminId+'/agencies?sort_by_desc=created_at');
}

getAgency(adminId,agencyId){
  return this.httpAdminService.get('admins/'+adminId+'/agencies/'+agencyId);
}

getAllContractAgency(adminId,agencyId){
  return this.httpAdminService.get("admins/"+adminId+"/agencies/"+agencyId+"/contracts");
}

getAllReservationsAgency(adminId, agentId){
  return this.httpAdminService.get("admins/"+adminId+"/agents/"+agentId+"/reservations");
}

getAllDemandsAgency(adminId, agentId){
  return this.httpAdminService.get("admins/"+adminId+"/agents/"+agentId+"/demands");
}

updateAgencyAdmin(adminId,agencyId,data){
  return this.httpAdminService.post('admins/'+adminId+'/agencies/'+agencyId,data);
}
activedDeactived(data){
   return this.httpAdminService.post(data.url,{id:data.id});
}
// -------------------------------demande ---------------------------------














//--------------------------payment-----------------------
getAllPayments(adminId,params?:string){
  if(params){
    return this.httpAdminService.get("admins/"+adminId+"/payments"+params);
  }else
  return this.httpAdminService.get('admins/'+adminId+'/payments?sort_by_desc=created_at');
}


getPayment(adminId,paymentId){
  return this.httpAdminService.get('admins/'+adminId+'/payments/'+paymentId);
}



//--------------------------Request-----------------------
getAllRequests(params?:string){
  if(params){
    return this.httpAdminService.get("admins/"+this.admin.id+"/demands"+params);
  }else
  return this.httpAdminService.get('admins/'+this.admin.id+'/demands?sort_by_desc=created_at');
}



getOneRequest(adminId,id){
  return this.httpAdminService.get('admins/'+adminId+'/demands/'+id);
}
  


//--------------------------Settings----------------------- supprimer
getAllSettings(adminId){
  return this.httpAdminService.get("admins/"+adminId+"/settings");
}

getOneSetting(adminId,name){
  return this.httpAdminService.get("admins/"+adminId+"/settings/"+name);
}

updateSetting(adminId,id,data){
  return this.httpAdminService.post("admins/"+adminId+"/settings/"+id,data);
}


getAllBookings(adminId){
  return this.httpAdminService.get("admins/"+adminId+"/bookings");
}


getDashboard(){

  return this.httpAdminService.get("admin/dashboard");
}

//--------------------------classement------------------------------
getRankingProduct(params?){
  if(params){
    return this.httpAdminService.get('admin/ranking-product'+params);
  }else{
    return this.httpAdminService.get('admin/ranking-product');
  }
 
}

getAllclient(){
  return this.httpAdminService.get('admin/allclients');

}


//--------------------------banks------------------------------ supprimer
getBank(){
  return this.httpAdminService.get('admin/banks');
}


getUserSettings(userId) {
  return this.httpAdminService.get('users/' + userId + '/settings');
}


// statistic


getAllStatistics(adminId){
  return this.httpAdminService.get("admins/"+adminId+"/statistics");
}

// categories


getAllCategories(adminId){
  return this.httpAdminService.get("admins/"+adminId+"/categories");
}


// visitors


getAllVisitisors(page?){
  if(page){
    return this.httpAdminService.get("admins/"+this.admin.id+"/visitors"+page);
  }else{
    return this.httpAdminService.get("admins/"+this.admin.id+"/visitors?sort_by_desc=created_at");
  }
  
}
}

