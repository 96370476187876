import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../../services/auth/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ProductsService} from '../../../../services/products/products.service';
import {FormStep} from '../../../../models/product.model';
import {AppService} from '../../../../services/app/app.service';

@Component({
    selector: 'app-room-edit-preview',
    templateUrl: './room-edit-preview.component.html',
    styleUrls: ['./room-edit-preview.component.css']
})
export class RoomEditPreviewComponent implements OnInit, OnDestroy {

    @Input() mode = 'details';

    product: any;
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    formWizardStepsSubscription: Subscription;
    formWizardSteps: FormStep[];
    productId;

    constructor(private router: Router,
                private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private appService: AppService) { }

    ngOnInit() {

        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;
                }
            }
        );
        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {

                        this.authUser = user;

                        if (!this.userProductSubscription) {
                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;

                                        this.formWizardStepsSubscription = this.productService.formWizardSubject.subscribe(
                                            (formWizardSteps: FormStep[]) => {
                                                this.formWizardSteps = formWizardSteps;

                                            }
                                        );

                                        this.productService.getFormWizardStep();
                                        this.productService.emitFormWizardSteps();

                                    }

                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                (error) => {

                                    console.log(error);
                                    this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                }
                            );
                        }

                    }

                }
            );

            this.authService.emitUser();
        }
    }

    getPrice() {
        return this.appService.transformCurrency(this.product.dailly_price, this.product.currency, null, false, this.product.price_unit);
    }

    getCurrentUrl() {
        return window.location.href.split('?')[0];
    }

    getFullCurrentStepUrl(product) {

        let url: string = null;
        if (this.getCurrentStepUrl(product)) {
            url = window.location.origin + this.router.createUrlTree([this.getCurrentStepUrl(product)]).toString();
        }
        return url;
    }

    isProductCompleted(room) {
        return !room.current_step || room.verified || room.published;
    }

    getCurrentStepUrl(product) {

        if (this.isProductCompleted(product)) {
            return null;
        }
        const step: FormStep = this.formWizardSteps.find(
            (formStep: FormStep) => {
                return formStep.tag === product.current_step;
            }
        );
        return step ? step.url : null;
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }

        if (this.formWizardStepsSubscription) {
            this.formWizardStepsSubscription.unsubscribe();
        }
    }

}
