import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() icon: any;
  @Input() title: any;
  @Input() table: boolean=false;
  @Input() footer: boolean;
  @Input() tag: string;
  @Input() id: number;
  @Input() value: any;
  @Input() menu: any=null;
  @Input() bn: boolean=false;
  @Input() total: number;
  @Input() data:{title:string,value:number,icon:string,color?:string, url?:string};
  constructor() { }

  ngOnInit() {
    // console.log('data',this.data);
  }


  generateId(id){
    let diff=7-id.toString().length;
    let zero="";
    if(diff>0){
    zero=this.generateZero(diff)
    }

    return zero+id;
  }

  generateZero(diff){
    let zero="";
    for (let index = 0; index <diff; index++) {
      zero=zero+"0";
    }
    return zero
  }
}
