import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UserService} from '../../../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {MustMatch} from '../../../../setting/must-match.validator';
import {AuthUser} from '../../../../models/auth-user.model';
import {Title} from '@angular/platform-browser';
import {AppService} from '../../../../services/app/app.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {
    loading: boolean;
    loadingPassword: boolean;
    emailForm: FormGroup;
    passwordForm: FormGroup;
    user;
    userSubscription: Subscription;
    errors: any;
    current_action: string;
    submitted;
    passwordModal: NgbModalRef;
    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private toastr: ToastrService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router,
                private title: Title,
                private appService: AppService,
                private modalService: NgbModal) { }

    ngOnInit() {

        this.appService.setAppTitle('Paramètres du compte');
        this.initForms();

        this.route.queryParamMap.subscribe(
            (params) =>  {
                this.current_action = params.get('section');
            }
        );

        this.userSubscription = this.authService.userSubject.subscribe(
            (user) => {
                this.user = user;
            }
        );

        this.authService.emitUser();

    }

    openPasswordModal(content, event) {
        event.preventDefault();
        this.passwordModal = this.modalService.open(content, { size: 'md', backdrop: 'static'});

    }

    changeEmail() {

        this.submitted = true;

        if (this.emailForm.invalid) {
            return;
        }

        this.loading = true;

        const  formData = new FormData();

        const redirectUri = window.location.origin + this.router.createUrlTree(['/email/verify']).toString();

        formData.append('_method', 'PUT');
        formData.append('email', this.emailForm.value.new_email);
        formData.append('current_password', this.emailForm.value.current_password);
        formData.append('redirect_uri', redirectUri);

        this.userService.editProfile(formData, this.user.id).then(
            (user: AuthUser) => {

                this.user = user;
                this.loading = false;
                this.emailForm.controls.new_email.setValue('');
                this.emailForm.controls.current_password.setValue('');

                this.errors = null;

                this.current_action = '';

                this.toastr.success('Email modifié avec succès', 'Succès!');
                this.submitted = false;


            }, err => {
                this.loading = false;

                this.errors = err.error.error;
                console.log(err);

            });
    }

    changePassword() {
        this.submitted = true;

        if (this.passwordForm.invalid) {
            return;
        }
        this.loading = true;

        const  formData = new FormData();

        formData.append('_method', 'PUT');
        formData.append('old_password', this.passwordForm.value.old_password);
        formData.append('new_password', this.passwordForm.value.new_password);
        formData.append('new_password_confirmation', this.passwordForm.value.new_password_confirmation);

        this.userService.editProfile(formData, this.user.id).then(
            (user: AuthUser) => {

                this.user = user;
                this.loading = false;
                this.passwordForm.controls.old_password.setValue('');
                this.passwordForm.controls.new_password.setValue('');
                this.passwordForm.controls.new_password_confirmation.setValue('');

                this.errors = null;

                this.current_action = '';

                this.toastr.success('Mot de passe modifié avec succès', 'Succès!');
                this.submitted = false;


            }, err => {
                this.loading = false;

                this.errors = err.error.error;
                console.log(err);

            });
    }


    initForms() {

        this.emailForm = this.fb.group({
            new_email: ['', [Validators.required]],
            current_password: ['', [Validators.required]],
        });

        this.passwordForm = this.fb.group({
            old_password: ['', [Validators.required]],
            new_password: ['', [Validators.required]],
            new_password_confirmation: ['', [Validators.required]],
        }, {
            validators: MustMatch('new_password', 'new_password_confirmation')
        });
    }

    get fEmail() {
        return this.emailForm.controls;
    }

    get fPassword() {
        return this.passwordForm.controls;
    }

}
