import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../services/admin.service';
import { FormBuilder } from '@angular/forms';
import { ConfirmationDialogService } from '../../common/notifications/notification.service'; 
@Component({
  selector: 'app-detail-customer',
  templateUrl: './detail-customer.component.html',
  styleUrls: ['./detail-customer.component.css']
})
export class DetailCustomerComponent implements OnInit {
  id: any;
  customer: any;
  breadcrumb: any;
  url: any;
  loaded = false;
  status: any[];

  adminId: any;
  spinner: boolean;
  checkedListMotif: any=[];
  note: any;
  MotifForm: any;
  motif=[];
  staticAlertClosed = false;
  modalReference: NgbModalRef;
  totalReservation:number=0;
  preferences: any;
  update_spinner=false;
  documents=[];
  constructor(private route: ActivatedRoute,
    private adminService: AdminService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    public config: NgbModalConfig,
    private fb: FormBuilder,
    private dialog :ConfirmationDialogService) {
    config.backdrop = 'static';
    config.keyboard = false;
  
  }

  ngOnInit() {
    this.breadcrumb = breadcrumb.detail_customer;
    this.id = this.route.snapshot.params.id;
    this.angForm();
    this.informationAdmin();
  }



  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneCustomer(this.adminId, this.route.snapshot.params.id);
   
    });
   }

  getOneCustomer(adminId, idClient) {
    this.adminService.getOneCustomer(adminId, idClient).then((data: any) => {
      this.customer = data;
      this.adminService.setTitle('information sur le client '+ this.customer.full_name);
      this.getMotif(this.customer.motif_rejected);
     this.getUserSetting(this.customer.id);
     if(this.customer.reservations){
      this.customer.reservations.forEach(element => {
       
        this.totalReservation= this.totalReservation+parseFloat(element.total);
      });
      // console.log('totalReservation', this.totalReservation);
    }
      this.loaded = true;
      this.getSatus(this.customer.status);

    }, err => {
      console.log('erreur', err)
    });
  }




  private getUserSetting(id){
    this.adminService.getUserSettings(id).then((data:any)=>{
        // console.log('bank',data,id)
        if(data.value){
        
          this.preferences=data.value.payment_preferences;
        
        }
       
       
    },err=>{
      console.log('erreur',err);
    });
  }



  statutDoc(date_del, date_ex) {
    let date_d = new Date(date_del).getTime();
    let date_e = new Date(date_ex).getTime();
    if (date_e > date_d) {
      return "expiry";
    } else {
      return 'valid'
    }
  }



  openModalRejectedCustomer(content) {
    this.modalReference =  this.modalService.open(content, { centered: true, size: 'lg', scrollable: true, backdropClass: 'light-blue-backdrop' });
  }



  openDoc(content_doc, url) {
    this.modalService.open(content_doc, { centered: true, size: 'lg' });
    this.url = url;
    // console.log('url', url);
  }

  updateCustomer(type){
    let data={
      type:type,
      _method:"PUT"
  }

  this.adminService.putOneCustomer(this.adminId,this.customer.id,data).then((data:any)=>{
    // console.log('-----------', data);
    this.customer=data;
    this.getSatus(this.customer.status);
    this.getMotif(this.customer.motif_rejected);
    if(type==="Activate"){
      this.toastr.success('Compte activé avec succès', 'Success');
    }else if(type==="Disabled"){
      this.toastr.success('Compte déactivé avec succès', 'Success');
    }else if(type==="Validate"){
      this.toastr.success('Compte validé avec succès', 'Success');
    }
    this.spinner=false;
  },err=>{
    // console.log('Err',err);
  });
  }
  activateOrDisabledOrValidedCustomer(type:string) {
    this.spinner=true;
    let messages = [{
      type: "Activate",
      title: "Activer le client",
      message: 'Voulez vous activer ce client?'
    },
    {
      type: "Disabled",
      title: "Désactiver le client",
      message: 'Voulez vous désactiver ce client?'
    },
    {
      type: "Validate",
      title: "Valider le client",
      message: 'Voulez vous valider ce client?'
    }]
    this.spinner=true;
   
    let m=messages.find(element=> element.type==type);
  
    this.dialog.confirm(m.title,m.message).then(reponse=>{
      if(reponse){
        this.updateCustomer(type);
      }else{
        this.spinner=false;
      }
    });
  }


  getSatus(status) {
    this.status = [];
    switch (status) {
      case "unverified":
        this.status = ['pending', 'En attente'];
        break;
      case "verified":
        this.status = ['approved', ' Vérifié'];
        break;
      case "rejected":
      this.status = ['cancel', ' Rejeté'];
      break;
    }
    // console.log('stats', this.status);
  }

  getMotif(motif){
  if(motif){
    this.motif = motif.split("@");
    this.motif= this.motif.filter(item => item);
    // console.log('ici',this.motif);
  }else{
    this.staticAlertClosed=true;
  }
    
  }


  rejectedCustomer(){
    this.update_spinner=true;
    let adress="";
    let photo="";
    if(this.MotifForm.value.doc_address){
      adress="doc_address_not_found"
    }else{
      adress="";
    }
    if(this.MotifForm.value.photo){
      photo="not_photo";
    }else{
      photo="";
    }
    let data={
      type:"Rejected",
      _method:"PUT",
      note:this.MotifForm.value.note,
      doc_address:adress,
      doc_office:this.MotifForm.value.doc_office,
      photo:photo
  }

  this.adminService.putOneCustomer(this.adminId,this.customer.id,data).then((data:any)=>{
    this.update_spinner=false;
    this.modalReference.close();
    this.toastr.success('Compte rejété avec succès', 'Success');
    this.customer=data;
    this.getSatus(this.customer.status);
    console.log('data',data);
  });
  // console.log('data',data);
 
    
  }
  // get f() {
  //   return this.MotifForm.controls;
  // }

  angForm(){
    this.MotifForm = this.fb.group({
      doc_address: [],
      doc_office: [],
      photo:[],
      note: [],
    });
  }

  resetPassword(){
    this.dialog.confirm('Réinitialiser mot de passe',"Voulez vous réinitialiser le mot de passe du client:"+this.customer.name+"?").then(reponse=>{
      if(reponse){
      
      }else{
        this.spinner=false;
      }
    });
  }
}
