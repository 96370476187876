import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(protected router: Router,
              protected authorizationService: AuthorizationService) { }
canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
  return this.authorizationService.hasPermission(route.data.permission);

}


}
