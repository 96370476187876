import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-footer-shapart-small',
    templateUrl: './footer-shapart-small.component.html',
    styleUrls: ['./footer-shapart-small.component.css']
})
export class FooterShapartSmallComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
