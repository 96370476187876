import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router} from '@angular/router';
import { AdminAccountService } from '../services/admin.account.service';
import { AdminAuthService } from '../services/admin.auth.service';
import {urlPages} from '../settings/env.routing';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  formErrors;
  errors=false;
  constructor(private toastr: ToastrService,
              private router: Router,
              private accountService: AdminAccountService,
              private formBuilder: FormBuilder,
              private authAdminService:AdminAuthService) { }

  ngOnInit() {
    this.authAdminService.logout().then((

    )=>{},err=>{console.log('erreur',err)});
      
    this.initForm();
  }

  initForm() {

    this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.min(6)]],
    });
}


doLogin() {
  if (!this.loginForm.invalid) {
    const email = this.loginForm.get('email').value;
    const password = this.loginForm.get('password').value;
    this.loading = true;
      this.accountService.login(email, password).then(
          (data: any) => {
              this.loading = false;
              //  console.log(data);
              if(data.admin && data.admin_status==1){
               this.accountService.setAdmin(data); // a supprimer
                this.accountService.setInfo(data);
                this.router.navigate([urlPages.pageDashboard]).then(
                  () => {

                    this.toastr.success('Bonjour ' + data.lastname);

                  }
              );
               
              }else{
                localStorage.removeItem('ad_current_token');
              }
            

          },

          (error) => {
              this.loading = false;
              console.error('erreur dans la page login admin',error.error);
              if(error.error.code==401){
                this.errors = true;
              }
             
          }
      );
  } else {

      this.formErrors = this.loginForm.controls;
      return;
  }


}
}
