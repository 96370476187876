import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsService} from '../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../../models/auth-user.model';
import {AppService} from '../../../../services/app/app.service';
import {BookingService} from '../../../../services/bookings/booking.service';

declare var $: any;

@Component({
    selector: 'app-product-booking-sidebar',
    templateUrl: './product-booking-sidebar.component.html',
    styleUrls: ['./product-booking-sidebar.component.css']
})
export class ProductBookingSidebarComponent implements OnInit, AfterViewInit {

    @Input() product: any;
    @Input() authUser: AuthUser;
    @Input() loadingPrice: boolean;
    @Input() queryParams: any;

    @Output() queryParamsChange = new EventEmitter<any>();

    userSubscription: Subscription;
    loading = false;
    submitting: boolean;
    submitted: boolean;
    searchs: any = {};

    constructor(private route: ActivatedRoute,
                private productsService: ProductsService,
                private router: Router,
                private toastr: ToastrService,
                private appService: AppService,
                private bookingService: BookingService) { }

    ngOnInit() {

        this.route.queryParamMap.subscribe(
            params => {
                if (params.get('currency')) {
                    this.searchs.currency = params.get('currency');
                }

                if (params.get('address')) {
                    this.searchs.address = params.get('address');
                }

                if (params.get('category')) {
                    this.searchs.category = params.get('category');
                }

                if (params.get('latitude')) {
                    this.searchs.latitude = params.get('latitude');
                }

                if (params.get('longitude')) {
                    this.searchs.longitude = params.get('longitude');
                }

            }
        );
    }

    transformCurrency(product) {
        return this.appService.transformCurrency(product.dailly_price, this.product.currency, null, true, product.price_unit);
    }

    transformCalculatedCurrency(price) {
        return this.appService.transformCurrency(price, this.product.pricing.currency, this.appService.appConfig.currency, false, null);
    }

    booking() {
        this.submitted = true;
        if (!this.searchs.dateStart) {
            return;
        }
        if (!this.searchs.dateEnd) {
            return;
        }

        this.submitting = true;

        const queryParams: any = this.searchs;
        queryParams.id = this.product.id;

        if (this.authUser && this.authUser.id !== this.product.user_id && this.authUser.id !== this.product.manager.id) {

            queryParams.user_id = this.authUser.id;

            this.bookingService.saveProductSelection(this.product.id, queryParams).then(
                (selection: any) => {
                    this.submitting = false;
                    queryParams.selection = selection.id;
                    delete queryParams.user_id;
                    delete queryParams.latitude;
                    delete queryParams.longitude;
                    delete queryParams.address;

                    this.router.navigate(['booking/new'], {queryParams});
                },

                () => {
                    this.submitting = false;
                }
            );
        } else {
            this.router.navigate(['booking/new'], {queryParams});

        }
    }

    ngAfterViewInit() {

        $('.panel-dropdown a').on('click', function(e) {
            if ($(this).parent().is('.active')) {
                $('.panel-dropdown').removeClass('active');
            } else {
                $('.panel-dropdown').removeClass('active');
                $(this).parent().addClass('active');
            }
            e.preventDefault();
        });
    }

}
