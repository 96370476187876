import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sous-categories',
  templateUrl: './sous-categories.component.html',
  styleUrls: ['./sous-categories.component.css']
})
export class SousCategoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
