import {environment} from '../../../environments/environment'
export const nameRoutes={
    dashboard:"dashboard",
    admin:"admin",
    login:"login",
    profil:"profil",
    company:'companies',
    restaurants:'restaurants',
    customers:'customers',
    types:'types',
    root:'',
    shappart:environment.folder_admin,
    list:"list",
    show:"show",
    id:":id/",
    currency:"currency",
    banks:"banks",
    reduction:"reduction",
    rate:"rate",
    accounting:"accounting",
    add:"add",
    claims:"claims",
    categories:"categories",
    analytics:"analytics"
  
}
export  const  urlPages={
    pageDashboard:'/'+nameRoutes.shappart+'/'+nameRoutes.dashboard,
    pageLogin:'/'+nameRoutes.shappart+'/'+nameRoutes.login,
    pageProfil:'/'+nameRoutes.shappart+'/'+nameRoutes.profil,
    // accounting
    pageCurrency:'/'+nameRoutes.shappart+'/'+nameRoutes.accounting+'/'+nameRoutes.currency,
    pageRate:'/'+nameRoutes.shappart+'/'+nameRoutes.accounting+'/'+nameRoutes.rate,
    pageBanks:'/'+nameRoutes.shappart+'/'+nameRoutes.accounting+'/'+nameRoutes.banks,
    pageReduction:'/'+nameRoutes.shappart+'/'+nameRoutes.accounting+'/'+nameRoutes.reduction,
    pageCurrencyAdd:'/'+nameRoutes.shappart+'/'+nameRoutes.accounting+'/'+nameRoutes.currency+'/'+nameRoutes.add,
    // reclamations
    pageClaims:'/'+nameRoutes.shappart+'/'+nameRoutes.claims,
    //categories
    pageCategories:'/'+nameRoutes.shappart+'/'+nameRoutes.categories+'/'+nameRoutes.list,
     //categories
     pageAnalytics:'/'+nameRoutes.shappart+'/'+nameRoutes.analytics
}

