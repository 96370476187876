import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/admin/services/admin.service';
@Component({
  selector: 'app-email-admin',
  templateUrl: './email-admin.component.html',
  styleUrls: ['./email-admin.component.css']
})
export class EmailAdminComponent implements OnInit {
  angForm: any;
  submitted=false;
  formData:any;
  userSubscription: Subscription;
  authAdmins: any;
  breadcrumb=breadcrumb.email_admin;
  erreur: any;
  constructor(private fb: FormBuilder,
              private serviceAdmin:AdminService,
              private route: ActivatedRoute,
              private toastr: ToastrService) { }
  get f(){
    return this.angForm.controls;
  }
  ngOnInit(){
    let data={
      breadcrumb:this.breadcrumb,
      id:this.route.snapshot.paramMap.get('id'),
      title:"Modifier l'email de l'administrateur",
      data:"email"
    }
    setTimeout(() => {
      this.serviceAdmin.sendBreadcrumb(data);
     }, 1000);

     
    this.initForm();
    // this.authAdmin.getUserInformation().then(data=>{
    // });
  
    }

  initForm() {
    this.angForm = this.fb.group({
      new_email: [null, [Validators.required,Validators.email]],
      password: [null,[Validators.required]],
    });
  }

  UpdateEmail(){
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }

    let formData= new FormData();
    formData.append('new_email',this.angForm.value.new_email);
    formData.append('admin_password',this.angForm.value.password);
    this.serviceAdmin.updateAdmin(formData,this.route.snapshot.paramMap.get('id')).then((data:any)=>{
      this.submitted=false;
      this.angForm.reset();
      this.toastr.success('Modification effectuée','Success');
    },err=>{
        console.log('erreur',err.error.error.admin_password[0]);
        this.erreur=err.error.error.admin_password[0];
    });
  }


 

}
