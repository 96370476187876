import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../../services/auth/auth.service';
import {UserService} from '../../../../services/user/user.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-password-forgotten',
    templateUrl: './password-forgotten.component.html',
    styleUrls: ['./password-forgotten.component.css']
})
export class PasswordForgottenComponent implements OnInit {

    @Input() loading: boolean;
    @Output() loadingChange = new EventEmitter<boolean>();
    user;
    userSubscription: Subscription;
    response;
    error;
    attempts = 0;
    errors;
    maxAttemps = 3;

    constructor(private authService: AuthService,
                private userService: UserService,
                private router: Router,
                private toastr: ToastrService) { }

    ngOnInit() {

        this.loadingChange.subscribe(
            (isLoading: boolean) => {
                this.loading = isLoading;
            }
        );

        this.userSubscription = this.authService.userSubject.subscribe(
            (user) => {
                this.user = user;
            }
        );

        this.authService.emitUser();
    }

    sendResetPasswordLink() {

        this.loadingChange.emit(true);
        this.error = null;
        this.errors = null;

        const redirectUri = window.location.origin + this.router.createUrlTree(['/password/reset']).toString();

        const formData = new FormData();

        formData.append('email', this.user.email);
        formData.append('redirect_uri', redirectUri);
        this.userService.sendResetLinkEmail(formData).then(
            (response: any) => {

                this.loadingChange.emit(false);

                if (response.status === 'passwords.sent') {
                    this.response = response;
                    this.attempts++;
                    this.toastr.success(' Nous vous avons envoyé par email le lien de réinitialisation du mot de passe !');
                    if (this.attempts >= this.maxAttemps) {
                        this.error = 'Nombre de tentatives maximales dépassées';
                    }
                } else if (typeof response.message === 'string') {
                    this.error = response.message;

                } else {
                    this.error = 'Désolé une erreur s\'est produite';
                }
            },
            error => {
                this.error = 'Désolé une erreur s\'est produite';

                this.loadingChange.emit(false);
                this.errors = error.error.error;
            }
        );
    }

}
