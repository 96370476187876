import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as  breadcrumb from '../../json/breadcrumb.json';

import { AdminService } from '../../services/admin.service';

import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-detail-agent',
  templateUrl: './detail-agent.component.html',
  styleUrls: ['./detail-agent.component.css']
})
export class DetailAgentComponent implements OnInit,GetAdmin {

  agent: any;
  breadcrumb=breadcrumb.detail_agent;
  actionBack: boolean;
  loaded: boolean;
  adminId: any;
  constructor(
    private route: ActivatedRoute,
    private serviceAdmin:AdminService,
  ) { }
 

  ngOnInit() {
    this.loaded=false;  
   this.informationAdmin();
  
    
   
  }

  informationAdmin(): void {
    this.serviceAdmin.$admin.then((admin:any)=>{
      this.adminId = admin.id;
      this.getOneAgent(this.route.snapshot.params.id);
     });
  }

  getOneAgent(id){
   
    this.serviceAdmin.getOneAgent(id).then((data:any)=>{
      this.agent=data;
      this.serviceAdmin.setTitle('information sur l\'agent '+this.agent.full_name);
      console.log('agent',this.agent);
      this.loaded=true;
    },err=>{
      console.log('erreur',err)
    })
  }



 

  open() {
  
  }
}
