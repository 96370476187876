import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../services/user/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../../services/auth/account.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-forgot-password-shapart',
    templateUrl: './forgot-password-shapart.component.html',
    styleUrls: ['./forgot-password-shapart.component.css']
})
export class ForgotPasswordShapartComponent implements OnInit {

    forgotPasswordForm: FormGroup;
    loading = false;
    errors: any;
    error;
    response: {status: string, message: string};
    formErrors;
    email: string;
    redirectTo: string;

    constructor(private toastr: ToastrService,
                private router: Router,
                private accountService: AccountService,
                private formBuilder: FormBuilder,
                private userService: UserService,
                private route: ActivatedRoute) {

        this.route.queryParams.subscribe(
            params => {
                this.email = params.email;
            });
    }

    ngOnInit() {
        this.initForm();
        this.redirectTo = this.route.snapshot.queryParams.redirect_to || '/';
    }

    initForm() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
        if (this.email) {
            this.forgotPasswordForm.setValue({
                    email: this.email,
                }
            );
        }
    }

    doSubmit() {

        this.error = null;

        if (!this.forgotPasswordForm.invalid) {

            this.loading = true;

            const email = this.forgotPasswordForm.get('email').value;
            const redirectUri = window.location.origin + this.router.createUrlTree(['/password/reset']).toString();

            this.email = email;

            const data = {
                email,
                redirect_uri: redirectUri
            };

            this.userService.sendResetLinkEmail(data).then(
                (response: any) => {
                    this.loading = false;

                    if (response.status === 'passwords.sent') {
                        this.response = response;

                    } else if (typeof response.message === 'string') {
                        this.error = response.message;

                    } else {
                        this.toastr.error('Désolé une erreur s\'est produite');
                    }
                },
                error => {

                    this.loading = false;
                    this.errors = error.error.error;
                }
            );
        } else {
            this.formErrors = this.forgotPasswordForm.controls;

        }

    }


}
