import {Component, OnInit} from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';

import * as  datatable from '../../json/datatable/datatable_bookings.json';
import { GetAdmin } from '../../model/getadmin';

@Component({
  selector: 'app-detail-booking',
  templateUrl: './detail-booking.component.html',
  styleUrls: ['./detail-booking.component.css']
})

export class DetailBookingComponent implements OnInit,GetAdmin {

  loaded: boolean;
  actionBack: boolean;
  adminId: any;
  data: any;
  booking: any;
  list_status = datatable.status;
  status: any[];
  constructor( public adminService: AdminService, private route: ActivatedRoute) {
    this.loaded = false;
  }
  breadcrumb = breadcrumb.detail_booking;
  ngOnInit() {
    this.informationAdmin();
    
  }
  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneBooking(this.adminId, this.route.snapshot.params.id);
    
    });
   }


  getOneBooking(AdminId, bookingId) {

    this.adminService.getReservation(AdminId, bookingId).then((data: any) => {
      this.booking = data;
      //  console.log('detail booking', this.booking);
      this.loaded = true;
      this.getSatus(this.booking.timing, this.booking.original_status);
    }, err => {

      console.log('ERREUR', err);
    });
  }



  getSatus(timing, status?) {
    this.status = [];
    let s = this.list_status.find(e => e.value === timing);
    // console.log('---------------------->',timing,s)
    this.status.push(s.class);
    this.status.push(s.text);
    
    if (s.type) {
      let c = s.type.find(e => e.value === status);
      this.status.push(c.label);
     
    } else {
      this.status.push(s.label);
    }

    // console.log('stats', this.status);
  }



}
