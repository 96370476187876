import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-product-options',
    templateUrl: './product-options.component.html',
    styleUrls: ['./product-options.component.css']
})
export class ProductOptionsComponent implements OnInit {

    @Input() product: any;

    constructor() { }

    ngOnInit() {
    }

    getOptionsCeiledLength() {
        return Math.ceil(this.product.equipments.length / 2);
    }

}
