import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../services/auth/auth.service';
import {AuthUser} from '../../../models/auth-user.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-become-agent',
    templateUrl: './become-agent.component.html',
    styleUrls: ['./become-agent.component.css']
})
export class BecomeAgentComponent implements OnInit, OnDestroy {
    userSubscription: Subscription;
    authUser: AuthUser;
    action: string;

    constructor(private toastr: ToastrService,
                private authService: AuthService,
                protected route: ActivatedRoute,
                protected router: Router) { }

    ngOnInit() {

        this.route.queryParams.subscribe(
            params => {
                if (params.action) {
                    this.action = params.action;
                } else {
                    this.action = null;
                }
            }
        );

        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {
                    if (user) {
                        this.authUser = user;

                        if (this.authUser.agency) {
                            this.router.navigate(['/account/profile'], {queryParams: {section: 'agency'}});
                        }
                    }
                }
            );

            this.authService.emitUser();
        }
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}
