import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-room-status-badge',
    templateUrl: './room-status-badge.component.html',
    styleUrls: ['./room-status-badge.component.css']
})
export class RoomStatusBadgeComponent implements OnInit {

    @Input() product: any;

    constructor() { }

    ngOnInit() {
    }

}
