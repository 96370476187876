import { Component, OnInit, } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable.role.json';
import { Subscription } from 'rxjs';
import { AdminAccountService } from '../../services/admin.account.service';
import { AdminService } from '../../services/admin.service';
import {IPagination} from '../../model/pagination';
@Component({
  selector: 'app-roles-admin',
  templateUrl: './roles-admin.component.html',
  styleUrls: ['./roles-admin.component.css']
})
export class RolesAdminComponent implements OnInit {
  breadcrumb=breadcrumb.roles_admin;

  data_json=datatable.list_role;
  data: any;
  userSubscription: Subscription;

  paramsPage: IPagination;
  constructor(private adminService :AdminService,
              private adminAccountAdmin: AdminAccountService) 
              {
                this.paramsPage={} as IPagination;  
               }

  ngOnInit() {
    this.listRoles();

   
  }

  listRoles(params?){
    this.adminService.getAllRoles(params).then((roles:any)=>{
        this.data=roles?roles.data:[];
        this.paramsPage.currentPage=roles?roles.current_page:0;
        this.paramsPage.totalItems=roles?roles.total:0;
        this.paramsPage.currentPage=roles?roles.current_page:0;
        this.paramsPage.itemsPerPage=roles.data?roles.per_page:0;
      //  console.log('liste des roles',data.data);
    },err=>{
      console.log('---------erreur-----------',err);
    });
  }



  getPage(page){
 
    this.listRoles("?page="+page);
  }
}
