import { Component, OnInit } from '@angular/core';

import * as  datatable from '../../json/datatable/datatable_collecte.json';
import { AdminService } from '../../services/admin.service';
import * as filter from '../../rankings/ranking.json'
import { ActivatedRoute } from '@angular/router';
import {IPagination} from '../../model/pagination';
@Component({
  selector: 'app-list-data',
  templateUrl: './list-data.component.html',
  styleUrls: ['./list-data.component.css']
})

export class ListDataComponent implements OnInit {
  breadcrumb=datatable.breadcrumb;
  data_json1=datatable.collecte;
  loaded:boolean;
  paramsPage: IPagination;
  filter = filter.data_actors;
  adminId: any;
  data: any;
  dashboard: any;
  statistics: any;
  constructor(private adminService:AdminService,private route: ActivatedRoute) {
    this.paramsPage={} as IPagination;
   }

  ngOnInit() {
    this.informationAdmin();

    
   

   
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getParams();

     this.adminService.getDashboard().then((data:any)=>{
      // console.log('dashboard',data.data);
      this.dashboard=data.data;
      this.getAllStatistics(this.adminId);
    },err=>{
        console.log('Erreur',err);
    });
    });
   
   }
  getParams() {
    this.route.queryParams.subscribe(
      params => {
        if (params['actors']) {
          if(params['actors']=='traveler'){
            this.data_json1=datatable.traveler;
          }else{
            // console.log('ccccc',params['actors'])
            this.data_json1=datatable.collecte;
          }
          // console.log(location.search,params)
          this.getAllLandlords(this.adminId,location.search);
        }else{
          this.getAllLandlords(this.adminId);
        }
      });
  }



  getPage(page){
   
    // this.getAllLandlords(this.adminId,"?page="+page);
  }

  private getAllLandlords(id,params?):void{
  
    this.loaded=false;
      this.adminService.getAllLandlords(id,params).then((response:any)=>{
        this.data=response ? response.data : [];
        this.loaded=true;
       this.paramsPage.currentPage=response.current_page;
       this.paramsPage.totalItems=response.total;
       this.paramsPage.currentPage=response.current_page;
       this.paramsPage.itemsPerPage=response.per_page;
       this.paramsPage.id="server";
       console.log('par',this.data);
  
      },err=>{
        console.log('erreur ',err);
      
      })
    }

    getAllStatistics(IdAdmin){
      this.adminService.getAllStatistics(IdAdmin).then((data:any)=>{
        this.statistics=data;
           console.log('statistics',data);
      },err=>{
        console.log('Erreur',err);
      })
    }
}
