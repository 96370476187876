import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/admin/services/admin.service';
import * as  datatable from '../../../json/datatable/datatable_ranking.json';
import * as  datatableexcel from '../../../json/excel/bookings.json';
import * as filter from '../../ranking.json'
import {IPagination} from '../../../model/pagination';
@Component({
  selector: 'app-list-ranking-host',
  templateUrl: './list-ranking-host.component.html',
  styleUrls: ['./list-ranking-host.component.css']
})
export class ListRankingHostComponent implements OnInit {
  breadcrumb = datatable.breadcrumb;
  data_json = datatable.acteurs;
  data_json1 = datatable.products;
  data: any;
  loaded: boolean;
  paramsPage: IPagination;
  adminId: any;
  excel=datatableexcel.booking

  filter = filter.actors;
  filter1 = filter.products;
  type = 'type';
  products: any;
  paramsPageProducts: IPagination;

  constructor(private adminService: AdminService, private route: ActivatedRoute) {
    this.paramsPage = {} as IPagination;
    this.paramsPageProducts = {} as IPagination;

  }

  ngOnInit() {
   this.informationAdmin();
   
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getParams();
    });
   
   }


  getParams() {
    this.route.queryParams.subscribe(
      params => {
       
        if (location.search) {
          if (params['product']) {
            
            this.getRankingProduct(location.search);

          }
          if (params['actors']) {
            if (params['actors'] == datatable.ranking_traveler.queryParams) {
              this.data_json = datatable.ranking_traveler;
            } else {
              this.data_json = datatable.acteurs;
            }

           
            this.getAllLandlords(this.adminId, location.search);

          }
        } else {
         
          this.data_json = datatable.ranking_all;
          this.getAllLandlords(this.adminId);
        }
      });
  }





  private getAllLandlords(id, params?): void {

    this.loaded = false;
    this.adminService.getAllLandlords(id, params).then((response: any) => {
      this.data = response ? response.data : [];
      this.loaded = true;
      this.paramsPage.currentPage = response.current_page;
      this.paramsPage.totalItems = response.total;
      this.paramsPage.currentPage = response.current_page;
      this.paramsPage.itemsPerPage = response.per_page;
      this.paramsPage.id = "server";
      console.log('user', this.data);

    }, err => {
      console.log('erreur ', err);

    })
  }



  getRankingProduct(params?) {
    this.loaded = false;
    this.adminService.getRankingProduct(params).then((response: any) => {
      this.products = response.data ? response.data.data : [];
      this.loaded = true;
      this.paramsPageProducts.currentPage = response.data.current_page;
      this.paramsPageProducts.totalItems = response.data.total;
      this.paramsPageProducts.currentPage = response.data.current_page;
      this.paramsPageProducts.itemsPerPage = response.data.per_page;
      this.paramsPageProducts.id = "server";
      console.log('product', this.products);
    })
  }

  getPage(page) {

    // this.getAllLandlords(this.adminId,"?page="+page);
  }







  changedType(type) {
    this.type = type;
    if (type == "categories") {
      this.getRankingProduct();
    }
  }
}
