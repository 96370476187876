import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {FormWizardStep, FormWizard} from '../../models/app.model';
import {Router} from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class FormWizardService {
    formWizardSubject = new Subject<FormWizard>();
    formWizardSubmittedSubject = new Subject<boolean>();
    formWizard: FormWizard;

    constructor(private router: Router) { }

    findStep(tag): FormWizardStep {
        return this.formWizard?.steps.find(
            (formStep: FormWizardStep) => {
                return formStep.tag === tag;
            }
        );
    }

    findStepIndex(tag): number {
        return this.formWizard?.steps.findIndex(
            (formStep: FormWizardStep) => {
                return formStep.tag === tag;
            }
        );
    }

    getPercentageLoaded(): number {
        return this.getActiveStepIndex() ? (this.getActiveStepIndex() / (this.formWizard.steps.length - 1)) * 100 : 0;
    }

    getActiveStepIndex(): number {
        return this.formWizard?.steps.findIndex(
            (formStep: FormWizardStep) => {
                return formStep.isActive;
            }
        );
    }

    getActiveStep(): FormWizardStep {
        return this.formWizard?.steps.find(
            (formStep: FormWizardStep) => {
                return formStep.isActive;
            }
        );
    }

    setActiveStep(tag: string): void {

        const step = this.findStep(tag);

        if (step.requiredStep && this.findStep(step.requiredStep).isRequired) {
            this.router.navigate([this.findStep(step.requiredStep).url]);
        } else {
            this.formWizard.steps.forEach(
                formStep => {
                    formStep.isActive = formStep.tag === tag;
                }
            );
        }

    }

    setLoading(val: boolean): void {
        this.formWizard.loading = val;
        this.emitFormWizard();
    }

    setLoaded(val: boolean): void {
        this.formWizard.loaded = val;
        this.emitFormWizard();
    }

    submit(): void {
        this.formWizardSubmittedSubject.next(true);
    }

    next(url: string = null): void {
        this.setLoaded(false);
        this.router.navigate([url || this.getNextStep()]).then(
            () => {
                this.setLoaded(true);
            }
        );
    }

    previous(): void {
        this.router.navigate([this.getPreviousStep()]);
    }

    createFormWizard(title: string, steps: FormWizardStep[]): void {
        this.formWizard = new FormWizard(title, steps);
        this.emitFormWizard();
    }

    updateFormWizardSteps(steps: FormWizardStep[]): void {
        this.formWizard.steps = steps;
        this.emitFormWizard();
    }

    getNextStep(): any {
        return this.formWizard?.steps[this.getActiveStepIndex() + 1]?.url || null;
    }

    getPreviousStep(): any {
        return this.formWizard?.steps[this.getActiveStepIndex() - 1]?.url || null;
    }

    emitFormWizard(): void {
        this.formWizardSubject.next(this.formWizard);
    }
    resetFormWizard(): void {
        this.formWizard = null;
        this.emitFormWizard();
    }
}
