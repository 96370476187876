import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable_products.json';
import * as filter from '../../json/filter/filter.json';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import {IPagination} from '../../model/pagination';
import { GetAdmin } from '../../model/getadmin';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.css']
})
export class ListProductsComponent implements OnInit,GetAdmin {
  data_json=datatable.products;
  breadcrumb=breadcrumb.list_product;
  data: any=[];
  filter=filter.products;
   loaded:boolean;
  paramsPage: IPagination;
  constructor(public adminService:AdminService,private route: ActivatedRoute) { 
    this.paramsPage={} as IPagination;
   
  }

  ngOnInit() {
    this.adminService.setTitle('Biens');
    
    this.informationAdmin();
  }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
        this.paramsPage={} as IPagination;
          if(params){
             this.getAllProducts(location.search);
            
          }else{
            this.getAllProducts();
         
          }
      });
  }


  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
      if(admin){
        this.getParams();
      }
    });
   }


 private getAllProducts(params?:string):void{
  this.loaded=false;
    this.adminService.getAllProducts(params).then((products:any)=>{
      this.data=products? products.data : [];
      this.loaded=true;
        console.log(products);

        this.paramsPage.currentPage=products.current_page;
        this.paramsPage.totalItems=products.data && products.data.length   ?products.total:0;
        console.log('test',  this.paramsPage.totalItems);
        this.paramsPage.currentPage=products.current_page;
        this.paramsPage.itemsPerPage=products.per_page;
        this.paramsPage.to=products.to;
        this.paramsPage.from=products.from;
        this.paramsPage.id="server";
      
    
     console.log('par',this.paramsPage);

    },err=>{
      console.log('erreur ',err);
    
    })
  }





  getPage(value){
   if(value.params){
    this.getAllProducts(value.params+'&page='+value.page);
   }else{
    this.getAllProducts("?page="+value.page);
   }
    
  }



}
