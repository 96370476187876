import {Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../services/products/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductMapAddress} from '../../../../models/product.model';
import {UserService} from '../../../../services/user/user.service';

@Component({
    selector: 'app-invitation-room-preview',
    templateUrl: './invitation-room-preview.component.html',
    styleUrls: ['./invitation-room-preview.component.css'],
    encapsulation: ViewEncapsulation.None,

})
export class InvitationRoomPreviewComponent implements OnInit, OnDestroy {
    authUser: AuthUser;
    contractForm: FormGroup;
    passwordForm: FormGroup;
    invitationForm: FormGroup;
    product: any;
    invitation: any;
    userSubscription: Subscription;
    productId;
    invitationId;
    loading: boolean;
    isAccepting: boolean;
    isDeclining: boolean;
    loaded: boolean;
    submitted: boolean;
    submittedPassword: boolean;
    submittedInvitation: boolean;
    errors: any;
    contractModal: any;
    passwordModal: any;
    contract: any;
    schedules = [];
    reversedSchedules = [];
    productMapAddress: ProductMapAddress;

    constructor(private productService: ProductsService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService,
                private modalService: NgbModal,
                private fb: FormBuilder,
                private userService: UserService) { }

    ngOnInit() {
        this.productId = this.route.parent.parent.snapshot.params.id;
        this.invitationId = this.route.parent.snapshot.params.invitation_id;
        this.initForms();

        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.productService.getProductInvitation(user.id, this.productId, this.invitationId).then(
                            (invitation: any) => {

                                if (invitation && !invitation.product.accepted_contract && invitation.product.pending_contract) {

                                    if (invitation && invitation.status === 'pending') {

                                        this.productService.getProductContract(user.id, this.productId).then(
                                            (contract: any) => {
                                                this.authUser = user;

                                                this.product = invitation.product;
                                                this.contract = contract;

                                                this.invitation = invitation;
                                                this.loading = false;
                                                this.loaded = true;

                                                const schedules = this.product.unavailable_dates;

                                                this.schedules = schedules.slice();

                                                this.reversedSchedules = schedules.reverse();

                                                if (this.product.latitude && this.product.longitude) {
                                                    this.productMapAddress = {
                                                        latitude: parseFloat(this.product.latitude),
                                                        longitude: parseFloat(this.product.longitude),
                                                        country: this.product.country,
                                                        town: this.product.town
                                                    };
                                                }

                                                this.readNotification();

                                            }
                                        );


                                    } else {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }

                                } else {
                                    this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                }
                            },

                            (error) => {
                                console.log(error);
                                this.router.navigateByUrl('not-found', {skipLocationChange: true});
                            }
                        );                }
                }
            );

            this.authService.emitUser();
        }

    }

    readNotification() {
        this.userService.readUserNotifications(this.authUser.id, {
            messageable_id: this.invitation.id, messageable_type: 'invitation'});
    }

    openContractModal(longContent) {
        this.contractModal = this.modalService.open(longContent, { scrollable: true, size: 'lg' });
    }

    openPasswordModal(content) {
        this.submitted = true;
        this.passwordModal = this.modalService.open(content, { centered: true, size: 'sm' });
    }

    acceptOrDenyInvitation(decision) {

        if (decision === 'accepted') {

            if (this.contractForm.invalid || this.passwordForm.invalid) {
                return;
            }

            this.submittedPassword = true;
            this.isAccepting = true;
        }

        if (decision === 'declined') {
            this.isDeclining = true;
        }

        const formData = new FormData();

        formData.append('status', decision);
        formData.append('user_role', this.invitation.invitee_role);

        if (decision === 'accepted') {
            formData.append('has_accepted_conditions', 'true');
            formData.append('has_signed_contract', 'true');
            formData.append('current_password', this.passwordForm.get('current_password').value);
        }

        this.productService.acceptOrDenyProductInvitation(this.product.id, this.invitation.id, formData).then(
            (invitation: any) => {

                if (invitation.status === 'declined') {
                    this.isDeclining = false;
                    this.router.navigate(['/']).then(
                        () => {
                            this.toastr.info('Invitation déclinée avec succès');
                        }
                    );
                }

                if (invitation.status === 'accepted') {
                    this.passwordModal.close();

                    setTimeout(() => {
                            this.isAccepting = false;

                            this.contractModal.close();
                            if (invitation.invitee_role === 'owner') {
                                this.router.navigate([
                                    '/rooms/' + this.product.id + '/invitations/' + invitation.id + '/property_room_settings'])
                                    .then(() => {
                                            this.toastr.success('Invitation acceptée avec succès');
                                        }
                                    );
                            }

                            if (invitation.invitee_role === 'agent') {
                                this.router.navigate([
                                    '/account/rooms'], {queryParams: {user_role: this.invitation.invitee_role}})
                                    .then(() => {
                                            this.toastr.success('Invitation acceptée avec succès');
                                        }
                                    );
                            }

                        }, 2000
                    );

                }
            },
            (err) => {
                this.isDeclining = false;
                this.isAccepting = false;
                this.errors = err.error.error;
                this.toastr.error('Désolé une erreur s\'est produite. Veuillez réessayer plus tard.');

            }
        );
    }

    initForms() {
        this.contractForm = this.fb.group({
            accept_terms: ['', [Validators.requiredTrue]]
        });
        this.passwordForm = this.fb.group({
            current_password: ['', [Validators.required]]
        });

        this.invitationForm = this.fb.group({
            accept_product: ['', [Validators.requiredTrue]]
        });
    }

    get f() {
        return this.contractForm.controls;
    }
    get f1() {
        return this.passwordForm.controls;
    }
    get f2() {
        return this.invitationForm.controls;
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }


}
