import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../json/breadcrumb.json';
import * as  datatable from '../json/datatable/datatable.visitors.json';
import { ApiResponse } from 'src/app/models/response.model';
import {  ActivatedRoute } from '@angular/router';
import { AdminService } from '../services/admin.service';
import {IPagination} from '../model/pagination';
import { GetAdmin } from '../model/getadmin';
import { Visitors, VisitorsData } from '../model/visitor.model';
@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css']
})
export class VisitorsComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.visitors;
  data_json=datatable.visitors;
  data=[];
  visitors:Array<VisitorsData>;

  response: ApiResponse;
  adminId: any;
  queryParams: any = {
    per_page: 15,
    page: 1
};
  status:any;

  loaded:boolean;
  paramsPage: IPagination;

  constructor(private adminService:AdminService, private route: ActivatedRoute)
   {
    this.paramsPage={} as IPagination;
    }

  
  ngOnInit() {
    this.adminService.setTitle('Visiteurs');
   this.informationAdmin()
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
          
     this.getParams();
    });
   }


  getParams(){
    this.route.queryParams.subscribe(
    
      params => {
        this.paramsPage = {} as IPagination;
          if (params.page) {
              this.queryParams.page = params.page;
          }
          if(params){
           
            if(this.adminId){
           
              this.getAllVisitors();
            }
          }
      }); 
  }
  
  getAllVisitors(page?){
    this.loaded=false;
    this.adminService.getAllVisitisors(page).then((visitors:Visitors)=>{
    
        this.data=visitors ? visitors.data : [];
        this.loaded = true;
          // console.log('visitors',visitors);
        this.paramsPage.currentPage=visitors.current_page;
        this.paramsPage.totalItems=visitors.total;
        this.paramsPage.currentPage=visitors.current_page;
        this.paramsPage.itemsPerPage=visitors.per_page;
        this.paramsPage.to=visitors.to;
        this.paramsPage.from=visitors.from;
    },err=>{
     
      console.log('ERREUR',err);
    });
  }


  getPage(value){
    console.log('value',value);
    if(value.params){
      this.getAllVisitors(value.params+'&page='+value.page);
     }else{
      this.getAllVisitors("?page="+value.page);
     }
   
  }

}
