import { Component, OnInit } from '@angular/core';
import {NavLink} from '../../../models/app.model';
import {AppService} from '../../../services/app/app.service';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'app-settings-account',
    templateUrl: './settings-account.component.html',
    styleUrls: ['./settings-account.component.css']
})
export class SettingsAccountComponent implements OnInit {

    title: string;
    private edit: string = null;

    constructor(private appService: AppService,
                private route: ActivatedRoute) { }

    ngOnInit() {

        this.appService.titleSubject.subscribe(
            (title: string) => {
                this.title = title;
                if (title) {
                    this.route.firstChild.params.subscribe(
                        (params: Params) => {
                            this.edit = params.hasOwnProperty('subject') ? params.subject : null;
                        }
                    );
                }
            }
        );

        this.appService.emitAppTitle();

    }

    getNavLinks(): NavLink[] {

        return [
            {
                title: 'Paramètres du compte',
                link: '/account/settings',
                queryParams: {},
                lastSegment: 'settings'
            },

            {
                title: 'Préférences de virement',
                link: '/account/settings/payment-preferences',
                queryParams: {},
                lastSegment: 'payment-preferences',
                isActive: this.edit !== null
            },
        ];
    }

}
