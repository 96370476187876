import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ApiResponse} from '../../../../models/response.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {BookingService} from '../../../../services/bookings/booking.service';
import {NavLink} from '../../../../models/app.model';
import {ResponseData} from '../../../../models/response-data.model';

@Component({
    selector: 'app-booking-list-model',
    templateUrl: './booking-list-model.component.html',
    styleUrls: ['./booking-list-model.component.css']
})
export class BookingListModelComponent implements OnInit, OnDestroy {
    @Input() mode = 'reservations';

    role: string | null;
    timing: string | null;
    response = new ApiResponse();
    loaded = false;
    loading = false;
    userSubscription: Subscription;
    authUser: AuthUser;
    queryParams: any = {
        per_page: 3,
        sort_by_desc: 'created_at',
        page: 1,
    };
    status: string | null;
    constructor(private router: Router,
                private route: ActivatedRoute,
                private bookingService: BookingService,
                private authService: AuthService) { }

    ngOnInit() {


        this.userSubscription = this.authService.userSubject.subscribe(
            (authUser: AuthUser) => {

                if (authUser) {
                    this.authUser = authUser;

                    this.route.queryParamMap.subscribe(
                        params => {
                            const queryParams: any = {
                                per_page: 3,
                                sort_by_desc: 'created_at',
                                page: 1,
                            };

                            if (params.get('display')) {
                                const mode = params.get('display');
                                if (mode === 'reservations' || mode === 'demands') {
                                    this.mode = mode;
                                }
                            }

                            if (params.get('role')) {
                                const role = params.get('role');
                                if (role === 'host' || role === 'tenant' || role === 'agent') {
                                    this.role = role;
                                }
                            } else {
                                this.role = null;
                            }

                            if (this.mode === 'reservations' && params.get('timing')) {
                                const timing = params.get('timing');
                                if (timing === 'ongoing' || timing === 'upcoming' || timing === 'closed' || timing === 'cancelled') {
                                    this.timing = timing;
                                    queryParams.timing = timing;
                                }
                            }

                            if (this.mode === 'demands' && params.get('status')) {
                                const status = params.get('status');
                                if (status === 'accepted' || status === 'declined' ||
                                    status === 'cancelled' || status === 'expired' || status === 'pending') {
                                    this.status = status;
                                    queryParams.status = status;
                                }
                            }

                            if (params.get('page')) {
                                queryParams.page = params.get('page');
                            }

                            this.queryParams = queryParams;
                            this.getBookings();

                        }
                    );
                }

            }
        );

        this.authService.emitUser();
    }

    getNavLinks(): NavLink[] {
        const url = this.router.createUrlTree([], {relativeTo: this.route}).toString();

        return [
            {
                title: 'En tant que hôte',
                link: url,
                isActive: !this.role || this.role === 'host',
            },

            {
                title: 'En tant que agent',
                link: url,
                queryParams: {role: 'agent'},
                isActive: this.role && this.role === 'agent',
            },
            {
                title: 'En tant que voyageur',
                link: url,
                queryParams: {role: 'tenant'},
                isActive: this.role && this.role === 'tenant',
            },
        ];
    }

    filterBy(event) {
        const val = event.target.options[event.target.selectedIndex].value;
        const queryParams: any = {};
        delete this.queryParams.page;

        if (this.role) {
            queryParams.role = this.role;
        }

        if (val) {

            if (this.mode === 'reservations') {
                queryParams.timing = val;
            }

            if (this.mode === 'demands') {
                queryParams.status = val;
            }

        } else {
            delete this.queryParams.timing;
            delete this.queryParams.status;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
        });
    }

    getApiUserRole() {
        switch (this.role) {
            case 'agent':
                return 'agents';
            case 'tenant':
                return 'clients';
            case 'host':
                return 'hosts';
            default:
                return 'hosts';
        }
    }

    getBookings() {
        this.loading = true;

        if (this.authUser.is_agent || this.role !== 'agent') {
            if (this.mode === 'demands') {
                this.queryParams.sort_by_desc = 'requested_at';
            }

            if (this.mode === 'reservations') {
                this.queryParams.sort_by_desc = 'reserved_at';
            }
            this.bookingService.getUserBookings(this.authUser.id, this.getApiUserRole(), this.queryParams, this.mode).then(
                (response: ResponseData) => {
                    this.response = response.data ? response.data : new ApiResponse();
                    this.loaded = true;
                    this.loading = false;
                },

                () => {
                    this.loaded = true;
                    this.loading = false;
                }
            );
        } else {
            this.loading = false;
            this.response = new ApiResponse();
        }
    }


    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

}
