import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinners',
  templateUrl: './loading-spinners.component.html',
  styleUrls: ['./loading-spinners.component.css']
})
export class LoadingSpinnersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
