import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../json/breadcrumb.json';
import * as  datatable from '../json/datatable/datatable.travelers.json';
import { ApiResponse } from 'src/app/models/response.model';
import { Subscription } from 'rxjs';
import { AdminAccountService } from '../services/admin.account.service';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import {IPagination} from '../model/pagination';
@Component({
  selector: 'app-travelers',
  templateUrl: './travelers.component.html',
  styleUrls: ['./travelers.component.css']
})
export class TravelersComponent implements OnInit {
  breadcrumb=breadcrumb.travelers;
  data_json=datatable.travelers;
  data=[];
  response: ApiResponse;
  adminId: any;
  paramsPage: IPagination;
  status:any;
  loaded:boolean;

  constructor(private adminService:AdminService, private route: ActivatedRoute) 
  { 
    this.paramsPage={} as IPagination;
  }

  
  ngOnInit() {
    this.adminService.setTitle('Voyageurs');
  
      this.informationAdmin();
   
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getParams();
    });
    
   }


  getParams(){
    this.route.queryParams.subscribe(
      params => {
        this.loaded=false;
        
          if(params){
           
            if(this.adminId){
           
              this.getAllTravellers( this.adminId,'?status=verified');
            }
          }
      }); 
  }
  
  getAllTravellers(AdminId, params?:string){
    this.loaded=false;
    // getAllCustomer old method
    this.adminService.getAllTravelers(AdminId).then((response:any)=>{
        this.data=response.data ? response.data : [];
        this.loaded = true;
       
        this.paramsPage.currentPage=response.current_page;
        this.paramsPage.totalItems=response.total;
        this.paramsPage.currentPage=response.current_page;
        this.paramsPage.itemsPerPage=response.per_page;
        this.paramsPage.id="server";
        // console.log('list des voyageurs',response);
    },err=>{
     
      console.log('ERREUR',err);
    });
  }


  getPage(page){
   
    this.getAllTravellers(this.adminId,"?page="+page);
  }
}
