import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {ProductsService} from '../../services/products/products.service';
import {SuggestedUser} from '../../models/user';


@Component({
    selector: 'app-users-suggestions',
    templateUrl: './users-suggestions.component.html',
    styleUrls: ['./users-suggestions.component.css']
})
export class UsersSuggestionsComponent implements OnInit {
    @Input() user: SuggestedUser = null;
    @Input() queryParam: any = {};
    @Input() hasSelectedUser = false;

    @Output() userChange = new EventEmitter<SuggestedUser>();
    @Output() hasSelectedUserChange = new EventEmitter<boolean>();

    fetchingSuggestions = false;
    suggestions: SuggestedUser[] = [];
    currentTimeout: any;
    model: any;

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(2000),
            map(term => term === '' ? []
                : this.suggestions.filter(
                    v =>  v.full_name.toLowerCase().replace(/ +/g, '').trim()
                            .indexOf(term.toLowerCase().replace(/ +/g, '').trim()) > -1 ||
                        v.email.toLowerCase().replace(/ +/g, '').trim()
                            .indexOf(term.toLowerCase().replace(/ +/g, '').trim())  > -1).slice(0, 10)))

    formatter = (x: {full_name: string}) => x.full_name;

    constructor(private productService: ProductsService) { }

    ngOnInit() {

    }

    selectedItem(item) {
        const user: SuggestedUser = item.item;
        user.userType = this.queryParam.account_type;
        this.userChange.emit(user);
        this.hasSelectedUser = true;
        this.hasSelectedUserChange.emit(this.hasSelectedUser);
    }

    onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    getSuggestions(event, focus = false) {

        if (this.currentTimeout) {
            clearTimeout(this.currentTimeout);
        }

        if (event.target.value) {
            this.fetchSuggestions(event.target.value);
            if (focus) {
                this.onFocus(event);
            }
        }
    }

    fetchSuggestions(query) {
        this.queryParam.key_word = query;
        this.currentTimeout = setTimeout(
            () => {
                this.fetchingSuggestions = true;
                this.productService.getUserSuggestions(this.queryParam).then(
                    (response: SuggestedUser[]) => {
                        this.fetchingSuggestions = false;
                        this.suggestions = response;
                    },
                );
            }, 1000);
    }

}
