import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-shapart-view',
  templateUrl: './register-shapart-view.component.html',
  styleUrls: ['./register-shapart-view.component.css']
})
export class RegisterShapartViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
