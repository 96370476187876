import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import {SearchModel} from 'src/app/models/search';
import {Subject} from 'rxjs';
import {ResponseData} from '../../models/response-data.model';
import {ApiResponse} from '../../models/response.model';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    searchModel = new SearchModel();
    searchSubject = new Subject<SearchModel>();
    perPage = 15;
    loading: boolean;

    constructor(private authService: AuthService) { }

    searchProduct(data: any = {}, perPage: any = this.perPage) {
        console.log(data);

        const queryParam = data;

        queryParam.per_page = perPage;

        if (perPage === '*') {
            this.searchModel.loadingMap = true;
            delete queryParam.page;
        } else {
            this.searchModel.loading = true;
        }

        this.emitSearchModel();

        return this.authService.get('search', queryParam).then(
            (response: ResponseData) => {

                console.log(queryParam);
                if (perPage === '*' && queryParam.latitude && queryParam.longitude && queryParam.address) {
                    this.searchModel.mapData = response.data ? response.data : new ApiResponse();
                } else {
                    this.searchModel.data = response.data ? response.data : new ApiResponse();
                    if (queryParam.latitude && queryParam.longitude && queryParam.address) {
                        this.searchModel.data.data.forEach(
                            (result: any, index) => {
                                result.mapPosition = index + (this.searchModel.data.per_page * (this.searchModel.data.current_page - 1));
                            }
                        );
                    }
                }

                if (perPage === '*' && queryParam.latitude && queryParam.longitude && queryParam.address) {
                    this.searchModel.loadingMap = false;
                    this.searchModel.loadedMap = true;
                } else {
                    this.searchModel.loading = false;
                    this.searchModel.loaded = true;
                }
                if (perPage !== '*' && queryParam.latitude && queryParam.longitude && queryParam.address
                    && this.searchModel.data.total > 0) {
                    this.searchProduct(queryParam, '*');
                }

                this.emitSearchModel();
            }
        );
    }

    emitSearchModel() {
        this.searchSubject.next(this.searchModel);
    }
}
