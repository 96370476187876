import {nameRoutes,urlPages} from '../settings/env.routing'
export const claims={

        queryParams: "claims",
        table: [{
                title: "ID",
                data: "id"
            },
            {
                title: "Titre",
                data: "title"

            },
            {
                title: "Description",
                data: "description"

            },
            {
                title: "Client",
                dataarray: "reservation.payment_client.user_name"
            },
            {
                title: "Hôte",
                dataarray: "reservation.payment_host.user_name"
            },
            
            {
                title: "Statut",
                status: "status",
                show: [{
                        value: "end",
                        text: "Achivée",
                        class: "approved"
                    },
                    {
                        value: "in_pending",
                        text: "en cours",
                        class: "pending"
                    }


                ]

            },
            {
                title: "source",
                status: "type",
                show: [{
                        value: "email",
                        text: "Email",
                        class: "approved"
                    },
                    {
                        value: "phone",
                        text: "Téléphone",
                        class: "approved"
                    }


                ]

            }

        ],
        menu_icon: [{
            text: "Detail réclamation",
            link:urlPages.pageClaims,
            "params": nameRoutes.show,
        }]
    }




  export const  breadcrumb= [{
            label: "Tableau de bord",
            url: urlPages.pageDashboard,
            active: false
        },
        {
            label: "Liste des reclamations",
            active: true
        }

    ]

    export const  breadcrumbDetail= [
        {
        label: "Tableau de bord",
        url: urlPages.pageDashboard,
        active: false
        },
        {
            label: "Liste des reclamations",
            url: urlPages.pageClaims,
            active: false
            },
        {
            label: "Detail reclamations",
            active: true
        }

]
