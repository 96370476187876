import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../json/breadcrumb.json';
@Component({
  selector: 'app-googleanalytics',
  templateUrl: './googleanalytics.component.html',
  styleUrls: ['./googleanalytics.component.css']
})
export class GoogleanalyticsComponent implements OnInit {
  breadcrumb=breadcrumb.analytics;
  constructor() { }

  ngOnInit(): void {
  }

}
