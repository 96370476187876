import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/admin/services/admin.service';
import { AdminAuthService } from 'src/app/admin/services/admin.auth.service';
@Component({
  selector: 'app-password-admin',
  templateUrl: './password-admin.component.html',
  styleUrls: ['./password-admin.component.css']
})
export class PasswordAdminComponent implements OnInit {
  angForm: any;
  submitted=false;
  formData:any;
  breadcrumb=breadcrumb.password_admin;
  erreur: any;
  constructor(private fb: FormBuilder,
              private serviceAdmin:AdminService,
              private authAdmin:AdminAuthService,
              private route: ActivatedRoute,
              private toastr: ToastrService) { }

  ngOnInit()
  {
    let data={
      breadcrumb:this.breadcrumb,
      id:this.route.snapshot.paramMap.get('id'),
      title:"Modifier le mot de passe l'administrateur",
      tag:"password"
    }
    setTimeout(() => {
      this.serviceAdmin.sendBreadcrumb(data);
     }, 1000);
    this.initForm();
  }

  get f() {
    return this.angForm.controls;
  } 
  
  initForm() {
    this.angForm = this.fb.group({
      new_password: [null, [Validators.required,Validators.minLength(6)]],
      password: [null,[Validators.required]],
    });
  }

  UpdatePassword(){
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }

    let formData= new FormData();
    formData.append('new_password',this.angForm.value.new_password);
    formData.append('admin_password',this.angForm.value.password);
    formData.append('_method','PUT');
    this.serviceAdmin.updateAdmin(formData,this.route.snapshot.paramMap.get('id')).then((data:any)=>{
     
      this.toastr.success('Modification effectuée','Success');
      this.submitted=false;
      this.angForm.reset();
    },err=>{
        console.log('erreur',err);
        if(err.error.error.admin_password){
          this.erreur=err.error.error.admin_password[0];
        }
    });
  }
}
