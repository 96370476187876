import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../../../services/auth/auth.service';
import {AuthUser} from '../../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {UserService} from '../../../../../services/user/user.service';
import {Image, UploadedImage} from '../../../../../models/Image';
import {FormWizardService} from '../../../../../services/form-wizard/form-wizard.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-verify-identity-status',
    templateUrl: './verify-identity-status.component.html',
    styleUrls: ['./verify-identity-status.component.css']
})
export class VerifyIdentityStatusComponent implements OnInit, OnDestroy {
    authUser: AuthUser;
    userSubscription: Subscription;
    documents: any = [];
    loaded = false;
    upLoadedImagesRecto: UploadedImage[] = [];
    upLoadedImagesVerso: UploadedImage[] = [];
    upLoadedImagesPassport: UploadedImage[] = [];
    upLoadedImagesWaterReceipt: UploadedImage[] = [];
    upLoadedImagesElectricityReceipt: UploadedImage[] = [];
    upLoadedImagesSnapPhoto: UploadedImage[] = [];
    private formWizardServiceSubscription: Subscription;

    constructor(private authService: AuthService,
                private userService: UserService,
                private formWizardService: FormWizardService,
                private router: Router) { }

    ngOnInit(): void {

        this.formWizardServiceSubscription = this.formWizardService.formWizardSubmittedSubject.subscribe(
            submitted => {
                if (submitted) {
                    this.router.navigate(['/account/profile'], {queryParams: {section: 'verify'}});
                }
            }
        );
        this.userSubscription = this.authService.userSubject.subscribe(
            authUser => {
                this.authUser = authUser;
                if (this.authUser) {
                    this.getUserDocuments();
                }
            }
        );

        this.authService.emitUser();
    }

    hasEmptyDocuments(): boolean {
        return !this.upLoadedImagesRecto.length && !this.upLoadedImagesVerso.length
            && !this.upLoadedImagesPassport.length && !this.upLoadedImagesSnapPhoto.length;
    }

    getUserDocuments(): void {
        this.userService.getUserDocuments(this.authUser.id, {per_page: '*'}).then(
            (response: any) => {
                this.documents = response.data || response;

                const uploadedImagesRecto: UploadedImage[] = [];
                const uploadedImagesVerso: UploadedImage[] = [];
                const uploadedImagesPassport: UploadedImage[] = [];
                const upLoadedImagesWaterReceipt: UploadedImage[] = [];
                const uploadedImagesElectricityReceipt: UploadedImage[] = [];
                const uploadedImagesSnapPhoto: UploadedImage[] = [];

                this.documents.forEach(
                    (image: Image) => {
                        const uploadedImage: UploadedImage = {
                            id: image.id,
                            uploadName: image.title,
                            hasUploaded: true,
                            isUploading: false,
                            link: image.url,
                            type: image.type,
                            url: image.url,
                            status: image.status,
                            isEditable: true
                        };

                        if (image.title === 'user_identity_image_recto') {
                            uploadedImagesRecto.push(uploadedImage);
                        }

                        if (image.title === 'user_identity_image_verso') {
                            uploadedImagesVerso.push(uploadedImage);
                        }

                        if (image.title === 'user_passport_image') {
                            uploadedImagesPassport.push(uploadedImage);
                        }

                        if (image.title === 'water_receipt') {
                            upLoadedImagesWaterReceipt.push(uploadedImage);
                        }

                        if (image.title === 'electricity_receipt') {
                            uploadedImagesElectricityReceipt.push(uploadedImage);
                        }

                        if (image.title === 'user_snapped_photo') {
                            uploadedImage.isEditable = false;
                            uploadedImagesSnapPhoto.push(uploadedImage);
                        }
                    }
                );
                this.upLoadedImagesRecto = uploadedImagesRecto;
                this.upLoadedImagesVerso = uploadedImagesVerso;
                this.upLoadedImagesPassport = uploadedImagesPassport;
                this.upLoadedImagesWaterReceipt = upLoadedImagesWaterReceipt;
                this.upLoadedImagesElectricityReceipt = uploadedImagesElectricityReceipt;
                this.upLoadedImagesSnapPhoto = uploadedImagesSnapPhoto;
                this.loaded = true;
            }
        );
    }

    getAcceptedFormats(): any {
        return [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.formWizardServiceSubscription) {
            this.formWizardServiceSubscription.unsubscribe();
        }
    }

}
