import { Injectable, OnDestroy } from '@angular/core';
import {  Observable, BehaviorSubject, Subscription } from 'rxjs';
import { HttpAdminService } from './http-admin.service';

@Injectable({
    providedIn: 'root'
})
export class AdminAccountService implements OnDestroy{
    adminSubject = new BehaviorSubject<any>(null);
    private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    infoSubscription: Subscription;
    public isAuth:Boolean;
    public previousUrl$: Observable<string> = this.previousUrl.asObservable(); // a supprimer
    setPreviousUrl(previousUrl: string) {
        this.previousUrl.next(previousUrl);
      }
    constructor(private httpClientAdmin: HttpAdminService) {}

    
    setAdmin(admin:any) {// a supprimer
       this.adminSubject.next(admin);
    }

    getAdmin() {//a supprimer
        return this.adminSubject;
    }


    setInfo(info) {
        if(info){
            this.isAuth=true;
            this.adminSubject.next(info);
        }
       
    }

    getInfo() :Observable<any>{
        return this.adminSubject.asObservable();
    }



    login(email: string, password: string) {

        return new Promise(
            (resolve, reject) => {
                this.httpClientAdmin.getPasswordToken(email, password).then(
                    (tokenData) => {
                        this.getAdminInformations().then(
                            (userData: any) => {
                             
                                resolve(userData); 
                            },
                            (error) => {
                                reject(error);
                            }
                        );

                       
                    },
                    (error) => {
                        reject(error);
                      
                    }
                );
            }
        ) ;
    }


    logout() {
        this.isAuth=false;
       return this.httpClientAdmin.logout();

    }
      //--------------routes pour les admins----------------
getAdminInformations() {
    return this.httpClientAdmin.get('ad/me', {});   
}

    getAdminInformation() {
        return new Promise(
            (resolve, reject) => {
                this.httpClientAdmin.get('ad/me').then(
                    (user:any) => {
                     
                        resolve(user);
                    },

                    () => {
                        resolve (null);
                    }
                );

            }
        );
    }


    public get $admin() {
        return new Promise((resolve,reject)=>{
         this.infoSubscription = this.getInfo().subscribe(
           (info:any)=>{
             if(info){
               resolve( info);
             } 
           },err=>{
             reject(err);
           });
        })
       }

       ngOnDestroy(): void {
        this.infoSubscription.unsubscribe();
      }
}
