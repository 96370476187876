import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FilterComponent implements OnInit {
  @Input() data: any;
  @Input() entity: any;

  checkedListStatus = [];

  query = [];
  arrayQuery = [];
  arrayQueryRadio = [];

  email: string;
  date: any;
  amount: any;
  filter = [];
  date_type: any;
  type_status = null;
  subStatu: boolean = false;
  message: string;



  constructor(private router: Router) { }

  ngOnInit() {
    // console.log('params',location.search)
    

    var url = new URL(location.href);
    var email = url.searchParams.get("email");
    if(email){
      this.message = (this.entity ? this.entity : "entitées") + " dont  l'adresse mail est égale à " + email;
    }
   

    var date_filter = url.searchParams.get("date_filter");
    if (date_filter) {
      const search = date_filter.split(',');

      switch (search[0]) {
        case "isDateEquals":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont la date de creaction est égale a " + search[1];
          break;

        case "isDateBetween":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont la date de creaction est comprise entre le " + search[1] + " et le " + search[2];
          break;
        case "isDateAfter":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont la date de creaction est après le " + search[1];
          break;

        case "isDateOnOrAfter":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont la date de creaction est le ou après " + search[1];
          break;

        case "isDateBefore":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont la date de creaction est avant le " + search[1];
          break;

        case "isDateBeforeOrOn":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont la date de creaction est avant ou le " + search[1];
          break;

        case "isDuringTheLast":
          if (parseInt(search[1]) > 0 && parseInt(search[1]) !== 1) {
            this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " au cours des " + search[1] + " derniers " + (search[2] === "day" ? " jours" : " mois");
          }

          if (parseInt(search[1]) == 0) {

            this.message = "Liste des " + (this.entity ? this.entity : "entitées") + (search[2] == "day" ? " crée aujourd\'hui" : " ce mois");

          }

          if (parseInt(search[1]) == 1) {

            this.message = "Liste des " + (this.entity ? this.entity : "entitées") + (search[2] == "day" ? " crée il y'a un jour" : " il y'a un mois");

          }
          break;



      }
    }



    var amount_filter = url.searchParams.get("amount_filter");
    if (amount_filter) {
      const amount_filter_search = amount_filter.split(',');

      switch (amount_filter_search[0]) {
        case "isAmountEquals":
          this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont le montant est égale à " + amount_filter_search[1];
          break;

          case "isAmountBetween":
            this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont le montant est compris entre "+ amount_filter_search[1] + " et le " + amount_filter_search[2];
            break;

            case "isAmountSuperior":
              this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont le montant est superieur  à "+ amount_filter_search[1] ;
              break;

              case "isAmountInferior":
                this.message = "Liste des " + (this.entity ? this.entity : "entitées") + " dont le montant est inferieur à "+ amount_filter_search[1];
                break;


            
        default:
          break;
      }
    }
  }


  getDate(e) {
    this.date = e;
  }
  getDateType(e) {
    if (e) {
      let arrDate = e.split(",");
      this.date_type = arrDate.pop();
      this.date = arrDate.toString();
    }
  }

  getAmount(e) {
    this.amount = e;
  }
  // radio  type
  onRadioChange(queryParams, event) {



    if (queryParams) {
      let t = this.arrayQueryRadio.find(e => e.key == queryParams);
      if (t) {
        t.value = event.target.value;
      } else {
        this.arrayQueryRadio.push({ key: queryParams, value: event.target.value })
      }
    }

    // console.log('tt', this.arrayQueryRadio,event.target.value);
  }

  // multi type
  onCheckboxChangeTypes(data, status, event) {
    if (data.queryParams) {
      let t = this.arrayQuery.find(e => e.key == data.queryParams);
      if (t) {
        if (event.target.checked) {
          t.value.push(status.value);
        } else {
          for (var i = 0; i < t.value.length; i++) {
            if (t.value[i] == status.value) {
              t.value.splice(i, 1);
            }
          }
          // console.log('t',t);
        }
      } else {
        this.arrayQuery.push({ key: data.queryParams, value: [status.value] });
      }
      // console.log('test',this.arrayQuery);
    }
  }


  // simple type
  onCheckboxChangeType(data, status, event) {

    if (event.target.checked) {
      this.checkedListStatus.push([status.value]);
    } else {
      for (var i = 0; i < this.checkedListStatus.length; i++) {
        if (this.checkedListStatus[i] == status.value) {
          this.checkedListStatus.splice(i, 1);
        }
      }
    }
    //  console.log('status',this.checkedListStatus);
  }
  ChangeEmail(event) {
    this.email = event.target.value;
  }














  sendFilter() {
    const queryParams: any = {};
    if (this.date) {
      queryParams.date_filter = this.date;
    }
    if (this.amount) {
      queryParams.amount_filter = this.amount;
    }
    if (this.email) {
      queryParams.email = this.email;
    }
    if (this.checkedListStatus.length) {
      if (this.type_status) {
        queryParams[this.type_status] = this.checkedListStatus.toString();
      } else {
        queryParams.status = this.checkedListStatus.toString();
      }

    }
    if (this.date_type) {
      queryParams.type_date = this.date_type;
    }

    if (this.arrayQuery.length) {
      this.arrayQuery.forEach(element => {
        if (element.value.length) {

          queryParams[element.key] = element.value.toString();
        }
      });
    }

    if (this.arrayQueryRadio.length) {
      this.arrayQueryRadio.forEach(element => {
        queryParams[element.key] = element.value.toString();
      });
    }

    const navigationExtras: NavigationExtras = { queryParams };
    this.router.navigate([location.pathname], navigationExtras).then(() => {
      // this.checkedListStatus=[];
      this.email = null;
      this.filter = [];
      this.date_type = null;
      this.date = [];
    });
  }



  Close() {
    const navigationExtras: NavigationExtras = {};
    this.router.navigate([location.pathname], navigationExtras).then(() => {
      this.checkedListStatus = [];
      this.email = null;
      this.filter = [];
      this.date_type = null;
      this.date = [];
      this.arrayQuery = [];
      this.arrayQueryRadio = [];
    });
  }


  changeFilter(event, type, type_status?) {

    if (event.target.checked) {
      this.filter.push([type]);
    } else {
      for (var i = 0; i < this.filter.length; i++) {
        if (this.filter[i] == type) {
          this.filter.splice(i, 1);
        }
      }
    }
    this.type_status = type_status;

  }

  checkedStatus(s) {
    console.log('filter', s);
    const resultat = this.filter.find(status => status === s);
    if (resultat) {
      return true;
    } else {
      return false;
    }
  }


  subStatus(type) {
    if (type == false) {
      this.subStatu = true;
    } else {
      this.subStatu = false;
    }
  }

  moveSpace() {

  }
}