import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import * as  datatable from '../../json/datatable/datatable_requests.json';
@Component({
  selector: 'app-detail-request',
  templateUrl: './detail-request.component.html',
  styleUrls: ['./detail-request.component.css']
})
export class DetailRequestComponent implements OnInit {
  breadcrumb = breadcrumb.detail_request;
  list_status=datatable.status;
  request: any;
  loaded=false;
  status: any[];
  constructor(
    private route: ActivatedRoute,
    private adminService:AdminService) { }


  ngOnInit() {
    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.getOneRequest(admin.id,this.route.snapshot.params.id)
   
    });
   }

  getOneRequest(idAdmin,id){
    this.adminService.getOneRequest(idAdmin,id).then((data:any)=>{
    
      // console.log('demande',data);
      this.request=data;
      this.loaded=true;
      this.getSatus(this.request.original_status);
      
      // this.getSatus(this.agency.status);
     
    },err=>{
      console.log('erreur',err)
    })
  }




  getSatus(status) {
    this.status = [];
    let s=  this.list_status.find(e=>e.value===status)
      this.status.push(s.class);
      this.status.push(s.text);
      this.status.push(s.label);
  }
}
