import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../../setting/must-match.validator';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
@Component({
  selector: 'app-new-admin',
  templateUrl: './new-admin.component.html',
  styleUrls: ['./new-admin.component.css']
})
export class NewAdminComponent implements OnInit {
  angForm: any;
  constructor(private fb: FormBuilder,
              private adminService :AdminService,
              private toastr: ToastrService,
              private router: Router) { }
  breadcrumb = breadcrumb.new_admin;
  submitted = false;
  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.angForm = this.fb.group({
      name: [null, [Validators.required]],
      lastname: [null],
      password: [null, [Validators.required]],
      password_confirmation: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      email: [null, [Validators.required]]
    },
      {
        validators: MustMatch('password', 'password_confirmation')
      });
  }

  get f() {

    return this.angForm.controls;
  }

  saveAdmin() {
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }
    let admin={
      name:this.angForm.value.name,
      lastname:this.angForm.value.lastname,
      phone:this.angForm.value.phone,
      adress:this.angForm.value.adress,
      password:this.angForm.value.password,
      email:this.angForm.value.email,
    }
  //  let admin =new Admin(this.angForm.value.name,
  //   this.angForm.value.lastname,
  //   this.angForm.value.phone,
  //   this.angForm.value.email,
  //   this.angForm.value.adress,
  //   this.angForm.value.password
  //   )
    // console.log('data',admin.admin());
    this.adminService.saveNewAdmin(admin).then((data:any)=>{
      this.toastr.success('Un nouveau admin a été crée','Success');
      this.router.navigateByUrl("/admin/admins/list")
      console.log('data',data);
    },err=>{
      console.log('erreur',err);
    });
  }
}
