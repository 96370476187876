import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GetAdmin } from '../../model/getadmin';
import { AdminAccountService } from '../../services/admin.account.service';
import {urlPages} from '../../settings/env.routing';

@Component({
  selector: 'app-header-admin-shapart',
  templateUrl: './header-admin-shapart.component.html',
  styleUrls: ['./header-admin-shapart.component.css']
})
export class HeaderAdminShapartComponent implements OnInit,GetAdmin {
  userSubscription: Subscription;

  currentAdmin: any;
  constructor(
    private accountAdmin: AdminAccountService,
    private router: Router,
    private toastr: ToastrService,
   ) {
    
     }
  

  ngOnInit() {
  
    this.informationAdmin();
  
  
   
  }
  informationAdmin(): void {
   
    this.accountAdmin.getInfo().subscribe(
        (info)=>{
          if(info){
            // console.log("test",info)
            this.currentAdmin=info;
          }else{
            this.getInfoAdmin();
          }  
        },err=>{
        });
    }

  logout() {
    this.accountAdmin.logout().then( data=>{
      if(data){
        this.router.navigate([urlPages.pageLogin]).then(
          () => {
            this.accountAdmin.isAuth=false;
              this.toastr.info('Vous êtes a présent déconnecté');
          }
      );
      }
     
    },err=>{

    });

}

profil(){
  this.router.navigate([urlPages.pageProfil]);
}



getInfoAdmin(){
  this.accountAdmin.getAdminInformation().then((data:any)=>{
   this.accountAdmin.setAdmin(data);// a supprimer
   this.accountAdmin.setInfo(data);
  },err=>{
    console.log('erreur',err);
  })
}
}
