import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminAccountService } from '../../services/admin.account.service';
import { HttpAdminService } from '../../services/http-admin.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService implements OnDestroy {
  infoSubscription: Subscription;
  constructor(private adminAccountService:AdminAccountService,private httpClientAdmin: HttpAdminService,
    private title:Title) {
   
  }

  public get $admin() {
    return new Promise((resolve,reject)=>{
     this.infoSubscription = this.adminAccountService.getInfo().subscribe(
       (info:any)=>{
         if(info){
           resolve( info);
         } 
       },err=>{
         reject(err);
       });
    })
   }

   setTitle(title){
    this.title.setTitle(title+'/'+environment.appName);
  }
   //--------------------------banks------------------------------
   getBank(){
    return this.httpClientAdmin.get('admin/banks');
  }

  getAllSettings(adminId){
    return this.httpClientAdmin.get("admins/"+adminId+"/settings");
  }
  
  getOneSetting(adminId,name){
    return this.httpClientAdmin.get("admins/"+adminId+"/settings/"+name);
  }
  
  updateSetting(adminId,id,data){
    return this.httpClientAdmin.post("admins/"+adminId+"/settings/"+id,data);
  }

  ngOnDestroy(): void {
    this.infoSubscription.unsubscribe();
  }
}
