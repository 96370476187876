import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { GetAdmin } from '../../model/getadmin';
import { AdminService } from '../../services/admin.service';
@Component({
  selector: 'app-preference-transfer',
  templateUrl: './preference-transfer.component.html',
  styleUrls: ['./preference-transfer.component.css']
})
export class PreferenceTransferComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.preference_transfer;
  preferences: any;
  loaded: boolean=false;
  adminId: any;
  customer: any;
  constructor(  private adminService: AdminService,
                private route: ActivatedRoute
              ) { }

  ngOnInit() {
  
this.informationAdmin();
  }
  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getUserSetting(this.route.snapshot.params.id);
     this.getOneCUstomer( this.adminId,this.route.snapshot.params.id);
    
    });
   }

  private getUserSetting(id){
    this.adminService.getUserSettings(id).then((data:any)=>{
        if(data && data.value){
        
          this.preferences=data.value.payment_preferences;
          this.loaded=true;
        
        }
       
       
    },err=>{
      console.log('erreur',err);
    });
  }

  getOneCUstomer(idAdmin:number,id:number){
     this.adminService.getOneCustomer(idAdmin,id).then((data:any)=>{
       this.customer=data;
      //  console.log('preference',this.customer);
     }).catch(err=>{throw new Error(err);
     })
  }

 
}
