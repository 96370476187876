import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../../services/excel.service';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.css']
})
export class ExportFileComponent implements OnInit {
  @Input() data: any;
  @Input() table: any;
  typeDate: any;
  typeColumns: any;
  columns=[];
  constructor(private modalService: NgbModal,config: NgbModalConfig,  private excelService:ExcelService) {
    config.backdrop = 'static';
    config.keyboard = false;
   


   }

  ngOnInit() {
    this.columns= this.table.table.filter(element =>element.default==true);
    // console.log('ffff', this.columns);
  }
  openScrollableContent(longContent) {
    this.modalService.open(longContent, {size: 'lg', scrollable: true, backdropClass: 'light-blue-backdrop'  });
  }


  exportAsXLSX():void {
  
    // this.excelService.exportAsExcelFile(this.data, 'sample');
    Object.keys(this.data[0]) .forEach(function eachKey(key) { 
      console.log(key); // alerts key 
   
    });
 }

 selectDate(type?){
    this.typeDate=type;
 }

 selectColumns(e){
  this.typeColumns=e;
  if(e=="all"){
    this.columns= this.table.table;
    // console.log('all',this.columns);
  }
  if(e=="default"){
    this.columns= this.table.table.filter(element =>element.default==true);
  }
 }


 onCheckboxChangeColumns(columns, event) {

  if (event.target.checked) {
    this.columns.push(columns);
  } else {
    for (var i = 0; i < this.columns.length; i++) {
      if (this.columns[i].data == columns.data) {
        this.columns.splice(i, 1);
      }
    }
  }
  //  console.log('columns',this.columns);
  //  console.log('table',this.table.table);
}
}
