import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {UserService} from '../../../../services/user/user.service';
import {ProductsService} from '../../../../services/products/products.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-user-room-identity',
    templateUrl: './user-room-identity.component.html',
    styleUrls: ['./user-room-identity.component.css']
})
export class UserRoomIdentityComponent implements OnInit, OnDestroy {

    authUser: AuthUser;
    documents: any;
    loaded: boolean;
    private userSubscription: Subscription;
    private userProductSubscription: Subscription;
    private productId: any;

    constructor(private authService: AuthService,
                private userService: UserService,
                private productService: ProductsService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {
        this.productId = this.route.parent.parent.snapshot.params.id;

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                this.authUser = user;
                if (this.authUser) {
                    this.userProductSubscription = this.productService.userProductSubject.subscribe(
                        (product: any) => {
                            if (product) {
                                this.getUserDocuments();

                            }
                        }
                    );

                    this.productService.getAuthUserProduct(user.id, this.productId).then(
                        () => {
                            this.productService.emitUserProduct();
                        },
                        () => {
                            this.router.navigate(['/rooms/new']);
                        }
                    );
                }
            }
        );

        this.authService.emitUser();
    }

    getUserDocuments(): void {
        this.userService.getUserDocuments(this.authUser.id, {per_page: '*'}).then(
            (response: any) => {
                this.documents = response.data;
                this.loaded = true;
            }
        );
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}
