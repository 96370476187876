import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  security from '../../json/security/security.json';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from '../services/accounting.service';
@Component({
  selector: 'app-service-rate',
  templateUrl: './service-rate.component.html',
  styleUrls: ['./service-rate.component.css']
})
export class ServiceRateComponent implements OnInit {
  breadcrumb = breadcrumb.rate;
  security = security.security.rate_service;
  adminId: any;
  rates: any;

  submittedRate = false;

  loaded: boolean;
  ratesValue = []
  bankFeesValue = [];
  oneRate: any;
  rateForm: any;
  price_rates: any;
  typeRate: any;
  oneBankFees: any;
  bankForm: any;
  submittedBank = false;

  constructor(private fb: FormBuilder, private accountingService: AccountingService, private toastr: ToastrService) {

    this.loaded = false;
  }

  ngOnInit() {
    this.accountingService.setTitle('Taux de services');
    this.angForm();
this.informationAdmin();
  }

  informationAdmin(): void {
    this.accountingService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneSetting(this.adminId);
    
    });
   }


  angForm() {
    this.rateForm = this.fb.group({
      rate: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
    });

    this.bankForm = this.fb.group({
      p_externe: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
      p_interne: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
      f_externe: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
      f_interne: [null, [Validators.required, Validators.max(100), Validators.min(0)]],
    });

  };

  get rate() {

    return this.rateForm.controls;
  }

  get bank() {

    return this.bankForm.controls;
  }


  getOneSetting(AdminId) {
    this.accountingService.getOneSetting(AdminId, 'price_rates').then((data: any) => {
      this.price_rates = data.value;
      // console.log('data',data.value);
      this.loaded = true;
      this.TransformObjectToArray(data.value)
    }, err => {
      console.log('err', err);

    })
  }


  TransformObjectToArray(value) {
    for (const property in value) {
      if (property !== "bank_fees") {
        this.ratesValue.push({ key: property, value: value[property] })
      } else {
        this.ratesValue.push({ key: property, value: value[property] });
        for (const property in value['bank_fees'].val) {
          this.bankFeesValue[property] = value['bank_fees'].val[property];
        }
        this.setValueBank();

      }

    }

    this.oneRate = this.ratesValue[0];
    this.typeRate = this.ratesValue[0].key;


    // console.log(this.ratesValue, this.oneRate);
  }






  setValueBank() {
    this.bankForm.controls.p_externe.setValue(this.bankFeesValue['percentage_rates'].external);
    this.bankForm.controls.p_interne.setValue(this.bankFeesValue['percentage_rates'].internal);
    this.bankForm.controls.f_externe.setValue(this.bankFeesValue['fixed_fees'].external);
    this.bankForm.controls.f_interne.setValue(this.bankFeesValue['fixed_fees'].internal);
  }

  getRate(rate) {
    this.typeRate = rate.key;
    if (rate.key != "bank_fees") {
      this.oneRate = rate;
      this.rateForm.controls.rate.setValue(this.oneRate.value.val);
    } else {
      this.setValueBank()
    }


  }
  updateOneRate(oneRate) {
    this.submittedRate = true;
    this.price_rates[oneRate.key].val = this.rateForm.controls.rate.value;
    console.log('update', this.price_rates)
    if (this.rateForm.invalid) {
      return;
    }
    let data = {
      value: JSON.stringify(this.price_rates),
      _method: 'PUT'
    }

    this.accountingService.updateSetting(this.adminId, 'price_rates', data).then(data => {
      this.toastr.success('Modification effectuée', 'Success');
      console.log('value', data);
    }, err => {
      console.log('erreur', err);
    });
    console.log('rr', oneRate)

  }
  updateBank() {
    this.submittedBank = true;
    if (this.bankForm.invalid) {
      return;
    }


    this.price_rates['bank_fees'].val.fixed_fees.internal = this.bankForm.controls.f_interne.value;
    this.price_rates['bank_fees'].val.fixed_fees.external = this.bankForm.controls.f_externe.value;
    this.price_rates['bank_fees'].val.percentage_rates.internal = this.bankForm.controls.p_interne.value;
    this.price_rates['bank_fees'].val.percentage_rates.external = this.bankForm.controls.p_externe.value;

    let data = {
      value: JSON.stringify(this.price_rates),
      _method: 'PUT'
    }
    this.accountingService.updateSetting(this.adminId, 'price_rates', data).then(data => {
      this.toastr.success('Modification effectuée', 'Success');
      console.log('value', data);
    }, err => {
      console.log('erreur', err);
    });

  }




  getBankFees(Bank) {
    console.log('Bank', Bank);
    this.oneBankFees = Bank;
  }

}
