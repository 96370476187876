import { BrowserModule, Title, HammerModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ProductsService } from './services/products/products.service';
import { NgxLoadingModule } from 'ngx-loading';
import { AppComponent } from './app.component';
import { TruncatePipe } from './pipe/truncate.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { IgxCalendarModule, IgxDialogModule } from 'igniteui-angular';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { ViewsModule } from './views/views.module';
import { routes } from './route/app-routing.module';
import { AuthService } from './services/auth/auth.service';
import { AgmService } from './services/agm/agm.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { SearchService } from './services/search/search.service';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SharingModule } from './sharing/sharing.module';
import { AdminModule } from './admin/admin.module';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BookingService } from './services/bookings/booking.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeFr, 'fr');

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [
        AppComponent,
        TruncatePipe
    ],

    imports: [
        BrowserAnimationsModule,
        SharingModule,
        BrowserModule,
        ShowHidePasswordModule,
        NgxLoadingModule.forRoot({
            fullScreenBackdrop: true
        }),
        IgxCalendarModule,
        IgxDialogModule,
        NgxPaginationModule,
        HeadroomModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            maxOpened: 1

        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        BsDropdownModule.forRoot(),
        ViewsModule,
        AdminModule,
        RouterModule.forRoot(routes, {
            useHash: false,
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
        HammerModule,
        CommonModule,
        //GoogleTagManagerModule.forRoot({id: 'GTM-PL6NC6S',})
    ],

    providers: [
        ProductsService,
        BookingService,
        AgmService,
        SearchService,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'fr'
        },
        AuthService,
        {provide: 'googleTagManagerId',  useValue: 'GTM-PL6NC6S'},
    ],

    bootstrap: [AppComponent]
})

export class AppModule {
}
