import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProductsService} from '../../../../services/products/products.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {ResponseData} from '../../../../models/response-data.model';
@Component({
    selector: 'app-rules-room-form',
    templateUrl: './rules-room-form.component.html',
    styleUrls: ['./rules-room-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RulesRoomFormComponent implements OnInit {
    @Input() formMode = 'create';

    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    formRoom: FormGroup;
    product: any;
    loading;
    loaded = false;
    submitted = false;
    productId;
    errors: any;
    timeModel = {
        arrival: {
            hour: 12, minute: 0
        },
        departure: {
            hour: 12, minute: 0
        },
    };

    rulesModel = [
        {
            key: 'authorized-smoking',
            text: 'Fumeurs autorisés',
        },

        {
            key: 'authorized-pets',
            text: 'Animaux domestiques autorisés',

        },

        /*{
            key: 'authorized-events',
            text: 'Fêtes/Evènements autorisés',

        },

        {
            key: 'authorized-children',
            text: 'Adapté aux enfants (2-12 ans)',
        },

        {
            key: 'authorized-babies',
            text: 'Adapté aux bébés (-2 ans)',
        },*/
    ];


    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.initForm();
        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {
                    this.productId = params.id;
                }
            }
        );

        this.formRoom.get('no-arrival-time').valueChanges.subscribe(
            (val) => {
                if (val) {
                    this.formRoom.get('arrival-time').disable();
                } else {
                    this.formRoom.get('arrival-time').enable();
                }
            }
        );

        this.formRoom.get('no-departure-time').valueChanges.subscribe(
            (val) => {
                if (val) {
                    this.formRoom.get('departure-time').disable();
                } else {
                    this.formRoom.get('departure-time').enable();
                }
            }
        );

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {
                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;

                                        if (this.product.settings && this.product.settings.value['house-rules']) {
                                            this.formRoom.patchValue(this.product.settings.value['house-rules']);
                                        }
                                        this.loaded = true;
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },
                                (error) => {
                                    console.log(error);
                                    if (this.formMode === 'create') {
                                        this.router.navigate(['/rooms/new']);
                                    }

                                    if (this.formMode === 'edit') {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();
        }

    }

    checkUser() {
        if (this.authUser && this.product && this.product.published) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;
    }

    doSubmit() {
        this.submitted = true;

        if (this.formRoom.invalid) {
            return;
        }

        this.loading = true;

        console.log(this.formRoom.value);

        const formData = {
            'house-rules': this.formRoom.getRawValue(),
            has_settings: true
        };

        this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
            (product: any) => {
                this.product = product;
                this.loading = false;

                if (this.formMode === 'create') {

                    this.router.navigate([this.productService.getNextStep()]).then(
                        () => {
                            this.toastr.success('Informations de règlement intérieur modifiées');
                        }
                    );
                }

                if (this.formMode === 'edit') {
                    this.toastr.success('Informations de règlement intérieur modifiées');

                }

            },
            (errors: any) => {
                this.loading = false;
                this.errors = errors.error.error;

            }
        );
    }

    previous() {
        return this.productService.getPreviousStep();
    }


    initForm() {
        this.formRoom = this.fb.group({
            authorizations: this.fb.group({
                'authorized-smoking': [false],
                // 'authorized-events': [false],
                'authorized-pets': [false],
               // 'authorized-children': [false],
               // 'authorized-babies': [false],
            }),
            'no-arrival-time': [false],
            'no-departure-time': [false],
            'arrival-time': [this.timeModel.arrival],
            'departure-time': [this.timeModel.departure],
        });
    }

}
