import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../services/user/user.service';
import {AccountService} from '../../../services/auth/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MustMatch} from '../../../setting/must-match.validator';

@Component({
    selector: 'app-new-password-shapart',
    templateUrl: './new-password-shapart.component.html',
    styleUrls: ['./new-password-shapart.component.css']
})
export class NewPasswordShapartComponent implements OnInit {

    redirectTo: string;

    resetPasswordForm: FormGroup;
    loading = false;
    errors: any;
    error;
    response: {status: string, message: string};
    formErrors;
    email: string;
    token: string;

    constructor(private toastr: ToastrService,
                private router: Router,
                private accountService: AccountService,
                private formBuilder: FormBuilder,
                private userService: UserService,
                private route: ActivatedRoute) {

        this.token = this.route.snapshot.params.token;

        this.route.queryParams.subscribe(
            params => {
                this.email = params.email;
            });
    }

    ngOnInit() {

        this.initForm();
        this.redirectTo = this.route.snapshot.queryParams.redirect_to || '/';
    }

    initForm() {
        this.resetPasswordForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]],
        }, {
            validators: MustMatch('password', 'password_confirmation')
        });

        if (this.email) {
            this.resetPasswordForm.setValue({
                    email: this.email,
                }
            );
        }
    }

    doSubmit() {

        this.error = null;

        if (!this.resetPasswordForm.invalid) {

            this.loading = true;

            const email = this.email;
            const password = this.resetPasswordForm.get('password').value;
            const passwordConfirmation = this.resetPasswordForm.get('password_confirmation').value;

            const data = {
                email,
                password,
                password_confirmation: passwordConfirmation,
                token: this.token
            };

            this.userService.resetPassword(data).then(
                (response: any) => {

                    console.log(response);
                    if (response.status === 'passwords.reset') {
                        this.response = response;

                        this.accountService.login(email, password).then(
                            () => {

                                this.loading = false;

                                this.router.navigateByUrl(this.redirectTo).then(
                                    () => {
                                        this.toastr.success(response.message);
                                    }
                                );
                            },
                            (err) => {
                                this.loading = false;

                                console.log(err);
                                this.errors = err.error.error;

                            }
                        );

                    } else if (typeof response.message === 'string') {
                        this.loading = false;

                        this.error = response.message;
                    } else {
                        this.loading = false;

                        this.toastr.error('Désolé une erreur s\'est produite');
                    }
                },
                error => {

                    this.loading = false;
                    this.errors = error.error.error;

                    console.log(error);

                }
            );
        } else {
            this.formErrors = this.resetPasswordForm.controls;
            return;

        }

    }


}
