import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/admin/services/admin.service';

@Component({
  selector: 'app-role-droit-admin',
  templateUrl: './role-droit-admin.component.html',
  styleUrls: ['./role-droit-admin.component.css']
})
export class RoleDroitAdminComponent implements OnInit {
  breadcrumb = breadcrumb.role_admin;
  roles: any;
  angForm: any;
  submitted = false;
  id: any;
  constructor(private serviceAdmin: AdminService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService) {
  
  }

  initForm() {
    this.angForm = this.fb.group({
      roles: [null, [Validators.required]],
    });
  }


  get f() {
    return this.angForm.controls;
  }


  ngOnInit() {
    this.initForm();
    let data = {
      breadcrumb: this.breadcrumb,
      id: this.route.snapshot.paramMap.get('id'),
      title: "Modifier le rôle de l'administrateur",
      tag:"role"
    }
    setTimeout(() => {
      this.serviceAdmin.sendBreadcrumb(data);
    }, 1000);

    this.getAllRoles();
  }

  getAllRoles() {
    this.serviceAdmin.getAllRoles().then((data: any) => {
      console.log('list roles', data.data);
      this.roles = data.data;
    }, err => {
      console.log('erreur', err);
    });

  }

  saveRole() {
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append('id', this.angForm.value.roles);

    this.serviceAdmin.updateRoleAdmin(formData, this.route.snapshot.paramMap.get('id')).then((data: any) => {
      console.log('data', data);
      this.toastr.success('Modification effectuée','Succès');
    }, err => {
      console.log('erreur', err);
    })
  }

 
}
