import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../../services/app/app.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth/auth.service';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {UserService} from '../../../../services/user/user.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Country} from '../../../../models/app.model';
import {ToastrService} from 'ngx-toastr';
declare var $: any;

@Component({
    selector: 'app-payments-preference',
    templateUrl: './payments-preference.component.html',
    styleUrls: ['./payments-preference.component.css']
})
export class PaymentsPreferenceComponent implements OnInit, OnDestroy {

    authUser: AuthUser;
    userSubscription: Subscription;

    formBank: FormGroup;
    formPhone: FormGroup;
    payPalForm = new FormControl('', [Validators.required, Validators.email]);
    formDefault = new FormControl('');
    loaded: boolean;
    settings: any;
    edit: string = null;
    submitted = {
        bank_details: false,
        payPal: false,
        mobile_money: false
    };
    loading = {
        bank_details: false,
        payPal: false,
        mobile_money: false
    };
    errors = {
        bank_details: null,
        payPal: null,
        mobile_money: null
    };
    countries: Country[] = [];

    constructor(private appService: AppService,
                private fb: FormBuilder,
                private authService: AuthService,
                private userService: UserService,
                private route: ActivatedRoute,
                private alert: ToastrService,
                private router: Router) { }

    ngOnInit() {
        this.appService.setAppTitle('Préférences de virement');
        this.initForm();
        this.loadScripts();
        this.countries = this.appService.getCountries();

        this.formDefault.valueChanges.subscribe(
            value => {
                if (value !== this.getDefaultPayment()) {
                    this.saveDefaultBankDetail();
                }
            }
        );

        this.route.params.subscribe(
            (params: Params) => {
                const newEdit: string = params.hasOwnProperty('subject') ? params.subject : null;
                this.changeEditSubject(newEdit);
            }
        );

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                this.authUser = user;

                if (this.authUser) {
                    this.getUserSettings();
                }
            }
        );

        this.authService.emitUser();
    }

    saveDefaultBankDetail() {
        const formData = {
            setting: 'default_payment_preference',
            default_payment_preference: this.formDefault.value
        };

        this.userService.saveUserSettings(this.authUser.id, formData).then(
            (setting: any) => {
                console.log(setting);
                this.settings = setting;
            },
            err => {
                this.alert.error(err.error.error);
                this.setDefaultPayment();
            }
        );

    }

    deleteBankDetail(name: string) {
        this.loading[name] = true;
        const formData: any = {};

        formData.setting = 'payment_preferences';
        formData.subject = name;

        this.userService.deleteUserSettings(this.authUser.id, name, formData).then(
            (setting: any) => {
                this.settings = setting;
                this.hidePaymentInfo(name);
                this.setDefaultPayment();
                this.loading[name] = false;
                this.alert.info('Vos informations bancaires on été supprimés avec succès');
            },
            () => {
                this.alert.error('Désolé, une erreur d\'est produite');
                this.loading[name] = false;
            }
        );
    }

    saveBankDetails(name: string) {
        this.submitted[name] = true;

        if (name === 'bank_details' && this.formBank.invalid) {
            return;
        }

        if (name === 'payPal' && this.payPalForm.invalid) {
            return;
        }

        if (name === 'mobile_money' && this.formPhone.invalid) {
            return;
        }

        this.loading[name] = true;

        const formData: any = {};

        if (name === 'bank_details') {
            formData[name] = this.formBank.value;
        }

        if (name === 'payPal') {
            formData[name] = {email_paypal: this.payPalForm.value};
        }

        if (name === 'mobile_money') {
            formData[name] = this.formPhone.value;
        }

        formData.setting = 'payment_preferences';
        formData.subject = name;

        this.userService.saveUserSettings(this.authUser.id, formData).then(
            (setting: any) => {
                this.settings = setting;
                this.loading[name] = false;
                this.submitted[name] = false;

                this.edit = null;
                this.setDefaultPayment();

                this.alert.success('Vos informations bancaires on été modifiées avec succès');
            },
            err => {
                this.errors[name] = err.error.error;
                this.loading[name] = false;
            }
        );
    }

    getUserSettings(): void {
        this.userService.getUserSettings(this.authUser.id).then(
            (setting: any) => {
                this.settings = setting;
                console.log(this.settings);
                this.loaded = true;
                this.setPaymentInfo('bank_details');
                this.setPaymentInfo('payPal');
                this.setPaymentInfo('mobile_money');
                this.setDefaultPayment();

                if (!this.edit && !this.hasPaymentSettings('bank_details')) {
                    this.changeEditSubject('bank_details');
                }
            }
        );
    }

    hasPaymentSettings(name: string): boolean {
        const settings = this.settings;
        return settings != null
            && settings.value != null
            && settings.value.payment_preferences != null
            && settings.value.payment_preferences[name] != null;
    }

    getPaymentInfo(name: string): any {
        return this.hasPaymentSettings(name)
            ? this.settings.value.payment_preferences[name][0]
            : null;
    }

    resetInterface() {
        this.submitted.payPal = false;
        this.submitted.mobile_money = false;
        this.submitted.bank_details = false;

        this.loading.bank_details = false;
        this.loading.mobile_money = false;
        this.loading.payPal = false;

        this.errors.payPal = null;
        this.errors.mobile_money = null;
        this.errors.bank_details = null;
    }

    changeEditSubject(name: string): void {

        if (!this.loading[name]) {
            this.resetInterface();
            this.setPaymentInfo(this.edit);

            if (name === 'bank_details') {
                this.hidePaymentInfo(name);
            } else {
                this.setPaymentInfo(name);
            }

            this.edit = name;
        }
    }

    setDefaultPayment() {
        this.formDefault.setValue(this.getDefaultPayment());
    }

    getDefaultPayment() {
        if (this.settings.value && this.settings.value.default_payment_preference) {
            return this.settings.value.default_payment_preference;
        }
        return null;
    }

    setPaymentInfo(name: string): void {
        const data = this.getPaymentInfo(name);

        if (name === 'bank_details') {
            this.formBank.patchValue(data ? data : {});
        }

        if (name === 'payPal') {
            this.payPalForm.setValue(data ? data.email_paypal : null);
        }

        if (name === 'mobile_money') {
            this.formPhone.patchValue(data ? data : {});
        }

    }

    hidePaymentInfo(name: string): void {
        if (name === 'bank_details') {
            this.formBank.reset();
        }

        if (name === 'payPal') {
            this.payPalForm.reset();
        }

        if (name === 'mobile_money') {
            this.formPhone.reset();
        }

    }

    initForm(): void {
        this.formBank = this.fb.group({
            bank_domiciliation: ['', [Validators.required]],
            account_owner: ['', [Validators.required]],
            bank_name: ['', [Validators.required]],
            iban_number: ['', [Validators.required]],
            bic_number: ['', [Validators.required]],

        });

        this.formPhone = this.fb.group({
            mobile_money_phone: ['', [Validators.required]],
            mobile_owner: ['', [Validators.required]],
            mobile_operator_name: ['', [Validators.required]],
        });
    }

    get f1() {
        return this.formBank.controls;
    }

    get f2() {
        return this.formPhone.controls;
    }

    loadScripts() {
        function toggleChevron(e) {
            $(e.target)
                .prev('.card-header')
                .find('i.indicator')
                .toggleClass('ti-angle-down ti-angle-right');

        }
        $('.accordion_2').on('hidden.bs.collapse shown.bs.collapse', toggleChevron);
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

}
