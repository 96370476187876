import { Routes} from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminTemplateComponent } from './template/admin-template/admin-template.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { DetailCustomerComponent } from './customers/detail-customer/detail-customer.component';
import { OwnersComponent } from './owners/owners/owners.component';
import { ListAdminComponent } from './administrators/list-admin/list-admin.component';
import { NewAdminComponent } from './administrators/new-admin/new-admin.component';
import { ListProductsComponent } from './products/list-products/list-products.component';
import { EditAdminComponent } from './administrators/edit-admin/edit-admin.component';
import { ProfilAdminComponent } from './administrators/edit-admin/profil-admin/profil-admin.component';
import { EmailAdminComponent } from './administrators/edit-admin/email-admin/email-admin.component';
import { PasswordAdminComponent } from './administrators/edit-admin/password-admin/password-admin.component';
import { PhotoAdminComponent } from './administrators/edit-admin/photo-admin/photo-admin.component';
import { RoleDroitAdminComponent } from './administrators/edit-admin/role-droit-admin/role-droit-admin.component';
import { RolesAdminComponent } from './administrators/roles-admin/roles-admin.component';
import { NewRoleComponent } from './administrators/roles-admin/new-role/new-role.component';
import { PermissionAdminComponent } from './administrators/permission-admin/permission-admin.component';
// import { AuthGuardService } from './security/service/auth-guard.service';
import { DetailProductComponent } from './products/detail-product/detail-product.component';
import { ListAgentComponent } from './agents/list-agent/list-agent.component';
import { DetailAgentComponent } from './agents/detail-agent/detail-agent.component';
import { AdminGuardService } from './security/service/admin-guard.service';
import { ProductsAgentComponent } from './agents/products-agent/products-agent.component';
import { ListBookingsComponent } from './bookings/list-bookings/list-bookings.component';
import { AdminProfilComponent } from './admin-profil/admin-profil.component';
import { RequestStatusComponent } from './requests/request-status/request-status.component';
import { PaymentComponent } from './payment/payment.component';
import { RequestsComponent } from './requests/requests/requests.component';
import { DetailBookingComponent } from './bookings/detail-booking/detail-booking.component';
import { ListAgenciesComponent } from './agencies/list-agencies/list-agencies.component';
import { DetailAgencyComponent } from './agencies/detail-agency/detail-agency.component';
import { VisitorsComponent } from './visitors/visitors.component';
import { DetailRequestComponent } from './requests/detail-request/detail-request.component';
import { DetailVisitorComponent } from './visitors/detail-visitor/detail-visitor.component';
import { ListPaymentComponent } from './payment/list-payment/list-payment.component';
import { MoreDetailProductComponent } from './products/more-detail-product/more-detail-product.component';
import { MoreDetailCustomerComponent } from './customers/more-detail-customer/more-detail-customer.component';
import { TravelersComponent } from './travelers/travelers.component';
import { DetailTravelerComponent } from './travelers/detail-traveler/detail-traveler.component';
import { DetailOwnerComponent } from './owners/detail-owner/detail-owner.component';
import { DetailRefundComponent } from './payment/refunds/detail-refund/detail-refund.component';
import { ListRefundsComponent } from './payment/refunds/list-refunds/list-refunds.component';
import { TransferPendingComponent } from './payment/transfer/transfer-pending/transfer-pending.component';
import { DetailTransferComponent } from './payment/transfer/detail-transfer/detail-transfer.component';
import { DetailPaymentComponent } from './payment/list-payment/detail-payment/detail-payment.component';
import { ListDataComponent } from './collection-data/list-data/list-data.component';
import { ListRankingHostComponent } from './rankings/hosts/list-ranking-host/list-ranking-host.component';
import { PreferenceTransferComponent } from './payment/preference-transfer/preference-transfer.component';
import { ClaimsComponent } from './claims/claims.component';
import {nameRoutes} from './settings/env.routing';
import { DetailClaimComponent } from './claims/detail-claim/detail-claim.component';
import { CategoriesComponent } from './products/categories/categories.component';
import { GoogleanalyticsComponent } from './googleanalytics/googleanalytics.component';


const CLAIMS_ADMIN:Routes=[
    {
        path:nameRoutes.root,component:ClaimsComponent
    },
    {
        path:nameRoutes.id+nameRoutes.show,component:DetailClaimComponent
    }
]


const AGENT_ADMIN: Routes = [
    { path: 'list', component: ListAgentComponent },
    { path: ':id/show', component: DetailAgentComponent },
    {
        path: ':id/products', component: ProductsAgentComponent,

        // canActivate: [AuthGuardService]
    },
];

const CUSTOMERS_ADMIN: Routes =
    [
        { path: 'list', component: CustomerComponent },
        { path: 'detail-customer/:id', component: DetailCustomerComponent },
        { path: ':id/more', component: MoreDetailCustomerComponent },
        { path: ':id/preferences', component: PreferenceTransferComponent }
    ];

// Routes editer le profil d'un admin
const EDIT_ADMIN: Routes = [
    { path: 'edit/:id', component: ProfilAdminComponent },
    { path: 'email/:id', component: EmailAdminComponent },
    { path: 'password/:id', component: PasswordAdminComponent },
    { path: 'photo/:id', component: PhotoAdminComponent },
    { path: 'roles/:id', component: RoleDroitAdminComponent },
]

const ADMIN_ADMIN: Routes =
    [
        {
            path: 'list',
            component: ListAdminComponent,
            // canActivate: [AuthGuardService]

        },
        {
            path: 'new',
            component: NewAdminComponent,
            // canActivate: [AuthGuardService]
        },
        {
            path: '',
            component: EditAdminComponent,
            children: EDIT_ADMIN
        }
    ];

// Routes gestions des roles 
const ROLE_ADMIN: Routes = [
    {
        path: 'list', component: RolesAdminComponent,
        // data: { permission: 'show_table_roles' },
        // canActivate: [AuthGuardService]

    },
    {
        path: 'new',
        component: NewRoleComponent,
        // canActivate: [AuthGuardService]
    },
    {
        path: ':id/permissions', component: PermissionAdminComponent

    },

]

/*
 Routes gestions des demandes
*/
const REQUEST_ADMIN:Routes=[
    {
        path:'status',
        component:RequestStatusComponent,
    },
    {
        path:'list',
        component:RequestsComponent,
    },
    {
        path:':id/show',
        component:DetailRequestComponent,
    }
];

const OWNERS_ADMIN: Routes =
    [
        {
            path: 'list',
            component: OwnersComponent,

            // canActivate: [AuthGuardService]
        },
        {
            path: ':id/show',
            component: DetailOwnerComponent,

            // canActivate: [AuthGuardService]
        },

    ];


const PRODUCT_ADMIN: Routes = [
    {
        path: 'list',
        component: ListProductsComponent,
        // data: { permission: 'show_table_product' },
        // canActivate: [AuthGuardService]
    },

    {
        path: 'show/:id',
        component: DetailProductComponent,
        // canActivate: [AuthGuardService]
    },
    {
        path: ':id/more',
        component: MoreDetailProductComponent,
        // canActivate: [AuthGuardService]
    },
];

const BOOKING_ADMIN: Routes = [
    {
        path: 'list',
        component: ListBookingsComponent,
        // data: { permission: 'show_table_product' },
        // canActivate: [AuthGuardService]
    },
    {
        path: ':id/show',
        component: DetailBookingComponent,
        // data: { permission: 'show_table_product' },
        // canActivate: [AuthGuardService]
    }
];

const PROFIL_ADMIN:Routes =[
    {
        path: '',
        component: AdminProfilComponent
        
    },
];


const PAYMENT_ADMIN:Routes =[
    {
        path: 'list',
        component: ListPaymentComponent
        
    },
    {
        path: ':id/show',
        component: DetailPaymentComponent
        
    },
    {
        path: 'transactions',
        component: PaymentComponent
        
    }
];

const TRANSFERS_ADMIN:Routes=[
    {
        path: ':id/show',
        component: DetailTransferComponent
        
    },
    {
        path: 'list',
        component: TransferPendingComponent
        
    },
];


const AGENCIES_ADMIN:Routes =[
    {
        path: 'list',
        component: ListAgenciesComponent   
    } ,
    {
        path: ':id/show',
        component: DetailAgencyComponent  
    }  
];

const VISIORS_ADMIN:Routes =[
    {
        path: 'list',
        component: VisitorsComponent   
    },
    {
        path: ':id/show',
        component: DetailVisitorComponent   
    } 
];



const TRAVELERS_ADMIN:Routes=[
    {
        path: 'list',
        component: TravelersComponent   
    } ,
    {
        path: ':id/show',
        component: DetailTravelerComponent   
    } ,
]
const REFUND_PAYMENT_ADMIN=[
    {
        path:'list',
        component:ListRefundsComponent
    },
    {
        path:':id/show',
        component:DetailRefundComponent
    }
]

const RANKINGS_ADMIN=[
    {
        path:"hosts/list",
        component:ListRankingHostComponent
    }
]

const DATA_ADMIN=[
    {
        path:"list",
        component:ListDataComponent
    }
]

const CATEGORIES_ADMIN=[
    {
        path:nameRoutes.list,
        component:CategoriesComponent
    }
]

const ROUTES_ADMIN: Routes = [
    { path: nameRoutes.dashboard, component: DashboardComponent },
    { path: 'owners', children: OWNERS_ADMIN },
    { path: 'admins', children: ADMIN_ADMIN },
    { path: 'products', children: PRODUCT_ADMIN },
    { path: 'roles', children: ROLE_ADMIN },
    { path: 'agents', children: AGENT_ADMIN },
    { path: 'customer', children: CUSTOMERS_ADMIN },
    { path: 'bookings', children: BOOKING_ADMIN },
    { path: 'profil', children: PROFIL_ADMIN },
    { path: 'requests', children: REQUEST_ADMIN },
    { path: 'payments', children: PAYMENT_ADMIN },
    { path: 'agencies', children: AGENCIES_ADMIN },
    {path: 'visitors', children:VISIORS_ADMIN},
    {path: nameRoutes.accounting,loadChildren:()=> import('../admin/accounting/admin-accounting.module').then(m => m.AdminAccountingModule)},
    {path: 'travelers', children:TRAVELERS_ADMIN},
    {path: 'refunds', children:REFUND_PAYMENT_ADMIN},
    {path:'transfers',children:TRANSFERS_ADMIN},
    {path:'rankings',children:RANKINGS_ADMIN},
    {path:'collectes',children:DATA_ADMIN},
    {path:nameRoutes.claims,children:CLAIMS_ADMIN},
    {path:nameRoutes.categories,children:CATEGORIES_ADMIN},
    {path:nameRoutes.analytics,
     component:GoogleanalyticsComponent
    }

      
];


export const routes: Routes =
    [
        {
            path: nameRoutes.root,
            component: AdminTemplateComponent,
            children: ROUTES_ADMIN,
            canActivate: [AdminGuardService]
        },
        { path: nameRoutes.login, component: LoginComponent },
        { 
            path: 'not-found',
            redirectTo: 'login',
            pathMatch: 'prefix'
         },
        // { 
        //     path: '**',
        //     redirectTo: 'login',
        //     pathMatch: 'prefix'

        // },

    ];




