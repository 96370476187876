import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable.permission.json';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../services/admin.service';
declare var $: any;

@Component({
  selector: 'app-permission-admin',
  templateUrl: './permission-admin.component.html',
  styleUrls: ['./permission-admin.component.css']
})
export class PermissionAdminComponent implements OnInit,AfterViewInit {
  breadcrumb=breadcrumb.permission;
  permission=datatable.list_permission;
  operation: any;
  id: string;
  el: ElementRef;
  data_operations=[];
  data_operations1=[];
  functionalities: any;
  constructor(private adminService :AdminService,private route: ActivatedRoute,private toastr: ToastrService) {
   
   }
  ngOnInit() {
    function toggleChevron(e) {
      $(e.target)
          .prev('.card-header')
          .find("i.indicator")
          .toggleClass('ti-minus ti-plus');
  }
  $('.accordion_2').on('hidden.bs.collapse shown.bs.collapse', toggleChevron);
    this.id=this.route.snapshot.paramMap.get('id');
  
  this.route.parent.url.subscribe((urlPath) => {
    const url = urlPath[urlPath.length - 1].path;
    // this.getRole();
    this.getRole1();
})
  }

  protected getListOperation(){
    this.adminService.getAllOperations().then((data:any)=>{
        this.operation=data.data.data;
       // console.log('roles', this.operation);
    },err=>{
    })
  }


  change(e){
    // console.log(e.target.checked,e.target.value);
 
    if(e.target.checked==true){
     let data={
       status:"active",
       operation_id:e.target.value.split('@')[1],
       role_id:this.id,
       type:e.target.value.split('@')[0]
     }
     this.addRoleOperations(data,'Activé');
  
    }else if(e.target.checked==false){
      let data={
        status:"inactive",
        operation_id:e.target.value.split('@')[1],
        role_id:this.id,
        type:e.target.value.split('@')[0]
      }
      this.addRoleOperations(data,'Déactivé');
    }
   
  }

  change1(e){
 
    if(e.target.checked==true){
     let data={
       status:"active",
       functionality_id:e.target.value.split('@')[1],
       role_id:this.id,
       name:e.target.value.split('@')[0]
     }
      this.addRoleFunctionalities(data,'Activé');
     console.log('active',data);
    }else if(e.target.checked==false){
      let data={
        status:"inactive",
        functionality_id:e.target.value.split('@')[1],
        role_id:this.id,
        name:e.target.value.split('@')[0]
      }
      console.log('inactive',data);
       this.addRoleFunctionalities(data,'Déactivé');
    }
   
  }

  addRoleOperations(data,message){
    this.adminService.addRoleOperations(data).then((data:any)=>{
      this.toastr.success(message,'Success');
     },err=>{
       console.log('erreur',err);
     });
  }

  
  addRoleFunctionalities(data,message){
    this.adminService.addRoleFunctionalities(data).then((data:any)=>{
      this.toastr.success(message,'Success');
     },err=>{
       console.log('erreur',err);
     });
  }
  

  getRole(){
    this.adminService.getRole(this.id).then((data:any)=>{
      this.data_operations=[];
        data.operations.forEach(element => {
          this.data_operations[element.id]=element;
        });
      
    },err=>{
      console.log('----------------------',err);
    });
  }

  getRole1(){
    this.adminService.getRole(this.id).then((data:any)=>{
      this.functionalities=data.functionalities;
    },err=>{
      console.log('----------------------',err);
    });
  }

  hasChecked(id){
    const functionality=  this.functionalities.find(element =>  
      parseInt(id)===element.pivot.functionality_id
     );
     if(functionality){
       return true;
     }else{
       return false;
     }
  }

  
  ngAfterViewInit(){
    this.getListOperation();
  }
}
