import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import {Router,NavigationEnd } from '@angular/router';
import { AdminAccountService } from '../../services/admin.account.service';
import {urlPages} from '../../settings/env.routing';
import * as security from '../../json/security/security.json';
@Component({
  selector: 'app-navbar-admin-shapart',
  templateUrl: './navbar-admin-shapart.component.html',
  styleUrls: ['./navbar-admin-shapart.component.css']
})
export class NavbarAdminShapartComponent implements OnInit {
  previousUrl: string = null;
  currentUrl: string = null;
  security=security.security;
  url=urlPages;
  constructor( private router: Router,private authAdmin:AdminAccountService ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.authAdmin.setPreviousUrl(this.previousUrl);
     
    });
    // console.log('currency',this.security);
  }

  

}
