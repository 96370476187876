import {Component, Input, OnInit} from '@angular/core';
import {ProductMapAddress} from '../../../../models/product.model';

@Component({
    selector: 'app-product-address',
    templateUrl: './product-address.component.html',
    styleUrls: ['./product-address.component.css']
})
export class ProductAddressComponent implements OnInit {

    @Input() zoom = 14;
    @Input() product: any;
    @Input() loading = false;

    address: ProductMapAddress;

    constructor() { }

    ngOnInit() {
        if (this.product.latitude && this.product.longitude) {
            this.address = {
                latitude: parseFloat(this.product.latitude),
                longitude: parseFloat(this.product.longitude),
                country: this.product.country,
                town: this.product.town,
                address: this.product.address
            };
        }
    }

}
