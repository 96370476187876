import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HttpAdminService } from '../../services/http-admin.service';
import {urlPages} from '../../settings/env.routing'
@Injectable({
  providedIn: 'root'
})
export class AdminGuardService {

  constructor(
    private httpAdminService:HttpAdminService,
    private router: Router,
    private toastr: ToastrService) {

   }

   canActivate(
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

if (!this.httpAdminService.isAuth) {
    this.router.navigate([urlPages.pageLogin], {queryParams: {redirect_to: state.url}}).then(
    () => {
        this.toastr.error('Vous devez vous connecter pour continuer');
    }
);
return false;

}

return true;
}
}
