import {AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {Gallery, GalleryRef} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
interface Interface {
    url: string;
    description: string;
    index: number;
    name: string;
}
@Component({
    selector: 'app-product-images-preview',
    templateUrl: './product-images-preview.component.html',
    styleUrls: ['./product-images-preview.component.scss'],
})
export class ProductImagesPreviewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('thumbTemplate') thumbTemplate: TemplateRef<any>;
    @ViewChild('itemTemplate') itemTemplate: TemplateRef<any>;

    @Input() product: any;
    loaded = false;

    galleryId = 'gallery';


    constructor(private gallery: Gallery,
                private lightbox: Lightbox,
                private renderer: Renderer2) { }

    ngOnInit() {

        this.lightbox.opened.subscribe(
            () => {
                this.renderer.setStyle(document.getElementById('header'), 'display', 'none');
            }
        );

        this.lightbox.closed.subscribe(
            () => {
                this.renderer.setStyle(document.getElementById('header'), 'display', 'block');
            }
        );

        this.loadGallery();
    }

    getFrontImage() {
        return this.product.images.find(
            (image) => {
                return image.name === 'front_image';
            }
        ) || this.product.images[0];
    }

    getHiHighlightedImageIndex(): number {
        let index = 0;
        this.product.images.findIndex(
            (image, i) => {
                if (image.name === 'front_image') {
                    index =  i;
                }
            }
        );

        return index;
    }

    openInFullScreen(index: number) {
        console.log(index);
        this.lightbox.open(index, this.galleryId);
    }

    getOtherImages(): Interface[] {
        const images: Interface[] = [];

        if (this.product.images.length > 1) {
            this.product.images.forEach(
                (image, index) => {
                    if (index !== this.getHiHighlightedImageIndex()) {
                        const img: Interface = {
                            index,
                            name: image.name,
                            description: image.description,
                            url: image.url
                        };
                        images.push(img);
                    }
                }
            );
        }

        return images;
    }

    loadGallery(): void {
        const galleryRef: GalleryRef = this.gallery.ref(this.galleryId);
        this.product.images.forEach(
            image => {
                galleryRef.addImage({src: image.url, thumb: image.url, title: image.description});
                // images.push({data: {src: image.url, thumb: image.url, title: image.description}, type: 'image'});
            }
        );

    }

    ngAfterViewInit(): void {
        this.gallery.ref(this.galleryId).setConfig({
            thumbTemplate: this.thumbTemplate,
            itemTemplate: this.itemTemplate,
            counterPosition: 'bottom'
        });
    }

    ngOnDestroy(): void {
        this.gallery.ref(this.galleryId).destroy();
        this.renderer.setStyle(document.getElementById('header'), 'display', 'block');
    }
}
