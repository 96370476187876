import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Image, UploadedImage} from '../../../../../models/Image';
import {AuthUser} from '../../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../../../../../services/user/user.service';
import {FormWizardService} from '../../../../../services/form-wizard/form-wizard.service';
import {ProductsService} from '../../../../../services/products/products.service';

@Component({
    selector: 'app-verify-identity',
    templateUrl: './verify-identity.component.html',
    styleUrls: ['./verify-identity.component.css']
})
export class VerifyIdentityComponent implements OnInit, OnDestroy {
    @Input() mode = 'product';
    @Input() documents: any = [];
    @Input() authUser: AuthUser;
    idType = null;
    identityForm: FormGroup;
    files = {
        recto: {
            isUploading: false,
            file: null,
            percentageLoaded: 0
        },

        verso: {
            isUploading: false,
            file: null,
            percentageLoaded: 0
        },
    };
    userSubscription: Subscription;
    loading = true;
    loaded = false;
    submitted: boolean;

    upLoadedImagesRecto: UploadedImage[] = [];
    upLoadedImagesVerso: UploadedImage[] = [];
    upLoadedImagesPassport: UploadedImage[] = [];
    upLoadedImagesAddressProof: UploadedImage[] = [];
    highlightedImageRecto: UploadedImage;
    highlightedImageVerso: UploadedImage;
    highlightedImagePassport: UploadedImage;
    highlightedImageAddressProof: UploadedImage;
    documentTitle: string;
    documentType: any;
    private formWizardSubscription: Subscription;
    private uploadedAddressProofs: UploadedImage[];

    constructor(private userService: UserService,
                private authService: AuthService,
                private httpClient: HttpClient,
                private toastr: ToastrService,
                private route: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder,
                private formWizardServive: FormWizardService,
                private productService: ProductsService) { }

    ngOnInit(): void {
        this.initForm();

        if (this.authUser) {
            this.formWizardSubscription = this.formWizardServive.formWizardSubmittedSubject.subscribe(
                submitted => {
                    if (submitted) {
                        this.doSubmit();
                    }
                }
            );
            this.identityForm.get('identity_type').valueChanges.subscribe(
                val => {
                    this.idType = null;

                    const formData = new FormData();

                    formData.append('identity_type', val);
                    formData.append('_method', 'PUT');

                    this.userService.editProfile(formData, this.authUser.id).then(
                        (user: AuthUser) => {
                            this.authService.authUser.identity_type = user.identity_type;
                            this.authService.emitUser();
                            this.idType = val;
                        },
                        () => {
                            this.toastr.error('Désole une erreur s\'est produite');
                        }
                    );
                }
            );

            this.identityForm.get('document_type').valueChanges.subscribe(
                val => {
                    this.documentType = val;

                    if (this.upLoadedImagesAddressProof?.length > 0 &&
                        this.upLoadedImagesAddressProof[this.upLoadedImagesAddressProof.length - 1].hasJustUploaded) {
                        this.upLoadedImagesAddressProof[this.upLoadedImagesAddressProof.length - 1].hasJustUploaded = false;
                        this.uploadedAddressProofs.push(this.upLoadedImagesAddressProof[this.upLoadedImagesAddressProof.length - 1]);
                    }

                    this.upLoadedImagesAddressProof = [];
                    if (this.uploadedAddressProofs?.length > 0) {
                        const uploadedImage = this.uploadedAddressProofs.find(
                            (document) => {
                                return document.uploadName === val;
                            }
                        );

                        if (uploadedImage) {
                            const uploadedImages: UploadedImage[] = [];
                            uploadedImages.push(uploadedImage);
                            this.upLoadedImagesAddressProof = uploadedImages;
                        }
                    }

                    switch (val) {
                        case 'water_receipt':
                            this.documentTitle = 'Quittance d\'eau en votre nom';
                            break;
                        case 'electricity_receipt':
                            this.documentTitle = 'Quittance d\'électicité en votre nom ';
                            break;
                    }

                    const formData = new FormData();

                    formData.append('address_proof_type', val);
                    formData.append('_method', 'PUT');

                    this.userService.editProfile(formData, this.authUser.id).then(
                        (user: AuthUser) => {
                            this.authService.authUser.address_proof_type = user.address_proof_type;
                            this.authService.emitUser();
                        },
                        () => {
                            this.toastr.error('Désole une erreur s\'est produite');
                        }
                    );
                }
            );

            if (this.authUser.identity_type && this.documents) {
                this.identityForm.controls.identity_type.setValue(this.authUser.identity_type);
                const uploadedImagesRecto: UploadedImage[] = [];
                const uploadedImagesVerso: UploadedImage[] = [];
                const uploadedImagesPassport: UploadedImage[] = [];
                const uploadedImagesAddressProof: UploadedImage[] = [];
                const uploadedAddressProofs: UploadedImage[] = [];

                this.documents.forEach(
                    (image: Image) => {
                        const uploadedImage: UploadedImage = {
                            id: image.id,
                            uploadName: image.title,
                            hasUploaded: true,
                            isUploading: false,
                            link: image.url,
                            type: image.type,
                            url: image.url
                        };

                        if (image.title === this.authUser.address_proof_type) {
                            this.highlightedImageAddressProof = uploadedImage;
                            uploadedImagesAddressProof.push(uploadedImage);
                            uploadedAddressProofs.push(uploadedImage);
                            this.uploadedAddressProofs = uploadedAddressProofs;
                            this.identityForm.controls.document_type.setValue(this.authUser.address_proof_type);
                        }

                        if (image.title === 'user_identity_image_recto') {
                            this.highlightedImageRecto = uploadedImage;
                            uploadedImagesRecto.push(uploadedImage);
                        }

                        if (image.title === 'user_identity_image_verso') {
                            this.highlightedImageVerso = uploadedImage;
                            uploadedImagesVerso.push(uploadedImage);
                        }

                        if (image.title === 'user_passport_image') {
                            this.highlightedImagePassport = uploadedImage;
                            uploadedImagesPassport.push(uploadedImage);
                        }
                    }
                );
                this.upLoadedImagesRecto = uploadedImagesRecto;
                this.upLoadedImagesVerso = uploadedImagesVerso;
                this.upLoadedImagesPassport = uploadedImagesPassport;
                this.upLoadedImagesAddressProof = uploadedImagesAddressProof;
                this.loading = false;
                this.loaded = true;

            }

        }
    }

    getAcceptedFormats(): any {
        return [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
    }

    doSubmit() {

        this.submitted = true;

        if (this.identityForm.invalid) {
            return;
        }

        if (!this.checkFiles()) {
            this.toastr.error('Veuillez charger votre pièce d\'identité pour continuer');
            return;
        }

        this.loading = true;

        if (this.mode === 'verify') {
            this.formWizardServive.next();
        }

        if (this.mode === 'product') {
            this.router.navigate([this.next()]).then(
                () => {
                    this.toastr.success('Les images de votre pièce ont été transmises');
                }
            );
        }
    }

    get f() {
        return this.identityForm.controls;
    }

    checkFiles() {

        if (this.idType === 'passport' && this.upLoadedImagesPassport.length === 0) {
            return false;
        }

        if (this.idType === 'id_card' && this.upLoadedImagesRecto.length === 0) {
            return false;
        }

        if (this.idType === 'id_card' && this.upLoadedImagesVerso.length === 0) {
            return false;
        }

        if (this.upLoadedImagesAddressProof.length === 0) {
            return false;
        }

        return true;
    }

    next(): string {
        return this.productService.getNextStep();
    }

    previous(): string {
        return this.productService.getPreviousStep();
    }

    initForm(): void {
        this.identityForm = this.fb.group({
            identity_type: ['', [Validators.required]],
            document_type: ['', [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.formWizardSubscription) {
            this.formWizardSubscription.unsubscribe();
        }
    }

}
