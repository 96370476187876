import {AfterViewInit, Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {NgbDate, NgbDateStruct, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CategoriesService } from '../../services/categories/categories.service';
import {FormBuilder, FormControl} from '@angular/forms';
import { AgmService } from 'src/app/services/agm/agm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchService} from '../../services/search/search.service';
import {Subscription} from 'rxjs';
import {AppConfig, MapCord} from '../../models/app.model';
import {AppService} from '../../services/app/app.service';
import {SearchModel} from '../../models/search';
declare var $: any;
declare var daterangepicker: any;

@Component({
    selector: 'app-search-bar-apart',
    templateUrl: './search-bar-apart.component.html',
    styleUrls: ['./search-bar-apart.component.css']
})
export class SearchBarApartComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("search") public searchElementRef: ElementRef;

    @Input() mode = 'home';
    @Input() perPage = 15;
    @Input() popoverPlacement = 'bottom';
    @Input() searchs: any = {};

    @Output() searchsChange = new EventEmitter<any>();

    mapCoorSubscription: Subscription;

    fromDate: NgbDate;
    toDate: NgbDate;
    searchControl = new FormControl();
    categoryControl = new FormControl();
    hoveredDate: NgbDate;
    minDate: NgbDateStruct;
    minFromDate;
    maxTodate;
    minDateSelected: NgbDate;
    isFocused: boolean;
    displayMonths = 2;
    adress: any;
    cord = new MapCord();
    maxNumberGuest = 20;
    loading: boolean;
    qtyModel = [
        {
            id: 'adults',
            tittle: 'Adultes',
            qty: 0,
            min: 0,
            max: 20
        },
        {
            id: 'children',
            tittle: 'Enfants (2-12 ans)',
            qty: 0,
            min: 0,
            max: 10
        },

        {
            id: 'babies',
            tittle: 'Bébés (-2 ans)',
            qty: 0,
            min: 0,
            max: 5
        },
    ];
    private previousSearch: any = {};
    private appConfigSubscription: Subscription;
    private searchSubscription: Subscription;
    searchModel: SearchModel;
    currency: string;
    private queryParamsSubscription: Subscription;
    categories: any;
    constructor(private categoriesService: CategoriesService,
                private agmService: AgmService,
                private router: Router,
                public formatter: NgbDateParserFormatter,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private searchService: SearchService,
                private appService: AppService) {}

    ngOnInit() {
        this.minDate = {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date()
        };

        this.categoryControl.valueChanges.subscribe(
            val => {
                if (val) {
                    this.searchs.category = val;
                }
            }
        );

        this.getCategories();
        this.categoryControl.setValue('');

        this.searchSubscription = this.searchService.searchSubject.subscribe(
            (searchModel: SearchModel) => {
                this.searchModel = searchModel;
            }
        );
        this.searchService.emitSearchModel();

        this.mapCoorSubscription = this.agmService.mapCordSubject.subscribe(
            (cord: MapCord) => {

                setTimeout(() => {
                    this.cord = cord;

                    if (cord && !cord.isLoading && cord.status == 'OK') {
                        this.searchControl.setValue(cord.adress);
                        this.searchs.address = cord.adress;
                        this.searchs.latitude = cord.latitude;
                        this.searchs.longitude = cord.longitude;
                    }
                }, 100);
            }
        );

        this.agmService.emitMapCord();

        this.appConfigSubscription  = this.appService.appConfigSubject.subscribe(
            (config: AppConfig) => {

                const currentCurrency = this.currency;
                this.currency = config.currency;
                this.searchs.currency = config.currency;
                const hasDates = this.searchs.dateStart && this.searchs.dateEnd;
                if (this.mode === 'result_search' && this.searchModel.loaded && config.currency !== currentCurrency && hasDates) {
                    console.log(this.mode);
                    this.doSearch();
                }

                if (this.mode === 'result_search' && !this.searchModel.loaded && !this.searchModel.loading) {
                    if (!this.queryParamsSubscription) {
                        this.queryParamsSubscription = this.route.queryParamMap.subscribe(
                            params => {
                                let dateStart;
                                let dateEnd;
                                if (params.get('address')) {
                                    this.searchControl.setValue(params.get('address'));
                                    this.searchs.address = params.get('address');
                                }

                                if (params.get('category')) {
                                    this.categoryControl.setValue(params.get('category'));
                                }

                                if (params.get('latitude')) {
                                    this.searchs.latitude = params.get('latitude');
                                }

                                if (params.get('longitude')) {
                                    this.searchs.longitude = params.get('longitude');
                                }

                                if (params.get('dateStart')) {
                                    dateStart = this.formatter.parse(params.get('dateStart'));
                                }

                                if (params.get('dateEnd')) {
                                    dateEnd = this.formatter.parse(params.get('dateEnd'));
                                }

                                if (dateStart && dateEnd) {
                                    const newDateStart = moment(new Date(this.formatter.format(dateStart)));
                                    const newDateEnd = moment(new Date(this.formatter.format(dateEnd)));

                                    if (newDateStart.isSameOrAfter(new Date()) &&
                                        newDateStart.isBefore(newDateEnd)) {
                                        this.fromDate = new NgbDate(dateStart.year, dateStart.month, dateStart.day);
                                        this.toDate = new NgbDate(dateEnd.year, dateEnd.month, dateEnd.day);
                                    }
                                }

                                if (params.get('adults')) {
                                    this.qtyModel[0].qty = parseInt(params.get('adults'));
                                }

                                if (params.get('children')) {
                                    this.qtyModel[1].qty = parseInt(params.get('children'));
                                }

                                if (params.get('babies')) {
                                    this.qtyModel[2].qty = parseInt(params.get('babies'));
                                }

                                if (params.get('page')) {

                                    const page = parseInt(params.get('page'));
                                    this.searchs.page = parseInt(params.get('page'));
                                    if (this.searchModel.loaded && this.searchModel.data &&
                                        this.searchModel.data.current_page !== page) {

                                        this.doSearch();
                                    }
                                }

                            }
                        );
                    }

                    if (!this.searchModel.loaded) {
                        this.doSearch();
                    }
                }
            }
        );

        this.appService.emitAppConfig();

    }

    getCategories() {
        this.categoriesService.getCategories().then(
            (categories: any) => {
                this.categories = categories;
            }
        );
    }

    doSearch() {

        if (this.fromDate) {
            this.searchs.dateStart = this.formatter.format(this.fromDate);
        }

        if (this.toDate) {
            this.searchs.dateEnd = this.formatter.format(this.toDate);
        }

        if (this.qtyModel[0].qty > 0) {
            this.searchs.adults = this.qtyModel[0].qty;
        }

        if (this.qtyModel[1].qty > 0) {
            this.searchs.children = this.qtyModel[1].qty;
        }

        if (this.qtyModel[2].qty > 0) {
            this.searchs.babies = this.qtyModel[2].qty;
        }

        this.searchsChange.emit(this.searchs);
        this.router.navigate(['search'], {queryParams: this.searchs});

        if (this.mode === 'result_search') {
            this.previousSearch = this.searchs;
            this.searchService.searchProduct(this.searchs);
        }

        const searchMapWp = $('.search_map_wp');

        if (searchMapWp) {
            searchMapWp.slideUp('fast');
        }

    }


    onDateSelection(date: NgbDate) {

        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.isFocused = true;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.isFocused = false;
            this.toDate = date;
        } else if (this.fromDate && !this.toDate && date.equals(this.fromDate)) {
            this.isFocused = false;
            this.toDate = date;
        } else {
            this.isFocused = true;
            this.toDate = null;
            this.fromDate = date;
        }

    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return (date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date));
    }

    isNotAllowed(date: NgbDate) {
        const newDate = moment( new Date(date.year + '-' + date.month + '-' + (date.day + 1)));
        return newDate.isBefore(new Date());
    }

    selectStartDate(date: NgbDate) {
        if (!this.isNotAllowed(date)) {
            this.fromDate = date;
            if (this.toDate && this.toDate.before(date)) {
                this.toDate = null;
            }
        }

        this.verifyDate();
    }

    selectEndDate(date: NgbDate) {
        if (!this.isNotAllowed(date)) {
            this.toDate = date;
            if (this.fromDate && this.fromDate.after(date)) {
                this.fromDate = null;
            }
        }

        this.verifyDate();
    }

    verifyDate() {
        if (this.fromDate && this.toDate && this.fromDate.equals(this.toDate)) {

            console.log(this.formatter.format(this.toDate));

            const formattedToDate = this.formatter.parse(moment(new Date(this.formatter.format(this.toDate))).add(1, 'days').format('YYYY-MM-DD'));

            this.toDate = new NgbDate(formattedToDate.year, formattedToDate.month, formattedToDate.day);
        }
    }

    getStartDate() {

        if (this.fromDate) {
            return this.formatter.format(this.fromDate);
        }

        return null;
    }

    getEndDate() {

        if (this.toDate) {
            return this.formatter.format(this.toDate);
        }

        return null;

    }

    clearDates() {

        this.fromDate = null;
        this.toDate = null;
        this.minFromDate = null;
        this.maxTodate = null;
        this.hoveredDate = null;

        delete this.searchs.dateStart;
        delete this.searchs.dateEnd;
    }

    editQtyInput(index, id, qty) {
        const modelQty = this.qtyModel[index].qty;
        const modelMin = this.qtyModel[index].min;

        if (qty < 0 &&  modelQty > modelMin) {
            this.qtyModel[index].qty += qty;
        }

        if (qty > 0 && this.isValidQtyInput(index, id)) {
            this.qtyModel[index].qty += qty;
        }
    }

    isValidQtyInput(index, id) {
        const modelQty = this.qtyModel[index].qty;
        const modelMax = this.qtyModel[index].max;

        if (modelQty < modelMax && id === 'babies') {
            return true;
        }

        if (modelQty < modelMax && this.getNbrGuests() < this.maxNumberGuest && id !== 'babies') {
            return true;
        }
        return false;
    }

    getNbrGuests() {

        let qty = 0;

        this.qtyModel.map(
            (model) => {
                if (model.id !== 'babies') {
                    qty += model.qty;
                }
            }
        );

        return qty;
    }

    getNbrBabies() {

        let qty = 0;

        this.qtyModel.map(
            (model) => {
                if (model.id === 'babies') {
                    qty += model.qty;
                }
            }
        );

        return qty;
    }

    ngAfterViewInit() {
        this.agmService.placeAutoComplete(this.searchElementRef.nativeElement);
    }

    setCurrentPosition() {
        this.agmService.setCurrentPosition();
    }

    ngOnDestroy(): void {

        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
        if (this.mapCoorSubscription) {
            this.mapCoorSubscription.unsubscribe();
        }

        if (this.appConfigSubscription) {
            this.appConfigSubscription.unsubscribe();
        }

        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        this.searchService.searchModel = new SearchModel();
        this.searchService.emitSearchModel();

        this.agmService.cord = new MapCord();
        this.agmService.emitMapCord();
    }
}
