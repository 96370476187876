import { Component, OnInit } from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {Subscription} from 'rxjs';
import {AppService} from './services/app/app.service';
import {AuthUser} from './models/auth-user.model';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    userSubscription: Subscription;

    //private gtmService: GoogleTagManagerService
    constructor(private authService: AuthService,
                private appService: AppService,
                private title: Title,
                ) {
        if (!this.authService.tokenExist) {
            this.authService.resolveAuthorization();
        }
    }

    ngOnInit() {
        //this.gtmService.addGtmToDom();

        this.appService.fetchCurrencies();

        this.appService.titleSubject.subscribe(
            (title: string) => {

                const formattedTitle = title !== environment.appName ? title + ' | ' + environment.appName : title;

                this.title.setTitle(formattedTitle);
            }
        );

        this.appService.emitAppTitle();

        this.userSubscription = this.authService.userSubject.subscribe(
            (authUser: AuthUser) => {
                if (authUser) {
                    this.appService.appConfig.currency = authUser.currency;
                    localStorage.setItem('currency', authUser.currency);
                    this.appService.emitAppConfig();
                }
            }
        );
    }


}
