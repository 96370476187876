import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import {Subject} from 'rxjs';
import {FormStep} from '../../models/product.model';
import {Router, UrlSegment} from '@angular/router';
import {NavLink} from '../../models/app.model';
import {AppService} from '../app/app.service';

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    userProductSubject = new Subject<any>();
    formWizardSubject = new Subject<FormStep[]>();
    userProduct: any;
    formWizardSteps: FormStep[];

    constructor(private httpClient: HttpClient,
                private authService: AuthService,
                private router: Router,
                private appService: AppService) { }


    checkAvailability(productId, params) {
        return this.authService.get('products/' + productId + '/is_available', params);
    }

    deleteUserProduct(userId, productId) {
        return this.authService.post('users/' + userId + '/products/' + productId, {_method: 'DELETE'} );
    }

    updateContract(userId, productId, contractId, formData: FormData) {
        formData.append('_method', 'PUT');
        return this.authService.post('users/' + userId + '/products/' + productId + '/contracts/' + contractId, formData);
    }

    deleteContract(userId, productId, contractId, formData: FormData) {
        formData.append('_method', 'DELETE');
        return this.authService.post('users/' + userId + '/products/' + productId + '/contracts/' + contractId, formData);
    }

    acceptOrDenyProductInvitation(productId, invitationId, formData: FormData) {
        return this.authService.post(
            'users/products/' + productId + '/invitations/' + invitationId + '/acceptOrDeny', formData);
    }

    cancelProductInvitation(userId, productId, invitationId, formData: FormData) {
        return this.authService.post('users/' + userId + '/products/' + productId + '/invitations/' + invitationId, formData);
    }

    sendProductInvitation(userId, productId, formData: FormData) {
        return this.authService.post('users/' + userId + '/products/' + productId + '/invitations', formData);
    }

    resendProductInvitation(userId, productId, invitationId, formData) {
        return this.authService.post('users/' + userId + '/products/' + productId + '/invitations/' + invitationId + '/resend', formData);
    }

    getProductInvitation(userId, productId, invitationId) {
        return this.authService.get('users/' + userId + '/products/' + productId + '/invitations/' + invitationId);
    }

    getProductContract(userId, productId) {
        return this.authService.get('users/' + userId + '/products/' + productId + '/contracts');
    }

    getUserSuggestions(queryParams: any) {
        return  this.authService.get('users/suggestions', queryParams);
    }

    getEquipments() {
        return  this.authService.get('equipments', {per_page: '*'});
    }

    getTypes() {
        return  this.authService.get('types', {per_page: '*'});
    }

    saveUserProductEquipments(userId, productId, formData) {
        return this.authService.post('users/' + userId + '/products/' + productId + '/equipments', formData);
    }

    getUserProduct(userId, productId) {
        return this.authService.get('users/' + userId + '/products/' + productId);
    }

    createUserProduct(userId, formData) {
        return this.authService.post('users/' + userId + '/products', formData);
    }

    updateUserProduct(userId, productId, formData) {
        formData._method = 'PUT';
        return this.authService.post('users/' + userId + '/products/' + productId , formData);
    }

    updateSchedules(data, productId, userId) {
        return this.authService.post('users/' + userId + '/products/' + productId + '/schedules', data);

    }

    deleteImage(imageId, productId, userId = 1) {

        const formData = new FormData();
        formData.append('_method', 'DELETE');

        return this.authService.post('users/' + userId + '/products/' + productId + '/images/' + imageId, formData);

    }
    getProduct(id: number, params: any = {}) {
        return this.authService.get('products/' + id, params);
    }

    getAuthUserProduct(userId, productId) {
        return new Promise<any>(
            (resolve, reject) => {

                this.getUserProduct(userId, productId).then(
                    (product: any) => {
                        this.userProduct = product;
                        this.emitUserProduct();
                        resolve(product);
                    },

                    (error) => {
                        this.userProduct = null;
                        reject(error);
                    }
                );
            }
        );
    }

    emitUserProduct() {
        this.userProductSubject.next(this.userProduct);
    }

    findStep(tag): FormStep {
        const step = this.formWizardSteps.find(
            (formStep: FormStep) => {
                return formStep.tag === tag;
            }
        );

        if (step) {
            return step;
        }
    }

    findStepIndex(formWizardSteps: FormStep[], tag): number {
        const index = formWizardSteps.findIndex(
            (formStep: FormStep) => {
                return formStep.tag === tag;
            }
        );

        if (formWizardSteps[index]) {
            return index;
        }
    }

    getActiveStepIndex() {

        let index = null;

        if (this.formWizardSteps && this.formWizardSteps.length > 0) {
            index = this.formWizardSteps.findIndex(
                (formStep: FormStep) => {
                    return formStep.isActive;
                }
            );
        }

        return index;
    }

    getActiveStep() {

        let step = null;
        if (this.formWizardSteps && this.formWizardSteps.length > 0) {

            step = this.formWizardSteps.find(
                (formStep: FormStep) => {
                    return formStep.isActive;
                }
            );
        }
        return step;
    }

    getNextStep(): string {
        return this.formWizardSteps ? this.formWizardSteps[this.getActiveStepIndex() + 1].url : null;
    }

    getPreviousStep(): string {
        return this.formWizardSteps ? this.formWizardSteps[this.getActiveStepIndex() - 1].url : null;
    }

    emitFormWizardSteps() {
        this.formWizardSubject.next(this.formWizardSteps);
    }

    isInvitationForm() {
        return this.appService.getUrlSegments().includes('invitations');
    }

    getFormWizardStep(product?: any): FormStep[] {
        try {
            const tag = this.appService.getCurrentUrlLastSegment();

            const steps = [];
            let heading = 'Ajouter une annonce';

            let url: string = null;

            if (product) {
                this.userProduct = null;
                this.formWizardSteps = null;
                url = '/rooms/' + product.id;
            }

            if (this.userProduct) {
                url = '/rooms/' + this.userProduct.id;
            }

            if (this.isInvitationForm()) {

                heading = this.userProduct.user_role === 'owner' ? 'Invitation a devenir agent' : 'Invitation à devenir propriétaire'

                steps.push({
                    url:    url + '/invitations/' + this.userProduct.last_invitation_id + '/preview',
                    tag:   'preview',
                    isRequired: true,
                    isSubmittable: true,
                    isActive: tag === 'preview',
                    heading
                });

                steps.push({
                    url:    url + '/invitations/' + this.userProduct.last_invitation_id + '/property_room_settings',
                    tag:   'property_room_settings',
                    isRequired: true,
                    isSubmittable: true,
                    isActive: tag === 'property_room_settings',
                    heading
                });

            } else {
                steps.push({
                    url:    url + '/category',
                    tag:   'category',
                    isRequired: true,
                    isSubmittable: true,
                    isActive: tag === 'category' || tag === 'new',
                    heading
                });
                steps.push({
                    url:   url + '/bedrooms',
                    tag:   'bedrooms',
                    isRequired: false,
                    isSubmittable: true,
                    isActive: tag === 'bedrooms',
                    heading
                });

                steps.push({
                    url:   url + '/options',
                    tag:   'options',
                    isRequired: true,
                    isSubmittable: true,
                    isActive: tag === 'options',
                    heading

                });

                steps.push({
                    url:   url + '/location',
                    tag:   'location',
                    isRequired: true,
                    isSubmittable: true,
                    isActive: tag === 'location',
                    heading
                });

                steps.push({
                    url:   url + '/house-rules',
                    tag:   'house-rules',
                    isRequired: false,
                    isSubmittable: true,
                    isActive: tag === 'house-rules',
                    heading
                });

                if (this.userProduct && this.userProduct.user_can_complete_profile) {
                    steps.push({
                        url:   url + '/user/informations',
                        tag:   'user.informations',
                        isRequired: true,
                        isSubmittable: true,
                        isActive: tag === 'informations',
                        heading
                    });
                }

                steps.push({
                    url:   url + '/images',
                    tag:   'images',
                    isRequired: false,
                    isSubmittable: false,
                    isActive: tag === 'images',
                    heading
                });

                steps.push( {
                    url:   url + '/schedule',
                    tag:   'schedule',
                    isRequired: true,
                    isSubmittable: true,
                    isActive: tag === 'schedule',
                    heading
                });

                if (this.userProduct && this.userProduct.user_can_verify_identity) {
                    steps.push({
                        url:   url + '/user/identity',
                        tag:   'user.identity',
                        isRequired: true,
                        isSubmittable: true,
                        requiredStep: 'user.informations',
                        isActive: tag === 'identity',
                        heading
                    });
                }

                if (this.userProduct && this.userProduct.user_can_snap_photo) {
                    steps.push({
                        url:   url + '/user/photo',
                        tag:   'user.photo',
                        isRequired: true,
                        isSubmittable: false,
                        requiredStep: 'user.identity',
                        isActive: tag === 'photo',
                        heading
                    });
                }

                steps.push({
                    url:   url + '/property/owner',
                    tag:   'property.owner',
                    isRequired: true,
                    isSubmittable: false,
                    isActive: tag === 'owner',
                    heading
                });

                if (this.userProduct && (this.userProduct.user_role === 'tenant' || this.userProduct.user_role === 'owner')) {
                    steps.push({
                        url:   url + '/property/documents',
                        tag:   'property.documents',
                        isRequired: true,
                        isSubmittable: false,
                        requiredStep: 'property.owner',
                        isActive: tag === 'documents',
                        heading
                    });
                }

                steps.push({
                    url:   url + '/checkout',
                    tag:   'checkout',
                    isRequired: true,
                    isSubmittable: false,
                    isActive: tag === 'checkout',
                    heading
                });

            }
            const formWizardSteps: FormStep[] = (steps as FormStep[]);

            if (!product) {
                this.formWizardSteps = formWizardSteps;
                const activeStep = this.getActiveStep();
                const requiredStep = activeStep && activeStep.requiredStep ? this.findStep(activeStep.requiredStep) : null;

                if (requiredStep && this.userProduct.steps_map[activeStep.requiredStep]) {
                    this.emitFormWizardSteps();
                } else if (requiredStep) {
                    this.router.navigate([requiredStep.url]);
                }
            }

            return formWizardSteps;
        } catch (e) {
            this.router.navigateByUrl('not-found', {skipLocationChange: true});
        }
    }

    getProductDetailsNavLinks(): NavLink[] {
        const urlLastSegment = this.appService.getCurrentUrlLastSegment();

        return  [
            {
                title: 'Aperçu',
                link: '/account/rooms/' + this.userProduct.id + '/preview',
                isActive: urlLastSegment === 'preview'
            },

            {
                title: 'Descriptif du bien',
                link: '/account/rooms/' + this.userProduct.id + '/category',
                isActive: urlLastSegment === 'category'
            },

            {
                title: 'Informations sur le bien',
                link: '/account/rooms/' + this.userProduct.id + '/bedrooms',
                isActive: urlLastSegment === 'bedrooms'
            },

            {
                title: 'Options',
                link: '/account/rooms/' + this.userProduct.id + '/options',
                isActive: urlLastSegment === 'options'
            },

            {
                title: 'Adresse',
                link: '/account/rooms/' + this.userProduct.id + '/location',
                isActive: urlLastSegment === 'location'
            },

            {
                title: 'Règlement intérieur',
                link: '/account/rooms/' + this.userProduct.id + '/house-rules',
                isActive: urlLastSegment === 'house-rules'
            },

            {
                title: 'Images',
                link: '/account/rooms/' + this.userProduct.id + '/images',
                isActive: urlLastSegment === 'images'
            },

            {
                title: 'Disponibilitées et prix',
                link: '/account/rooms/' + this.userProduct.id + '/schedule',
                isActive: urlLastSegment === 'schedule'
            },

            {
                title: 'Informations sur la propriété',
                link: '/account/rooms/' + this.userProduct.id + '/property',
                isActive: urlLastSegment === 'property'
            },

            {
                title: 'Réservation instantanée',
                link: '/account/rooms/' + this.userProduct.id + '/instant_booking',
                isActive: urlLastSegment === 'instant_booking'
            },

            {
                title: 'Paramètres',
                link: '/account/rooms/' + this.userProduct.id + '/settings',
                isActive: urlLastSegment === 'settings'
            },
        ];
    }
}
