import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-options-room',
    templateUrl: './options-room.component.html',
    styleUrls: ['./options-room.component.css']
})
export class OptionsRoomComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}
