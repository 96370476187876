import {Component, Input, OnInit} from '@angular/core';
import {NavLink} from '../../models/app.model';
import {AppService} from '../../services/app/app.service';

@Component({
    selector: 'app-nav-manager',
    templateUrl: './nav-manager.component.html',
    styleUrls: ['./nav-manager.component.css']
})
export class NavManagerComponent implements OnInit {

    @Input() navLinks: NavLink[] = [];
    @Input() navIconStyle: any = {float: 'right', 'margin-top': '-55px'};
    @Input() isCustomActive = true;

    constructor(private appService: AppService) { }

    ngOnInit() {}

    isCurrentUrlSegment(navLink: NavLink): boolean {
        const activeNav = this.navLinks.find(
            (nav: NavLink) => {
                return nav.isActive;
            }
        );

        const currentUrlLastSegment = this.appService.getCurrentUrlLastSegment();

        return !activeNav && navLink.lastSegment ? currentUrlLastSegment === navLink.lastSegment : false;
    }

    toggleManager(): void {

        const managerLeft = document.getElementById('manager-left');
        const managerRight = document.getElementById('manager-right');

        if (getComputedStyle(managerLeft).display !== getComputedStyle(managerRight).display) {
            managerLeft.classList.toggle('d-block');
            managerRight.classList.toggle('d-none');
        }
    }

    activateNavLink(navLink: NavLink) {
        this.navLinks.forEach(
            (nav: NavLink) => {
                nav.isActive = false;
            }
        );

        navLink.isActive = true;
    }
}
