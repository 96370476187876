import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../services/user/user.service';
import {AuthService} from '../../../services/auth/auth.service';
import {AuthUser} from '../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Message} from '../../../models/booking.model';
declare var $: any;
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import Echo from 'laravel-echo';
import {BookingService} from '../../../services/bookings/booking.service';
import {Channel} from 'laravel-echo/dist/channel';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit, OnDestroy {

    authUser: AuthUser;
    authUserSubscription: Subscription;
    allMessages: Message[] = [];
    id: any;
    role: any;
    loaded = false;
    typing;
    loadedMessages: boolean;
    private stopingTyping: any;
    messageArea = new FormControl();
    private echo: any;
    submittedMessage: boolean;
    readingMessages: boolean;
    private currentMessageChannel: Channel;
    private userChannel: Channel;
    bookingDetails: any;
    loadingBookingDetails: boolean;

    constructor(private userService: UserService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private bookingService: BookingService) {
        moment.locale('fr');

    }
    ngOnInit() {

        this.authUserSubscription = this.authService.userSubject.subscribe(
            (authUser: AuthUser) => {
                if (authUser && !this.loaded) {
                    this.authUser = authUser;

                    this.authService.broadcast().then(
                        (echo: Echo) => {
                            this.echo = echo;
                            if (!this.userChannel) {
                                this.subscribeToBroadcastingMessage();
                            }

                        }
                    );

                    this.route.params.subscribe(
                        (params: Params) => {
                            if (params.hasOwnProperty('id') && params.hasOwnProperty('role')) {

                                if (this.currentMessageChannel) {
                                    this.currentMessageChannel.stopListeningForWhisper('typing');
                                }

                                this.loadedMessages = false;
                                this.userService.getUserMessages(authUser.id).then(
                                    (messages: any) => {
                                        const allMessage: Message[] = [];

                                        messages.forEach(
                                            (message: any) => {
                                                allMessage.push(this.getFormattedMessage(message));
                                            }
                                        );

                                        this.id = Number(params.id);
                                        this.getUserSelectionBookingDetails();
                                        this.role = params.role;
                                        this.allMessages = allMessage;

                                        this.currentMessageChannel = this.echo
                                            .private('Chat.Selection.Typing.' + this.id)
                                            .listenForWhisper( 'typing', (e) => {
                                                if (e && e.typing) {
                                                    this.typing = e;
                                                } else {
                                                    this.typing = null;
                                                }
                                            });

                                        this.loadScripts();

                                        this.loaded = true;
                                        this.loadedMessages = true;

                                        this.readMessages();
                                        this.readNotification();

                                    }
                                );

                            } else {
                                this.userService.getUserMessages(authUser.id).then(
                                    (messages: any) => {
                                        const allMessage: Message[] = [];

                                        messages.forEach(
                                            (message: any) => {
                                                allMessage.push(this.getFormattedMessage(message));
                                            }
                                        );

                                        this.allMessages = allMessage;
                                        this.redirectToClosestConversation();

                                    }
                                );
                            }
                        }
                    );
                }
            }
        );
    }

    isOwnerViewing() {
        return false;
    }

    getUserSelectionBookingDetails() {
        this.loadingBookingDetails = true;
        this.bookingService.getUserSelectionBookingDetails(this.authUser.id, this.id).then(
            (bookingDetails: any) => {
                this.bookingDetails = bookingDetails;
                this.loadingBookingDetails = false;
            }
        );
    }

    redirectToClosestConversation() {
        if (this.getHighlightedMessages().length > 0) {
            const message: Message = this.getHighlightedMessages().find(
                (msg: Message) => {
                    return msg.seen || msg.senderId === this.authUser.id;
                }
            );

            if (message) {
                this.router.navigate(['/messages/' + message.messageableId + '/' + this.getMessageUserRole(message)]);
            } else {
                this.loadScripts();
                this.loaded = true;
                this.loadedMessages = true;
            }
        } else {
            this.loadScripts();
            this.loaded = true;
            this.loadedMessages = true;
        }
    }

    getBookingUserRole() {
        if (this.bookingDetails.user_id === this.authUser.id) {
            return 'tenant';
        }

        if (this.bookingDetails.product.manager_type === 'owner') {
            return 'host';
        }

        if (this.bookingDetails.product.manager_type === 'agent') {
            return 'agent';
        }
    }

    getMessageUserRole(message: Message) {
        return message.senderId === this.authUser.id ? message.userRole : message.receiverRole;
    }

    getMessageReceiverRole(message: Message) {
        return message.senderId === this.authUser.id ? message.receiverRole : message.userRole;
    }

    getStatusMessage(message: Message) {

        if (message.status === 'pending') {
            return 'Envoi en cours';
        }

        if ((this.authUser.id === message.senderId || this.isOwnerViewing()) && message.seen) {
            return 'Vu ' + message.seenAt;
        }
    }

    getMessagesAtPosition(index) {
        return this.getDisplayMessages()[index] ? this.getDisplayMessages()[index] : [];
    }

    getHighlightedMessageDate(message: Message) {
        if (moment(moment().format('YYYY-MM-DD')).isSame(moment(message.creationDate).format('YYYY-MM-DD'))) {
            return message.creationHour;
        }

        return message.creationDateHuman;
    }

    hasMessage(code): boolean {
        let hasMessage = false;
        this.allMessages.forEach(
            (message: Message) => {
                if (message.code === code) {
                    hasMessage = true;
                }
            }
        );

        return hasMessage;
    }

    readNotification() {
        this.userService.readUserNotifications(this.authUser.id, {
            messageable_id: this.id, messageable_type: 'selection'});
    }

    readMessages() {
        this.readingMessages = true;
        const unReadMessages = [];

        this.allMessages.forEach(
            (message: Message) => {
                if (message.messageableId === this.id && !message.seen && message.receiverId === this.authUser.id) {
                    unReadMessages.push(message.id);
                }
            });

        const messages = {messages: JSON.stringify(unReadMessages)};

        this.bookingService.readSelectionMessages(this.authUser.id, this.id, messages).then(
            () => {
                this.readingMessages = false;
                unReadMessages.forEach(
                    (id: any) => {
                        this.markMessageAsRead({id, seen_at_human: moment().calendar()});
                    }
                );
            }
        );
    }

    markMessageAsRead(data: {id: any, seen_at_human: string}) {
        this.allMessages.forEach(
            (message: Message) => {
                if (message.id === data.id) {
                    message.seen = true;
                    message.seenAt = data.seen_at_human;
                }
            }
        );
    }

    subscribeToBroadcastingMessage() {

        this.userChannel = this.echo
            .private(`App.User.${this.authUser.id}`)
            .listen('.message.sent', (data) => {
                const formattedMessage = this.getFormattedMessage(data.message);

                if (!this.hasMessage(formattedMessage.code)) {
                    this.allMessages.push(this.getFormattedMessage(data.message));
                }

                if (!formattedMessage.seen && formattedMessage.receiverId === this.authUser.id) {
                    this.readMessages();
                    this.readNotification();
                }

            })
            .listen('.message.read', (data) => {
                this.markMessageAsRead(data.data);
            });
    }


    handleKeyPress(event) {
        if (event.keyCode === 13) {
            event.preventDefault();

            if (!event.shiftKey) {
                this.sendMessage();
            }

            if (event.shiftKey) {
                const currentMessage = this.messageArea.value ? this.messageArea.value : '';
                this.messageArea.setValue(currentMessage + '\n');
            }
        }

        if (this.stopingTyping) {
            clearTimeout(this.stopingTyping);
        }

        if (this.messageArea.value) {
            this.echo.private('Chat.Selection.Typing.' + this.id)
                .whisper('typing', {
                    user: this.authUser.lastname,
                    typing: true
                });
        } else {
            this.stopTyping();
        }
    }

    stopTyping() {
        this.stopingTyping = setTimeout( () => {
            this.echo.private('Chat.Selection.Typing.' + this.id)
                .whisper('typing', {
                    user: this.authUser.lastname,
                    typing: false
                });
            clearTimeout(this.stopingTyping);
        }, 1500);
    }

    sendMessage() {

        this.submittedMessage = true;

        if (!this.messageArea.value) {
            document.getElementById('msg-text').focus();
            return;
        }
        this.submittedMessage = false;
        const textMessage = this.messageArea.value;
        this.messageArea.setValue('');

        const message: Message = {
            receiverId: this.getHighlightedDisplayUser().id,
            senderId: this.authUser.id,
            message: textMessage,
            photo: this.authUser.photo,
            lastName: this.authUser.lastname,
            userRole: this.role,
            receiverRole: this.getMessageReceiverRole(this.getHighlightedMessage()),
            code: Date.now().toString(),
            messageableId: this.id,
            creationDate: moment().format('YYYY-MM-DD'),
            creationDateHuman: moment().calendar().split(' ')[0],
            creationHour: new Date().getHours() + ':' + new Date().getMinutes(),
            status: 'pending',
            seen: false,
            sender: this.authUser,
            receiver: this.getHighlightedDisplayUser()
        };

        const formData = {
            message: textMessage,
            code: message.code
        };

        const messagePosition = this.allMessages.length;

        this.allMessages.push(message);

        this.bookingService.saveSelectionMessage(this.authUser.id, this.id, formData).then(
            (savedMessage: any) => {
                this.allMessages[messagePosition].status = 'saved';
                this.allMessages[messagePosition].creationHour = savedMessage.creation_hour;
                this.allMessages[messagePosition].creationDate = savedMessage.creation_date;
                this.allMessages[messagePosition].creationDateHuman = savedMessage.creation_date_human;
                this.allMessages[messagePosition].id = savedMessage.id;
            }
        );

    }



    getSelectionMessageQty(id): number {
        let qty = 0;
        this.allMessages.forEach(
            (message: Message) => {
                if (message.messageableId === id && message.senderId !== this.authUser.id && !message.seen) {
                    qty++;
                }
            }
        );
        return qty;
    }

    getMessagesDates(): any {

        const messagesDates = [];
        this.allMessages.forEach(
            (message: Message) => {

                if (message.messageableId === this.id && !messagesDates.includes(message.creationDateHuman)) {
                    messagesDates.push(message.creationDateHuman);
                }
            }
        );

        return messagesDates;

    }

    getDisplayMessages(): Message[][] {
        const messages: Message[][] = [];

        this.getMessagesDates().forEach(
            (date: any, i) => {
                this.allMessages.forEach(
                    (message: Message) => {
                        if (message.creationDateHuman === date && message.messageableId === this.id) {
                            if (!messages[i]) {
                                messages[i] = [];
                            }
                            messages[i].push(message);
                        }
                    }
                );
            }
        );

        return messages;
    }

    getHighlightedMessages(): Message[] {
        const highlightedMessages: Message[] = [];
        this.allMessages.slice().reverse().forEach(
            (currentMessage: Message) => {
                const msg = highlightedMessages.find(
                    (highlightedMessage: Message) => {
                        return  highlightedMessage.messageableId === currentMessage.messageableId;
                    }
                );

                if (!msg) {
                    highlightedMessages.push(currentMessage);
                }
            }
        );

        return highlightedMessages;
    }

    getHighlightedMessage(): Message {
        return this.getHighlightedMessages().find(
            (msg: Message) => {
                return msg.messageableId === this.id;
            }
        );
    }

    getHighlightedDisplayUser() {
        return this.getHighlightedUser(this.getHighlightedMessage());
    }

    getHighlightedUser(message: Message): AuthUser {
        return message.senderId === this.authUser.id ? message.receiver : message.sender;
    }

    getFormattedMessage(message: any): Message  {
        return {
            receiverId: message.receiver.id,
            senderId: message.sender.id,
            message: message.message,
            photo: message.sender.photo,
            lastName: message.sender.lastname,
            userRole: message.user_role,
            receiverRole: message.receiver_role,
            code: message.code,
            id: message.id,
            messageableId: message.messageable_id,
            creationDate: message.creation_date,
            creationHour: message.creation_hour,
            creationDateHuman: message.creation_date_human,
            status: 'saved',
            seen: !!message.seen_at,
            seenAt: message.seen_at_human,
            sender: message.sender,
            receiver: message.receiver
        };
    }

    loadScripts() {
        $(function(){
            'use script';

            $('.messages-list .media').on('click', function(e) {
                e.preventDefault();
                if (window.matchMedia("(max-width: 991px)").matches) {
                    $('.messages-left').fadeOut();
                    $('.messages-right').fadeIn();
                }
            });

            $('.message-back').on('click', function(e) {
                e.preventDefault();
                if (window.matchMedia("(max-width: 991px)").matches) {
                    $('.messages-right').fadeOut();
                    $('.messages-left').fadeIn();
                }
            });

            $(window).resize(function(){
                if (window.matchMedia("(min-width: 992px)").matches) {
                    $('.messages-right').show();
                    $('.messages-left').show();
                }
            });
        });

        if (this.id && window.matchMedia("(max-width: 991px)").matches) {
            $('.messages-left').fadeOut();
            $('.messages-right').fadeIn();
        }

    }

    isMessageRightFadedIn(): boolean {
        const messagesRight = document.getElementById('messages-right');

        return getComputedStyle(messagesRight).display !== 'none';
    }

    ngOnDestroy(): void {

        if (this.authUserSubscription) {
            this.authUserSubscription.unsubscribe();
        }
    }
}
