import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../json/breadcrumb.json';
import * as  datatable from '../json/datatable/datatable_transaction.json';
import * as filter from '../json/filter/filter.json';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import {IPagination} from '../model/pagination';
import { GetAdmin } from '../model/getadmin';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.transaction;

  filter=filter.bookings;
  data:any=[];
  loaded = false;
  data_json=datatable.transcation;

  adminId: any;
  paramsPage: IPagination;
  constructor(private adminService:AdminService, private route: ActivatedRoute) {
    this.paramsPage={} as IPagination;  
    
   }

  ngOnInit() {
  
this.informationAdmin();
 
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
          
     this.getParams();
    });
   }


  getParams(){
    this.route.queryParams.subscribe(
      params => {
        
          if(params){
           
            if(this.adminId){
              this.getAllReservations( this.adminId,location.search);
            }
          
          }else{
           if(this.adminId){
            this.getAllReservations(this.adminId);
           }
           
          }
      }); 
  }


  getAllReservations(AdminId, params?:string){
    this.loaded = false;
    this.adminService.getAllReservations(AdminId).then((response:any)=>{
        this.data=response.data ? response.data.data : [];
        this.loaded = true;
        this.paramsPage.currentPage=response.data?response.data.current_page:0;
        this.paramsPage.totalItems=response.data?response.data.total:0;
        this.paramsPage.currentPage=response.data?response.data.current_page:0;
        this.paramsPage.itemsPerPage=response.data?response.data.per_page:0;
        console.log('list payements',this.data);
    },err=>{
     
      console.log('ERREUR',err);
    });
  }

  getPage(page){
 
    this.getAllReservations(this.adminId,"?page="+page);
  }
  
}
