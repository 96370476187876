import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user/user.service';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private toastr: ToastrService,
                private router: Router,
                private userService: UserService) { }

    ngOnInit() {

        const token = this.route.snapshot.queryParams.token;
        const email = this.route.snapshot.queryParams.email;
        const verified = this.route.snapshot.queryParams.verified;

        if (token && email) {

            this.userService.verifyEmail(token).then(
                response => {

                    if (response === 'success') {
                        this.router.navigate(['/']).then(
                            () => {
                                this.toastr.success('Votre email a été vérifié !');
                            }
                        );
                    }
                },
                error => {

                    console.log(error);

                    this.router.navigate(['/']).then(
                        () => {
                            this.toastr.error('Désolé une erreur s\'est produite.');
                        }
                    );
                }
            );
        }

        if (verified === 'failed') {
            this.router.navigate(['/']).then(
                () => {
                    this.toastr.error('Désolé une erreur s\'est produite.');
                }
            );
        }

    }

}
