import { Injectable } from '@angular/core';
import {UserService} from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  url;
  constructor(private userService: UserService) { }

  saveResponse(response, provider) {
     return this.userService.findOrCreateSocialAccount(response, provider);
  }
}
