import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    constructor(private authService: AuthService) {}

    saveSelectionMessage(userId, selectionId, params: any = {}) {
        return this.authService.post('users/' + userId + '/selections/' + selectionId + '/messages', params);
    }

    getBookingMessage(userId, bookingId, params: any = {}) {
        return this.authService.get('users/' + userId + '/bookings/' + bookingId + '/messages', params);
    }

    readSelectionMessages(userId, selectionId, messages: any = {}) {
        return this.authService.post('users/' + userId + '/selections/' + selectionId + '/messages/read', messages);
    }

    readBookingMessages(userId, bookingId, messages: any = {}) {
        return this.authService.post('users/' + userId + '/bookings/' + bookingId + '/messages/read', messages);
    }

    saveProductMessage(userId, productId, params: any = {}) {
        return this.authService.post('users/' + userId + '/products/' + productId + '/messages', params);
    }

    saveBookingMessage(userId, bookingId, params: any = {}) {
        return this.authService.post('users/' + userId + '/bookings/' + bookingId + '/messages', params);
    }

    getUserBooking(userId, userRole, bookingId , params: any = {}, state = 'reservations') {
        return this.authService.get(userRole + '/' + userId + '/' + state + '/' + bookingId, params);
    }

    getUserSelectionBookingDetails(userId, selectionId , params: any = {}) {
        return this.authService.get('users/' + userId + '/selections/' + selectionId + '/bookingDetails', params);
    }

    updateUserSelectionBookingDetails(userId, selectionId , params: any = {}) {
        return this.authService.post('users/' + userId + '/selections/' + selectionId + '/bookingDetails/update', params);
    }

    updateUserBooking(userId, userRole, bookingId,   params: any = {}, state = 'reservations') {
        params._method = 'PUT';
        return this.authService.post(userRole + '/' + userId + '/' + state + '/' + bookingId, params);
    }

    getUserBookings(userId, userRole,   params: any, state = 'reservations') {
        return this.authService.get(userRole + '/' + userId + '/' + state, params);
    }

    saveClientDemandReservation(userId, bookingId, params: any) {
        return this.authService.post('clients/' + userId + '/demands/' + bookingId + '/reservations', params);
    }

    saveUserSelectionBooking(userId, selectionId, params: any) {
        return this.authService.post('users/' + userId + '/selections/' + selectionId + '/bookings', params);
    }

    updateProductSelection(productId, selectionId, params: any) {
        params._method = 'PUT';
        return this.authService.post('products/' + productId + '/selections/' + selectionId, params);
    }

    getProductSelection(productId, selectionId) {
        return this.authService.get('products/' + productId + '/selections/' + selectionId);
    }

    getSelection(selectionId) {
        return this.authService.get('selections/' + selectionId);
    }

    saveProductSelection(productId, params: any) {
        return this.authService.post('products/' + productId + '/selections', params);
    }
}
