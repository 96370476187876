import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { AdminService } from '../../services/admin.service';
import { AccountingService } from '../services/accounting.service';
@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css']
})
export class BanksComponent implements OnInit {
  breadcrumb=breadcrumb.banks;
  bank: any;
  constructor( private serviceAccounting:AccountingService) { }

  ngOnInit() {
    this.serviceAccounting.setTitle('Banques');
    this.getBank();
  }


  getBank(){
    this.serviceAccounting.getBank().then((data:any)=>{
      this.bank=data;
        // console.log('banque',data);
    },erreur=>{
      console.log('erreur',erreur);
    });
  }
}
