import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-room-owner',
  templateUrl: './contract-room-owner.component.html',
  styleUrls: ['./contract-room-owner.component.css']
})
export class ContractRoomOwnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
