import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AuthUser } from 'src/app/models/auth-user.model';
import { TokenData } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class HttpAdminService {

  authUser: AuthUser;

  userSubject = new Subject<any>();
  isAuthSubject = new Subject<boolean>();

  currentToken: any;

  accessToken: string;

  isAuth: boolean;

  /**
   *  The URL to send the request
   */
  baseUri: string;

  /**
   * The client id to identify the client in ther API
   */
  protected clientId: number;

  /**
   * The client secret to identify the client in ther API
   */
  protected clientSecret: string;

  /**
   * The client id to identify the password client in ther API
   */
  protected passwordClientId: number;

  /**
   * The client secret to identify the password client in the API
   */
  protected passwordClientSecret: string;

  constructor(private httpClient: HttpClient) {

      this.baseUri = environment.adminBaseUrlApi;
      // console.log('url',  this.baseUri);
      this.passwordClientId = environment.passport.passport_client_id;
      this.passwordClientSecret = environment.passport.passport_client_secret;

      this.currentToken = localStorage.getItem('ad_current_token') != null ? JSON.parse(localStorage.getItem('ad_current_token')) : null;
      this.isAuth = this.currentToken != null && this.currentToken.grant_type === 'password';
  }

  emitUser() {
      this.userSubject.next(this.authUser);
      this.isAuthSubject.next(this.isAuth);
  }


  makeRequest(method: string, requestUrl: string, body: any = {}) {

      return new Promise(

          (resolve, reject) => {

              this.getHeaders().then(
                  (headers: HttpHeaders) => {
                      const  req = new HttpRequest(method, requestUrl, body, {
                          headers,
                          reportProgress: true
                      });

                      resolve(this.httpClient.request(req));

                  },
                  () => {
                      resolve(null);

                  }

              );
          }
      ) ;

  }

  post(requestUrl: string, body: any = {}) {

      return new Promise(
          (resolve, reject) => {
              this.getHeaders().then(
                  (headers: HttpHeaders) => {
                      this.httpClient.post(this.baseUri + requestUrl, body, {headers, reportProgress: true}).subscribe(
                          data => {
                              resolve(data);
                          },
                          error => {
                              reject(error);
                          }
                      );
                  }

              );

          }
      );
  }

  get(requestUrl: string, body: any = {}) {

      return new Promise(
          (resolve, reject) => {
              this.getHeaders().then(
                  (headers: HttpHeaders) => {
                      this.httpClient.get(this.baseUri + requestUrl, {headers, params: body}).subscribe(
                          data => {
                              resolve(data);
                          },
                          error => {
                              reject(error);
                          }
                      );
                  }

              );

          }
      );

  }

  put(requestUrl: string, body: any = {}) {

      return new Promise(
          (resolve, reject) => {
              this.getHeaders().then(
                  (headers: HttpHeaders) => {
                      this.httpClient.put(this.baseUri + requestUrl, body, {headers}).subscribe(
                          (data) => {
                              resolve(data);
                          },
                          (error) => {
                              reject(error);
                          }
                      );
                  }

              );

          }
      );

  }

  delete(requestUrl: string, body: any = {}) {

      return new Promise(
          (resolve, reject) => {
              this.getHeaders().then(
                  (headers: HttpHeaders) => {
                      this.httpClient.delete(this.baseUri, {headers, params: body}).subscribe(
                          (data) => {
                              resolve(data);
                          },
                          (error) => {
                              reject(error);
                          }
                      );
                  }

              );

          }
      );

  }

  getHeaders() {

      return new Promise(
          (resolve, reject) => {

              this.resolveAuthorization().then(
                  (accessToken: string) => {

                      const headers = new HttpHeaders({
                          Authorization: accessToken
                      });

                      resolve(headers);

                  },

                  (error) => {
                      reject(error);

                  }
              );
          }
      );
  }

  refreshAuthenticatedUserToken(currentToken) {

      return this.httpClient.post<TokenData>(this.baseUri + 'oauth/token', {
          grant_type: 'refresh_token',
          client_id: this.passwordClientId,
          client_secret: this.passwordClientSecret,
          refresh_token: currentToken.refresh_token,
      });
  }


  resolveAuthorization() {
      const currentToken = localStorage.getItem('ad_current_token') != null ? JSON.parse(localStorage.getItem('ad_current_token')) : null;

      return new Promise(
          (resolve, reject) => {

              if (this.existingValidToken()) {

                  resolve(this.existingValidToken());
                  return;
              }
              if (this.isAuth && currentToken != null && currentToken.refresh_token) {

                  this.refreshAuthenticatedUserToken(currentToken).subscribe(
                      data => {
                          this.storeValidToken(data, data.grant_type);
                          resolve(data.access_token);
                          return;
                      },
                      (error: any) => {

                          console.log(error);
                          this.logout();
                        
                      }
                  );
              } else {
               
              }
          }
      );
  }

  // fonction qui genere le token password
  getPasswordToken(userName: string, pass: string) {
      const formParams = {
          grant_type: 'password',
          client_id: this.passwordClientId,
          client_secret: this.passwordClientSecret,
          username: userName,
          password: pass,
          base: this.baseUri,
          // scope: 'purchase-product manage-products manage-account read-general',

      };

      return new Promise(
          (resolve, reject) => {

              this.httpClient.post(this.baseUri + 'oauth/token', formParams).subscribe((data) => {
                      resolve(data);
                  },

                  (error) => {
                      reject(error);
                  }
              );
          }
      )
      .then(
          (data: TokenData) => {
              this.storeValidToken(data, 'password');
              this.isAuth = true;
            
          }
      );

  }



  storeValidToken(tokenData: any = {}, grantType) {

      tokenData.token_expires_at = moment().add(tokenData.expires_in - 5, 'seconds' ).format();
      tokenData.access_token = tokenData.token_type + ' ' + tokenData.access_token;
      tokenData.grant_type = grantType;

      localStorage.setItem('ad_current_token', JSON.stringify(tokenData));
      this.currentToken = tokenData;
      this.accessToken = tokenData.access_token;

      if (grantType === 'password') {

          this.isAuth = true;
      }
  }

  existingValidToken(): any {

      let tokenData;

      if (localStorage.getItem('ad_current_token') !== null) {

          tokenData = JSON.parse(localStorage.getItem('ad_current_token'));

          const tokenExpiresAt = moment(tokenData.token_expires_at);

          if (moment().isBefore(tokenExpiresAt)) {

              this.currentToken = tokenData;
              return tokenData.access_token;

          }
      }

      return false;
  }

  logout() {

      return new Promise(
          (resolve) => {
              localStorage.removeItem("ad_current_token");
              this.isAuth = false;
              this.authUser = null;
              resolve(true); 
          }
      );

  }


}
