import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountingService } from '../../services/accounting.service';
import {urlPages} from '../../../settings/env.routing'
@Component({
  selector: 'app-add-currency',
  templateUrl: './add-currency.component.html',
  styleUrls: ['./add-currency.component.css']
})
export class AddCurrencyComponent implements OnInit {
  breadcrumb = breadcrumb.add_currency;
  adminId: any;
  currency: any;
  loaded:boolean;

  updateCurrency: any;
  default: any;
  currencyForm: any;
  submitted = false;
  arrCurrency: any;
  status: number=0;
  constructor(private router:Router, private route:ActivatedRoute, private fb: FormBuilder, public accountingService: AccountingService ,private toastr: ToastrService) {
    this.loaded=false;
    
  }

  ngOnInit() {
   
    this.angForm();
    
    if (this.route.snapshot.queryParams['update']) {
      this.accountingService.setTitle('Modifier une devise ');
        this.updateCurrency=this.route.snapshot.queryParams['update'];
      }else{
        this.accountingService.setTitle('Ajouter une devise ');
      }
     
      this.informationAdmin();
    }

    informationAdmin(): void {
      this.accountingService.$admin.then((admin:any)=>{
       this.adminId = admin.id;
       this.getOneSetting(this.adminId);
     
      });
     }
     
    get f() {
      return this.currencyForm.controls;
    }
  


  angForm(){
    this.currencyForm = this.fb.group({
      default_currency: [{value:null,disabled: true}],
      default_rates: [{value:null,disabled: true}],
      rates: ["", [Validators.required]],
      text: ["", [Validators.required]],
      currency: ["", [Validators.required]],
      convert: [{value:0,disabled: true}],
      code:["", [Validators.required]],
    });
  }

  getOneSetting(AdminId){
    this.accountingService.getOneSetting(AdminId,'currencies').then((data:any)=>{
        this.arrCurrency=data;
      this.default=data.value.find(element=>element.default===1);
      this.currencyForm.controls.default_currency.setValue(this.default.text);
      this.currencyForm.controls.default_rates.setValue(this.default.exchange_rate.rate);

      if(this.updateCurrency){
        this.initForm(data);
      }
      if(this.default){
        this.loaded=true;
      }

  
    
    },err=>{
        console.log('err',err);
    })
  }

  initForm(data){
    this.currency= data.value.find(element=> element.id==this.updateCurrency);
    if(this.currency){
      // console.log('currency',this.currency);
      this.currencyForm.controls.rates.setValue(this.currency.exchange_rate.rate);
      this.currencyForm.controls.text.setValue(this.currency.text);
      this.currencyForm.controls.currency.setValue(this.currency.currency);
      this.currencyForm.controls.convert.setValue(this.currency.exchange_rate.convert);
      this.currencyForm.controls.code.setValue(this.currency.iso_code);
      
    }else{
        this.router.navigate([urlPages.pageCurrency]);
    }
 
  }



  getValue(e){
    // console.log('statut',e);
    if(e.status==true){
      this.status=1;
     
    }else{
      this.status=0;
    
    }
   
  }



  update(){
    this.submitted = true;
    if (this.currencyForm.invalid) {
     
      return;
    }
    this.currency.currency=this.currencyForm.value.currency;
    this.currency.exchange_rate.rate=this.currencyForm.value.rates;
    this.currency.exchange_rate.convert=this.default.exchange_rate.rate/this.currencyForm.value.rates;
    this.currency.text=this.currencyForm.value.text;
    this.currency.iso_code=this.currencyForm.value.code;
    
    this.currency.default=0;
    this.currency.activated=this.status;
    this.currency.id=this.currency.id;
    let newarray = this.arrCurrency.value.filter(element => element.id !== this.currency.id);

   // let currency=[]= this.arrCurrency.value.;
   newarray.push( this.currency);
    // console.log('update',newarray);
    this.save(newarray,"Enregistrement effectué");
 
  }

  add(){
    this.submitted = true;
    if (this.currencyForm.invalid) {
      return;
    }
    let currency=[]= this.arrCurrency.value;
    let convert=this.default.exchange_rate.rate/this.currencyForm.value.rates;
    // console.log('communi',convert);
    let c={
      id:currency.length+1,
      currency:this.currencyForm.value.currency,
      increment:500,
      max:655000,
      min:500,
      text:this.currencyForm.value.text,
      iso_code:this.currencyForm.value.code,
      exchange_rate:{
          rate:this.currencyForm.value.rates,
          convert:convert
      },
      activated: this.status,
      default:0
    }
   
    currency.push(c)
  this.save(currency,"Enregistrement effectué");
    
  
  }

  changeRate(e){
    this.currencyForm.controls.convert.setValue(this.default.exchange_rate.rate/e);
  }

  save(datas,message){
    let data={
      value:JSON.stringify(datas),
      _method:'PUT'
    }
    this.accountingService.updateSetting(this.adminId,'currencies',data).then(data=>{
      this.toastr.success(message,'Success');
      // console.log('value',data);
      this.router.navigate([urlPages.pageCurrency]);
    },err=>{
        console.log('erreur',err);
    });
  }
}
