import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-beds-room',
    templateUrl: './beds-room.component.html',
    styleUrls: ['./beds-room.component.css']
})
export class BedsRoomComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}
