import {AfterViewInit, Component, OnInit} from '@angular/core';
declare var $: any;

@Component({
    selector: 'app-collapse-account',
    templateUrl: './collapse-account.component.html',
    styleUrls: ['./collapse-account.component.css']
})
export class CollapseAccountComponent implements OnInit, AfterViewInit {

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // Collapse filters
        $(window).bind('load resize', function() {
            const width = $(window).width();
            if ($(this).width() < 991) {
                $('.collapse#collapseFilters').removeClass('show');
            } else {
                $('.collapse#collapseFilters').addClass('show');
            }
        });
    }

}
