import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/admin/services/admin.service';
@Component({
  selector: 'app-profil-admin',
  templateUrl: './profil-admin.component.html',
  styleUrls: ['./profil-admin.component.css']
})
export class ProfilAdminComponent implements OnInit {
  breadcrumb = breadcrumb.profil_admin;
  angForm: any;
  submitted = false;
  formData: any;
  id: string;
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private serviceAdmin: AdminService,
    private toastr: ToastrService) { }

  ngOnInit() {
    let data = {
      breadcrumb: this.breadcrumb,
      id: this.route.snapshot.paramMap.get('id'),
      title: "Modifier le profil de l'administrateur",
      tag:"edit"
    }
    setTimeout(() => {
      this.serviceAdmin.sendBreadcrumb(data);
    }, 1000);
    this.id = this.route.snapshot.paramMap.get('id')
    console.log('---------------------------', this.id);
    this.getAdmin(this.route.snapshot.paramMap.get('id'));
    this.initForm();
  }

  get f() {

    return this.angForm.controls;
  }

  initForm() {
    this.angForm = this.fb.group({
      name: [null, [Validators.required]],
      lastname: [null],
      phone: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      email: new FormControl({ value: null, disabled: true }),
    });
  }

  getAdmin(id) {
    this.serviceAdmin.getOneAdmin(id).then((data: any) => {
      this.angForm.controls.name.setValue(data.name);
      this.angForm.controls.lastname.setValue(data.lastname);
      this.angForm.controls.phone.setValue(data.phone);
      this.angForm.controls.adress.setValue(data.adress);
      this.angForm.controls.email.setValue(data.email);
    }, err => {
      console.log('erreur', err);
    });
  }


  UpdateAdmin() {
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append('id', this.route.snapshot.paramMap.get('id'));
    formData.append('name', this.angForm.value.name);
    formData.append('lastname', this.angForm.value.lastname);
    formData.append('phone', this.angForm.value.phone);
    formData.append('adress', this.angForm.value.adress);
    formData.append('_method', 'PUT');
    // let data={
    //   id:this.route.snapshot.paramMap.get('id'),
    //   name:this.angForm.value.name,
    //   lastname:this.angForm.value.lastname,
    //   phone:this.angForm.value.phone,
    //   adress:this.angForm.value.adress
    // }
    this.serviceAdmin.updateAdmin(formData, this.route.snapshot.paramMap.get('id')).then((data: any) => {
      this.toastr.success('Modification effectuée', 'Success');
    }, err => {
      console.log('erreur', err);
    });



  }
}
