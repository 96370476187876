import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-room-edit-bedrooms',
    templateUrl: './room-edit-bedrooms.component.html',
    styleUrls: ['./room-edit-bedrooms.component.css']
})
export class RoomEditBedroomsComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}
