import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthUser} from '../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
    selector: 'app-profile-model',
    templateUrl: './profile-model.component.html',
    styleUrls: ['./profile-model.component.css']
})
export class ProfileModelComponent implements OnInit, OnDestroy {
    @Input() profileMode = 'user';

    userSubscription: Subscription;

    result_search = "header map_view menu_fixed";
    user_id: string;
    user: any;
    maxAboutLenght = 100;
    authUser: AuthUser;

    constructor(private userService: UserService,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService) { }

    ngOnInit() {

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {
                    if (user) {
                        this.authUser = user;
                    }
                }
            );

            this.authService.emitUser();
            this.getUser(this.route.snapshot.paramMap.get('id'));

        }
    }

    isAuthUser() {
        return this.user && this.authUser && this.profileMode === 'user' && this.authUser.id === this.user.id;
    }

    getUser(id) {

        if (this.profileMode === 'user') {
            this.userService.getUser(id).then(
                (data: AuthUser) => {
                    this.user = data;
                }, err => {
                    console.log('erreur', err);
                    this.router.navigateByUrl('not-found', {skipLocationChange: true});

                }
            );
        }

        if (this.profileMode === 'agency') {
            this.userService.getAgency(id).then(
                (data: any) => {
                    this.user = data;
                }, err => {
                    console.log('erreur', err);
                    this.router.navigateByUrl('not-found', {skipLocationChange: true});
                }
            );
        }
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}
