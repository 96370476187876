import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private authService: AuthService) {}

    createUserDocument(userId, params: any = {}) {
        return this.authService.post('users/' + userId + '/documents', params);
    }

    getUserDocuments(userId, params: any = {}) {
        return this.authService.get('users/' + userId + '/documents', params);
    }

    deleteUserSettings(userId, setting: string, params: any) {
        params._method = 'DELETE';
        return this.authService.post('users/' + userId + '/settings/' + setting, params);
    }

    saveUserSettings(userId, params: any) {
        return this.authService.post('users/' + userId + '/settings', params);
    }

    getUserSettings(userId) {
        return this.authService.get('users/' + userId + '/settings');
    }

    getUserPayments(userId, userRole, params: any = {}) {
        return this.authService.get(userRole + '/' + userId + '/payments', params);
    }

    getUserMessages(userId, params: any = {}) {
        return this.authService.get('users/' + userId + '/messages', params);
    }

    readUserNotification(userId, notificationId) {
        return this.authService.post('users/' + userId + '/notifications/' + notificationId, {_method: 'PUT'});
    }

    readUserNotifications(userId, params: {messageable_type: string, messageable_id: string | number}) {
        return this.authService.post('users/' + userId + '/notifications/read', params);
    }

    getUserNotifications(userId) {
        return this.authService.get('users' + '/' + userId + '/notifications', {per_page: '*', sort_by_desc: 'created_at'});
    }

    createUserAgency(userId, formData) {
        return this.authService.post('users/' + userId + '/agency', formData);
    }

    updateUserAgency(userId, agencyId ,  formData) {
        return this.authService.post('users/' + userId + '/agency/' + agencyId , formData);
    }

    verifyEmail(token: string) {
        return this.authService.post('email/verify/' + token);
    }

    resetPassword(data) {
        return this.authService.post('password/reset', data);
    }

    sendResetLinkEmail(data) {
        return this.authService.post('password/email', data);
    }

    findOrCreateSocialAccount(user, provider) {
        return this.authService.post('social_accounts/find_or_create/' + provider, user);
    }

    getUserInformations() {
        return this.authService.get('users/me', {});
    }

    createUser(user: FormData) {
        return this.authService.post('users', user);
    }

    getUser(id) {
        return this.authService.get('users' + '/' + id);
    }

    getAgency(id) {
        return this.authService.get('agencies' + '/' + id);
    }

    getUserAnnounces(id, role: string, queryParams: any = {}) {
        return this.authService.get(role + '/' + id + '/' + 'products', queryParams);
    }


    editProfile(data, id) {
        return this.authService.post('users/' + id, data);

    }
}
