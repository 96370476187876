import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../services/admin.service';
import { Subscription } from 'rxjs';
import { AdminAccountService } from '../../services/admin.account.service';

@Component({
  selector: 'app-more-detail-customer',
  templateUrl: './more-detail-customer.component.html',
  styleUrls: ['./more-detail-customer.component.css']
})
export class MoreDetailCustomerComponent implements OnInit {
  id: any;
  customer: any;
  breadcrumb:any;
  loaded=false;
  url: any;
  userSubscription: Subscription;
  adminId: any;
  totalReservation:number=0;
  status: any[];
  actionBack: boolean;
  constructor( private route: ActivatedRoute,
               private serviceAdmin:AdminService,
               private authAdmin: AdminAccountService) { }

  ngOnInit() {
    this.breadcrumb=breadcrumb.detail_customer;
    this.id = this.route.snapshot.params.id;
    this.userSubscription = this.authAdmin.getAdmin().subscribe(
      (admin: any) => {
        if (admin[0]) {
          this.adminId = admin[0].admin.id;
          this.getOneCustomer(this.adminId, this.route.snapshot.params.id);
        }

      }
    );

    this.actionBack=this.serviceAdmin.getActionBack();
  }

  getOneCustomer(adminId, idClient){
    this.serviceAdmin.getOneCustomer(adminId, idClient).then((data:any)=>{
      this.customer=data;
      console.log('customer',this.customer);
      if(this.customer.reservations){
        this.customer.reservations.forEach(element => {
         
          this.totalReservation= this.totalReservation+parseFloat(element.total);
        });
        console.log('totalReservation', this.totalReservation);
        this.getSatus(this.customer.status);
      }
      this.loaded=true;
    
    },err=>{
      console.log('erreur',err)
    });
  }

  getSatus(status) {
    this.status = [];
    switch (status) {
      case "unverified":
        this.status = ['pending', 'En attente de vérification'];
        break;
      case "verified":
        this.status = ['approved', ' Vérifié'];
        break;
      case "rejected":
      this.status = ['cancel', ' Rejeté'];
      break;
    }
    console.log('stats', this.status);
  }
}
