import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../json/breadcrumb.json';
import { Subscription } from 'rxjs';

import { FormBuilder, Validators, FormControl } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { UploadedImage } from 'src/app/models/Image';
import { AdminAccountService } from '../services/admin.account.service';
import { AdminService } from '../services/admin.service';
import { GetAdmin } from '../model/getadmin';
@Component({
  selector: 'app-admin-profil',
  templateUrl: './admin-profil.component.html',
  styleUrls: ['./admin-profil.component.css']
})
export class AdminProfilComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.admin_profil;
  userSubscription: Subscription;
  angForm: any;
  upLoadPhotoFormData: { key: string, value: any }[] = [];
  upLoadedPhoto: UploadedImage;
  angFormPwd: any;
  submitted=false;
  submitted1=false;
  admin: any;
  erreur: any;
  constructor(  
    private authAdmin: AdminAccountService,
    private fb: FormBuilder,
    private serviceAdmin: AdminService,
    private toastr: ToastrService) { }


  ngOnInit() {

    this.initForm();
    this.initFormPwd();

  
    this.informationAdmin();

   
  }

  informationAdmin(): void {
    this.serviceAdmin.$admin.then((admin:any)=>{
      if(admin){  
        this.admin=admin;
        this.angForm.controls.name.setValue(admin.name);
        this.angForm.controls.lastname.setValue(admin.lastname);
        this.angForm.controls.phone.setValue(admin.phone);
        this.angForm.controls.adress.setValue(admin.adress);
        this.angForm.controls.email.setValue(admin.email);
        this.upLoadPhotoFormData = [
         {
             key: 'model_name',
             value: 'admin',
         },
         {
             key: 'file_name',
             value: 'photo',
         },
         {
             key: 'model_id',
             value:admin.id
         }
     ];
     if (admin.has_photo) {
         this.upLoadedPhoto = {
             link:admin.photo,
             hasUploaded: true,
             uploadName: 'photo'
         };
         }
        
      }
     
     });
  }

  

  protected initForm() {
    this.angForm = this.fb.group({
      name: [null, [Validators.required]],
      lastname: [null],
      phone: [null, [Validators.required]],
      adress: [null, [Validators.required]],
      email: new FormControl({ value: null, disabled: true }),
    });
  }

  protected initFormPwd(){
    this.angFormPwd= this.fb.group({
      new_password: [null, [Validators.required,Validators.minLength(6)]],
      password: [null,[Validators.required]],
    });
  }

  get f() {
    return this.angForm.controls;
  }

  get f1() {
    return this.angFormPwd.controls;
  }


  UpdateProfil(){
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }

    let formData = new FormData();
    formData.append('id', this.admin.id);
    formData.append('name', this.angForm.value.name);
    formData.append('lastname', this.angForm.value.lastname);
    formData.append('phone', this.angForm.value.phone);
    formData.append('adress', this.angForm.value.adress);
    formData.append('_method', 'PUT');
    this.serviceAdmin.updateAdmin(formData,this.admin.id).then((data: any) => {
      this.toastr.success('Modification effectuée', 'Success');
      this.authAdmin.setAdmin(data);
    }, err => {
      console.log('erreur', err);
    });
  }

  UpdatePassword(){
    this.submitted1 = true;
    if (this.angFormPwd.invalid) {
      return;
    }

    let formData= new FormData();
    formData.append('admin_new_password',this.angFormPwd.value.new_password);
    console.log('this.angForm.value.new_password',this.angFormPwd.value.new_password);
    formData.append('admin_old_password',this.angFormPwd.value.password);
    formData.append('_method','PUT');
    this.serviceAdmin.updateAdmin(formData,this.admin.id).then((data:any)=>{
     
      this.toastr.success('Votre mot de passe a été changé avec succès','Success');
      this.submitted=false;
      this.angForm.reset();
    },err=>{
        console.log('erreur',err);
        if(err.error.error.admin_old_password){
          this.erreur=err.error.error.admin_old_password[0];
         // console.log('admin',err.error.error.admin_old_password[0]);
        }
    });
  }
}
