import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  security from '../../json/security/security.json';
import { Subscription } from 'rxjs';
import { AccountingService } from '../services/accounting.service';
import {urlPages} from '../../settings/env.routing';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})
export class CurrencyComponent implements OnInit {

  breadcrumb = breadcrumb.currency;
  userSubscription: Subscription;
  adminId: any;
  currency: any;
  loaded:boolean;
  url=urlPages;
  security=security.security.currency;
  constructor( private accountingService: AccountingService ,private toastr: ToastrService) {
    this.loaded=false;
    this.accountingService.setTitle('Devises');
  }
  

  ngOnInit() {
  this.informationAdmin();
  }
  informationAdmin(): void {
    this.accountingService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneSetting(this.adminId);
   
    });
   }



  getOneSetting(AdminId){
    this.accountingService.getOneSetting(AdminId,'currencies').then((data:any)=>{
      // console.log('data',data);
      this.currency=data.value;
      this.loaded=true;
    },err=>{
        console.log('err',err);
    })
  }

  getValue(e){
     let currencyNew=this.currency.filter(element => element.id == e.id);
   
    if(e.status==true){
      currencyNew[0].activated=1;
    }else{
      currencyNew[0].activated=0;
    }

    let newarray =  this.currency.filter(element => element.id !== e.id);

    newarray.push(currencyNew[0]);
    // console.log("sssss", currencyNew[0]);
    // console.log(newarray);
    this.save(newarray);
  }

  save(datas){
    

    let data={
      value:JSON.stringify(datas),
      _method:'PUT'
    }
    this.accountingService.updateSetting(this.adminId,'currencies',data).then(data=>{
      this.toastr.success("Modification effectuée",'Success');
      // console.log('value',data);
    
    },err=>{
        console.log('erreur',err);
    });
  }
}
