import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.css']
})
export class CardButtonComponent implements OnInit {
  @Input() icon: any;
  @Input() title: any;
  @Output() sendAction = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {
  }

  action(){
      this.sendAction.next(true);
  }
}
