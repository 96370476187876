import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductsService} from '../../../../services/products/products.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SuggestedUser} from '../../../../models/user';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-room-edit-property',
    templateUrl: './room-edit-property.component.html',
    styleUrls: ['./room-edit-property.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class RoomEditPropertyComponent implements OnInit {
    product: any;
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    productId;
    deletionModal: any;
    removingAgent: boolean;
    agentHasBeenRemove: boolean;
    action = null;
    selectedUser: SuggestedUser = null;
    constructor(private router: Router,
                private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private modalService: NgbModal,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;
                }
            }
        );

        this.route.queryParams.subscribe(
            params => {
                if (params.action && params.action === 'add_agency') {

                    this.action = params.action;
                }
            }
        );

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {

                        this.authUser = user;

                        if (!this.userProductSubscription) {
                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;
                                    }

                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                (error) => {

                                    console.log(error);
                                    this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                }
                            );
                        }

                    }

                }
            );

            this.authService.emitUser();
        }

    }

    openDeletionModel(content) {
        this.deletionModal = this.modalService.open(content, { centered: true, size: 'sm' });
    }

    removeAgent() {

        if (!this.product.contract) {
            return;
        }

        this.removingAgent = true;

        const formData = new FormData();

        formData.append('return_model', 'product');

        this.productService.deleteContract(this.authUser.id, this.product.id, this.product.contract.id, formData).then(
            (product: any) => {
                this.product = product;
                this.productService.userProduct = product;
                this.productService.emitUserProduct();

                this.deletionModal.close();
                this.agentHasBeenRemove = true;
                this.removingAgent = false;

                this.product.emit(this.product);

                if (this.authUser.id !== this.product.user_id) {
                    this.router.navigate(['/account/rooms']).then(
                        () => {
                            this.toastr.info('Le contract a été rompu avec succès');
                        }
                    );
                }

            },

            () => {
                this.deletionModal.close();
                this.removingAgent = false;
            }
        );

    }


}
