import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookings-demands-list',
  templateUrl: './bookings-demands-list.component.html',
  styleUrls: ['./bookings-demands-list.component.css']
})
export class BookingsDemandsListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
