import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthUser} from '../../../../models/auth-user.model';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductsService} from '../../../../services/products/products.service';

@Component({
    selector: 'app-room-details',
    templateUrl: './room-details.component.html',
    styleUrls: ['./room-details.component.css']
})
export class RoomDetailsComponent implements OnInit, OnDestroy {
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    productId;
    product;
    navLinks = [];
    loading = true;

    constructor(private route: ActivatedRoute,
                private authService: AuthService,
                private productService: ProductsService,
                private router: Router) { }

    ngOnInit() {

        this.route.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;
                    this.loading = true;

                    this.userSubscription = this.authService.userSubject.subscribe(
                        (user: AuthUser) => {

                            if (user) {

                                this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                    (product: any) => {

                                        if (product) {

                                            this.product = product;
                                            this.navLinks = this.productService.getProductDetailsNavLinks();
                                            this.loading = false;

                                        }

                                    },
                                    error => {

                                        console.log(error);
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                );

                                this.productService.emitUserProduct();

                            }
                        }
                    );
                }
            }
        );
        this.authService.emitUser();

    }
    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }
}
