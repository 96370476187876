import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-buttons-wizard',
    templateUrl: './buttons-wizard.component.html',
    styleUrls: ['./buttons-wizard.component.css']
})
export class ButtonsWizardComponent implements OnInit {
    @Input() loading;
    @Input() next: string;
    @Input() previous: string;
    @Input() submit = true;
    @Input() disableSubmit = false;
    @Input() nextText = 'Suivant';

    constructor(private router: Router) { }

    ngOnInit() {
    }

    doNext() {
        this.router.navigate([this.next]);
    }
}
