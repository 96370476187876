import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { Subscription } from 'rxjs';
import { AdminAccountService } from '../../../services/admin.account.service';
@Component({
  selector: 'app-detail-payment',
  templateUrl: './detail-payment.component.html',
  styleUrls: ['./detail-payment.component.css']
})
export class DetailPaymentComponent implements OnInit {
  breadcrumb=breadcrumb.detail_payment;
  userSubscription: Subscription;
  booking: any;
  status: any[];
  adminId: any;
  constructor(private authAdmin: AdminAccountService)
   { 
          
   }

  ngOnInit() {
  }

}
