import { Injectable } from '@angular/core';
import { TokenData} from '../../models/user';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ResponseMessage} from '../../models/response-message.model';
import {AuthUser} from '../../models/auth-user.model';
import Echo from 'laravel-echo';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    authUser: AuthUser;

    userSubject = new Subject<any>();
    isAuthSubject = new Subject<boolean>();
    tokenExistSubject = new Subject<boolean>();
    currentTokenSubject = new Subject<boolean>();
    loadingSubject = new Subject<boolean>();

    currentToken: any;
    accessToken: string;
    isAuth: boolean;
    tokenExist: boolean;
    loading: boolean;

    /**
     *  The URL to send the request
     */
    baseUri: string;

    /**
     * The client id to identify the client in ther API
     */
    protected clientId: number;

    /**
     * The client secret to identify the client in ther API
     */
    protected clientSecret: string;

    /**
     * The client id to identify the password client in ther API
     */
    protected passwordClientId: number;

    /**
     * The client secret to identify the password client in the API
     */
    protected passwordClientSecret: string;

    constructor(private router: Router,
                private httpClient: HttpClient,
                private toastr: ToastrService) {

        this.baseUri = environment.baseUrlApi;
        this.clientId = environment.passport.client_id;
        this.clientSecret = environment.passport.client_secret;
        this.passwordClientId = environment.passport.passport_client_id;
        this.passwordClientSecret = environment.passport.passport_client_secret;
        this.currentToken = this.getCurrentToken();
        this.isAuth = this.currentToken != null && this.currentToken.grant_type === 'password';
        this.tokenExist = this.currentToken != null;
    }


    emitUser() {
        this.userSubject.next(this.authUser);
        this.isAuthSubject.next(this.isAuth);
    }

    emitTokenExist() {
        this.tokenExistSubject.next(this.tokenExist);
    }

    emitLoading() {
        this.loadingSubject.next(this.loading);
    }

    emitCurrentToken() {
        this.currentTokenSubject.next(this.currentToken);
    }

    getCurrentToken() {
        return localStorage.getItem('current_token') != null ? JSON.parse(localStorage.getItem('current_token')) : null;
    }

    getAuthUser() {

        return new Promise<AuthUser>(
            (resolve, reject) => {
                if (this.isAuth) {
                    this.get('users/me', {}).then(
                        (response: ResponseMessage) => {
                            this.authUser = response.data;
                            this.isAuth = true;
                            this.emitUser();
                            resolve(response.data);

                        },

                        (error) => {
                            this.isAuth = false;
                            this.logout().then(
                                () => {
                                    this.resolveAuthorization().then(
                                        () => {
                                            this.router.navigate(['']).then(
                                                () => {
                                                    this.toastr.error('L\'authentification a échoué. Veuillez réésayer plus tard');
                                                }
                                            );
                                        }
                                    );
                                }
                            );
                            reject(error);
                            resolve(null);

                            console.log(error);

                        }
                    );
                }

                resolve(null);

            }
        );
    }

    makeRequest(method: string, requestUrl: string, body: any = {}) {

        return new Promise(

            (resolve, reject) => {

                this.getHeaders().then(
                    (headers: HttpHeaders) => {
                        const  req = new HttpRequest(method, requestUrl, body, {
                            headers,
                            reportProgress: true
                        });

                        resolve(this.httpClient.request(req));

                    },
                    () => {
                        resolve(null);

                    }

                );
            }
        ) ;

    }

    broadcast() {
        return new Promise<Echo>(
            (resolve, reject) => {

                this.resolveAuthorization().then(
                    (accessToken: string) => {
                        const options = {
                            broadcaster: 'pusher',
                            key: environment.pusherAppKey,
                            cluster: environment.pusherAppCluster,
                            encrypted: true,
                            authEndpoint:  this.baseUri + 'broadcasting/auth',
                            auth: {
                                headers: {
                                    Authorization: `${accessToken}`,
                                    Accept: 'application/json',
                                },
                            },
                        };

                        const echo = new Echo(options);

                        resolve(echo);

                    },

                    (error) => {
                        reject(error);
                    }
                );
            }
        );
    }

    post(requestUrl: string, body: any = {}) {
        this.loading = true;
        this.emitLoading();
        return new Promise(
            (resolve, reject) => {
                this.getHeaders().then(
                    (headers: HttpHeaders) => {
                        this.httpClient.post(this.baseUri + requestUrl, body, {headers, reportProgress: true}).subscribe(
                            data => {
                                this.loading = false;
                                this.emitLoading();
                                resolve(data);
                            },
                            error => {
                                this.loading = false;
                                this.emitLoading();
                                reject(error);
                            }
                        );
                    }

                );

            }
        );
    }

    get(requestUrl: string, body: any = {}) {
        this.loading = true;
        this.emitLoading();
        return new Promise(
            (resolve, reject) => {
                this.getHeaders().then(
                    (headers: HttpHeaders) => {
                        this.httpClient.get(this.baseUri + requestUrl, {headers, params: body}).subscribe(
                            data => {
                                this.loading = false;
                                this.emitLoading();
                                resolve(data);
                            },
                            error => {
                                this.loading = false;
                                this.emitLoading();
                                if (!this.getCurrentToken() || !this.getCurrentToken().grant_type) {
                                    localStorage.removeItem('current_token');
                                    this.resolveAuthorization();
                                }

                                if (this.getCurrentToken().grant_type === 'client_credentials' && error.error.code === 401) {
                                    localStorage.removeItem('current_token');
                                    this.resolveAuthorization();
                                }
                                reject(error);

                            }
                        );
                    }

                );

            }
        );

    }

    put(requestUrl: string, body: any = {}) {
        this.loading = true;
        this.emitLoading();
        return new Promise(
            (resolve, reject) => {
                this.getHeaders().then(
                    (headers: HttpHeaders) => {
                        this.httpClient.put(this.baseUri + requestUrl, body, {headers}).subscribe(
                            (data) => {
                                this.loading = false;
                                this.emitLoading();
                                resolve(data);
                            },
                            (error) => {
                                this.loading = false;
                                this.emitLoading();
                                reject(error);
                            }
                        );
                    }

                );

            }
        );

    }

    delete(requestUrl: string, body: any = {}) {
        this.loading = true;
        this.emitLoading();
        return new Promise(
            (resolve, reject) => {
                this.getHeaders().then(
                    (headers: HttpHeaders) => {
                        this.httpClient.delete(this.baseUri, {headers, params: body}).subscribe(
                            (data) => {
                                this.loading = false;
                                this.emitLoading();
                                resolve(data);
                            },
                            (error) => {
                                this.loading = false;
                                this.emitLoading();
                                reject(error);
                            }
                        );
                    }

                );

            }
        );

    }

    getHeaders() {

        return new Promise(
            (resolve, reject) => {

                this.resolveAuthorization().then(
                    (accessToken: string) => {

                        const headers = new HttpHeaders({
                            Authorization: accessToken
                        });

                        resolve(headers);

                    },

                    (error) => {
                        reject(error);

                    }
                );
            }
        );
    }

    /*storeCredentials(userData) {

        userData = JSON.stringify(userData.data);
        this.authUser = JSON.parse(userData);
        this.isAuth = true;

    }*/

    getUserInformation() {

        return new Promise(
            (resolve, reject) => {
                this.get('users/me', {}).then(
                    (user) => {

                        resolve(user);
                    },

                    () => {
                        resolve (null);
                    }
                );

            }
        );
    }

    getClientCredentialToken() {

        return this.httpClient.post<TokenData>(this.baseUri + 'oauth/token', {
            grant_type: 'client_credentials',
            client_id: this.clientId,
            client_secret: this.clientSecret,
        });
    }

    refreshAuthenticatedUserToken(currentToken) {

        return this.httpClient.post<TokenData>(this.baseUri + 'oauth/token', {
            grant_type: 'refresh_token',
            client_id: this.passwordClientId,
            client_secret: this.passwordClientSecret,
            refresh_token: currentToken.refresh_token,
        });
    }


    resolveAuthorization() {
        const currentToken = localStorage.getItem('current_token') != null ? JSON.parse(localStorage.getItem('current_token')) : null;

        return new Promise(
            (resolve, reject) => {

                if (this.existingValidToken()) {
                    resolve(this.existingValidToken());
                    return;
                }
                if (this.isAuth && currentToken != null && currentToken.refresh_token) {

                    this.refreshAuthenticatedUserToken(currentToken).subscribe(
                        data => {
                            this.storeValidToken(data, data.grant_type);
                            resolve(data.access_token);
                            return;
                        },
                        (error: any) => {

                            console.log(error);
                            this.logout().then(
                                (tokenData: TokenData) => {
                                    this.storeValidToken(tokenData, tokenData.grant_type);
                                    this.router.navigate(['']).then(
                                        () => {
                                            this.toastr.error('L\'authentification a échoué. Veuillez réésayer plus tard');
                                        }
                                    );
                                },

                                error1 => {

                                    reject(error1);
                                }
                            );
                        }
                    );
                } else {

                    this.getClientCredentialToken().subscribe(
                        (data) => {
                            this.storeValidToken(data, 'client_credentials');

                        },

                        (error) => {
                            reject(error);
                        }
                    );
                }
            }
        );
    }

    getPasswordToken(userName: string, pass: string) {
        const formParams = {
            grant_type: 'password',
            client_id: this.passwordClientId,
            client_secret: this.passwordClientSecret,
            username: userName,
            password: pass,
            base: this.baseUri,
            // scope: 'purchase-product manage-products manage-account read-general',

        };

        return new Promise(
            (resolve, reject) => {

                this.httpClient.post(this.baseUri + 'oauth/token', formParams).subscribe((data) => {
                        resolve(data);
                    },

                    (error) => {
                        reject(error);
                    }
                );
            }
        ).then(
            (data: TokenData) => {
                this.storeValidToken(data, 'password');
                this.isAuth = true;
            }
        );

    }

    storeValidToken(tokenData: any = {}, grantType) {

        tokenData.token_expires_at = moment().add(tokenData.expires_in - 5, 'seconds' ).format();
        tokenData.access_token = tokenData.token_type + ' ' + tokenData.access_token;
        tokenData.grant_type = grantType;

        localStorage.setItem('current_token', JSON.stringify(tokenData));
        this.currentToken = tokenData;
        this.accessToken = tokenData.access_token;

        if (grantType === 'password') {
            this.isAuth = true;
            this.emitUser();
        }

        this.tokenExist = true;
        this.emitCurrentToken();
        this.emitTokenExist();
    }

    existingValidToken(): any {

        let tokenData;

        if (localStorage.getItem('current_token') !== null) {

            tokenData = JSON.parse(localStorage.getItem('current_token'));

            const tokenExpiresAt = moment(tokenData.token_expires_at);

            if (moment().isBefore(tokenExpiresAt)) {

                this.currentToken = tokenData;
                return tokenData.access_token;

            }
        }

        return false;
    }

    logout() {

        return new Promise(
            (resolve) => {
                localStorage.removeItem('current_token');
                this.isAuth = false;
                this.authUser = null;

                this.getClientCredentialToken().subscribe(
                    (data: TokenData) => {
                        resolve(data);
                    },

                );
            }
        );

    }


    /* getClientCredentialsToken() {

        if (this.existingValidToken()) {

            this.accessToken = this.existingValidToken();
        }

        const formParams = {
            grant_type: 'client_credentials',
            client_id: this.clientId,
            client_secret: this.clientSecret,
        };

        return new Promise(
            (resolve, reject) => {

                this.httpClient.post(this.baseUri + 'oauth/token', formParams, {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'},
                    responseType: 'json'
                }).subscribe(

                    (data) => {
                        resolve(data);

                    },

                    (error) => {
                        reject(error);
                    }
                );
            }
        ).then(
            (data: TokenData) => {
                this.storeValidToken(data, 'client_credentials');

            }
        );

    }


    getAuthenticatedUserToken() {

        const currentToken = JSON.parse(localStorage.getItem('current_token'));

        const tokenExpiresAt = moment(currentToken.token_expires_at);

        if (moment().isBefore(tokenExpiresAt)) {

            this.currentToken = currentToken;
            this.accessToken = currentToken.access_token;
            return;

        }
        this.refreshAuthenticatedUserToken(currentToken).then(
            (data: TokenData) => {

                this.storeValidToken(data, data.grant_type);

            }
        );

    }

    refreshAuthenticatedUserToken(tokenData: TokenData) {
        let clientId = this.clientId;
        let clientSecret = this.clientSecret;

        if (tokenData.grant_type === 'password') {
            clientId = this.passwordClientId;
            clientSecret = this.passwordClientSecret;
        }

        const formParams = {
            grant_type: 'refresh_token',
            client_id: clientId,
            client_secret: clientSecret,
            refresh_token: tokenData.refresh_token,
        };

        return new Promise(
            (resolve, reject) => {

                this.httpClient.post(this.baseUri + 'oauth/token', formParams, {headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    } }).subscribe(

                    (data) => {
                        resolve(data);
                    },

                    (error) => {
                        reject(error);
                    }
                );
            }
        );
    }


    getCodeToken(codeString: string) {
        const formParams = {
            grant_type: 'authorization_code',
            client_id: this.clientId,
            client_secret: this.clientSecret,
            redirect_uri: this.router.createUrlTree(['/authorization']).toString(),
            code: codeString
        };

        return new Promise(
            (resolve, reject) => {

                this.httpClient.post(this.baseUri + 'oauth/token', formParams, {headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    } }).subscribe(

                    (data: TokenData) => {
                        this.storeValidToken(data, 'authorization_code');
                        resolve(data);
                    },

                    (error) => {
                        reject(error);
                    }
                );
            }
        );
    }


    resolveAuthorizationUrl() {
        const query = this.httpBuildQuery({
            client_id: this.clientId,
            redirect_uri: this.router.createUrlTree(['/authorization']).toString(),
            response_type: 'code',
            // scope: 'purchase-product manage-products manage-account read-general',
        });

        return this.baseUri + '/oauth/authorize?' + query;

    }


    httpBuildQuery(params) {

        if (typeof params === 'undefined' || typeof params !== 'object') {
            params = {};
            return params;
        }

        let query = '?';
        let index = 0;

        for(const i in params) {
            index++;
            const param = i;
            const value = params[i];
            if (index === 1) {
                query += param + '=' + value;
            } else {

                query += '&' + param + '=' + value;
            }

        }
        return query;

    }
*/

}
