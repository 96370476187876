import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetAdmin } from '../../model/getadmin';
import { AdminService } from '../../services/admin.service';
import * as  datatable from '../data.claims';
@Component({
  selector: 'app-detail-claim',
  templateUrl: './detail-claim.component.html',
  styleUrls: ['./detail-claim.component.css']
})
export class DetailClaimComponent implements OnInit,GetAdmin {
  breadcrumb=datatable.breadcrumbDetail;
  admin: any;
  roles: any;
  show_comment: boolean=false;
  claim: any;
  constructor( public adminService:AdminService, private route: ActivatedRoute) { }

  

  ngOnInit(): void {
    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
      this.admin = admin;
      this.getAllRoles(); 
      this.getOneClaim();
     
     });
}

getAllRoles(){
  this.adminService.getAllRoles().then((roles:any)=>{
    // console.log('roles',roles);
    this.roles=roles.data;
  },err=>{
    console.log('error',err);
  })
}

showComment(){
  this.show_comment=true;
}

getOneClaim(){
  this.adminService.getOneClaim(this.route.snapshot.params.id).then((data)=>{
      this.claim=data;
      this.adminService.setTitle('Information sur la reclamation '+ this.claim.id);
      console.log('claim', this.claim);
  },err=>{
    console.log('error',err);
  });
}


}
