import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AdminAccountService } from './admin.account.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentAdminService implements OnDestroy,OnInit {
  userSubscription: Subscription;
 public currentAdmin: any;
  constructor(private authAdmin: AdminAccountService) {
    this.getCurrentAdmin();
   }
  ngOnInit() {
    
  }

  getAdminCurrent(){
    return this.currentAdmin;
  }

  getCurrentAdmin(){
    this.userSubscription = this.authAdmin.getAdmin().subscribe(
      (admin: any) => {
        if (admin[0]) {
        
         return  this.currentAdmin= admin[0].admin;     
        }else{
        return  this.currentAdmin=null;     
        }

      }
    );
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
