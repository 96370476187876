import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../services/auth/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ProductsService} from '../../../../services/products/products.service';
import {UploadedImage} from '../../../../models/Image';

@Component({
    selector: 'app-images-room-form',
    templateUrl: './images-room-form.component.html',
    styleUrls: ['./images-room-form.component.scss']
})
export class ImagesRoomFormComponent implements OnInit, OnDestroy {

    @Input() formMode = 'create';
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    productId ;
    loading: boolean = true;
    fileIsUploading: boolean;
    loaded = false;
    product: any;
    upLoadedImages: UploadedImage[] = [];
    highlightedImage: UploadedImage;

    constructor(private productService: ProductsService,
                private authService: AuthService,
                private httpClient: HttpClient,
                private toastr: ToastrService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {

        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;
                }
            }
        );

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {

                            this.userProductSubscription =  this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    this.product = product;
                                    if (product) {
                                        this.product = product;
                                        this.loading = false;
                                        this.loaded = true;
                                        const uploadedImages: UploadedImage[] = [];
                                        product.images.forEach(
                                            (image, i) => {
                                                const uploadedImage: UploadedImage = {
                                                    id: image.id,
                                                    uploadName: 'image',
                                                    hasUploaded: true,
                                                    isUploading: false,
                                                    link: image.url,
                                                    name: image.name,
                                                    description: image.description
                                                };
                                                uploadedImages.push(uploadedImage);

                                                if (uploadedImage.name === 'front_image' || i === 0) {
                                                    this.highlightedImage = uploadedImage;
                                                }
                                            }
                                        );

                                        this.upLoadedImages = uploadedImages;
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },
                                () => {
                                    if (this.formMode === 'create') {
                                        this.router.navigate(['/rooms/new']);
                                    }
                                    if (this.formMode === 'edit') {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                }
                            );
                        }
                    }
                }
            );
            this.authService.emitUser();
        }
    }

    startUpload(): void {
        document.getElementById('image').click();
    }

    checkUser() {
        if (this.authUser && this.product && this.product.published) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;
    }

    getHighlightedImage() {
        if (this.upLoadedImages && this.upLoadedImages.length > 0) {
            return this.upLoadedImages.slice()[0];
        }
    }

    next() {
        return this.productService.getNextStep();
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
