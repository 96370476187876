import { Component, OnInit } from '@angular/core';
import * as  datatable from '../../../json/datatable/datatable_refunds.json';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { AdminService } from 'src/app/admin/services/admin.service';
import { ActivatedRoute } from '@angular/router';
import * as  filter from '../../../json/filter/filter.json';
import {IPagination} from '../../../model/pagination';
import { GetAdmin } from 'src/app/admin/model/getadmin';
@Component({
  selector: 'app-list-refunds',
  templateUrl: './list-refunds.component.html',
  styleUrls: ['./list-refunds.component.css']
})
export class ListRefundsComponent implements OnInit,GetAdmin {

 
  breadcrumb=breadcrumb.refunds; 
  data_json=datatable.refunds;
  loaded : boolean;
  adminId: any;
  paramsPage: IPagination;
  data: any;
  filter=filter.refunds
  constructor(
    public adminService:AdminService, 
    private route: ActivatedRoute
  ) {
this.paramsPage={} as IPagination;  
}
  ngOnInit() {
    this.informationAdmin();
    this.adminService.setTitle('Remboursements');
  }
  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getParams();
    });
   
   }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
          if(params){
           
            if(this.adminId){
              this.getAllPayements( this.adminId,location.search);
            }
          
          }else{
           if(this.adminId){
            this.getAllPayements(this.adminId);
           }
           
          }
      }); 
  }

  getAllPayements(AdminId, params?:string){
    this.loaded=false;
  this.adminService.getAllPayementRefunded(AdminId,params).then((response:any)=>{
      this.data=response.data ? response.data : [];
      this.loaded = true;
      this.paramsPage.currentPage=response.data?response.current_page:0;
      this.paramsPage.totalItems=response.data?response.total:0;
      this.paramsPage.currentPage=response.data?response.current_page:0;
      this.paramsPage.itemsPerPage=response.data?response.per_page:0;
      // console.log('list booking',this.data);
  },err=>{
    console.log('ERREUR',err);
  });
}

getPage(page){
 
  this.getAllPayements(this.adminId,"?page="+page);
}



}
