import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { GetAdmin } from '../../model/getadmin';
import { AccountingService } from '../services/accounting.service';
@Component({
  selector: 'app-reduction',
  templateUrl: './reduction.component.html',
  styleUrls: ['./reduction.component.css']
})
export class ReductionComponent implements OnInit,GetAdmin {
  breadcrumb = breadcrumb.currency;
  submitted=false;
  userSubscription: Subscription;
  adminId: any;
  loaded=false;
  data: any;
  constructor(
     private fb: FormBuilder, 
     public acountingService: AccountingService ,
     private toastr: ToastrService,
  ) { }
    reductionForm:any;
  ngOnInit() {
    this.acountingService.setTitle('Reduction');
    this.informationAdmin();
    
    this.initForm();
  
  }

  informationAdmin(): void {
    this.acountingService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneSetting(this.adminId);
   
    });
   }
  initForm(){
    this.reductionForm=  this.fb.group({
      R3_7: [null, [Validators.required]],
      R7_21: [null, [Validators.required]],
      R21_X: [null, [Validators.required]],
      RM3_7: [null, [Validators.required]],
      RM7_21: [null, [Validators.required]],
      RM21_X: [null, [Validators.required]]
 
    });
  }
  getOneSetting(AdminId) {
    this.acountingService.getOneSetting(AdminId, 'products').then((data: any) => {
      // console.log('data',data.value);
      this.data=data;
    
      this.setValueReduction(data.value);
      this.loaded = true;
    
    }, err => {
      console.log('err', err);

    })
  }



  get f() {
    return this.reductionForm.controls;
  }
 
  setValueReduction(value){
    this.reductionForm.controls.R3_7.setValue( value.discount_min_dates['3_7']);
    this.reductionForm.controls.R7_21.setValue( value.discount_min_dates['7_21'] );
    this.reductionForm.controls.R21_X.setValue(value.discount_min_dates['21_X']);

    this.reductionForm.controls.RM3_7.setValue( value.discount_max_dates['3_7']);
    this.reductionForm.controls.RM7_21.setValue( value.discount_max_dates['7_21'] );
    this.reductionForm.controls.RM21_X.setValue(value.discount_max_dates['21_X']);
  
  }

  saveRedcution(){
    this.submitted = true;
 
    if (this.reductionForm.invalid) {
      return;
    }
    this.data.value.discount_min_dates["3_7"]= this.reductionForm.controls.R3_7.value;
    this.data.value.discount_min_dates["7_21"] = this.reductionForm.controls.R7_21.value;
    this.data.value.discount_min_dates["21_X"] = this.reductionForm.controls.R21_X.value;

    this.data.value.discount_max_dates["3_7"]= this.reductionForm.controls.RM3_7.value;
    this.data.value.discount_max_dates["7_21"] = this.reductionForm.controls.RM7_21.value;
    this.data.value.discount_max_dates["21_X"] = this.reductionForm.controls.RM21_X.value;
      // console.log('data',this.data.value);
    let data = {
      value: JSON.stringify(this.data.value),
      _method: 'PUT'
    }
    // console.log('dd',data)
    this.acountingService.updateSetting(this.adminId, 'products', data).then(data => {
      this.toastr.success('Modification effectuée', 'Success');
      // console.log('value', data);
    }, err => {
      console.log('erreur', err);
    });
  }

}
