import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.css']
})
export class FilterDateComponent implements OnInit {
  @Input() dateRadio: boolean=false;
  dateChange: any;
  current: boolean;
  DayOrMonth=[];
  current_name: any;
  StartEnd=[];
  result: any;
  changeDate: string;
  currentDayOrMonth: any;
  @Output() SendDate = new EventEmitter<any>(); 
  constructor() { }

  ngOnInit() {
    console.log('icicici',this.dateRadio);
  }
  selectDate(e) {
    this.dateChange = e;
    this.current=false;
    this.result=null;
  }
  selectDayOrMonth(value,type) {
    this.currentDayOrMonth=value;
    this.DayOrMonth[0]='isDuringTheLast';
  
    if(type=='input'){
      this.DayOrMonth[1]=value;
      this.DayOrMonth[2]='day';
     
    }
    if(type==='select'){
     
      this.DayOrMonth[2]=value;
    } 
    this.result=null;
    this.result=this.DayOrMonth.toString();
    this.SendDate.emit(this.result);
   // console.log('selectDayOrMonth',this.result);
  }



  onCheckboxChangeCurrent(e){
    this.DayOrMonth[0]='isDuringTheLast';
    this.DayOrMonth[1]=0;
    this.current_name=e.target.name;
    this.DayOrMonth[2]=this.current_name;
    
   
    // console.log('onCheckboxChangeCurrent', this.result);
  
    if(e.target.checked){
      this.current=true;
      this.result=this.DayOrMonth.toString();
      this.SendDate.emit(this.result);
    }else{
      this.current=false;
      this.SendDate.emit(null);
    }
  }



  StartEndDate(e,type){
    this.StartEnd[0]='isDateBetween';
    if(type==='Start'){
      this.StartEnd[1]=e;
    }else if(type==='End'){
      this.StartEnd[2]=e;
    }
    this.result=null;
    if(this.StartEnd.length==3){
      this.result=this.StartEnd.toString();
    }
    this.SendDate.emit(this.result);
    // console.log('StartEndDate',this.result);
  }

  ChangeDate(e,type){
     this.changeDate=type+','+e;
    this.result=this.changeDate;
    this.SendDate.emit(this.result);
    // console.log('ChangeDate',this.result);
  }

}
