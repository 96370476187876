import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductsService} from '../../../../services/products/products.service';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../../models/auth-user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-room-edit-settings',
    templateUrl: './room-edit-settings.component.html',
    styleUrls: ['./room-edit-settings.component.css'],
    encapsulation: ViewEncapsulation.None,

})
export class RoomEditSettingsComponent implements OnInit, OnDestroy {
    product: any;
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    isLoading = false;
    productId;
    loaded = false;
    deletionModal: any;

    constructor(private router: Router,
                private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private modalService: NgbModal,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {
                    if (!this.userSubscription) {

                        this.userSubscription = this.authService.userSubject.subscribe(
                            (user: AuthUser) => {
                                if (user) {
                                    this.authUser = user;
                                    if (!this.userProductSubscription) {
                                        this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                            (product: any) => {
                                                if (product) {
                                                    this.product = product;
                                                    this.loaded = true;
                                                }
                                            }
                                        );

                                        this.productService.getAuthUserProduct(user.id, params.id).then(
                                            () => {
                                                this.productService.emitUserProduct();
                                            },
                                            (error) => {

                                                console.log(error);
                                                this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                            }
                                        );
                                    }
                                }
                            }
                        );

                        this.authService.emitUser();
                    }
                }
            }
        );
    }

    checkUser() {
        if (this.authUser && this.product) {
            return this.product.user_id === this.authUser.id;
        }

        return false;

    }

    openDeletionModal(content) {
        this.deletionModal = this.modalService.open(content, { centered: true, size: 'sm' });
    }

    activateOrDesactivateProduct(activated) {

        this.isLoading = true;
        const formData = new FormData();

        formData.append('activated', activated);
        formData.append('_method', 'PUT');

        this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
            (product: any) => {

                this.isLoading = false;
                this.product = product;
                if (activated) {
                    this.toastr.info('L\'annonce a été activée avec succès');
                }

                if (!activated) {
                    this.toastr.info('L\'annonce a été désactivée avec succès');
                }

            }
        );

    }

    deleteProduct() {

        this.isLoading = true;

        this.productService.deleteUserProduct(this.authUser.id, this.product.id).then(
            (product: any) => {
                this.isLoading = false;

                this.deletionModal.close();

                this.router.navigate(['/account/rooms']).then(
                    () => {
                        this.toastr.info('L\'annonce a été supprimée avec succès');
                    }
                );
            }
        );
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
