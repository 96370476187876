import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, Output, EventEmitter, Input} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.css']
})

export class CameraComponent implements OnInit, OnDestroy {

    @ViewChild('video', { static: true }) videoElement: ElementRef;
    @ViewChild('canvas', { static: true }) canvas: ElementRef;
    @ViewChild('photo', { static: true }) photo: ElementRef;
    @Output() sendObjectImage = new EventEmitter<any>();
    @Input() captures: any;
    @Input() loading: any;
    file: File;
    videoWidth = 0;
    videoHeight = 0;
    isCameraOn = false;
    image: File;

    constructor(private renderer: Renderer2,
                private toastr: ToastrService) { }

    ngOnInit() {

    }
    startCamera() {
        if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
            navigator.mediaDevices.getUserMedia({ video: true }).then(
                this.attachVideo.bind(this)
            ).catch(this.handleError);

        } else {
            this.toastr.error('Nous sommes désolé, la caméra n\'a pas pu être détectée');
        }
    }

    handleError(error) {
        this.toastr.error('Nous sommes désolé, une erreur s\'est produite' + error);
    }
    attachVideo(stream) {
        this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
        this.isCameraOn = true;
        this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
            this.videoHeight = this.videoElement.nativeElement.videoHeight;
            this.videoWidth = this.videoElement.nativeElement.videoWidth;
            // console.log('dimension',this.videoHeight,this.videoWidth);
        });

    }
    stopStreamedVideo(videoElem) {
        const stream = videoElem.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(  (track) => {
            track.stop();
        });

        videoElem.srcObject = null;
    }

    cancelCamera() {
        this.stopStreamedVideo(this.videoElement.nativeElement);
        this.renderer.destroy();
        this.isCameraOn = false;
    }


    TakeImage() {
        this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
        this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
        this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0, 640, 480);
        this.captures = [];
        this.captures.push(this.canvas.nativeElement.toDataURL('image/png'));
        this.file = new File([this.convertBase64ToFile(this.canvas.nativeElement.toDataURL('image/png'))], this.nameFile(), {
            type: 'image/png',
        });

        this.cancelCamera();

        this.sendObjectImage.emit(this.file);

    }

    convertBase64ToFile(image) {
        const byteString = atob(image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
            type: "image/png",
        });
        return newBlob;
    }

    private nameFile() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 20; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text + '.png';
    }

    ngOnDestroy(): void {
        if (this.isCameraOn) {
            this.cancelCamera();
        }
    }
}
