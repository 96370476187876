import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../services/products/products.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {AuthUser} from '../../../models/auth-user.model';
import {FormStep} from '../../../models/product.model';

@Component({
    selector: 'app-template-wizard',
    templateUrl: './template-wizard.component.html',
    styleUrls: ['./template-wizard.component.css']
})
export class TemplateWizardComponent implements OnInit, OnDestroy {
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    formWizardStepsSubscription: Subscription;
    formWizardSteps: FormStep[];
    productId;
    product: any;
    title: string;
    loading = true;
    activeStep: FormStep;

    constructor(private route: ActivatedRoute,
                private authService: AuthService,
                private productService: ProductsService,
                private router: Router) { }

    ngOnInit() {
        this.route.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;

                    this.userSubscription = this.authService.userSubject.subscribe(
                        (user: AuthUser) => {
                            if (this.productId === 'new') {
                                this.loading = false;

                                this.formWizardSteps = this.productService.getFormWizardStep();
                                this.activeStep = this.productService.getActiveStep();

                            }

                            if (user && this.productId !== 'new') {
                                this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                    (product: any) => {
                                        if (product && (product.verified || product.published)) {
                                            this.router.navigate(['/account/rooms', product.id]);
                                        } else {
                                            this.product = product;
                                            this.loading = false;
                                            this.formWizardStepsSubscription = this.productService.formWizardSubject.subscribe(
                                                (formWizardSteps: FormStep[]) => {
                                                    this.formWizardSteps = formWizardSteps;
                                                    this.activeStep = this.productService.getActiveStep();
                                                }
                                            );
                                            this.productService.getFormWizardStep();
                                            this.productService.emitFormWizardSteps();
                                        }
                                    },
                                    error => {

                                        console.log(error);
                                        this.router.navigate(['/rooms/new']);
                                    }
                                );
                            }
                        }
                    );

                    this.authService.emitUser();
                }
            }
        );
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }

        if (this.formWizardStepsSubscription) {
            this.formWizardStepsSubscription.unsubscribe();
        }
    }



}
