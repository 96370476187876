import { Component, OnInit, Input,  AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { AuthorizationService } from '../../security/service/authorization.service';
import {  Router } from '@angular/router';
import {IPagination} from '../../model/pagination';

declare var $ :any;
@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableComponent implements OnInit,AfterViewInit {
  @ViewChild("myimg") elementView: ElementRef;
  // configuration de la tabelau les donnees venant du fichier json
  @Input() config: string;

  // donnees du tableau 
  @Input() data: any;
    // on a deux type d'action icon et menu
  @Input() type: any;
  // si existe alors on affiche la colonne checkbox
  @Input() checkbox:any;
  // l'object qui vient du fichier json
  @Input() dataJson: any;

  @Input() paramsPage: IPagination;
  total_link=0;
  total_hide=0;
  total_permission=0;
  @Output() sendPage = new EventEmitter<any>();

 

  constructor(public authorization:AuthorizationService,public router:Router) { 

 
   
  }

  ngOnInit() {

    // if(!this.paramsPage){
    //   this.paramsPage.id="server";
    //   this.paramsPage.currentPage=0;
    //   this.paramsPage.totalItems=0;
    //   this.paramsPage.itemsPerPage=0;
    // }
      console.log('paa',this.paramsPage);
   
    this.total_link=(this.dataJson.menu_icon_function?this.dataJson.menu_icon_function.length:0)+(this.dataJson.menu_icon?this.dataJson.menu_icon.length:0);
    this.dataJson.table=this.authorization.moveHasPermission(this.dataJson.table);
    if(this.checkbox && this.checkbox!=''){
      $(document).ready(function() {
       
       var table= $('#dataTable').DataTable(
          {
            "paging":   false,
            "info":     false,
            columnDefs: [ {
                orderable: false,
                className: 'select-checkbox',
                targets:   0,
                render: function (data, type, full, meta){
                  return '<input type="checkbox" name="id[]" value="' + $('<div/>').text(data).html() + '">';
              }
            } ],
            select: {
                style:    'os',
                selector: 'td:first-child'
            },
            "language": {
              "emptyTable": "aucune donnée disponible"//Change your default empty table message
            }
           
        }
        );
        // Clic sur le bouton "Sélectionner tout"
        $('#example-select-all').on('click', function(){
         // Récupère toutes les lignes avec la recherche appliquée
          var rows = table.rows({ 'search': 'applied' }).nodes();
         // Cochez / décochez les cases pour toutes les lignes du tableau
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
       });


      // Cliquez sur la case à cocher pour définir l'état du contrôle "Sélectionner tout"
   $('#dataTable tbody').on('change', 'input[type="checkbox"]', function(){
   // Si la case n'est pas cochée
    if(!this.checked){
       var el = $('#example-select-all').get(0);
       // Si le contrôle "Sélectionner tout" est coché et possède la propriété "indéterminée"
       if(el && el.checked && ('indeterminate' in el)){
          // Définit l'état visuel du contrôle "Sélectionner tout"
                    // comme 'indéterminé'
          el.indeterminate = true;
       }
    }
    });

    // Handle form submission event
   $('#frm-example').on('submit', function(e){
    var form = this;

    // Iterate over all checkboxes in the table
    table.$('input[type="checkbox"]').each(function(){
       // If checkbox doesn't exist in DOM
       if(!$.contains(document, this)){
          // If checkbox is checked
          if(this.checked){
             // Create a hidden element
             $(form).append(
                $('<input>')
                   .attr('type', 'hidden')
                   .attr('name', this.name)
                   .val(this.value)
             );
          }
       }
    });
 });
 
      });
    }else{
      $(document).ready(function() {
        $('#dataTable').DataTable({
          "language": {
            "emptyTable": "No data available in table"//Change your default empty table message
          }
        });
      });
    }
    
  }
  

  // change(e){
  //   console.log(e.target.checked,e.target.value);
  // }
  
  retrieveDataChild(e){
    // console.log('test',e);
     this.data=e.data.data;
  }
  
  ngAfterViewInit(){
    // console.log(this.elementView);
  }

  clickTr(item,link){
    console.log('item',item);
    if(item && link.link_parent){
      this.router.navigate([link.link_parent,item.id]);
      if(link.link_child){
        this.router.navigate([link.link_parent,item.id,link.link_child]);
      }
    }
  }
 
  getValue(data,value){
 
    let level = value.split(".");
    var currentObjState = data; 
    for (var i = 0; i < level.length; i++) {
      currentObjState = currentObjState[level[i]];
    }  
    return currentObjState;
  }

  

  getPage(page: number){
    console.log(location.search);
    console.log('page',page);
    this.sendPage.emit({page:page,params:location.search});
  }

  parseFloat(number){
    return parseFloat(number);
  }
}
