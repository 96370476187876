import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AdminAccountService } from '../../services/admin.account.service';
import { Subscription } from 'rxjs';
import { AdminService } from '../../services/admin.service';
@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit,OnDestroy {
  @Input() url: string;
  @Input() subUrl: any;
  @Input() params: any;
  @Input() back:boolean=false;
  @Input() icon:boolean=false;
  @Input() id:any;

  urlBack: string;
  previousUrl: string;
  subscription: Subscription;
  constructor(private router: Router, private authAdmin: AdminAccountService,private adminService:AdminService) { 
  
   
  }
  ngOnDestroy(): void {
  // this.subscription.unsubscribe();
  }
 
  ngOnInit() {

   
  }

  action(){
    this.adminService.setActionBack(true);
    // console.log(this.adminService.getActionBack());
    if(this.subUrl && this.params){
      this.router.navigate([this.url,this.params,this.subUrl]);
    }else{
      this.router.navigate([ this.url]);
    }
    if(this.id && this.url){
      this.router.navigate([ this.url,this.id]);
    }

  }

  backAction(){
     this.adminService.setActionBack(false);
    // this.router.navigate([this.previousUrl]);
    window.history.back();
  }
}
