import { Injectable } from '@angular/core';
import { AdminAccountService } from '../../services/admin.account.service';
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService  {
  public permissions:any; // Stocke les actions pour lesquelles cet utilisateur est autorisé
  isSuperAdmin='';
  currentUser: any;

  t2=[];
  i: any;
  constructor( private authAdmin: AdminAccountService) { 
        this.authAdmin.$admin.then(
      (admin: any) => {
        if (admin) {
          this.currentUser =admin;
          this.permissions = this.currentUser.roles[0].functionalities;
          this.isSuperAdmin = this.currentUser.roles[0].name;
          // console.log('super',admin);
        
          
        }
  
      }
    );
  }

 public hasPermission(hasPermission:string):boolean {
  console.log(hasPermission);
  let p=hasPermission!=undefined?hasPermission:"";
  let permissions = false;
   
      if( this.isSuperAdmin && this.isSuperAdmin==="superadmin"){
        return true;
      }
      if (this.isSuperAdmin) {
        const permissionFound = this.permissions.find(element => element.name ===p);
        if (permissionFound) {
          permissions=true;
        } else {
          permissions = false;
        }
      }
      console.info('permission',permissions);
      return permissions;
  
}

public moveHasPermission(columns){
    // on filtre les colonne qui n'ont de permission declaree
    let t1=columns.filter(function(item){
      if(!item.hasPermission){
        return true;
      }
    });

      // on filtre les colonnes qui ont une permission declaree
      let t=columns.filter(function(item){
        if(item.hasPermission){
          return true;
        }
      });
     
      if(this.isSuperAdmin==="superadmin" && this.isSuperAdmin){
        return columns;
      }else{
        t.forEach(i => {
          const permissionFound = this.permissions.find(element => element.name ===i.hasPermission);
          if (!permissionFound) {
              this.t2.push(i);
              
          } 
        });
       
        if(this.t2.length!=0){
          return t1.concat(this.t2);
        }else{
          // console.log('')
          return t1;
        }
          
      }
    
    

}




}
