import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../../services/app/app.service';

@Component({
    selector: 'app-booking-price-details',
    templateUrl: './booking-price-details.component.html',
    styleUrls: ['./booking-price-details.component.css']
})
export class BookingPriceDetailsComponent implements OnInit {
    @Input() data: any;
    @Input() product: any;
    @Input() submit: boolean;
    @Input() loading: boolean;
    @Input() loaded: boolean;
    @Input() submittedPayment: boolean;
    @Input() currency: string;
    @Input() step: string;

    constructor(private appService: AppService) { }

    ngOnInit() {
    }

    transformCalculatedCurrency(price) {
        return this.appService.transformCurrency(price, this.currency);
    }

}
