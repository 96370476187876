import { Injectable } from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Subject} from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    forwardedProductByCategories: any;
    categories: any;
    forwardedProductByCategoriesSubject = new Subject<any>();

    constructor(private authService: AuthService) { }

    emitForwardedProductByCategories() {
        this.forwardedProductByCategoriesSubject.next(this.forwardedProductByCategories);
    }

    getCategories() {
        return this.authService.get('categories');
    }

    public getForwardedProductByCategories() {
        return this.authService.get('settings/products/forwarded').then(
            (categories: any) => {
                this.forwardedProductByCategories = Object.entries(categories);
                this.emitForwardedProductByCategories();
            },
            (err: any) => {
                console.log(err);
            }
        );
    }

    getCategoryNameByTag(tag) {
        const categoryName = {
            'furnished-homes': 'Habitations meublées',
            'furnished-accommodation': 'Habitations meublées',
            'sports-halls': 'Salles d\'activité',
            'sport-hall': 'Salles d\'activité',
            offices: 'Bureaux équipés'
        };

        return categoryName[tag];
    }
}
