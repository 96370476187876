import { Routes } from '@angular/router';
import { NotFoundComponent } from '../views/not-found/not-found.component';

export const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: 'site',
                pathMatch: 'full'
            },

            {
                path: 'site',
                loadChildren: () => import('../views/views.module').then(m => m.ViewsModule)
            },
            {
                path: 'admin',
                loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule)
            },
            { path: 'not-found', component: NotFoundComponent },
            { path: '**', component: NotFoundComponent },
        ]
    }
];
