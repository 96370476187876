export const environment = {
    production: true,
    appName: 'Shapart',
    folder_admin: 'admin',
    baseUrlApi: 'https://api.shapart.fr/api/',
    adminBaseUrlApi: 'https://api.shapart.fr/admin-api/',
    keyStripe: 'pk_test_WfnE7JNW92EcEg8869ArjZM6002IeAISlC',
    pusherAppKey: 'a4b0b762c8ec9d60ec5e',
    pusherAppSecret: '57deea9f2768464eebfd',
    pusherAppCluster: 'eu',
    payPalClientId: 'AQr8-JkzSE2s_OHHjbPVq9C5pPlvCYuAlWJuoqlXMeYW-V-VsiByRStR57gwo4f228PUglNTgwYEist2',
    passport: {
        grant_type: 'password',
        client_id: 3,
        client_secret: 'dtjgD8HNt1Mzx3PvpWMThXFUapSe9bTcVRo2ak9V',
        passport_client_id: 2,
        passport_client_secret: 'qKJ5xptMXlrMY9LPcGXWtEQdyrM6dO2XZgzQIzSG'
    },
};
