import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {NgbCalendar, NgbDate, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsService} from '../../services/products/products.service';
import {QtyModel} from '../../models/app.model';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {
    @Input() product: any;
    @Input() mode = 'booking';
    @Input() fromDate: NgbDate;
    @Input() toDate: NgbDate;
    @Input() dateStartId = '';
    @Input() dateEndId = '';
    @Input() qtyModel: QtyModel[] = [
        {
            id: 'adults',
            tittle: 'Adultes',
            qty: 0,
            min: 1,
            max: 20
        },
        {
            id: 'children',
            tittle: 'Enfants (2-12 ans)',
            qty: 0,
            min: 0,
            max: 10
        },

        {
            id: 'babies',
            tittle: 'Bébés (-2 ans)',
            qty: 0,
            min: 0,
            max: 5
        },
    ];
    @Input() queryParams: any = {};

    @Input() loading: boolean;
    @Input() submitted: boolean;
    @Input() submitting: boolean;
    @Input() loadingPrice: boolean;
    @Input() initData: boolean;

    @Output() productChange = new EventEmitter<any>();
    @Output() queryParamsChange = new EventEmitter<any>();
    @Output() loadingChange = new EventEmitter<boolean>();
    @Output() submittedChange = new EventEmitter<boolean>();
    @Output() loadingPriceChange = new EventEmitter<boolean>();
    @Output() fromDateChange = new EventEmitter<NgbDate>();
    @Output() toDateChange = new EventEmitter<NgbDate>();
    @Output() qtyModelChange = new EventEmitter<QtyModel[]>();

    schedules = [];
    reversedSchedules = [];
    hoveredDate: NgbDate;
    isFocused: boolean;
    minDate: NgbDateStruct;
    minFromDate;
    maxToDate;
    errorMessage: string;
    private hasInitData: boolean;
    private loaded: boolean;

    constructor(calendar: NgbCalendar,
                public formatter: NgbDateParserFormatter,
                private route: ActivatedRoute,
                private productsService: ProductsService,
                private router: Router,
                private toastr: ToastrService) {
        this.minDate = {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date()
        };
    }

    ngOnInit() {
        this.qtyModelChange.emit(this.qtyModel);

        const schedules = this.product.unavailable_dates;

        this.schedules = schedules.slice();

        this.reversedSchedules = schedules.reverse();

        this.qtyModelChange.subscribe(() => {
            const adults =  this.getQtyModel('adults').qty;
            const children = this.getQtyModel('children').qty;
            const babies = this.getQtyModel('babies').qty;
            const guests = adults + children;

            this.queryParams.adults = adults > 0 ? adults : null;
            this.queryParams.children = children > 0 ? children : null;
            this.queryParams.babies = babies > 0 ? babies : null;
            this.queryParams.guests = guests > 0 ? guests : null;

            this.queryParamsChange.emit(this.queryParams);

        });

        this.fromDateChange.subscribe((date: NgbDate) => {
            setTimeout(() => {
                this.fromDate = date;
                this.setDates();
            });
        });

        this.toDateChange.subscribe((date: NgbDate) => {
            setTimeout(() => {
                this.toDate = date;
                this.setDates();
            });
        });

        if (this.initData && !this.hasInitData) {
            this.initQueryParams();
        }

        if (this.mode === 'booking') {
            this.subscribeToQueryParams();
        }
    }

    initQueryParams() {
        let adults = 0;
        let children = 0;
        let babies = 0;

        if (this.queryParams.dateStart && this.queryParams.dateEnd) {
            this.initDates(this.queryParams.dateStart, this.queryParams.dateEnd);
        }

        if (this.queryParams.adults) {
            adults = parseInt(this.queryParams.adults.toString());
        }

        if (this.queryParams.children) {
            children = parseInt(this.queryParams.children.toString());
        }

        if (this.queryParams.babies) {
            babies = parseInt(this.queryParams.babies.toString());
        }

        this.setQuantities(adults, children, babies);
        this.hasInitData = true;

    }

    initDates(dateStart, dateEnd) {
        if (dateStart && dateEnd) {
            const formattedDateStart = this.formatter.parse(dateStart);
            const formattedDateEnd = this.formatter.parse(dateEnd);

            this.fromDateChange.emit(new NgbDate(
                formattedDateStart.year,
                formattedDateStart.month,
                formattedDateStart.day)
            );
            this.toDateChange.emit(new NgbDate(
                formattedDateEnd.year,
                formattedDateEnd.month,
                formattedDateEnd.day)
            );
        }

    }

    subscribeToQueryParams() {
        this.route.queryParamMap.subscribe(
            params => {

                let adults = 0;
                let children = 0;
                let babies = 0;

                if (params.get('dateStart') && params.get('dateEnd')) {
                    this.initDates(params.get('dateStart'), params.get('dateEnd'));
                } else if (this.hasDates()) {
                    this.clearDates();
                }

                if (!this.loaded) {
                    if (params.get('adults')) {
                        adults = parseInt(params.get('adults'));
                    }

                    if (params.get('children')) {
                        children = parseInt(params.get('children'));
                    }

                    if (params.get('babies')) {
                        babies = parseInt(params.get('babies'));
                    }
                    this.setQuantities(adults, children, babies);
                    this.loaded = true;
                }

            }
        );
    }
    setQuantities(adults, children, babies) {
        const guests = adults + children;
        if (this.qtyModel[2].qty !== babies) {
            this.editQtyInput(2, 'babies', babies);
        }

        if (guests > this.product.number_people || adults === 0) {
            this.qtyModel[0].qty = 1;
            this.qtyModelChange.emit(this.qtyModel);
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {adults: 1, children: null},
                queryParamsHandling: 'merge'
            });

        } else {
            if (this.qtyModel[0].qty !== adults) {
                this.editQtyInput(0, 'adults', adults);
            }

            if (this.qtyModel[1].qty !== children) {
                this.editQtyInput(1, 'children', children);
            }
        }

    }


    setDates() {
        this.loadingPriceChange.emit(true);

        const dateStart = this.fromDate ? this.formatter.format(this.fromDate) : null;
        const dateEnd = this.toDate ? this.formatter.format(this.toDate) : null;

        if (dateStart && dateEnd) {
            if (this.isValid()) {
                this.productsService.checkAvailability(this.product.id, {dateStart, dateEnd}).then(
                    (isAvailable: boolean) => {
                        this.loadingPriceChange.emit(false);
                        if (isAvailable) {
                            this.errorMessage = null;

                            this.queryParams.dateStart = dateStart;
                            this.queryParams.dateEnd = dateEnd;
                            this.queryParamsChange.emit(this.queryParams);

                            if (this.mode === 'booking') {
                                this.refreshPrice();
                            }
                        } else {
                            this.product.pricing = null;
                            this.productChange.emit(this.product);
                            this.errorMessage = 'Annonce indisponible à ces dates';
                            this.toastr.error('Annonce indisponible à ces dates');

                            this.clearDates();
                        }
                    },

                    () => {
                        this.queryParams.dateStart = null;
                        this.queryParams.dateEnd = null;
                        this.queryParamsChange.emit(this.queryParams);
                        this.loadingPriceChange.emit(false);
                        this.toastr.error('Désolé, une erreur s\'est produite');
                    }
                );
            } else {
                this.clearDates();
                this.toastr.error('Date invalides');
            }
        } else  {
            this.queryParams.dateStart = null;
            this.queryParams.dateEnd = null;
            this.queryParamsChange.emit(this.queryParams);
            this.loadingPriceChange.emit(false);

            if (!this.isAllowed()) {
                this.clearDates();
            }
        }
    }

    editQtyInput(index, id, qty) {
        this.fillQtyInput(index, id, qty);
        this.setQtyInputQueryParams(index, id);
    }

    fillQtyInput(index, id, qty) {
        const modelQty = this.qtyModel[index].qty;
        const modelMin = this.qtyModel[index].min;

        if (qty < 0 &&  modelQty > modelMin) {
            this.qtyModel[index].qty += qty;
        }

        if (qty > 0 && this.isValidQtyInput(index, id)) {
            this.qtyModel[index].qty += qty;
        }

        this.qtyModelChange.emit(this.qtyModel);
    }

    setQtyInputQueryParams(index, id, qty = this.qtyModel[index].qty) {

        const query: any = {};

        query[id] = qty > 0 ? qty : null;

        if (this.mode === 'booking') {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: query,
                queryParamsHandling: 'merge'
            });
        }
    }

    isValidQtyInput(index, id) {
        const modelQty = this.qtyModel[index].qty;
        const modelMax = this.qtyModel[index].max;

        if (modelQty < modelMax && id === 'babies') {
            return true;
        }

        if (modelQty < modelMax && this.getNbrGuests() < this.product.number_people && id !== 'babies') {
            return true;
        }
        return false;
    }

    getNbrGuests(): number {
        return this.getQtyModel('adults').qty + this.getQtyModel('children').qty;
    }

    getQtyModel(id): QtyModel {
        return this.qtyModel.find(
            (model: QtyModel) => {
                return model.id === id;
            }
        );
    }

    onDateSelection(date: NgbDate) {

        if (!this.isUnavailable(date) && !this.checkDate(date)) {
            if (!this.fromDate && !this.toDate) {
                this.fromDateChange.emit(date);
                this.isFocused = true;
            } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
                this.isFocused = false;
                this.toDateChange.emit(date);
            } else if (this.fromDate && !this.toDate && date.equals(this.fromDate)) {
                this.isFocused = false;
                this.toDateChange.emit(date);
            } else {
                this.isFocused = true;
                this.toDateChange.emit(null);
                this.fromDateChange.emit(date);
            }
        }

    }


    isValid(): boolean {
        return this.hasDates() && this.isAllowed() && this.isAccepted();
    }
    hasDates(): boolean {
        return this.fromDate != null && this.toDate != null;
    }

    isAllowed(): boolean {
        return !this.isNotAllowed(this.fromDate) && !this.isNotAllowed(this.toDate);
    }

    isAccepted(): boolean {
        return this.fromDate.before(this.toDate) || this.fromDate.equals(this.toDate);
    }

    isHovered(date: NgbDate): boolean {
        return this.fromDate && !this.toDate &&
            !this.isUnavailable(date) && !this.checkDate(date) &&
            this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate): boolean {
        return !this.isUnavailable(date) && !this.checkDate(date) && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate): boolean {
        return (!this.isUnavailable(date) && !this.checkDate(date)) &&
            (date.equals(this.fromDate) || date.equals(this.toDate) ||
                this.isInside(date) || this.isHovered(date));
    }

    isNotAllowed(date: NgbDate): boolean {
        return date ? moment(new Date(this.formatter.format(date))).isBefore(new Date()) : false;
    }

    isUnavailable(date: NgbDate): boolean {
        const newDate = moment(new Date(this.formatter.format(date))).format('YYYY-MM-DD');

        return this.schedules.includes(newDate) || !this.isAllowedRange(date);
    }

    isAllowedRange(date: NgbDate): boolean {
        if (this.isNotAllowed(date)) {
            return true;
        }

        if (!this.product.settings || !this.product.settings.value.booking_constrains_time) {
            return true;
        }

        if (!this.fromDate && !this.toDate) {
            return true;
        }

        const minDuration: number = this.product.settings.value.booking_constrains_time[0];
        const maxDuration: number = this.product.settings.value.booking_constrains_time[1];
        let newFromDate = null;
        let newToDate = null;

        if (this.fromDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
            newFromDate = moment(new Date(this.formatter.format(this.fromDate)));
            newToDate = moment(new Date(this.formatter.format(date)));
        } else if (this.toDate && (date.before(this.toDate) || date.equals(this.toDate))) {
            newFromDate = moment(new Date(this.formatter.format(date)));
            newToDate = moment(new Date(this.formatter.format(this.toDate)));
        } else {
            return true;
        }

        const duration = newToDate.diff(newFromDate, 'days');
        return duration >= minDuration && duration <= maxDuration;


    }

    getStartDate() {
        return this.fromDate ? moment(new Date(this.formatter.format(this.fromDate))).format('DD-MM-YYYY') : null;
    }

    getEndDate() {
        return this.toDate ? moment(new Date(this.formatter.format(this.toDate))).format('DD-MM-YYYY') : null;
    }

    checkDate(date: NgbDate) {

        if (this.minFromDate && this.maxToDate && !this.isNotAllowed(date)) {

            const currentDate = moment(new Date(this.formatter.format(date)));

            const minFromDate = moment(new Date(this.minFromDate));
            const maxToDate = moment(new Date(this.maxToDate));

            return currentDate.isBefore(minFromDate) || currentDate.isAfter(maxToDate);
        }

        return false;
    }

    checkStartDate(date: NgbDate) {
        if (!this.isNotAllowed(date) && !this.isUnavailable(date) && !this.checkDate(date)) {
            this.fromDateChange.emit(date);
            this.loadingChange.emit(true);

            if (this.toDate && date.after(this.toDate)) {
                this.toDateChange.emit(null);
                this.loadingChange.emit(true);
            }

            if (!this.toDate) {
                this.loadingChange.emit(true);
                const minFromDate = moment(new Date(this.formatter.format(date)));

                this.schedules.find(
                    (schedule) => {
                        const currentDate = moment(schedule);

                        if (currentDate.isAfter(minFromDate)) {

                            this.minFromDate = minFromDate.format('YYYY-MM-DD');
                            this.maxToDate = currentDate.format('YYYY-MM-DD');
                            return true;
                        }

                        this.minFromDate = null;
                        this.maxToDate = null;

                    }
                );
            }
        }

    }

    checkEndDate(date: NgbDate) {
        if (!this.isNotAllowed(date) && !this.isUnavailable(date) && !this.checkDate(date)) {
            this.toDateChange.emit(date);
            this.loadingChange.emit(true);
            if (this.fromDate && date.before(this.fromDate)) {
                this.fromDateChange.emit(null);
            }

            if (!this.fromDate) {
                const maxToDate = moment(new Date(this.formatter.format(date)));

                this.reversedSchedules.find(
                    (schedule) => {
                        const currentDate = moment(schedule);

                        if (currentDate.isBefore(maxToDate) && currentDate.isSameOrAfter(moment())) {
                            this.maxToDate = maxToDate.format('YYYY-MM-DD');
                            this.minFromDate = currentDate.format('YYYY-MM-DD');
                            return true;
                        }

                        this.minFromDate = null;
                        this.maxToDate = null;
                    }
                );
            }
        }
    }


    refreshPrice() {
        if (this.getStartDate() && this.getEndDate()) {
            if (this.fromDate.equals(this.toDate)) {
                const formattedToDate = this.formatter.parse(moment(
                    this.formatter.format(this.toDate))
                    .add(1, 'days')
                    .format('YYYY-MM-DD'));

                const newToDate = new NgbDate(formattedToDate.year, formattedToDate.month, formattedToDate.day);
                this.toDateChange.emit(newToDate);
            }
            setTimeout(() => {
                const fromDate = this.formatter.format(this.fromDate);
                const toDate = this.formatter.format(this.toDate);

                this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {
                            dateStart: fromDate,
                            dateEnd: toDate
                        },
                        queryParamsHandling: 'merge'
                    }
                );
            });
        }
    }

    clearDates() {

        this.fromDateChange.emit(null);
        this.toDateChange.emit(null);
        this.minFromDate = null;
        this.maxToDate = null;
        this.submittedChange.emit(false);

        if (this.mode === 'booking') {
            this.router.navigate([],
                {
                    relativeTo: this.route,
                    queryParams:
                        {
                            dateStart: null,
                            dateEnd: null
                        },
                    queryParamsHandling: 'merge'
                }
            );

        }
    }

}
