import { Component, OnInit } from '@angular/core';
import * as filter from '../../json/filter/filter.json';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable_bookings.json';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import * as  excel from '../../json/excel/bookings.json';
import {IPagination} from '../../model/pagination';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-list-bookings',
  templateUrl: './list-bookings.component.html',
  styleUrls: ['./list-bookings.component.css']
})
export class ListBookingsComponent implements OnInit,GetAdmin {
  filter=filter.bookings;
  excelTable=excel.booking;
  data:any;
  breadcrumb=breadcrumb.bookings;

  loaded : boolean;
  data_json=datatable.bookings;

  adminId: any;
  paramsPage: IPagination;
 
  constructor(
              public adminService:AdminService, 
              private route: ActivatedRoute
            ) {
    this.paramsPage={} as IPagination;  
   }



  ngOnInit() {
    this.adminService.setTitle('Reservations');
    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getParams();
    });
    
   }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
          if(params){
           
            if(this.adminId){
              this.getAllBookings( this.adminId,location.search);
            }
          
          }else{
           if(this.adminId){
            this.getAllBookings(this.adminId);
           }
           
          }
      }); 
  }


    getAllBookings(AdminId, params?:string){
      this.loaded=false;
    this.adminService.getAllReservations(AdminId,params).then((response:any)=>{
        this.data=response.data ? response.data.data : [];
        this.loaded = true;
        this.paramsPage.currentPage=response.data?response.data.current_page:0;
        this.paramsPage.totalItems=response.data?response.data.total:0;
        this.paramsPage.currentPage=response.data?response.data.current_page:0;
        this.paramsPage.itemsPerPage=response.data?response.data.per_page:0;
         console.log('list booking',this.data);
    },err=>{
      console.log('ERREUR',err);
    });
  }

  getPage(page){
   
    this.getAllBookings(this.adminId,"?page="+page);
  }

}
