import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';
import {MapsAPILoader} from '@agm/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {MapCord} from '../../../../models/app.model';
import {AgmService} from '../../../../services/agm/agm.service';
import * as countries from 'i18n-iso-countries';
declare var require: any;

@Component({
    selector: 'app-location-room-form',
    templateUrl: './location-room-form.component.html',
    styleUrls: ['./location-room-form.component.css']
})
export class LocationRoomFormComponent implements OnInit, OnDestroy {
    @ViewChild('search') public searchElementRef: ElementRef;

    @Input() formMode = 'create';

    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    formRoom: FormGroup;
    loaded;
    productId: Params;
    loading;
    latitude: number;
    longitude: number;
    zoom: number;
    map = false;
    searchControl: any;
    submitted;
    errors: any;
    product: any;
    countries: any;

    private mapCoorSubscription: Subscription;
    private cord: MapCord;
    constructor(private router: Router,
                private authService: AuthService,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private productService: ProductsService,
                private toastr: ToastrService,
                private agmService: AgmService) {
        this.zoom = 14;

        this.searchControl = new FormControl();
    }

    ngOnInit() {
        this.initForm();

        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {
                    this.productId = params.id;
                }
            }
        );

        countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

        this.countries = countries.getNames('fr');

        this.mapCoorSubscription = this.agmService.mapCordSubject.subscribe(
            (cord: MapCord) => {
                setTimeout(() => {
                    this.cord = cord;

                    if (cord && !cord.isLoading && cord.status == 'OK') {
                        this.searchControl.setValue(cord.adress);
                        this.formRoom.controls.address.setValue(cord.adress);
                        this.formRoom.controls.town.setValue(cord.city);
                        this.formRoom.controls.country.setValue(this.countries[cord.country] ? this.countries[cord.country] : cord.country);
                        this.formRoom.controls.country_code.setValue(cord.country);
                        this.latitude = cord.latitude;
                        this.longitude = cord.longitude;

                        if (this.latitude && this.longitude) {
                            this.map = true;
                        }
                    }
                }, 100);
            }
        );

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {
                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {

                                    if (product) {
                                        this.product = product;
                                        this.loaded = true;

                                        setTimeout(() => {
                                            this.agmService.placeAutoComplete(this.searchElementRef.nativeElement);
                                        }, 500);

                                        if (product.latitude && product.longitude && product.adress) {
                                            this.searchControl.setValue(product.adress);
                                            this.formRoom.controls.address.setValue(product.adress);
                                            this.formRoom.controls.country.setValue(this.countries[product.country] ?
                                                this.countries[product.country] : product.country);
                                            this.formRoom.controls.country_code.setValue(product.country);
                                            this.formRoom.controls.town.setValue(product.town);
                                            this.latitude =  parseFloat(product.latitude);
                                            this.longitude = parseFloat(product.longitude);
                                            this.map = true;
                                        }
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },
                                (error) => {
                                    console.log(error);
                                    if (this.formMode === 'create') {
                                        this.router.navigate(['/rooms/new']);
                                    }

                                    if (this.formMode === 'edit') {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();
        }
    }

    checkUser() {
        if (this.authUser && this.product && this.product.published) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;
    }
    previous() {
        return this.productService.getPreviousStep();
    }

    doSubmit() {
        this.submitted = true;

        if (this.searchControl.value && !this.formRoom.value.town) {
            this.formRoom.controls.town.enable();
        }

        if (this.formRoom.invalid) {
            return;
        }

        this.loading = true;

        const formData = {
            adress: this.formRoom.value.address,
            country: this.formRoom.value.country_code,
            town: this.formRoom.value.town,
            latitude: this.latitude,
            longitude: this.longitude
        };

        this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
            (product: any) => {
                this.product = product;
                this.loading = false;

                if (this.formMode === 'create') {

                    this.router.navigate([this.productService.getNextStep()]).then(
                        () => {
                            this.toastr.success('Informations de location modifiées');
                        }
                    );
                }

                if (this.formMode === 'edit') {
                    this.toastr.success('Informations de location modifiées');

                }

            },
            (errors: any) => {
                this.loading = false;
                this.errors = errors.error.error;

            }
        );
    }

    initForm() {
        this.formRoom = this.fb.group({
            address: ['', [Validators.required]],
            country: ['', [Validators.required]],
            country_code: ['', [Validators.required]],
            town: ['', [Validators.required]],

        });

        this.formRoom.controls.country.disable();
        this.formRoom.controls.town.disable();
    }

    setCurrentPosition() {
        this.agmService.setCurrentPosition();
    }

    get f() {
        return this.formRoom.controls;
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }

        if (this.mapCoorSubscription) {
            this.mapCoorSubscription.unsubscribe();
        }


    }

}
