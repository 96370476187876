import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { GetAdmin } from '../../model/getadmin';
import { IPagination } from '../../model/pagination';
import { AdminService } from '../../services/admin.service';
import{categories} from './data.owners.json'
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit,GetAdmin {

  breadcrumb=breadcrumb.categories;
   data_json=categories;

  data: any;
  loaded:boolean;
  adminId: any;
  paramsPage: IPagination;
  
  constructor(public adminService:AdminService) {
    this.paramsPage={} as IPagination;
    this.adminService.setTitle('Categories')
   }


  ngOnInit(): void {
    this.informationAdmin();
  }


  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
      this.getAllCategories(this.adminId);
    });
   
   }

     getAllCategories(adminId,params?){
    this.loaded=false;
    this.adminService.getAllCategories(adminId).then((response:any)=>{
        this.data=response.data ? response.data : [];
        this.loaded=true;
        this.paramsPage.currentPage=response.data?response.current_page:0;
        this.paramsPage.totalItems=response.data?response.total:0;
        this.paramsPage.currentPage=response.data?response.current_page:0;
        this.paramsPage.itemsPerPage=response.data?response.per_page:0;
        this.paramsPage.id="server";
        // console.log('categoiris',this.paramsPage);
    },err=>{
      console.log('err',err);
      this.loaded=false;
    
    });
  }

  getPage(e){

  };
}
