import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from '../../services/admin.service';
@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit , OnDestroy ,AfterViewInit {
  subscription: Subscription;
  breadcrumb:any;
  id: string;
  title: any;
  tag: any;
  constructor(private serviceAdmin:AdminService) { }

  ngOnInit() {
 
    this.subscription = this.serviceAdmin.getBreadcrumb().subscribe((data:any) => {
      if(data){
        this.id=data.id?data.id:'';
        this.breadcrumb=data.breadcrumb;
        this.title=data.title;
        this.tag=data.tag?data.tag:'';
        
      }
     
    });
  }
ngAfterViewInit(){
 
}
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
