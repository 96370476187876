import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  security from '../../json/security/security.json';
import { AdminService } from '../../services/admin.service';
import * as  datatable from '../../json/datatable/datatable_bookings.json';

import * as  demands from '../../json/datatable/datatable_requests.json';
import { ActivatedRoute } from '@angular/router';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-detail-traveler',
  templateUrl: './detail-traveler.component.html',
  styleUrls: ['./detail-traveler.component.css']
})
export class DetailTravelerComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.detail_traveler;

  loaded:boolean;
  adminId: any;
  traveler: any;
  list_status = datatable.status;
  list_status_demand = demands.status;
  person={children:0,babies:0,adults:0};

  totalRequest: number=0;
  security=security.security.travelers;

  montant={ca:0, gains:0 ,nbr_refund:0,total_refund:0,nbr_reservation:0};

  constructor(
    private adminService:AdminService,
     private route: ActivatedRoute) {}


  ngOnInit() {
   this.informationAdmin();

  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneTraveler( this.adminId, this.route.snapshot.params.id);
    
    });
   }


  getOneTraveler(AdminId,id){
    this.loaded=false;
    this.adminService.getOneTraveler(AdminId,id).then((data:any)=>{
      
      this.traveler=data;
      this.loaded = true;
       console.log('traveler',data);

      this.calculMontant(data);
    },err=>{
      console.log('err',err);
    });
  }

  // function a refaire dans le backend
  calculMontant(traveler:any){
 
 if(traveler.reservations){
  traveler.reservations.forEach(reservation => {
    this.person.adults=this.person.adults+reservation.adults;
    this.person.children=this.person.children+reservation.children;
    this.person.babies=this.person.babies+reservation.babies;
    this.montant.nbr_reservation=this.montant.nbr_reservation+1;
    if(reservation.payment_client.payment_status=='success'){
      this.montant.ca=this.montant.ca+parseFloat(reservation.payment_client.total_paid);
      this.montant.gains=this.montant.gains+parseFloat(reservation.payment_client.total_paid);
    }

    if(reservation.payment_client.payment_status=="cancelled"){
      this.montant.nbr_refund=this.montant.nbr_refund+1;
      this.montant.total_refund=this.montant.total_refund+reservation.payment_client.total_paid;
    }
  });
 }
  
   
    // console.log('product',this.montant);
}

getSatus(list_status,timing, status?) {
  let statusArr = [];
  if(timing.length>0){
    let s = list_status.find(e => e.value === timing);
    statusArr.push(s.text);
    if (s.type) {
    
      let c = s.type.find(e => e.value === status);
    
      statusArr.push(c.class);
      statusArr.push(c.text);
    } else {
      statusArr.push(s.class);
      statusArr.push(s.text);
    }
  }else{
    let s = list_status.find(e => e.value === status);
   
    statusArr.push(s.class);
    statusArr.push(s.text);
    statusArr.push(s.label);
  }
 
 
 return statusArr;
}

}
