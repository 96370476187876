import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-empty-recipient',
    templateUrl: './empty-recipient.component.html',
    styleUrls: ['./empty-recipient.component.css']
})
export class EmptyRecipientComponent implements OnInit {
    @Input() actionLink: string;
    @Input() iconClass: string;
    @Input() iconStyle = {};
    @Input() textEmpty: string;
    @Input() textLink: string;
    @Input() triggerClickEvent = false;
    @Input() queryParamLink: any = {};

    @Output() actionClickEvent = new EventEmitter();
    constructor() { }

    ngOnInit() {
    }

    onActionClick(): void {
        this.actionClickEvent.emit();
    }

}
