import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  security from '../../json/security/security.json';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import * as  datatable from '../../json/datatable/datatable_bookings.json';
import * as  demands from '../../json/datatable/datatable_requests.json';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-detail-owner',
  templateUrl: './detail-owner.component.html',
  styleUrls: ['./detail-owner.component.css']
})
export class DetailOwnerComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.detail_owner;
  security=security.security.host;
  list_status = datatable.status;
  list_status_demand = demands.status;
  loaded:any;
  owner: any;
  p: number = 1;
  totalReservation: number=0;
  totalRequest: number=0;

  montant={ca:0, gains:0 ,nbr_refund:0,total_refund:0,nbr_reservation:0};
  person={children:0,babies:0,adults:0};
  constructor(
    private route: ActivatedRoute,
    private adminService:AdminService,
    public router:Router) { }

  ngOnInit() {
    this.informationAdmin();
 
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{      
     this.getOneOwner(admin.id,this.route.snapshot.params.id)
    });
   }


  getOneOwner(idAdmin,id){
    this.adminService.getOneOwner(idAdmin,id).then((data:any)=>{
 
      this.adminService.setTitle('information sur  l\'hôte'+ data.full_name); 
      // console.log('owner',data);
      this.owner=data;
      // this.getSatus(this.agency.status);
      this.loaded=true;

      if(this.owner.reservations){
        this.owner.reservations.forEach(element => {
          this.totalReservation= this.totalReservation+parseFloat(element.total);
        });
      }

      if(this.owner.demands){
        this.owner.demands.forEach(element => {
          this.totalRequest= this.totalRequest+parseFloat(element.total);
        });
      }

       this.calculMontant(this.owner);
    },err=>{
      console.log('erreur',err)
    })
  }

  openUrl(id,url,subUrl){
    // console.log('url',url+"/"+id+"/"+subUrl);
    this.router.navigate([url,id,subUrl]);
  }

  calculMontant(owner:any){

    // console.log('owner',owner.reservations);
    owner.reservations.forEach(reservation => {
    
      this.montant.nbr_reservation=this.montant.nbr_reservation+1;
      this.montant.nbr_reservation=this.montant.nbr_reservation+1;
      if(
        reservation.payment_host.payment_status=='success'
        ||
        reservation.payment_host.payment_status=='pending'
      ){
        this.person.adults=this.person.adults+reservation.adults;
        this.person.children=this.person.children+reservation.children;
        this.person.babies=this.person.babies+reservation.babies;
        this.montant.ca=this.montant.ca+parseFloat(reservation.total);
        this.montant.gains=this.montant.gains+parseFloat(reservation.payment_host.total);
      }
      
      if(reservation.payment_host.payment_status=="cancelled"){
        this.montant.nbr_refund=this.montant.nbr_refund+1;
        this.montant.total_refund=this.montant.total_refund+reservation.payment_host.total;
      }
    });
 
   
      // console.log('product',this.montant);
  }


  getSatus(list_status,timing, status?) {
    let statusArr = [];
    if(timing.length>0){
      let s = list_status.find(e => e.value === timing);
      statusArr.push(s.text);
      if (s.type) {
      
        let c = s.type.find(e => e.value === status);
      
        statusArr.push(c.class);
        statusArr.push(c.text);
      } else {
        statusArr.push(s.class);
        statusArr.push(s.text);
      }
    }else{
      let s = list_status.find(e => e.value === status);
     
      statusArr.push(s.class);
      statusArr.push(s.text);
      statusArr.push(s.label);
    }
   
   
   return statusArr;
  }
}
