import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as filter from '../../json/filter/filter.json';
import * as  datatable from '../../json/datatable/datatable_requests.json';
import { AdminAccountService } from '../../services/admin.account.service';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/response.model';
import {IPagination} from '../../model/pagination';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit,GetAdmin {
  loaded :boolean;
  breadcrumb=breadcrumb.requests;
  data_json=datatable.requests;
  response: ApiResponse;
  queryParams: any = {
    per_page: 15,
    page: 1
};
filter=filter.requests;
  data: any;
  paramsPage: IPagination;
  constructor(public adminService:AdminService, private route: ActivatedRoute) {
    this.paramsPage={} as IPagination;
   }

  

  ngOnInit() {
  this.adminService.setTitle('Demandes');

    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{     
     this.getParams();
    });
   }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
          if(params){
            this.getAllRequests(location.search);
           
          }else{

            this.getAllRequests(); 
          }
      }); 
  } 
  
  

  getAllRequests(params?:string){
    this.loaded = false;
    this.adminService.getAllRequests(params).then((requests:any)=>{
        this.data=requests ? requests.data : [];
        this.loaded = true;

          this.paramsPage.currentPage=requests.current_page;
          this.paramsPage.totalItems=requests.total;
          this.paramsPage.currentPage=requests.current_page;
          this.paramsPage.itemsPerPage=requests.per_page;
          this.paramsPage.to=requests.to;
          this.paramsPage.from=requests.from;
      
     
        // console.log('list demande',this.data);
    },err=>{
     
      console.log('ERREUR',err);
    });
  }

  getPage(value){
    if(value.params){
      this.getAllRequests(value.params+'&page='+value.page);
     }else{
      this.getAllRequests("?page="+value.page);
     }
  }

}
