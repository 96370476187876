import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';
import { Subscription } from 'rxjs';
import { AuthUser } from 'src/app/models/auth-user.model';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-user-room-photo',
    templateUrl: './user-room-photo.component.html',
    styleUrls: ['./user-room-photo.component.css']
})
export class UserRoomPhotoComponent implements OnInit, OnDestroy {

    userSubscription: Subscription;
    userProductSubscription: Subscription;
    authUser: AuthUser;
    productId: any;
    loading = true;
    loaded: boolean;
    documents: any;
    constructor(private userService: UserService,
                private authService: AuthService,
                private router: Router,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                private productService: ProductsService) { }

    ngOnInit() {
        this.productId = this.route.parent.parent.snapshot.params.id;

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                this.authUser = user;
                if (this.authUser) {
                    this.userProductSubscription = this.productService.userProductSubject.subscribe(
                        (product: any) => {
                            if (product) {
                                this.getUserDocuments();

                            }
                        }
                    );

                    this.productService.getAuthUserProduct(user.id, this.productId).then(
                        () => {
                            this.productService.emitUserProduct();
                        },
                        () => {
                            this.router.navigate(['/rooms/new']);
                        }
                    );
                }
            }
        );

        this.authService.emitUser();
    }

    getUserDocuments(): void {
        this.userService.getUserDocuments(this.authUser.id, {per_page: '*'}).then(
            (response: any) => {
                this.documents = response.data;
                this.loaded = true;
                this.loading = false;
            }
        );
    }

    ngOnDestroy(): void {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }
}
