import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import * as  security from '../../../json/security/security.json';
import { AdminService } from 'src/app/admin/services/admin.service';
import { ActivatedRoute } from '@angular/router';
import * as  datatable from '../../../json/datatable/datatable_bookings.json';
import { ConfirmationDialogService } from 'src/app/admin/common/notifications/notification.service';
import { GetAdmin } from 'src/app/admin/model/getadmin';
declare var $:any;
@Component({
  selector: 'app-detail-transfer',
  templateUrl: './detail-transfer.component.html',
  styleUrls: ['./detail-transfer.component.css']
})
export class DetailTransferComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.detail_transfer;
  security=security.security.transfer
  loaded: boolean;
  booking: any;
  status: any[];
  list_status = datatable.status;
  adminId: any;
  transfer: any;
  default_preference: any;
  preferences: any;
  constructor(
    public adminService: AdminService, 
    private route: ActivatedRoute,
    private notification:ConfirmationDialogService) {
this.loaded = false;
}

  ngOnInit() {
   this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getTransferPending(this.adminId, this.route.snapshot.params.id);
     
    });
   }


  getTransferPending(AdminId, bookingId) {

    this.adminService.getOnePayementSuccess(AdminId, bookingId).then((data: any) => {
      this.transfer = data;
      this.adminService.setTitle('Information sur le Transfère '+ this.transfer.id);
      // console.log('detail Transfer Pending', this.transfer);
      this.loaded = true;
      // this.getSatus(this.booking.timing, this.booking.original_status);
      this.getUserSetting(this.transfer.user.id)
    }, err => {

      console.log('ERREUR', err);
    });
  }

 

  test(e){
 
    if(e){
      if(this.transfer.reservation.timing=="closed" && this.transfer.payment_status=="pending" &&  this.transfer.payment_type=="transfer"){

        
          $('#exampleModal').modal('show')
      }
      else{
       let message="Ce transfert ne peut pas être effectuer car la reservation est en cours";
        this.notification.confirm("Effectuer le Transfert",message,'sm').then(data=>{
          console.log('action',data);
        });
      }
    
    }
   
  }


  private getUserSetting(id){
    this.adminService.getUserSettings(id).then((data:any)=>{
      // console.log('bank',data)
      if(data.value){
      
        this.default_preference=data.value.default_payment_preference
        this.preferences=data.value.payment_preferences[data.value.default_payment_preference];
        // console.log('bank', this.preferences[0])
      }
    },err=>{
      console.log('erreur',err);
    });
  }
}
