import {AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {MapsAPILoader} from '@agm/core';
import {UserService} from '../../../../services/user/user.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../../models/auth-user.model';
import {Service} from '../../../../setting/settings';
import * as countries from 'i18n-iso-countries';
import {UploadedImage} from '../../../../models/Image';
import {Location} from '@angular/common';
import {AgmService} from '../../../../services/agm/agm.service';
import {MapCord} from '../../../../models/app.model';
declare var require: any;

@Component({
    selector: 'app-profile-agency-form',
    templateUrl: './profile-agency-form.component.html',
    styleUrls: ['./profile-agency-form.component.css']
})
export class ProfileAgencyFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('search2') public searchElementRef: ElementRef;

    @Input() formMode = '';

    angForm: FormGroup;
    userSubscription: Subscription;
    authUser: AuthUser;
    errors: any;
    countries: { code: string, name: string, dial_code: string}[] = [];
    countriesDialCodes: { code: string, name: string, dial_code: string}[] = [];
    dialCodes: { name: string, dial_code: string, code: string}[] = [];
    submitted = false;
    latitude: number;
    longitude: number;
    zoom: number;
    map = false;
    company_address: any;
    loading = false;
    upLoadLogoFormData: { key: string, value: any }[] = [];
    upLoadedLogo: UploadedImage;
    mapCoorSubscription: Subscription;
    private cord: MapCord;

    constructor(private toastr: ToastrService,
                private userService: UserService,
                private authService: AuthService,
                private fb: FormBuilder,
                private router: Router,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private _location: Location,
                private agmService: AgmService) {
        this.zoom = 14;

        this.company_address = new FormControl();
    }

    ngOnInit() {
        this.dialCodes = Service.DIAL_CODES;

        this.initForm();
        this.angForm.controls.company_dial_code.valueChanges.subscribe(
            val => {
                if (!val) {
                    this.angForm.controls.company_phone.disable();
                } else {
                    this.angForm.controls.company_phone.enable();
                }
            }
        );

        this.mapCoorSubscription = this.agmService.mapCordSubject.subscribe(
            (cord: MapCord) => {
                setTimeout(() => {
                    this.cord = cord;

                    if (cord && !cord.isLoading && cord.status == 'OK') {
                        this.company_address.setValue(cord.adress);
                        this.angForm.controls.company_address.setValue(cord.adress);
                        this.angForm.controls.company_city.setValue(cord.city);
                        this.angForm.controls.company_country.setValue(cord.country);
                        this.angForm.controls.company_postal_code.setValue(cord.postalCode);
                        this.latitude = cord.latitude;
                        this.longitude = cord.longitude;

                        if (this.latitude && this.longitude) {
                            this.map = true;
                        }
                    }
                }, 100);
            }
        );

        this.angForm.controls.company_country.valueChanges.subscribe(
            val => {

                if (this.authUser) {
                    const dialCode = this.dialCodes.find(
                        (dial) => {
                            return dial.code === val;
                        }
                    );

                    if (!this.authUser.agency || !this.authUser.agency.company_dial_code) {

                        this.angForm.controls.company_dial_code.setValue(dialCode && dialCode.dial_code ?
                            dialCode.dial_code : this.angForm.value.company_dial_code);
                    }
                }
            }
        );

        countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

        const countriesObject = countries.getNames('fr');

        for (const [key, value] of Object.entries(countriesObject)) {
            const dialCode = this.dialCodes.find(
                (dial) => {
                    return dial.code === key;
                }
            );
            const country = {
                code: key,
                name: value,
                dial_code: dialCode && dialCode.dial_code ? dialCode.dial_code : null
            };

            if (country.dial_code) {
                this.countriesDialCodes.push(country);
            }
            this.countries.push(country);
        }

        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {
                    if (user) {
                        this.authUser = user;

                        if (this.authUser.agency) {

                            const agency = this.authUser.agency;

                            if (agency.has_company_logo) {
                                this.upLoadedLogo = {
                                    link: agency.company_logo,
                                    hasUploaded: true,
                                    uploadName: 'company_logo'
                                };
                            }

                            this.upLoadLogoFormData = [
                                {
                                    key: 'model_name',
                                    value: 'agency',
                                },
                                {
                                    key: 'file_name',
                                    value: 'company_logo',
                                },
                                {
                                    key: 'model_id',
                                    value: agency.id,
                                }
                            ];

                            this.fillForm(agency);
                        }

                    }
                }
            );

            this.authService.emitUser();
        }
    }

    fillForm(agency) {

        this.submitted = true;
        this.angForm.controls.company_name.setValue(agency.company_name);
        this.angForm.controls.company_email.setValue(agency.company_email);

        if (agency.company_dial_code) {
            this.angForm.controls.company_dial_code.setValue(agency.company_dial_code);
        } else {
            this.angForm.controls.company_dial_code.setValue('');

        }
        this.angForm.controls.company_phone.setValue(agency.company_phone);
        this.angForm.controls.company_postal_code.setValue(agency.company_postal_code);
        this.angForm.controls.company_address.setValue(agency.company_address);
        this.angForm.controls.company_country.setValue(agency.company_country);
        this.angForm.controls.company_city.setValue(agency.company_city);
        this.angForm.controls.company_taxpayers_number.setValue(agency.company_taxpayers_number);

        if (agency.company_address_latitude && agency.company_address_longitude && agency.company_address) {

            this.angForm.controls.company_address.setValue(agency.company_address);
            this.latitude = parseFloat(agency.company_address_latitude.toString());
            this.longitude = parseFloat(agency.company_address_longitude.toString());

            this.map = true;
            this.company_address.value = agency.company_address;
        }

    }

    onSubmit() {
        this.submitted = true;

        if (this.angForm.invalid) {
            return;
        }

        this.errors = null;
        this.loading = true;
        this.angForm.controls.company_address_latitude.setValue(this.latitude);
        this.angForm.controls.company_address_longitude.setValue(this.longitude);

        const formData = new FormData();
        formData.append('company_name', this.angForm.get('company_name').value);
        formData.append('company_email', this.angForm.get('company_email').value);
        formData.append('company_dial_code', this.angForm.get('company_dial_code').value);
        formData.append('company_phone', this.angForm.get('company_phone').value);
        formData.append('company_postal_code', this.angForm.get('company_postal_code').value);
        formData.append('company_address', this.angForm.get('company_address').value);
        formData.append('company_address_latitude', this.angForm.get('company_address_latitude').value);
        formData.append('company_address_longitude', this.angForm.get('company_address_longitude').value);
        formData.append('company_country', this.angForm.get('company_country').value);
        formData.append('company_city', this.angForm.get('company_city').value);
        formData.append('company_taxpayers_number', this.angForm.get('company_taxpayers_number').value);

        if (!this.authUser.agency) {

            if (this.upLoadedLogo && this.upLoadedLogo.file) {
                formData.append('company_logo', this.upLoadedLogo.file);
            }

            this.userService.createUserAgency(this.authUser.id, formData).then(
                (agency: any) => {
                    this.loading = false;
                    this.authUser.agency = agency;

                    this.router.navigate(['/account/profile'], {queryParams: {section: 'agency'}}).then(
                        () => {
                            this.toastr.success('Votre profil agent a été crée avec succès!', 'Succès!');
                        }
                    );

                },

                (error) => {
                    this.loading = false;
                    this.toastr.error('Le formulaire comporte des erreurs, vérifiez et rééssayer svp.');

                    this.errors = error.error.error;
                }
            );
        }

        if (this.authUser.agency) {
            formData.append('_method','PUT');
            this.userService.updateUserAgency(this.authUser.id, this.authUser.agency.id, formData).then(
                (agency: any) => {
                    this.loading = false;
                    this.authUser.agency = agency;
                    this.toastr.success('Modfication effectuée!', 'Succès!');
                },

                (error) => {
                    this.loading = false;
                    this.errors = error.error.error;
                }
            );
        }
    }

    initForm() {
        this.angForm = this.fb.group({
            company_name: ['', [Validators.required]],
            // company_head_office: ['', [Validators.required]],
            company_email: ['', [Validators.required, Validators.email]],
            company_taxpayers_number: ['', [Validators.required]],
            company_address: ['', [Validators.required]],
            company_address_latitude: [''],
            company_address_longitude: [''],
            company_country: ['', [Validators.required]],
            company_postal_code: ['', [Validators.required]],
            company_city: ['', [Validators.required]],
            company_dial_code: ['', [Validators.required]],
            company_phone: ['', [Validators.required]],
        });
    }

    setCurrentPosition() {
        this.agmService.setCurrentPosition();
    }


    cancelEditProfile() {
        this._location.back();
    }

    get f() {
        return this.angForm.controls;
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.mapCoorSubscription) {
            this.mapCoorSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {

        this.agmService.placeAutoComplete(this.searchElementRef.nativeElement);

    }

}
