import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {SuggestedUser} from '../../../../../models/user';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthUser} from '../../../../../models/auth-user.model';
import {UserService} from '../../../../../services/user/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductsService} from '../../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from '../../../../../services/auth/auth.service';
import Echo from 'laravel-echo';

@Component({
    selector: 'app-selected-user-model',
    templateUrl: './selected-user-model.component.html',
    styleUrls: ['./selected-user-model.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectedUserModelComponent implements OnInit, OnDestroy {
    @Input()  selectedUser: SuggestedUser = null;
    @Input()  owner: AuthUser;
    @Input()  agent: AuthUser;
    @Input()  authUser: AuthUser;
    @Input() accountType: string;
    @Input() userType: string;
    @Input() managerType: string;
    @Input() product: any;
    @Input() submitted: boolean;
    @Input() contract: any;
    @Input() propertyForm: FormGroup;
    @Input() invitation: any;
    @Input() hasSelectedUser = false;
    @Input() formMode = 'create';

    @Output() selectedUserChange = new EventEmitter<SuggestedUser>();
    @Output() ownerChange = new EventEmitter<AuthUser>();
    @Output() agentChange = new EventEmitter<AuthUser>();
    @Output() productChange = new EventEmitter<any>();
    @Output() submittedChange = new EventEmitter<boolean>();
    @Output() propertyFormChange = new EventEmitter<FormGroup>();
    @Output() contractChange = new EventEmitter<any>();
    @Output() invitationChange = new EventEmitter<any>();
    @Output() hasSelectedUserChange = new EventEmitter<boolean>();


    userProductSubscription: Subscription;

    passwordForm: FormGroup;
    contractForm: FormGroup;
    submittedPassword: boolean;
    redirectUri: string;

    contractModal: any;
    passwordModal: any;
    deletionModal: any;
    errors: any;
    loading = true;
    loaded = false;

    constructor(private modalService: NgbModal,
                private userService: UserService,
                private productService: ProductsService,
                private toastr: ToastrService,
                private fb: FormBuilder,
                private router: Router,
                private authService: AuthService) { }

    /* ngOnChanges() {
         this.selectedUserChange.emit(this.selectedUser);
         this.ownerChange.emit(this.owner);
         this.agentChange.emit(this.agent);
         this.productChange.emit(this.product);
         this.submittedChange.emit(this.submitted);
         this.propertyFormChange.emit(this.propertyForm);
         this.contractChange.emit(this.contract);
         this.invitationChange.emit(this.invitation);
         this.hasSelectedUserChange.emit(this.hasSelectedUser);
     }*/

    ngOnInit() {
        this.initForms();

        if (!this.userProductSubscription) {

            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                (product: any) => {
                    if (product) {
                        this.product = product;
                        this.redirectUri = window.location.origin + this.router.createUrlTree([
                            '/rooms/' + product.id + '/invitations/[invitation_id]'
                        ]).toString();

                        if (product.invitation) {
                            this.invitation = product.invitation;
                            this.invitationChange.emit(this.invitation);

                            this.contract = product.contract;

                            let invitee: any = this.invitation.invitee;

                            if (this.invitation.invitee_role === 'agent' && this.contract) {
                                invitee = this.contract.agency;
                            }

                            if (this.invitation.invitee_role === 'agent' && !this.contract) {
                                invitee = this.invitation.invitee.agency;
                            }

                            this.selectedUser = {
                                id: invitee.id,
                                full_name: invitee.full_name,
                                email: invitee.email,
                                photo: invitee.photo,
                                country: invitee.country,
                                city: invitee.city,
                                userType: this.invitation.invitee_role,
                                isSaved: true,
                                hasContract: !!product.contract,
                                status: this.invitation.status
                            };
                            this.selectedUserChange.emit(this.selectedUser);


                            this.hasSelectedUser = true;
                            this.hasSelectedUserChange.emit(this.hasSelectedUser);

                            this.readNotification();

                        }

                        if (product.pending_contract || product.accepted_contract) {
                            if (this.formMode === 'create') {
                                this.propertyForm.controls.is_options_disabled.setValue(true);
                                this.propertyFormChange.emit(this.propertyForm);

                            }
                        }

                        this.authService.broadcast().then(
                            (echo: Echo) => {
                                echo.private(`App.User.${this.authUser.id}`)
                                    .listen('.invitation.status.changed.' + this.product.id, (data) => {
                                        const invitation = data.data;
                                        this.invitation = invitation;
                                        if (this.selectedUser) {
                                            if (invitation.invitee_id === this.selectedUser.id &&
                                                invitation.invitee_role === this.userType) {
                                                this.selectedUser.status = invitation.status;
                                                this.selectedUser.hasContract = invitation.status === 'accepted';
                                                this.contract = invitation.contract;

                                                this.readNotification();
                                            }
                                        }

                                    });
                            }
                        );


                        this.loading = false;
                        this.loaded = true;


                    }
                }
            );

            this.productService.emitUserProduct();

        }



    }

    readNotification() {
        this.userService.readUserNotifications(this.authUser.id, {
            messageable_id: this.invitation.id, messageable_type: 'invitation'});
    }

    removeAgent() {

        if (!this.contract || !this.selectedUser || this.selectedUser.userType !== this.userType) {
            return;
        }

        const formData = new FormData();
        this.selectedUser.isBeenRemoved = true;
        this.selectedUserChange.emit(this.selectedUser);

        this.productService.deleteContract(this.authUser.id, this.product.id, this.contract.id, formData).then(
            (contract: any) => {
                this.deletionModal.close();
                this.contract = contract;

                this.selectedUser.isBeenRemoved = false;
                this.selectedUser.hasBeenRemoved = true;
                this.selectedUser.status = 'deleted';

                this.selectedUser.errorMessage = 'Agence suprimé';
                this.selectedUserChange.emit(this.selectedUser);

                if (this.formMode === 'create') {
                    this.propertyForm.controls.is_options_disabled.setValue(false);
                    this.propertyFormChange.emit(this.propertyForm);
                }

            },

            () => {
                this.deletionModal.close();

                this.selectedUser.isBeenRemoved = false;

                this.selectedUser.errorMessage = 'Désolé une erreur s\'est produite';
                this.selectedUserChange.emit(this.selectedUser);

            }
        );

    }


    cancelInvitation() {
        this.selectedUser.cancelingInvitation = true;
        this.selectedUserChange.emit(this.selectedUser);
        const formData = new FormData();

        formData.append('_method', 'PUT');
        formData.append('status', 'canceled');
        formData.append('return_model', 'invitation');

        this.productService.cancelProductInvitation(this.authUser.id, this.product.id, this.invitation.id, formData)
            .then((invitation: any) => {

                    this.submitted = true;

                    if (this.formMode === 'create') {
                        this.propertyForm.controls.is_options_disabled.setValue(false);
                        this.propertyFormChange.emit(this.propertyForm);
                    }


                    this.selectedUser.status = invitation.status;
                    this.selectedUser.cancelingInvitation = false;
                    this.selectedUser.hasCanceledInvitation = true;
                    this.selectedUser.errorMessage = 'Invitation annulée';
                    this.selectedUserChange.emit(this.selectedUser);

                }
            );
    }


    resendInvitation() {

        this.selectedUser.resendingInvitation = true;
        this.selectedUserChange.emit(this.selectedUser);

        const formData = new FormData();

        formData.append('redirect_uri', this.redirectUri);
        formData.append('invitee_role', this.invitation.invitee_role);
        formData.append('invitee_id', this.invitation.invitee_id);
        formData.append('return_model', 'invitation');
        formData.append('user_role', this.accountType);

        this.productService.resendProductInvitation(this.authUser.id, this.product.id, this.invitation.id, formData)
            .then((invitation: any) => {

                    this.invitation = invitation;
                    this.selectedUser.resendingInvitation = false;
                    this.selectedUser.hasResendInvitation = true;
                    this.invitationChange.emit(this.invitation);
                    this.selectedUserChange.emit(this.selectedUser);

                    this.toastr.success('L\'invitation a été renvoyée');
                } ,

                () => {

                    this.selectedUser.resendingInvitation = false;
                    this.selectedUserChange.emit(this.selectedUser);
                    this.toastr.error('Désolé une erreur s\'est produite');

                }
            );
    }


    sendInvitation() {

        if (this.selectedUser.userType !== this.userType) {
            return;
        }
        this.submittedPassword = true;
        this.selectedUser.sendingInvitation = true;
        this.selectedUserChange.emit(this.selectedUser);

        const formData = new FormData();

        formData.append('redirect_uri', this.redirectUri);
        formData.append('user_role', this.accountType);
        formData.append('return_model', 'invitation');
        formData.append('current_password', this.passwordForm.get('current_password').value);
        formData.append('has_accepted_conditions', 'true');

        formData.append('invitee_id', this.selectedUser.id.toString());
        formData.append('has_invitation', 'true');

        if (this.accountType === 'agent') {
            formData.append('invitee_role', 'owner');
        }

        if (this.accountType === 'owner' && this.managerType === 'agent') {
            formData.append('invitee_role', 'agent');
        }

        this.productService.sendProductInvitation(this.authUser.id, this.product.id, formData).then(
            (invitation: any) => {

                this.passwordModal.close();
                this.passwordForm.reset();

                setTimeout( () => {
                    this.submittedPassword = false;
                    // this.contractModal.close();
                    // this.contractForm.reset();

                    if (this.formMode === 'create') {
                        this.propertyForm.controls.is_options_disabled.setValue(true);
                        this.propertyFormChange.emit(this.propertyForm);

                    }
                    this.selectedUser.sendingInvitation = false;
                    this.selectedUser.hasSendInvitation = true;
                    this.invitation = invitation;
                    this.selectedUser.isSaved = true;
                    this.selectedUser.status = 'pending';
                    this.selectedUserChange.emit(this.selectedUser);
                    this.invitationChange.emit(this.invitation);

                    this.toastr.success('L\'invitation a été envoyée');
                }, 2000 );
            },
            (err) => {
                this.submittedPassword = false;
                this.selectedUser.sendingInvitation = false;
                this.errors = err.error.error;
                this.selectedUserChange.emit(this.selectedUser);

                this.toastr.error('Désolé une erreur s\'est produite');
            }
        );
    }


    signContract() {
        this.submittedPassword = true;
        this.selectedUser.isSigningContract = true;
        this.selectedUserChange.emit(this.selectedUser);

        const formData = new FormData();

        formData.append('user_role', this.accountType);
        formData.append('has_signed_contract', 'true');
        formData.append('current_password', this.passwordForm.get('current_password').value);
        if (this.formMode === 'edit') {
            formData.append('return_model', 'product');
        }
        this.productService.updateContract(this.authUser.id, this.product.id, this.contract.id, formData).then(
            (response: any) => {

                this.passwordModal.close();

                setTimeout(() => {

                    if (this.formMode === 'create') {
                        this.contract = response;
                        this.contractChange.emit(this.contract);

                    }

                    if (this.formMode === 'edit') {
                        this.product = response;
                        this.productChange.emit(this.product);
                    }

                    this.submittedPassword = false;
                    this.selectedUser.isSigningContract = false;
                    this.contractModal.close();
                    this.selectedUserChange.emit(this.selectedUser);


                    this.toastr.success('Le contract a été signé avec succès');

                }, 2000);


            }
        );
    }

    openDeletionModel(content) {
        this.deletionModal = this.modalService.open(content, { centered: true, size: 'sm' });
    }

    openPasswordModal(content) {
        this.submitted = true;
        this.submittedChange.emit(this.submitted);
        this.passwordModal = this.modalService.open(content, { centered: true, size: 'sm' });
    }

    openContractModal(longContent, selectedUser: SuggestedUser) {

        this.owner = null;
        this.agent = null;

        this.contractModal = this.modalService.open(longContent, { scrollable: true, size: 'lg' });

        this.userService.getUser(selectedUser.id).then(
            (user: AuthUser) => {
                if (this.accountType === 'agent') {
                    this.owner = user;
                    this.agent = this.authUser;
                }

                if (this.accountType === 'owner' && this.managerType === 'agent') {
                    this.agent = user;
                    this.owner = this.authUser;
                }

                this.ownerChange.emit(this.owner);
                this.agentChange.emit(this.agent);

            }
        );

    }

    initForms() {
        this.contractForm = this.fb.group({
            accept_terms: ['', [Validators.requiredTrue]]
        });

        this.passwordForm = this.fb.group({
            current_password: ['', [Validators.required]]
        });
    }

    get f1() {
        return this.contractForm.controls;
    }

    get f2() {
        return this.passwordForm.controls;
    }

    ngOnDestroy() {
        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }


}
