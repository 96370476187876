import { Directive, ElementRef, Input } from '@angular/core';
@Directive({
  selector: '[HideUlIfNotChilden]'
})
export class HideUlIfNotChildenDirective {

  constructor(private el: ElementRef) { }

  
  ngOnInit() { 
    // console.log('eeeee',this.el.nativeElement.children[1].children);
    //  if(!this.el.nativeElement.children[1].children[0]){
    //    this.el.nativeElement.style.display = 'none';
    //  }
  }

}
