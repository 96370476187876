import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../models/auth-user.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ProductsService} from '../../../services/products/products.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';
import {AppService} from '../../../services/app/app.service';
import {BookingService} from '../../../services/bookings/booking.service';

@Component({
    selector: 'app-booking-template',
    templateUrl: './booking-template.component.html',
    styleUrls: ['./booking-template.component.css']
})
export class BookingTemplateComponent implements OnInit, OnDestroy {
    @Input() mode = 'booking';
    step: string;
    loading: boolean;
    loaded = false;
    search: any = {};
    id;
    product: any;
    angForm: FormGroup;
    submitted = false;
    selectionId: any;
    selection: any;
    userSubscription: Subscription;
    authUser: AuthUser;

    submittedPayment: boolean;
    success: boolean;
    booking: any;
    message: any;

    constructor(private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService,
                private fb: FormBuilder,
                private authService: AuthService,
                private appService: AppService,
                private bookingService: BookingService) {
        if (this.mode === 'booking') {
            this.step = 'new';
        } else if (this.mode === 'message') {
            this.step = 'contact-host';
        }
    }

    ngOnInit() {
        this.initForm();
        this.userSubscription = this.authService.userSubject.subscribe(
            (authUser: AuthUser) => {

                if (authUser) {
                    this.authUser = authUser;

                    if (this.authUser.completed && this.step === 'complete_profile') {
                        if (this.selection && !this.selection.message) {
                            this.next('new');
                        } else {
                            this.next('checkout');
                        }
                    }

                    this.route.queryParamMap.subscribe(
                        params => {
                            if (params.get('step')) {
                                this.step = params.get('step');

                                this.appService.setAppTitle(this.getTitle());

                            } else {
                                if (this.mode === 'booking') {
                                    this.step = 'new';
                                } else if (this.mode === 'message') {
                                    this.step = 'contact-host';
                                }
                            }

                            if (params.get('dateStart')) {
                                this.search.dateStart = params.get('dateStart');
                            }

                            if (params.get('dateEnd')) {
                                this.search.dateEnd = params.get('dateEnd');
                            }

                            if (params.get('address')) {
                                this.search.address = params.get('address');
                            }

                            if (params.get('latitude')) {
                                this.search.latitude = params.get('latitude');
                            }

                            if (params.get('longitude')) {
                                this.search.longitude = params.get('longitude');
                            }

                            if (params.get('currency')) {
                                this.search.currency = params.get('currency');
                            }

                            let adults = 0;
                            let children = 0;
                            let babies = 0;

                            if (params.get('adults')) {
                                adults = parseInt(params.get('adults'));
                            }

                            if (params.get('children')) {
                                children = parseInt(params.get('children'));
                            }

                            if (params.get('babies')) {
                                babies = parseInt(params.get('babies'));
                            }

                            if (adults > 0) {
                                this.search.adults = adults;
                            }

                            if (children > 0) {
                                this.search.children = children;
                            }

                            if (babies > 0) {
                                this.search.babies = babies;
                            }

                            if (adults + children > 0) {
                                this.search.guests = adults + children;
                            }
                            if (this.mode === 'booking' && params.get('id')) {
                                this.search.id = params.get('id');
                                this.id = params.get('id');

                            } else if (this.mode === 'message') {
                                this.id = this.route.snapshot.params.id;
                            }

                            if (params.get('selection')) {
                                this.search.selection = params.get('selection');
                                this.selectionId = params.get('selection');
                            }

                            if (params.get('category')) {
                                this.search.category = params.get('category');
                            }

                            if (this.mode === 'booking' && !this.selectionId && this.step === 'payment') {
                                this.redirectionFailure();
                            }

                            if (this.search.dateStart && this.search.dateEnd && this.id && !this.product) {
                                this.__getOneProduct();
                            }

                            if (this.authUser && !this.authUser.completed) {
                                this.next('complete_profile');
                                return;
                            }

                            if (this.selectionId && this.id && !this.selection) {
                                this.getSelection();
                            } else if (!this.selectionId && (this.search.dateStart || this.search.dateEnd)) {
                                if (!this.search.adults) {
                                    this.search.adults = 1;
                                }

                                if (this.mode === 'booking') {
                                    this.next('new');
                                } else if (this.mode === 'message') {
                                    this.next('contact-host');
                                }
                                return;
                            } else if (this.mode === 'booking' && this.selection && this.selection.message) {
                                this.next(this.step);
                                return;
                            } else if (this.mode === 'message' && (this.search.dateStart || this.search.dateEnd)) {
                                this.next('contact-host');
                            } else if (!this.search.dateStart || !this.search.dateEnd) {
                                this.redirectionFailure();
                            }

                        }
                    );
                }
            }
        );

        this.authService.emitUser();
    }

    getPricing() {
        return {
            number_days: this.product.pricing.nbDays,
            base_tenant_price: this.product.pricing.basePrice,
            total_tenant_ht: this.product.pricing.totalHt,
            tenant_fees: this.product.pricing.fees,
            cleaning_fees_tenant: this.product.pricing.cleaning_fees,
            discount: this.product.pricing.discount,
            vta_fees: this.product.pricing.vta_fees,
            total: this.product.pricing.total
        };
    }

    getTitle(): string {

        if (this.step === 'new') {
            return 'Message pour l\'hôte';
        }

        if (this.step === 'checkout' && this.product && !this.product.is_instant_booking) {
            return 'Récapitulatifs de ma demande';
        }

        if (this.step === 'checkout' && this.product && this.product.is_instant_booking) {
            return 'Récapitulatifs de ma réservation';
        }

        if (this.step === 'payment') {
            return 'Payer ma réservation';
        }

        return '';

    }

    doSubmit() {

        if (this.step === 'new' || this.step === 'checkout') {
            if (this.product.user_id === this.authUser.id || this.product.manager.id === this.authUser.id) {
                this.loading = true;
                setTimeout(() => {
                    this.router.navigate(['/search'], {queryParams: this.getQueryParams()});
                    return;
                }, 2000);
            }

            this.submitted = true;
            if (this.angForm.invalid) {
                return;
            }

            this.loading = true;

            this.checkSelection().then(
                () => {
                    if (this.step === 'new') {
                        this.saveMessage();
                    }

                    if (this.step === 'checkout') {
                        if (this.product.is_instant_booking) {
                            this.loading = false;
                            this.next('payment');
                        } else {
                            const queryParams = this.getQueryParams();
                            queryParams.isInstantBooking = this.product.is_instant_booking;
                            this.saveBooking(queryParams);
                        }

                    }
                },

                () => {
                    this.loading = false;
                }
            );

        }

        if (this.step === 'contact-host') {
            this.submitted = true;
            if (this.angForm.invalid) {
                return;
            }
            if (!this.checkUser()) {
                this.loading = true;
                setTimeout(() => {
                    this.router.navigate(['/search'], {queryParams: this.getQueryParams()});
                    return;
                }, 2000);
            } else {
                this.sendMessage();
            }
        }

    }

    checkUser(): boolean {
        return this.product.user_id !== this.authUser.id && this.product.manager.id !== this.authUser.id;
    }

    getRedirectUrl() {
        // remplacer booking par selection
        if (this.mode === 'booking') {
            return this.product.is_instant_booking ? '/account/bookings/' + this.booking.id + '/tenant' :
                '/account/booking-demands/' + this.booking.id + '/tenant';
        }

        return '/messages';

    }

    saveBooking(params) {
        this.disableInputs();

        this.bookingService.saveUserSelectionBooking(this.authUser.id, this.selection.id, params).then(
            (booking: any) => {
                this.booking = booking;
                this.loading = false;
                this.submittedPayment = false;
                this.enableInputs();

                this.success = true;
            },

            () => {
                this.submittedPayment = false;
                this.enableInputs();
                this.loading = false;
                this.toastr.error('Désolé, une erreur s\'est produite');

            }
        );
    }

    saveMessage() {

        const queryParams = this.getQueryParams();

        queryParams.message = this.angForm.get('message').value;
        this.disableInputs();

        this.bookingService.updateProductSelection(this.product.id, this.selection.id, queryParams).then(
            (selection: any) => {
                this.loading = false;
                this.selection = selection;
                this.enableInputs();

                this.next('checkout');
            },

            () => {
                this.loading = false;
                this.enableInputs();

                this.toastr.error('Désolé, une erreur s\'est produite');
            }
        );
    }

    disableInputs() {
        if (this.angForm.get('message').enabled) {
            this.angForm.get('message').disable();
        }

        if (this.mode === 'message' && this.angForm.get('reason').enabled) {
            this.angForm.get('reason').disable();
        }
    }

    enableInputs() {
        if (this.angForm.get('message').disabled) {
            this.angForm.get('message').enable();
        }

        if (this.mode === 'message' && this.angForm.get('reason').disabled) {
            this.angForm.get('reason').enable();
        }
    }

    sendMessage() {
        this.submitted = true;
        if (this.angForm.invalid) {
            return;
        }
        this.loading = true;
        this.disableInputs();
        const queryParams = this.getQueryParams();
        queryParams.message = this.angForm.get('message').value;
        queryParams.reason = this.angForm.get('reason').value;

        this.bookingService.saveProductMessage(this.authUser.id, this.product.id, queryParams).then(
            (message: any) => {
                this.loading = false;
                this.enableInputs();

                this.message = message;
            },
            () => {
                this.loading = false;
                this.enableInputs();
                this.toastr.error('Désolé, une erreur s\'est produite');
            }
        );

    }

    next(step) {
        const queryParams = this.getQueryParams();
        delete queryParams.user_id;
        delete queryParams.address;
        delete queryParams.latitude;
        delete queryParams.longitude;
        delete queryParams.message;
        delete queryParams._method;
        queryParams.step = step;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams
        });
    }

    redirectionFailure() {
        this.router.navigate(['/']).then(
            () => {
                this.toastr.error('Votre demande n\'a pas pu aboutir rééssayez plus tard svp');
            }
        );
    }

    checkSelection() {
        return new Promise(
            (resolve, reject) => {
                if (this.selection) {
                    resolve(this.selection);
                } else {
                    this.bookingService.saveProductSelection(this.product.id, this.getQueryParams()).then(
                        (selection: any) => {
                            this.selection = selection;
                            this.selectionId = selection.id;
                            this.search.selection = selection.id;
                            resolve(selection);
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                }
            }
        );
    }


    getSelection() {

        this.bookingService.getProductSelection(this.id, this.selectionId).then(
            (selection: any) => {
                if (!selection.user_id) {
                    this.bookingService.updateProductSelection(this.id, selection.id, this.getQueryParams()).then(
                        (newSelection: any) => {
                            this.fillSelection(newSelection);
                        }
                    );
                } else {
                    this.fillSelection(selection);
                }
            },
            () => {
                this.redirectionFailure();

            }
        );
    }

    fillSelection(selection) {
        if (selection.reserved) {
            this.redirectionFailure();
        }

        if (!selection.message && this.step !== 'new' && this.step !== 'complete_profile') {
            this.next('new');
        }

        this.selection = selection;
        this.angForm.controls.message.setValue(selection.reason);
        if (!this.search.dateStart || !this.search.dateEnd) {
            this.search.dateStart = selection.date_start;
            this.search.dateEnd = selection.date_end;

            if (selection.adults) {
                this.search.adults = selection.adults;
                this.search.guests = selection.adults;
            }

            if (selection.children) {
                this.search.children = selection.children;
                this.search.guests += selection.children;
            }

            if (selection.babies) {
                this.search.babies = selection.babies;
            }

            this.__getOneProduct();
        }
    }

    getQueryParams() {

        const queryParams: any = {
            dateStart: this.search.dateStart,
            dateEnd: this.search.dateEnd,
        };

        if (this.search.adults)  {
            queryParams.adults = this.search.adults;
            queryParams.guests = this.search.adults;
        }

        if (this.search.children)  {
            queryParams.children = this.search.children;
            queryParams.guests += this.search.children;
        }

        if (this.search.babies)  {
            queryParams.babies = this.search.babies;
        }


        if (this.search.currency)  {
            queryParams.currency = this.search.currency;
        }

        if (this.search.selection)  {
            queryParams.selection = this.search.selection;
        }

        if (this.search.category)  {
            queryParams.category = this.search.category;
        }

        if (this.search.address)  {
            queryParams.address = this.search.address;
        }

        if (this.search.latitude)  {
            queryParams.latitude = this.search.latitude;
        }

        if (this.search.longitude)  {
            queryParams.longitude = this.search.longitude;
        }

        if (this.search.id)  {
            queryParams.id = this.search.id;
        }

        if (this.authUser) {
            queryParams.user_id = this.authUser.id;
        }

        return queryParams;
    }

    __getOneProduct() {

        this.loading = true;
        this.disableInputs();

        this.productService.getProduct(this.id, this.search).then(
            (product: any) => {
                this.loaded = true;
                this.loading = false;
                this.enableInputs();

                if (product.pricing) {
                    this.product = product;
                    this.appService.setAppTitle(this.getTitle());
                } else {
                    this.router.navigate(['/']).then(
                        () => {
                            this.toastr.error('Votre demande n\'a pas pu aboutir rééssayez plus tard svp');
                        }
                    );
                }
            },
            err => {
                console.log(err);
                this.enableInputs();
                this.toastr.error('Désolé une erreur est suvenue lors de la réservation rééssayez plus tard svp');
            }
        );
    }

    initForm() {
        this.angForm = this.fb.group({
            message: ['', [Validators.required]]
        });

        if (this.mode === 'message') {
            this.angForm.addControl('reason', new FormControl('', Validators.required));
        }
    }

    get f() {
        return this.angForm.controls;
    }

    ngOnDestroy(): void {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }


}
