import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductsService} from '../../../../services/products/products.service';

@Component({
    selector: 'app-user-room-informations',
    templateUrl: './user-room-informations.component.html',
    styleUrls: ['./user-room-informations.component.css']
})
export class UserRoomInformationsComponent implements OnInit, OnDestroy {

    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    productId;
    loading = true;
    loaded = false;
    constructor(private route: ActivatedRoute,
                private router: Router,
                private productService: ProductsService,
                private authService: AuthService) { }

    ngOnInit() {
        this.productId = this.route.parent.parent.snapshot.params.id;
        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {

                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {

                                    if (product) {

                                        this.loading = false;
                                        this.loaded = true;

                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                (error) => {

                                    console.log(error);

                                    this.router.navigate(['/rooms/new']);

                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();
        }

    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
        this.userProductSubscription.unsubscribe();
    }

}
