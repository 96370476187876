import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../models/auth-user.model';
import {ToastrService} from 'ngx-toastr';
import {ProductsService} from '../../../services/products/products.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'app-checkout-room',
    templateUrl: './checkout-room.component.html',
    styleUrls: ['./checkout-room.component.css']
})
export class CheckoutRoomComponent implements OnInit, OnDestroy {
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    productId;
    product;
    loaded = false;
    loading = false;

    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;
                }
            }
        );


        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user && !this.userProductSubscription) {
                        this.authUser = user;
                        this.userProductSubscription = this.productService.userProductSubject.subscribe(
                            (product: any) => {
                                if (product) {
                                    this.product = product;
                                    this.loaded = true;
                                }
                            }
                        );

                        this.productService.getAuthUserProduct(user.id, this.productId).then(
                            () => {
                                this.productService.emitUserProduct();
                            },

                            (error) => {
                                console.log(error);
                                this.router.navigate(['/rooms/new']);
                            }
                        );
                    }
                }
            );
            this.authService.emitUser();
        }
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    doSubmit() {

        if (this.product.completed === 'uncompleted') {
            return;
        }

        this.loading = true;

        this.productService.updateUserProduct(this.authUser.id, this.product.id, {published: 1}).then(
            (product: any) => {

                this.loading = false;

                this.router.navigate(['/account/rooms']).then(
                    () => {
                        this.toastr.success('Votre annonce a été publiée avec succès');
                    }
                );
            },

            err => {
                this.loading = false;
                this.toastr.error('Désolé une erreur s\'est produite');

            }
        );
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
