import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {AccountService} from '../../../services/auth/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {UserService} from '../../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../../setting/must-match.validator';
import {MapsAPILoader} from '@agm/core';
import * as countries from 'i18n-iso-countries';
declare var require: any;
import { Service } from '../../../setting/settings';

@Component({
    selector: 'app-register-shapart',
    templateUrl: './register-shapart.component.html',
    styleUrls: ['./register-shapart.component.css']
})
export class RegisterShapartComponent implements OnInit {
    @ViewChild('search') public searchElementRef: ElementRef;

    redirectTo: string;

    registerForm: FormGroup;
    loading = false;
    errors: any;
    formErrors;
    registerMethod;
    status;
    hasAgency;
    searchControl: any;
    geoCoder: google.maps.Geocoder;
    latitude: number;
    longitude: number;
    countries: { code: string, name: string, dial_code: string}[] = [];
    dialCodes: { name: string, dial_code: string, code: string}[] = [];
    country;
    mapLoading: boolean = false;

    constructor(private toastr: ToastrService,
                private router: Router,
                private title: Title,
                private accountService: AccountService,
                public userService: UserService,
                private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone) {
        this.searchControl = new FormControl();

    }

    ngOnInit() {
        this.dialCodes = Service.DIAL_CODES;
        countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

        this.redirectTo = this.route.snapshot.queryParams.redirect_to || '/';
        this.registerMethod = this.route.snapshot.queryParams.register_method;
        this.status = this.route.snapshot.queryParams.status || 'client';
        this.hasAgency = this.route.snapshot.queryParams.has_agency;
        this.initForm();
        this.getCountries();
        /*this.registerForm.get('has_agency').valueChanges.subscribe(
            val => {

                this.hasAgency = val;

                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        has_agency: val
                    },
                    queryParamsHandling: 'merge',
                });

                if (val === 'true') {

                    this.mapLoading = true;

                    setTimeout(
                        () => {

                            this.placeAutoComplete();

                        }, 2000);

                }

            }
        );
        this.registerForm.get('account_type').valueChanges.subscribe(
            val => {

                this.status = val;

                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        status: val
                    },
                    queryParamsHandling: 'merge',
                });

                if (val === 'agent') {

                    this.mapLoading = true;

                    setTimeout(
                        () => {

                            this.placeAutoComplete();

                        }, 2000);

                }

            }
        );*/

      /*  this.registerForm.controls.account_type.setValue(this.status ? this.status : 'client');
        this.registerForm.controls.has_agency.setValue(this.hasAgency ? this.hasAgency : 'false');*/


    }

    getCountries() {

        const countriesObject = countries.getNames('fr');

        for (const [key, value] of Object.entries(countriesObject)) {

            const dialCode = this.dialCodes.find(
                (dial) => {

                    return dial.code === key;
                }
            );

            const country = {
                code: key,
                name: value,
                dial_code: dialCode ? dialCode.dial_code : null
            };

            this.countries.push(country);
        }
    }

    initForm() {

        this.registerForm = this.formBuilder.group({
            // account_type: ['', [Validators.required]],
            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            // taxpayers_number: ['', [Validators.required]],
           /* postal_code: ['', [Validators.required]],
            city: ['', [Validators.required]],
            country: ['', [Validators.required]],
            address: ['', [Validators.required]],
            company_name: ['', [Validators.required]],
            company_dial_code: ['+33', [Validators.required]],
            company_phone: ['', [Validators.required]],
            company_head_office: ['', [Validators.required]],
            has_agency: [''],*/
            email: ['', [Validators.required, Validators.email]],
            // company_email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            password_confirmation: ['', [Validators.required]],
            accept_terms: [false, [Validators.requiredTrue]]
        }, {
            validators: MustMatch('password', 'password_confirmation')
        });

    }

    doRegister() {

       /* if (this.status === 'client' || (this.status === 'owner' && this.hasAgency === 'false')) {
            this.registerForm.removeControl('taxpayers_number');
            this.registerForm.removeControl('postal_code');
            this.registerForm.removeControl('address');
            this.registerForm.removeControl('city');
            this.registerForm.removeControl('country');
            this.registerForm.removeControl('company_name');
            this.registerForm.removeControl('company_email');
            this.registerForm.removeControl('company_dial_code');
            this.registerForm.removeControl('company_phone');
            this.registerForm.removeControl('company_head_office');
        }
*/

        /*if (this.status === 'agent') {

            this.registerForm.removeControl('company_name');
            this.registerForm.removeControl('company_email');
            this.registerForm.removeControl('company_dial_code');
            this.registerForm.removeControl('company_phone');
            this.registerForm.removeControl('company_head_office');

        }
*/
        if (!this.registerForm.invalid) {
            const formData = new FormData();
            const email = this.registerForm.get('email').value;
            const password = this.registerForm.get('password').value;
            const redirectUri = window.location.origin + this.router.createUrlTree(['/password/reset']).toString();

            formData.append('name', this.registerForm.get('name').value);
            formData.append('lastname', this.registerForm.get('surname').value);
            formData.append('email', email);
            formData.append('password', password);
            formData.append('redirect_uri', redirectUri);
            formData.append('social_register', 'false');
            formData.append('register_type', this.status);

            if (this.status === 'client') {
                formData.append('has_professional_info', 'false');
            }

            if (this.status === 'owner') {
                formData.append('is_owner', 'true');
                formData.append('has_agency', this.hasAgency);
                formData.append('has_professional_info', this.hasAgency);

            }

            if (this.status === 'agent') {
                formData.append('is_agent', 'true');
                formData.append('has_professional_info', 'true');

            }

            if (this.status === 'owner' || this.status === 'agent') {

                if (this.status === 'owner' && this.hasAgency === 'true') {

                    formData.append('company_name', this.registerForm.get('company_name').value);
                    formData.append('company_email', this.registerForm.get('company_email').value);
                    formData.append('company_head_office', this.registerForm.get('company_head_office').value);
                    formData.append('company_dial_code', this.registerForm.get('company_dial_code').value);
                    formData.append('company_phone', this.registerForm.get('company_phone').value);

                }

                if (this.status === 'agent' || this.hasAgency === 'true') {

                    formData.append('adress', this.registerForm.get('address').value);
                    formData.append('address_latitude', this.latitude.toString());
                    formData.append('address_longitude', this.longitude.toString());
                    formData.append('country', this.country);
                    formData.append('city', this.registerForm.get('city').value);
                    formData.append('postal_code', this.registerForm.get('postal_code').value);
                    formData.append('taxpayers_number', this.registerForm.get('taxpayers_number').value);

                }
            }

            this.loading = true;

            this.userService.createUser(formData).then(

                () => {
                    this.accountService.login(email, password).then(
                        () => {

                            this.loading = false;

                            this.router.navigateByUrl(this.redirectTo).then(
                                () => {

                                    document.getElementsByTagName('BODY')[0].removeAttribute('id');

                                }
                            );
                        },
                        (err) => {
                            this.loading = false;

                            console.log(err);
                            this.errors = err.error.error;

                        }
                    );

                }, err => {
                    this.loading = false;

                    this.errors = err.error.error;
                });

        } else {

            console.log(this.registerForm.controls);
            this.formErrors = this.registerForm.controls;
            return;

        }

    }

    setCurrentPosition() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.getAddress(position.coords.latitude, position.coords.longitude);
            });
        }
    }

    getAddress(latitude, longitude) {
        this.geoCoder = new google.maps.Geocoder;
        // new google.maps.
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

            console.log(status);
            if (status === 'OK') {
                console.log('resultat', results[0]);
                if (results[0]) {

                    this.registerForm.controls.address.setValue(results[0].formatted_address);
                } else {
                    window.alert('No results found');
                }
                if (results[1]) {
                    // get town
                    for (let i = 0; i < results[0].address_components.length; i++) {
                        for (let b = 0; b < results[0].address_components[i].types.length; b++) {
                            if (results[0].address_components[i].types[b] == "locality") {
                                console.log('name town', results[0].address_components[i].long_name);
                                this.registerForm.controls.city.setValue(results[0].address_components[i].long_name);
                            }
                        }
                    }
                    // get country
                    for (let i = 0; i < results[0].address_components.length; i++) {
                        for (let b = 0; b < results[0].address_components[i].types.length; b++) {
                            if (results[0].address_components[i].types[b] == "country") {
                                console.log('name country', results[0].address_components[i].long_name);
                                this.registerForm.controls.country.setValue(results[0].address_components[i].long_name);
                                this.country = results[0].address_components[i].short_name;

                            }
                        }
                    }

                    // get postal_code
                    for (let i = 0; i < results[0].address_components.length; i++) {
                        for (let b = 0; b < results[0].address_components[i].types.length; b++) {
                            if (results[0].address_components[i].types[b] == "postal_code") {
                                console.log('name postal code', results[0].address_components[i].short_name);
                                this.registerForm.controls.postal_code.setValue(results[0].address_components[i].short_name);

                            }
                        }
                    }
                }

            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    placeAutocomplete() {
        //load Places Autocomplete

        if (typeof this.searchElementRef !== 'undefined') {
            this.mapsAPILoader.load().then(() => {
                let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                    types: ['address']
                });
                autocomplete.addListener('place_changed', () => {
                    this.ngZone.run(() => {
                        //get the place result
                        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                        //verify result
                        if (place.geometry === undefined || place.geometry === null) {
                            return;
                        }

                        this.registerForm.controls.address.setValue(place.formatted_address);

                        if (place.address_components) {
                            // get town
                            for (let i = 0; i < place.address_components.length; i++) {
                                for (let b = 0; b < place.address_components[i].types.length; b++) {
                                    if (place.address_components[i].types[b] == 'locality') {
                                        console.log('name town', place.address_components[i].long_name);

                                        this.registerForm.controls.city.setValue(place.address_components[i].long_name);

                                    }
                                }
                            }
                            // get country
                            for (let i = 0; i < place.address_components.length; i++) {
                                for (let b = 0; b < place.address_components[i].types.length; b++) {
                                    if (place.address_components[i].types[b] == 'country') {
                                        this.registerForm.controls.country.setValue(place.address_components[i].long_name);
                                        this.country = place.address_components[i].short_name;

                                    }
                                }
                            }

                            // get postal_code
                            for (let i = 0; i < place.address_components.length; i++) {
                                for (let b = 0; b < place.address_components[i].types.length; b++) {
                                    if (place.address_components[i].types[b] == 'postal_code') {
                                        console.log('name postal code', place.address_components[i].short_name);
                                        this.registerForm.controls.postal_code.setValue(place.address_components[i].short_name);
                                    }
                                }
                            }
                        }

                        this.latitude = place.geometry.location.lat();
                        this.longitude = place.geometry.location.lng();

                    });
                });
            });

        }


        this.mapLoading = false;
    }




}
