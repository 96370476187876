import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationService } from '../../security/service/authorization.service';
import { AdminAccountService } from '../../services/admin.account.service';
import { HttpAdminService } from '../../services/http-admin.service';

@Component({
  selector: 'app-menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.css']
})
export class MenuLinkComponent implements OnInit {

  @Input() linkMenuIcon: any;
  @Input() data: any;
  @Input() linkMenuIconFunction: any=[];
  @Input() hideIcon: any;
  @Output() sendDataParent = new EventEmitter<any>();

  admin: any;

  total_permission=0;

  constructor(private toastr: ToastrService,
    private httpAdmin:HttpAdminService ,
    private adminAccount:AdminAccountService,
    public authorizationService:AuthorizationService) { }

  ngOnInit() {
    // console.log('list data',this.data);
    
   
    
     this.adminAccount.$admin.then(
      (user: any) => {
       
        if (user) {
          this.admin = user;
        }

      }
    );
  }
  execute(d:any) {
    let data = {
      id: this.data.id
    };
    this.httpAdmin.post(d.link, data).then((data: any) => {
      this.sendDataParent.emit(data);
      this.toastr.success(d.message);

    }, err => {
      console.log('erreur', err);
    });
  }

 
  isVisibile(name){
    // return true;
    
    const test=  this.hideIcon.find(element => 
      
      this.data[element.data]===element.value
     
     );
     
   
      if(test){
      const name_i= test.icon.find(name_icon=> name_icon===name);
        if(name_i){
          return true;
        }else{
          return false
        }
      }else{
        return false;
      }
    }
  
}
