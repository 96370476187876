import {Component, Input, OnInit} from '@angular/core';
import {AuthUser} from '../../../models/auth-user.model';

@Component({
    selector: 'app-detail-product-view',
    templateUrl: './detail-product-view.component.html',
    styleUrls: ['./detail-product-view.component.css']
})
export class DetailProductViewComponent implements OnInit {
    @Input() mode = 'details';
    @Input() product: any;
    @Input() queryParams: any;
    @Input() authUser: AuthUser;
    @Input() loading = false;
    @Input() viewAs = 'guest';

    constructor() { }

    ngOnInit() {
    }

}
