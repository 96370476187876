import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-images-room',
    templateUrl: './images-room.component.html',
    styleUrls: ['./images-room.component.css']
})
export class ImagesRoomComponent implements OnInit {

    constructor() { }

    ngOnInit() {}

}
