import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable.admins.json';
import * as  security from '../../json/security/security.json';
import { AdminService } from '../../services/admin.service';
import {IPagination} from '../../model/pagination';
@Component({
  selector: 'app-list-admin',
  templateUrl: './list-admin.component.html',
  styleUrls: ['./list-admin.component.css']
})
export class ListAdminComponent implements OnInit {
  breadcrumb=breadcrumb.admin;
  security=security.security.admin;
  linkMenuIcon=datatable.admins.menu_icon;
  columns=datatable.admins.table;
  data_json=datatable.admins;
  data: any;
  loaded:boolean;
  paramsPage: IPagination;

  constructor(public adminService:AdminService) {
    this.paramsPage={} as IPagination;  
   }

  ngOnInit() {
    this.getAllAdmins();
  }

  getAllAdmins(params?){
    this.loaded=false;
    this.adminService.getAllAdmins(params).then((data:any)=>{
      this.data=data.data.data;
      this.paramsPage.currentPage=data.data?data.data.current_page:0;
      this.paramsPage.totalItems=data.data?data.data.total:0;
      this.paramsPage.currentPage=data.data?data.data.current_page:0;
      this.paramsPage.itemsPerPage=data.data?data.data.per_page:0;
      this.loaded=true;
    // console.log(this.data);
    },err=>{
      console.log('erreur ',err);
    })
  }

  getPage(page){
   
    this.getAllAdmins("?page="+page);
  }
}
