import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../services/auth/auth.service';
import {ResponseData} from '../../../../models/response-data.model';
import {CategoriesService} from '../../../../services/categories/categories.service';

@Component({
    selector: 'app-category-room-form',
    templateUrl: './category-room-form.component.html',
    styleUrls: ['./category-room-form.component.css']
})
export class CategoryRoomFormComponent implements OnInit, OnDestroy {
    @Input() formMode = 'create';
    @Input() create = false;

    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    formRoom: FormGroup;
    product: any;
    loading;
    loaded = false;
    submitted = false;
    productId;
    categories: any = [];
    currentTypes: any = [];
    errors: any;
    private loadingTypes: boolean;

    constructor(private authService: AuthService,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService,
                private categoriesService: CategoriesService) { }

    ngOnInit() {

        this.initForm();

        this.formRoom.controls.category.disable();
        this.formRoom.controls.type.disable();

        this.formRoom.controls.category.valueChanges.subscribe(
            val => {
                this.formRoom.controls.type.disable();

                const currentCategory = this.categories.find(
                    category => {
                        return category.tag === val;
                    }
                );

                if (currentCategory) {
                    this.currentTypes = currentCategory.children;
                    this.formRoom.controls.type.setValue(currentCategory.children[0].tag);
                    this.formRoom.controls.type.enable();

                }
            }
        );

        this.route.parent.params.subscribe(
            (params: Params) => {
                if (params.hasOwnProperty('id')) {
                    this.productId = params.id;
                }
            }
        );

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {
                    this.authUser = user;

                    if (user && !this.userProductSubscription) {
                        if (this.create === false) {
                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;
                                        if (this.categories.length === 0 && !this.loadingTypes) {
                                            this.loadingTypes = true;
                                            this.productService.getTypes().then(
                                                (types: ResponseData) => {
                                                    if (types) {
                                                        this.categories = types.data;
                                                        if (this.product.type_id && this.product.type) {
                                                            this.formRoom.controls.category.setValue(this.product.type.parent.tag);
                                                            this.formRoom.controls.category.enable();
                                                            this.setCurrentType(this.product.type.parent.tag, this.product.type.tag);
                                                            this.formRoom.controls.title.setValue(product.name);
                                                            this.formRoom.controls.is_private.setValue(this.product.is_private ?
                                                                'private_home' : 'common_home');
                                                            this.formRoom.controls.description.setValue(this.product.description);
                                                            this.formRoom.controls.commodities.setValue(this.product.commodities);
                                                            this.loaded = true;
                                                            this.loadingTypes = false;
                                                        }
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                (error) => {
                                    console.log(error);
                                    if (this.formMode === 'create') {
                                        this.router.navigate(['/rooms/new']);
                                    }

                                    if (this.formMode === 'edit') {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                }
                            );
                        } else {
                            if (this.categories.length === 0) {
                                this.productService.getTypes().then(
                                    (types: ResponseData) => {
                                        if (types) {
                                            this.categories = types.data;
                                            this.formRoom.controls.category.enable();
                                            this.loaded = true;
                                        }
                                    }
                                );
                            }
                        }
                    }
                }
            );

            this.authService.emitUser();

        }
    }

    getCategoryNameByTag(tag) {
        return this.categoriesService.getCategoryNameByTag(tag);
    }

    checkUser() {
        if (this.authUser && this.product && this.product.published) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;
    }

    setCurrentType(categoryTag, typeTag) {
        const currentCategory = this.categories.find(
            category => {
                return category.tag === categoryTag;
            }
        );

        if (currentCategory) {
            this.currentTypes = currentCategory.children;
            const currentType = this.currentTypes.find(
                type => {
                    return type.tag === typeTag;
                }
            );

            if (currentType) {
                this.formRoom.controls.type.setValue(currentType.tag);
                this.formRoom.controls.type.enable();

            }
        }

    }

    doSubmit() {

        this.submitted = true;

        if (this.formRoom.invalid) {

            return;
        }

        this.loading = true;
        const formData = this.formRoom.value;

        if (!this.product) {
            this.productService.createUserProduct(this.authUser.id, formData).then(
                (product: any) => {
                    this.productService.userProduct = product;
                    this.productService.emitUserProduct();
                    this.productService.getFormWizardStep();
                    this.productService.emitFormWizardSteps();

                    this.router.navigate([this.productService.getNextStep()]).then(
                        () => {
                            this.toastr.success('Information basiques insérées');
                        }
                    );
                },
                (errors: any) => {
                    this.loading = false;
                    this.errors = errors.error.error;

                }
            );
        }

        if (this.product) {

            this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
                (product: any) => {
                    this.product = product;
                    this.loading = false;

                    if (this.formMode === 'create') {

                        this.router.navigate([this.productService.getNextStep()]).then(
                            () => {
                                this.toastr.success('Modifications effectuées');
                            }
                        );
                    }

                    if (this.formMode === 'edit') {

                        this.toastr.success('Modifications effectuées');

                    }


                },
                (errors: any) => {
                    this.loading = false;
                    this.errors = errors.error.error;

                }
            );

        }


    }

    initForm() {
        this.formRoom = this.fb.group({
            category: ['', [Validators.required]],
            type: ['', [Validators.required]],
            title: [''],
            is_private: ['', [Validators.required]],
            description: [''],
            commodities: [''],
        });
    }

    get f() {
        return this.formRoom.controls;
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
