import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';
import {AuthUser} from '../../models/auth-user.model';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

    authUser: AuthUser;
    userSubscription: Subscription;

    constructor(private authService: AuthService) { }

    ngOnInit() {
        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                this.authUser = user;
            }
        );

        this.authService.getAuthUser().then(
            () => {
                this.authService.emitUser();

            },
            () => {
                this.authService.emitUser();

            }
        );

    }


}
