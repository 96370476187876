import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import * as  datatable from '../../../json/datatable/datatable_bookings.json';
import * as  security from '../../../json/security/security.json';

import { AdminService } from 'src/app/admin/services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { GetAdmin } from 'src/app/admin/model/getadmin';
declare var $:any;
@Component({
  selector: 'app-detail-refund',
  templateUrl: './detail-refund.component.html',
  styleUrls: ['./detail-refund.component.css']
})
export class DetailRefundComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.detail_refund; 
  loaded: boolean;
  security=security.security.transactions;

  adminId: any;
  data: any;
  booking: any;
  list_status = datatable.status;
  status: any[];
  preferences: any;
  default_preference: any;

  constructor(
              public adminService: AdminService, 
              private route: ActivatedRoute,
         ) {
    this.loaded = false;
  }
  ngOnInit() {
    this.informationAdmin();
    this.adminService.setTitle('Detail remboursement');

  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
          
     this.getOneBooking(this.adminId, this.route.snapshot.params.id);
    });
   }


  getOneBooking(AdminId, bookingId) {

    this.adminService.getOnePayementRefunded(AdminId, bookingId).then((data: any) => {
      this.booking = data;
    // console.log('refund', this.booking);
     this.getUserSetting(this.booking.traveler.id);
      this.loaded = true;
      this.getSatus(this.booking.reservation.timing, this.booking.reservation.original_status);
    }, err => {

      console.log('ERREUR', err);
    });
  }

  getSatus(timing, status?) {
    this.status = [];
    let s = this.list_status.find(e => e.value === timing);
    if (s.type) {
      let c = s.type.find(e => e.value === status);
     
      this.status.push(c.text);
    } else {
      this.status.push(s.class);
      this.status.push(s.text);
    }

    // console.log('stats', this.status);
  }

  test(e){
    if(e){
      // this.notification.confirm("Effectuer le Transfert","Voulez vous effectuer ce transfert?",'sm').then(data=>{
      //   console.log('action',data);
      // });
       $('#exampleModal').modal('show')
    }
  }

  private getUserSetting(id){
    this.adminService.getUserSettings(id).then((data:any)=>{
     
      if(data.value){
      
        this.default_preference=data.value.default_payment_preference
        this.preferences=data.value.payment_preferences[data.value.default_payment_preference];
        // console.log('bank', this.preferences[0])
      }
    },err=>{
      console.log('erreur',err);
    });
  }

}
