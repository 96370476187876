import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-booking-product-identity',
    templateUrl: './booking-product-identity.component.html',
    styleUrls: ['./booking-product-identity.component.css']
})
export class BookingProductIdentityComponent implements OnInit {

    @Input() product: any;
    @Input() data: any;

    constructor() { }

    ngOnInit() {
    }

}
