import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../../../services/auth/auth.service';
import {BookingService} from '../../../../../services/bookings/booking.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../../../../services/app/app.service';
import {ToastrService} from 'ngx-toastr';
import {AuthUser} from '../../../../../models/auth-user.model';
import * as moment from 'moment';
import Echo from 'laravel-echo';

@Component({
    selector: 'app-list-booking',
    templateUrl: './list-booking.component.html',
    styleUrls: ['./list-booking.component.css']
})
export class ListBookingComponent implements OnInit {
    @Input() mode = 'reservations';
    @Input() booking: any;
    @Input() role: string | null;
    @Input() authUser: AuthUser;
    @Input() displayOption = 'list';

    @Output() bookingChange = new EventEmitter<any>();

    editMode: boolean;
    queryParams: any = {};
    product: any;
    editingBookingDetails: boolean;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private bookingService: BookingService,
                private authService: AuthService,
                private appService: AppService,
                private toastr: ToastrService) {
        moment.locale('fr');

    }

    ngOnInit() {

        this.initEditData();
        this.authService.broadcast().then(
            (echo: Echo) =>  {
                if (this.mode === 'demands') {
                    echo.private(`App.User.${this.authUser.id}`)
                        .listen('.demand.status.changed.' + this.booking.id, (data) => {
                            console.log(data);
                            const demand = data.data;
                            this.booking.status = demand.status;
                            this.booking.original_status = demand.status;
                        });
                }

                if (this.mode === 'reservations') {
                    echo.private(`App.User.${this.authUser.id}`)
                        .listen('.reservation.status.changed.' + this.booking.id, (data: any) => {
                            console.log(data);
                            const reservation = data.data;
                            this.booking.status = reservation.status;
                            this.booking.timing = reservation.timing;
                            this.booking.original_status = reservation.original_status;
                        });
                }
            }
        );
    }

    cancelEdit() {
        this.editMode = false;
        this.initEditData();
    }

    initEditData() {
        this.product = this.booking.product;
        this.queryParams.dateStart = this.booking.date_start;
        this.queryParams.dateEnd = this.booking.date_end;
        this.queryParams.adults = this.booking.adults;
        this.queryParams.children = this.booking.children;
        this.queryParams.babies = this.booking.babies;
    }

    getBookingDateString(createdAt: string): string {
        return moment(createdAt).fromNow();
    }

    getDetailUrl() {
        return this.mode === 'demands' ? '/account/booking-demands/' + this.booking.id + '/' + this.getUserRole() :
            '/account/bookings/' + this.booking.id + '/' + this.getUserRole();
    }

    getUserRole(): string {
        return this.role ? this.role : 'host';
    }

    checkUser(reservation) {
        return this.authUser.id === reservation.product.manager.id;
    }

    getHostFullName(reservation) {
        return reservation.product.manager_type === 'agent' ? reservation.product.agent.lastname : reservation.product.user.lastname;
    }

    pursuePayment(booking) {
        if (this.mode === 'demands') {
            this.router.navigate(['/booking/payment'], {queryParams: {booking_id: booking.id}});

        }

        if (this.mode === 'information') {
            this.router.navigate(['/booking/new'], {queryParams: {
                    selection: booking.selection_id,
                    id: this.booking.product.id,
                    step: 'checkout'
                }});
        }
    }

    goToDemandBooking(booking) {
        this.router.navigate(['/booking/new'], {queryParams: {
                selection: booking.selection_id,
                id: this.booking.product.id,
                step: 'checkout'
            }});
    }

    transformCurrency(reservation, price) {
        return this.appService.transformCurrency(price, reservation.currency);
    }

    getApiUserRole() {
        switch (this.role) {
            case 'agent':
                return 'agents';
            case 'tenant':
                return 'clients';
            case 'host':
                return 'hosts';
            default:
                return 'hosts';
        }
    }

    getCancelableStatus() {
        switch (this.role) {
            case 'host':
                return 'c-o';
            case 'agent':
                return 'c-a';
            case 'tenant':
                return 'c-t';
            default:
                return 'c-o';
        }
    }

    setLoadingOptions(status, isLoading: boolean) {
        switch (status) {
            case 'accepted':
                this.booking.accepting = isLoading;
                break;
            case 'declined':
                this.booking.declining = isLoading;
                break;
            case 'cancelled':
                this.booking.cancelling = isLoading;
                break;
            case 'c-o':
                this.booking.cancelling = isLoading;
                break;
            case 'c-t':
                this.booking.cancelling = isLoading;
                break;
            case 'c-a':
                this.booking.cancelling = isLoading;
                break;
        }
    }
    editSelection() {
        this.editingBookingDetails = true;
        this.bookingService.updateUserSelectionBookingDetails(this.authUser.id, this.booking.selection.id, this.queryParams).then(
            (bookingDetails: any) => {
                this.bookingChange.emit(bookingDetails);
                this.toastr.info('La sélection a été modifiée avec succès');

                this.editMode = false;
                this.editingBookingDetails = false;
            },
            () => {
                this.editingBookingDetails = false;
            }
        );

    }

    editBookingStatus(status: string) {

        this.setLoadingOptions(status, true);

        this.bookingService.updateUserBooking(this.authUser.id, this.getApiUserRole(), this.booking.id, {status}, this.mode).then(
            (booking: any) => {

                if (this.mode === 'demands') {
                    this.booking.status = booking.status;
                    this.booking.original_status = booking.original_status;
                }

                if (this.mode === 'reservations') {
                    this.booking.status = booking.status;
                    this.booking.timing = booking.timing;
                    this.booking.original_status = booking.original_status;
                }

                this.setLoadingOptions(status, false);

            }
        );

    }


}
