import {AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../services/user/user.service';
import * as moment from 'moment';
import {Service} from '../../../../setting/settings';
import * as countries from 'i18n-iso-countries';
import {ProductsService} from '../../../../services/products/products.service';
import {Router} from '@angular/router';
import {AuthUser} from '../../../../models/auth-user.model';
import {MapsAPILoader} from '@agm/core';
import {FormStep} from '../../../../models/product.model';
import {UploadedImage} from '../../../../models/Image';
declare var require: any;
import {Location} from '@angular/common';
import {MapCord} from '../../../../models/app.model';
import {AgmService} from '../../../../services/agm/agm.service';
import {FormWizardService} from '../../../../services/form-wizard/form-wizard.service';


@Component({
    selector: 'app-profile-account-form',
    templateUrl: './profile-account-form.component.html',
    styleUrls: ['./profile-account-form.component.css']
})
export class ProfileAccountFormComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('search') public searchElementRef: ElementRef;
    @ViewChild('search1') public search1ElementRef: ElementRef;

    @Input() formMode: string;
    @Input() isFormWizardMode = false;
    @Input() disabled = false;

    latitude: number;
    longitude: number;
    zoom: number;
    map = false;
    geoCoder: google.maps.Geocoder;
    address: any;
    address_complement: any;
    mapCoorSubscription: Subscription;

    user: AuthUser;
    userData: any;
    profil: any;
    id: any;
    errors: any;
    loading = false;
    angForm: FormGroup;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    product: any;
    days = [];
    months = [];
    years = [];
    countries: { code: string, name: string, dial_code: string}[] = [];
    countriesDialCodes: { code: string, name: string, dial_code: string}[] = [];
    dialCodes: { name: string, dial_code: string, code: string}[] = [];

    invalidBirthday;
    formSteps: FormStep[];
    submitted;
    country: string;
    upLoadedPhoto: UploadedImage;
    upLoadPhotoFormData: { key: string, value: any }[] = [];
    private formWizardSubscription: Subscription;


    constructor(private toastr: ToastrService,
                private userService: UserService,
                private authService: AuthService,
                private fb: FormBuilder,
                private productService: ProductsService,
                private router: Router,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private _location: Location,
                private agmService: AgmService,
                private formWizardServive: FormWizardService) {
        moment.locale('fr');
        this.zoom = 14;

        this.address = new FormControl();
        this.address_complement = new FormControl();
    }

    ngOnInit() {

        this.dialCodes = Service.DIAL_CODES;

        if (this.formMode === 'edit') {
            this.initEditForm();
        }

        if (this.formMode === 'complete' || this.formMode === 'verify') {
            this.initCompleteForm();
        }

        this.mapCoorSubscription = this.agmService.mapCordSubject.subscribe(
            (cord: MapCord) => {
                setTimeout(() => {

                    if (cord && !cord.isLoading && cord.status == 'OK') {

                        this[cord.searchControl].setValue(cord.adress);
                        this.angForm.controls[cord.searchControl].setValue(cord.adress);

                        if (cord.searchControl === 'address') {
                            this.angForm.controls.city.setValue(cord.city);
                            this.angForm.controls.country.setValue(cord.country);
                            this.angForm.controls.postal_code.setValue(cord.postalCode);
                        }

                        this.latitude = cord.latitude;
                        this.longitude = cord.longitude;

                        if (this.latitude && this.longitude) {
                            this.map = true;
                        }
                    }
                }, 100);
            }
        );

        this.angForm.controls.dial_code.valueChanges.subscribe(
            val => {
                if (!val) {
                    this.angForm.controls.phone.disable();
                } else {
                    this.angForm.controls.phone.enable();
                }
            }
        );

        this.angForm.controls.country.valueChanges.subscribe(
            val => {

                if (this.user) {
                    const dialCode = this.dialCodes.find(
                        (dial) => {

                            return dial.code === val;
                        }
                    );

                    if (!this.user.dial_code) {

                        this.angForm.controls.dial_code.setValue(dialCode && dialCode.dial_code ?
                            dialCode.dial_code : this.angForm.value.dial_code);
                    }
                }
            }
        );

        countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

        const countriesObject = countries.getNames('fr');

        for (const [key, value] of Object.entries(countriesObject)) {

            const dialCode = this.dialCodes.find(
                (dial) => {

                    return dial.code === key;
                }
            );

            const country = {
                code: key,
                name: value,
                dial_code: dialCode && dialCode.dial_code ? dialCode.dial_code : null
            };

            if (country.dial_code) {
                this.countriesDialCodes.push(country);
            }

            this.countries.push(country);
        }

        this.days = Array(31).fill(1).map((x, i) => i + 1); // [0,1,2,3,4]
        this.months = Array.apply(0, Array(12)).map(function(_, i ) { return moment().month(i).format('MMMM'); });

        for (let i = moment().year(); i >= 1900; i--) {
            this.years.push(i);
        }

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                if (user) {
                    this.user = user;
                    if (user.has_photo) {
                        this.upLoadedPhoto = {
                            link: user.photo,
                            hasUploaded: true,
                            uploadName: 'photo'
                        };
                    }

                    this.upLoadPhotoFormData = [
                        {
                            key: 'model_name',
                            value: 'user',
                        },
                        {
                            key: 'file_name',
                            value: 'photo',
                        },
                        {
                            key: 'model_id',
                            value: user.id,
                        }
                    ];
                    if (this.formMode === 'complete' && !this.userProductSubscription) {
                        this.userProductSubscription = this.productService.userProductSubject.subscribe(
                            (product: any) => {
                                if (product) {
                                    this.product = product;
                                    this.formSteps = this.productService.getFormWizardStep();
                                }
                            }
                        );
                        this.productService.emitUserProduct();
                    }

                    if (this.formMode === 'verify') {
                        this.formWizardSubscription =  this.formWizardServive.formWizardSubmittedSubject.subscribe(
                            submitted => {
                                if (submitted) {
                                    this.onSubmit();
                                }
                            }
                        );
                    }

                    this.angForm.controls.name.setValue(user.name);
                    this.angForm.controls.surname.setValue(user.lastname);
                    this.angForm.controls.civility.setValue(user.civility);
                    this.angForm.controls.birth_day.setValue(user.day_of_birth);
                    this.angForm.controls.birth_month.setValue(user.birth_month);
                    this.angForm.controls.birth_year.setValue(user.birth_year);
                    // this.angForm.controls.birth_place.setValue(user.birth_place);
                    this.angForm.controls.country.setValue(user.country);
                    this.angForm.controls.postal_code.setValue(user.postal_code);
                    this.angForm.controls.city.setValue(user.city);

                    if (user.dial_code) {
                        this.angForm.controls.dial_code.setValue(user.dial_code);
                    } else {
                        this.angForm.controls.dial_code.setValue('');

                    }
                    this.angForm.controls.phone.setValue(user.phone);
                    this.angForm.controls.about.setValue(user.about);
                    this.angForm.controls.twitter.setValue(user.twitter);
                    this.angForm.controls.linkedin.setValue(user.linkedin);
                    this.angForm.controls.facebook.setValue(user.facebook);

                    if (user.address_latitude && user.address_longitude && user.adress) {

                        this.angForm.controls.address.setValue(user.adress);
                        this.latitude = parseFloat(user.address_latitude.toString());
                        this.longitude = parseFloat(user.address_longitude.toString());

                        this.map = true;
                        this.address.value = user.adress;
                        this.address_complement.value = user.address_complement;
                    }
                }
            }
        );

        this.authService.getAuthUser().then(
            () => {
                this.authService.emitUser();

            },
            () => {
                this.authService.emitUser();

            }
        );
    }

    next() {
        return this.productService.getNextStep();
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    onSubmit() {
        this.submitted = true;
        if (this.angForm.invalid) {
            return;
        }
        this.errors = null;
        let birthday = null;

        const formData = this.angForm.value;

        const birthDay = formData.birth_day;
        const birthMonth = formData.birth_month;
        const birthYear = formData.birth_year;

        if (birthDay && birthMonth && birthYear) {

            birthday = birthYear + '-' + birthMonth + '-' + birthDay;

            if (!moment(new Date(birthday)).isValid()) {
                this.invalidBirthday = true;
                this.toastr.error('La date saisie est invalide');
                return;
            }
        }
        this.loading = true;

        if (this.isFormWizardMode) {
            this.formWizardServive.setLoading(true);
        }

        this.invalidBirthday = false;

        this.userData = {
            _method: 'PUT',
            name: formData.name,
            lastname: formData.surname,
            civility: formData.civility,
            birthday,
            // birth_place: formData.birth_place,
            adress: formData.address,
            address_complement: formData.address_complement,
            country: formData.country,
            postal_code: formData.postal_code,
            city: formData.city,
            dial_code: formData.dial_code,
            phone: formData.phone,
            about: formData.about,
            twitter: formData.twitter,
            linkedin: formData.linkedin,
            facebook: formData.facebook,
            address_latitude: this.latitude,
            address_longitude: this.longitude,

        };

        this.userService.editProfile(this.userData, this.user.id).then(
            (user: AuthUser) => {

                this.user = user;
                this.authService.authUser = user;
                this.authService.emitUser();

                if (this.formMode === 'edit') {
                    this.toastr.success('Modfication effectuée!', 'Succès!');
                }

                if (this.isFormWizardMode) {
                    this.formWizardServive.setLoading(false);
                    this.formWizardServive.next();
                }

                if (this.formMode === 'complete') {
                    this.router.navigate([this.next()]).then(
                        () => {
                            this.toastr.success('Vous informations ont bien été mis à jour');
                        }
                    );
                }

                this.loading = false;
            }, err => {
                this.loading = false;

                if (this.isFormWizardMode) {
                    this.formWizardServive.setLoading(false);
                }

                this.errors = err.error.error;

            });

    }

    initEditForm() {
        this.angForm = this.fb.group({

            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            civility: [''],
            birth_day: ['', {disabled: !this.days}],
            birth_month: ['', {disabled: !this.months}],
            birth_year: ['', {disabled: !this.years}],
            // birth_place: [''],
            address: [''],
            address_complement: [''],
            country: ['', {disabled: !this.countries}],
            postal_code: [''],
            city: [''],
            dial_code: ['', {disabled: !this.countries}],
            phone: ['', {disabled: !this.countries}],
            about: [''],
            twitter: [''],
            linkedin: [''],
            facebook: [''],
        });
    }
    initCompleteForm() {
        this.angForm = this.fb.group({

            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            civility: ['', [Validators.required]],
            birth_day: [{value: '', disabled: !this.days}, Validators.required],
            birth_month: [{value: '', disabled: !this.months}, Validators.required],
            birth_year: [{value: '', disabled: !this.years}, Validators.required],
            // birth_place: ['', [Validators.required]],
            address: ['', [Validators.required]],
            address_complement: [''],
            country: [{value: '', disabled: !this.countries}, Validators.required],
            postal_code: [''],
            city: ['', [Validators.required]],
            dial_code: [{value: '', disabled: !this.countries}, Validators.required],
            phone: [{value: '', disabled: !this.countries}, Validators.required],
            about: [''],
            twitter: [''],
            linkedin: [''],
            facebook: [''],
        });
    }

    setCurrentPosition(searchControl: string) {
        this.agmService.setCurrentPosition(searchControl);
    }

    cancelEditProfile() {
        this._location.back();
    }

    get f() {
        return this.angForm.controls;
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }

        if (this.mapCoorSubscription) {
            this.mapCoorSubscription.unsubscribe();
        }

        if (this.formWizardSubscription) {
            this.formWizardSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.agmService.placeAutoComplete(this.searchElementRef.nativeElement, 'address');
            this.agmService.placeAutoComplete(this.search1ElementRef.nativeElement, 'address_complement');
        }, 2000);

    }
}
