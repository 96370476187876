import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-bookings-list',
    templateUrl: './bookings-list.component.html',
    styleUrls: ['./bookings-list.component.css']
})
export class BookingsListComponent implements OnInit {
    constructor() { }

    ngOnInit() {

    }

}
