import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnHide: boolean;
  @Input() btnOkClass = 'btn btn-info btn-sm';
  constructor(private activeModal: NgbActiveModal, private config: NgbModalConfig) {

  }

  ngOnInit() {

    this.config.backdrop = 'static';
    this.config.keyboard = false;

  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {

    this.activeModal.close(true);
  
  }

  public dismiss() {

    this.activeModal.dismiss();
  }

}
