import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents-customer',
  templateUrl: './documents-customer.component.html',
  styleUrls: ['./documents-customer.component.css']
})
export class DocumentsCustomerComponent implements OnInit {
  documents=[];
  document=[];

  constructor() { }
  @Input() customer:any;
  ngOnInit(): void {
    // console.log('documents',this.customer.documents);
    // this.getDocuments(this.customer.documents);

    if(this.customer.documents){
  
        this.customer.documents.forEach(element => {
          this.documents[element.title]=element;
        });

        console.log('doc',this.document);
    
    }
  }


  private getDocuments(documents){
    if(documents){
      if(documents.find(element=> element.title=="user_identity_image_recto")){
        this.documents['user_identity_image_recto']= {title:'Carte d\'identité recto', type:"tp",value:documents.find(element=> element.title=="user_identity_image_recto")} ;
       }
  
       if(documents.find(element=> element.title=="user_identity_image_verso")){
        this.documents['user_identity_image_verso']= {title:'Carte d\'identité verso', type:"tp",value:documents.find(element=> element.title=="user_identity_image_verso")} ;
       }
  
       if(documents.find(element=> element.title=="user_snapped_photo")){
        this.documents['user_snapped_photo']= {title:'Photo instantanée', type:"tp",value:documents.find(element=> element.title=="user_snapped_photo")} ;
       }
  
       if(documents.find(element=> element.title=="user_passport_image")){
        this.documents['user_passport_image']= {title:'Passport', type:"tp",value:documents.find(element=> element.title=="user_passport_image")} ;
       }
  
       if(documents.find(element=> element.title=="electricity_receipt")){
        this.documents['electricity_receipt']= {title:'facture d\'éléctricité', type:"tp",value:documents.find(element=> element.title=="electricity_receipt")} ;
       }
       if(documents.find(element=> element.title=="gas_receipt")){
        this.documents['gas_receipt']= {title:'Facture de gaz', type:"tp",value:documents.find(element=> element.title=="gas_receipt")} ;
       }
    }
   

     
  }
}
