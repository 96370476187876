import { Component, OnInit} from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {


  zoom: number;
  latitude: number;
  longitude: number;
  submitted=false;

angForm:FormGroup;
  constructor(private fb:FormBuilder,
    private toastr: ToastrService,) {
   
  }

  ngOnInit() {
    this.zoom = 14;
    this.latitude = 4.057372955390335;
    this.longitude = 9.722126710037173;
    this.createForm();
  }

  

  createForm(){
    this.angForm=this.fb.group({
      name:['',[Validators.required]],
      email:['', [Validators.required,Validators.email]],
      subject:['',[Validators.required]],
      message:['',[Validators.required]]
    });

  }

  get f() {
    return this.angForm.controls;
  }

  onSubmit(){
    console.log('icic');
    this.submitted=true;
    if(this.angForm.invalid){
      return;
    }
  }

}
