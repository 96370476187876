import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { Subscription } from 'rxjs';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-detail-visitor',
  templateUrl: './detail-visitor.component.html',
  styleUrls: ['./detail-visitor.component.css']
})
export class DetailVisitorComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.detail_visitor;
  userSubscription: Subscription;
  loaded=false;
  adminId: any;
  visitor: any;
  constructor(private adminService:AdminService,private route: ActivatedRoute) {
     
      }


  ngOnInit() {
    this.informationAdmin();
  }
  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;  
     this.getOneVisitor( this.adminId, this.route.snapshot.params.id);
    });
   }

  getOneVisitor(AdminId,id){
    
    this.adminService.getOneCustomer(AdminId,id).then((data:any)=>{
      this.visitor = data;
        this.loaded = true;
        //  console.log('visitor',this.visitor);
    },err=>{
     
      console.log('ERREUR',err);
    });
  }

 
}
