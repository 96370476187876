import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-actived-deactived',
  templateUrl: './actived-deactived.component.html',
  styleUrls: ['./actived-deactived.component.css']
})
export class ActivedDeactivedComponent implements OnInit {
  @Input() data: any;
  @Input() data_json: any;
  private message:string;
 
  constructor( private toastr: ToastrService,
               private serviceAdmin:AdminService) { }

  ngOnInit() {
  }

  activedDeactived(data,type){
        if(type=="actived"){
          this.message=this.data_json.message;
        }else if (type=="deactived"){
          this.message=this.data_json.message;
        };

      this.serviceAdmin.activedDeactived(data).then((data:any)=>{
      this.toastr.success(this.message,'Success');
    },err=>{
      console.error('Erreur lors de l\'activation ou de deactivation',err);
    });

  }


}
