import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GetAdmin } from '../../model/getadmin';
import { AdminAccountService } from '../../services/admin.account.service';
@Directive({
  selector: '[hasPermission]'
})
export class HideIfUnauthorizedDirective implements GetAdmin{
  private isSuperAdmin: any;

  private permi: any;
  private isHidden = true;
  userSubscription: Subscription;
  @Input()
  set hasPermission(val) {
    this.permi = val;
    this.updateView();
  };
  private currentUser;
  private permissions = [];

  constructor(private el: ElementRef,
    private accountAdmin: AdminAccountService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }
 

  ngOnInit() {
    this.informationAdmin();
  }

  informationAdmin(): void {
    this.accountAdmin.$admin.then((data:any)=>{
       this.currentUser=data;
       this.permissions = this.currentUser.permissions;
       // console.log('admin', this.currentUser);
       this.isSuperAdmin = this.currentUser.roles[0].name;
       if(this.permissions){
         this.updateView();
       }
       
    },err=>{
      console.log('error',err);
    })
   }


  private updateView() {
   
    if (this.checkPermission()) {
    
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }


  private checkPermission() {
    
    let hasPermission = false;
    if (this.isSuperAdmin === "superadmin" && this.isSuperAdmin) {
      hasPermission = true;
     
      return hasPermission;
    }
   
    if (this.isSuperAdmin) {
      const permissionFound = this.permissions.find(element => element ==this.permi);
      if (permissionFound) {
        //  console.log('permission',this.permissions);
        hasPermission = true;
      } else {
        hasPermission = false;
      }
    }
    return hasPermission;
  }

 
}
