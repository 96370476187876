import { Component,OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import * as filter from '../json/filter/filter.json';
import { IPagination } from '../model/pagination';
import * as  datatable from './data.claims';
import { GetAdmin } from '../model/getadmin';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

declare var $;
@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.css']
})
export class ClaimsComponent implements OnInit,GetAdmin {
  breadcrumb=datatable.breadcrumb;
  adminId: any;
  data=[];
  paramsPage: IPagination;
  filter=filter.bookings;
  data_json=datatable.claims;
  angForm: any;
  submitted = false;
  spinner=false;
  keyword = 'search';
  data1: { id: number; reservation: string; client: string; host: string; title: string; status: string; }[];
  errors: any;
  loaded=false;
  constructor(public adminService:AdminService,private fb: FormBuilder,  private toastr: ToastrService) {

   
    
      this.paramsPage={} as IPagination;  

     }

  ngOnInit(): void {
    this.adminService.setTitle('litiges&reclamations');
    this.informationAdmin();
    this.initForm();
    
  }

  initForm() {
    this.angForm = this.fb.group({
      reservation: [null, [Validators.required]],
      title: [null, [Validators.required]],
      client: [null, [Validators.required]],
      description: [null, [Validators.required]],
      type: [null, [Validators.required]],
     
    });
  }

  get f() {

    return this.angForm.controls;
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;     
     this.getAllclient();
     this.getAllClaims();
    });
   }
  getPage(page){}

  addClaim(){
    $('#claimsModal').modal('show');
  }

  saveClaim() {
 
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }
    let claim={
     title: this.angForm.value.title,
     description: this.angForm.value.description,
     user_id: this.angForm.value.client.id,
     type: this.angForm.value.type,
     reservation:this.angForm.value.reservation,
    //  admin_id:this.adminId
    }

    this.spinner=true;
    this.adminService.postClaims(claim).then((claim:any)=>{
      console.log('claims',claim);
      this.toastr.success('La reclamation a été créée','Success');
      $('#claimsModal').modal('hide');
      this.getAllClaims();
      this.spinner=false;
    },err=>{
      this.spinner=false;
      this.errors=err;
      console.log('err',err);
    });


  }

  getAllClaims(){
    this.loaded = false;
    this.adminService.getAllClaims().then((claims:any)=>{
      console.log('claims',claims);
      this.data1=claims.data;
      this.loaded = true;
      this.paramsPage.currentPage = claims.current_page;
      this.paramsPage.totalItems = claims.length? claims.total:0;
      this.paramsPage.currentPage = claims.current_page;
      this.paramsPage.itemsPerPage = claims.per_page;
      this.paramsPage.to=claims.to;
        this.paramsPage.from=claims.from;
      this.paramsPage.id = "server";
    }
    ,err=>{
      console.log('Error',err);
    })
  }

  getAllclient(){
    this.adminService.getAllclient().then((data:any)=>{
        // console.log('client',data);
        this.data=data;
    },err=>{
      console.log('err',err);
    });
  }


  selectEvent(e){

  }

  onChangeSearch(e){

  }

  onFocused(e){

  }
}
