import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../services/app/app.service';

@Component({
    selector: 'app-price-tag',
    templateUrl: './price-tag.component.html',
    styleUrls: ['./price-tag.component.css']
})
export class PriceTagComponent implements OnInit {

    @Input() price: number;
    @Input() priceO: number;
    @Input() from: string;
    @Input() to: string;
    @Input() convert: boolean;
    @Input() unit: string;
    @Input() discounted: boolean;
    @Input() breakPrice: boolean;
    @Input() digitsInfo: string;

    constructor(private appService: AppService) { }

    ngOnInit() {
    }

    getTransformedCurrency(): string {

        let prefix = '';
        if (this.discounted) {
            prefix = '<del class="price_o">' + this.transformCurrency(this.priceO, null) + '</del> ';
        }

        if (this.breakPrice) {
            prefix += '<br>';
        }

        return prefix + this.transformCurrency(this.price);

    }

    transformCurrency(price, unit = this.unit) {

        return this.appService.transformCurrency(
            price,
            this.from,
            this.to,
            this.convert,
            unit,
            this.digitsInfo,
        );
    }

}
