import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable.customers.json';
import * as filter from '../../json/filter/filter.json';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse } from 'src/app/models/response.model';
import { AdminService } from '../../services/admin.service';
import {IPagination} from '../../model/pagination';
import { GetAdmin } from '../../model/getadmin';
declare var $: any;
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit, GetAdmin {

  data: any;
  breadcrumb = breadcrumb.customers;
  response: ApiResponse;
  loaded: boolean;
  data_json = datatable.customers;
  filter = filter.customers;

  adminId: any;
  paramsPage: IPagination;

  constructor(public adminService: AdminService, private route: ActivatedRoute) {
    this.paramsPage = {} as IPagination;

  }


  ngOnInit() {
    this.adminService.setTitle('Clients');
      this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;     
     this.getParams();
    });
   }

   getParams() {
    this.route.queryParams.subscribe(
      params => {
        if (params) {
         
          this.getAllCustomer(location.search);

        } else {
          this.getAllCustomer();
        }
      });
  }

  getAllCustomer(params?: string) {
    this.loaded = false;
   
    this.adminService.getAllCustomer(params).then((clients:any) => {
      
      this.data = clients ? clients.data : [];
      this.loaded = true;
      this.paramsPage.currentPage = clients.current_page;
      this.paramsPage.totalItems = clients? clients.total:0;
      this.paramsPage.currentPage = clients.current_page;
      this.paramsPage.itemsPerPage = clients.per_page;
      this.paramsPage.to=clients.to;
        this.paramsPage.from=clients.from;
      this.paramsPage.id = "server";
    }, err => {

      console.log('ERREUR', err);
    });

    // console.log('clients',this.paramsPage)
  }
  getPage(value) {
    
    // console.log('value',value);
    if(value.params){
      this.getAllCustomer(value.params+'&page='+value.page);
     }else{
      this.getAllCustomer("?page="+value.page);
     }

  
  }


}
