import {Component, Input, OnInit} from '@angular/core';
import {FormStep} from '../../../models/product.model';
import {ProductsService} from '../../../services/products/products.service';

@Component({
    selector: 'app-header-wizard',
    templateUrl: './header-wizard.component.html',
    styleUrls: ['./header-wizard.component.css']
})
export class HeaderWizardComponent implements OnInit {

    @Input() formWizardSteps: FormStep[];
    @Input() activeStep: FormStep;
    @Input() product: any;

    constructor(private productService: ProductsService) { }

    ngOnInit() {

    }

    getDisplayOccupationTye(): string {
        if (this.product.category_tag === 'furnished-accommodation' || this.product.category_tag === 'furnished-homes') {
            return this.product.is_private ? '(Bien intégral)' : '(Bien commun)';
        }

        return this.product.is_private ? '(Espace privatif)' : '(Espace commun)';

    }

    getPercentageLoaded() {
        const activeStepIndex = this.productService.getActiveStepIndex();

        return this.formWizardSteps && activeStepIndex ? (activeStepIndex / (this.formWizardSteps.length - 1)) * 100 : 0;
    }
}
