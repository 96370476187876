import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../../../models/auth-user.model';
import {ProductsService} from '../../../../../services/products/products.service';
import {UserService} from '../../../../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {FormWizardService} from '../../../../../services/form-wizard/form-wizard.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-verify-user-photo',
    templateUrl: './verify-user-photo.component.html',
    styleUrls: ['./verify-user-photo.component.css']
})
export class VerifyUserPhotoComponent implements OnInit, OnDestroy {
    @Input() mode = 'product';
    @Input() documents: any = [];
    @Input() authUser: AuthUser;
    @Input() loading: boolean;
    captures: any = [];
    document: any;
    savingImage: boolean;
    private formWizardSubscription: Subscription;

    constructor(private productService: ProductsService,
                private userService: UserService,
                private toastr: ToastrService,
                private formWizardService: FormWizardService) { }

    ngOnInit(): void {

        if (this.authUser && this.documents) {

            this.formWizardSubscription = this.formWizardService.formWizardSubmittedSubject.subscribe(
                submitted => {
                    if (submitted) {
                        if (!this.document) {
                            this.toastr.error('Vous devez prendre une photo de vous');
                        } else {
                            this.formWizardService.next();
                        }
                    }
                }
            );

            this.documents.forEach(
                (doc: any) => {
                    if (doc.title === 'user_snapped_photo') {
                        this.captures.unshift(doc.url);
                        this.document = doc;
                    }
                }
            );
        }
    }

    getObjectImage(file) {
        const formData = new FormData();
        formData.append('user_snapped_photo', file);
        this.savingImage = true;

        this.userService.createUserDocument(this.authUser.id, formData).then(
            (document: any) => {
                this.document = document;
                this.documents.push(document);
                this.toastr.success('La photo a été enregistré avec succès');
                this.savingImage = false;
            },
            () => {
                this.toastr.error('Désolé une erreur s\'est produite');
            }
        );
    }

    next() {
        return this.productService.getNextStep();
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    ngOnDestroy(): void {
        if (this.formWizardSubscription) {
            this.formWizardSubscription.unsubscribe();
        }
    }


}
