import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { ActivatedRoute } from '@angular/router';
import { ProductMapAddress } from 'src/app/models/product.model';
import { AdminService } from '../../services/admin.service';
import {Lightbox} from 'ngx-lightbox';
declare var $: any;
@Component({
  selector: 'app-more-detail-product',
  templateUrl: './more-detail-product.component.html',
  styleUrls: ['./more-detail-product.component.css']
})
export class MoreDetailProductComponent implements OnInit {

  id: any;
  product: any;
  breadcrumb = breadcrumb.detail_product;
  url: any;
  schedules = [];
  reversedSchedules = [];
  productMapAddress: ProductMapAddress;
  loaded:boolean;
  adminId: any;
  previousUrl: string;
  urlPrevious: any;

 zoom = 14;

  address: { latitude: number; longitude: number; country: any; town: any; address: any; };
  authorizations: number;
  value: any;
  img: any;
  private _album=[];
  rooms: any;
  bedrooms=[];
  bathroom=[];
  kitchen=[];
  sojourn=[];
  
  constructor(
    private route: ActivatedRoute,
    private serviceAdmin: AdminService,
    private lightbox: Lightbox
  ) {

    this.id = this.route.snapshot.params.id;
  
  }
  loadedMagnificGallery = false;

  ngOnInit() {
    this.informationAdmin();
    this.urlPrevious=null;
   

   
  }

  informationAdmin(): void {
    this.serviceAdmin.$admin.then((admin:any)=>{
     this.adminId = admin.id;
     this.getOneProduct(this.adminId,this.route.snapshot.params.id)
    });
   }


  getOneProduct(adminId,productId) {
    this.loaded=false;
    this.serviceAdmin.getOneProduct(adminId,productId).then((data: any) => {

      this.product = data;
      console.log('products',this.product);
      //rooms
      if(this.product.rooms){

        this.product.rooms.forEach(element => {
          if(element.name=="bedrooms"){
            this.bedrooms.push(element);
          }

          if(element.name=="bathroom"){
            this.bathroom.push(element);
          }

          if(element.name=="kitchen"){
            this.kitchen.push(element);
          }

          if(element.name=="sojourn"){
            this.sojourn.push(element);
          }

          
        });

       
      }
     
      
      this.loaded = true;
      console.log('products',this.bathroom);
      if(this.product.settings && this.product.settings.value ){
        this.authorizations= this.product.settings.value['house-rules'].authorizations;
        this.value=this.product.settings.value;
      }
   
      
     
      if (this.product.latitude && this.product.longitude) {
        this.address = {
            latitude: parseFloat(this.product.latitude),
            longitude: parseFloat(this.product.longitude),
            country: this.product.country,
            town: this.product.town,
            address: this.product.adress
        };

      
    }

    let schedules = data.unavailable_dates;
    if(schedules){
      this.schedules = schedules.slice();
      this.reversedSchedules = schedules.reverse();
    }
      
  // console.log('product',this.product); 
    }, err => {
      console.log('erreur', err)
    })
  }




  getUrlPrevious(e){
    // console.log('ulr-------------',e);
    this.urlPrevious=e;
  }

  getOptionsCeiledLength() {
    return Math.ceil(this.product.equipments.length / 2);
}
getImage(img){
  this._album.push({src:img,thumb:img});
  this.lightbox.open(this._album,0);
}


}






