import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-shapart-view',
  templateUrl: './forgot-password-shapart-view.component.html',
  styleUrls: ['./forgot-password-shapart-view.component.css']
})
export class ForgotPasswordShapartViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
