import {Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductMapAddress } from 'src/app/models/product.model';
import { AdminService } from '../../services/admin.service';
import * as  datatable from '../../json/datatable/datatable_products.json';
import { FormBuilder } from '@angular/forms';
import { ConfirmationDialogService } from '../../common/notifications/notification.service';
@Component({
  selector: 'app-detail-product',
  templateUrl: './detail-product.component.html',
  styleUrls: ['./detail-product.component.css']
})
export class DetailProductComponent implements OnInit {

  id: any;
  product: any;
  breadcrumb = breadcrumb.detail_product;
  url: any;

  productMapAddress: ProductMapAddress;
  loaded = false;
  spinner:boolean;
  status: any[];
  list_status=datatable.products.status;
  adminId: any;
  MotifForm: any;
  motif=[];
  staticAlertClosed=false;
  montant={ca:0,gain_owner:0,nbr_reservation:0,nbr_refund:0,nbr_transaction:0,total_refund:0};
  modalReference: NgbModalRef;
  update_spinner=false;
  constructor(private toastr: ToastrService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public config: NgbModalConfig,
    private fb: FormBuilder,
    private dialog :ConfirmationDialogService
  ) {

    this.id = this.route.snapshot.params.id;
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
  this.informationAdmin();
    this.angForm();

  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;     
     this.getOneProduct(this.adminId,this.route.snapshot.params.id)
    });
   }


  getOneProduct(adminId,productId) {
    this.adminService.getOneProduct(adminId,productId).then((data: any) => {
      this.product = data;
      this.loaded = true;
      console.log('product', this.product);
      this.getSatus(this.product.status);
      this.getMotif(this.product.motif_rejected);
    }, err => {
      console.log('erreur', err)
    })
  }



updateProduct(type){
  let data={
    _method:"PUT",
    type:type
  }
  this.adminService.updateProductAdmin(this.adminId,this.route.snapshot.params.id,data).then((data:any)=>{
    this.product=data;
    this.getSatus(this.product.status);
    if(type==="Activate"){
      this.toastr.success('Bien activé avec succès', 'Success');
    }else if(type==="Disabled"){
      this.toastr.success('Bien déactivé avec succès', 'Success');
    }else if(type==="Validate"){
      this.toastr.success('Bien validé avec succès', 'Success');
    }
    this.spinner=false;
},err=>{
  console.log('Err',err);
});
}
 

  validateOrDisabledOrActivedProduct(type:string){
    this.spinner=true;
    
  
    let messages = [{
      type: "Activate",
      title: "Activer le bien",
      message: 'Voulez vous activer ce bien?'
    },
    {
      type: "Disabled",
      title: "Désactiver le bien",
      message: 'Voulez vous désactiver ce bien?'
    },
    {
      type: "Validate",
      title: "Valider le bien",
      message: 'Voulez vous valider ce bien?'
    }]
    this.spinner=true;
   
    let m=messages.find(element=> element.type==type);
  
    this.dialog.confirm(m.title,m.message).then(reponse=>{
      if(reponse){
        this.updateProduct(type);
      }else{
        this.spinner=false;
      }
    });
   
  }

  openDoc(content_doc, url) {

  this.modalService.open(content_doc, { centered: true, size: 'lg' });
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  getSatus(status) {
    this.status = [];
    let s=  this.list_status.find(e=>e.value===status)
      this.status.push(s.class);
      this.status.push(s.text);
  }


  openModalRejected(rejectedContent){
    this.modalReference =    this.modalService.open(rejectedContent, {  size: 'lg', scrollable: true, backdropClass: 'light-blue-backdrop',windowClass: 'appart-modal'  });

  }



  angForm(){
    this.MotifForm = this.fb.group({
      doc_address: [],
      photo:[],
      note: [],
      identity:[],
      title_property:[],
      photo_product:[]
    });
  }

  rejectProduct(){
    this.update_spinner=true;
    let data={
      type:"Rejected",
      _method:"PUT",
      note:this.MotifForm.value.note,
      doc_address:"",
      photo:"",
      title_property:"",
      photo_product:"",
      identity:this.MotifForm.value.identity
  }
  if(this.MotifForm.value.doc_address){
    data.doc_address="doc_address_not_found"
  }
  if(this.MotifForm.value.photo){
    data.photo="not_photo"
  }
  if(this.MotifForm.value.title_property){
    data.title_property="title_property"
  }
  if(this.MotifForm.value.photo_product){
    data.photo_product="photo_product"
  }
 
  this.adminService.updateProductAdmin(this.adminId,this.route.snapshot.params.id,data).then((data:any)=>{
    this.update_spinner=false;
    this.product=data;
    // console.log('data',data);
    this.modalReference.close();
    this.getSatus(this.product.status);
    this.getMotif(this.product.motif_rejected);
    this.toastr.success('Bien validé avec succès', 'Success');
  });
  // console.log('data',data);
  }

  getMotif(motif){
    if(motif){
      this.motif = motif.split("@");
      this.motif= this.motif.filter(item => item);
      // console.log('ici',this.motif);
      this.staticAlertClosed=false;
    }else{
      this.staticAlertClosed=true;
    }
      
    }

  
    
}
