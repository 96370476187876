import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductsService} from '../../../../services/products/products.service';
import {AppService} from '../../../../services/app/app.service';
import {AppConfig, CurrencyModel} from '../../../../models/app.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
interface PriceRates {
    dailyPrice: number;
    nbrDays?: number;
    basePrice?: number;
    discount?: number;
    discountPercentage?: string;
    fees: number;
    agentFees: number;
    gain: number;
    gainWithoutDiscount?: number;
    cleaningFees: number;
    insuranceFees: number;
}
@Component({
    selector: 'app-schedule-room-form',
    templateUrl: './schedule-room-form.component.html',
    styleUrls: ['./schedule-room-form.component.scss']
})
export class ScheduleRoomFormComponent implements OnInit, OnDestroy {

    @Input() formMode = 'create';
    formRoom: FormGroup;
    formRoomSimulation: FormGroup;
    submitted;
    errors: any;
    product: any;

    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    appConfigSubscription: Subscription;

    simulationModal: NgbModalRef;

    schedules = [];
    loading = true;
    loaded = false;
    productId;
    priceRates: any;

    rangeConfig: any = {
        connect: true,
        margin: 1,
        range: {
            min: 0,
            max: 90
        },
        step: 1,
    };
    discountsArr = [
        {
            name: '3_7',
            text: '4-7 jours',
            max: 100
        },

        {
            name: '7_21',
            text: '8-21 jours',
            max: 100
        },

        {
            name: '21_X',
            text: '+21 jours',
            max: 100
        },
    ];

    antDiscountsArr = [
        {
            name: '15_30',
            text: '15-30 jours',
            max: 100
        },

        {
            name: '30_60',
            text: '31-60 jours',
            max: 100
        },

        {
            name: '60_90',
            text: '61-90 jours',
            max: 100
        },

        {
            name: '90_X',
            text: '+90 jours',
            max: 100
        },
    ];

    simDiscountsArr = [
        {
            name: '3_7',
            text: 'entre 4 et 7 jours',
            min: 4,
            max: 7
        },

        {
            name: '7_21',
            text: 'entre 8 et 21 jours',
            min: 8,
            max: 21
        },

        {
            name: '21_X',
            text: 'plus de 21 jours',
            min: 22,
            max: 365
        },
    ];

    simAntDiscountsArr = [
        {
            name: '15_30',
            text: 'entre 15 et 30 jours',
            min: 15,
            max: 30
        },

        {
            name: '30_60',
            text: 'entre 31 et 60 jours',
            min: 31,
            max: 60
        },

        {
            name: '60_90',
            text: 'entre 61 et 90 jours',
            min: 61,
            max: 90
        },

        {
            name: '90_X',
            text: 'plus de 90 jours',
            min: 91,
            max: 365
        },
    ];
    private minPrice: number;
    private maxPrice: number;
    private currency: string;


    constructor(private route: ActivatedRoute,
                private router: Router,
                private productService: ProductsService,
                private authService: AuthService,
                private formBuilder: FormBuilder,
                private toastr: ToastrService,
                private fb: FormBuilder,
                private appService: AppService,
                private modalService: NgbModal) { }

    ngOnInit() {
        this.productId = this.route.parent.snapshot.params.id;
        this.initForm();

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {

                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {

                                    if (product) {
                                        this.appConfigSubscription = this.appService.appConfigSubject.subscribe(
                                            (config: AppConfig) => {

                                                this.product = product;
                                                this.minPrice = this.getPricing().min;
                                                this.maxPrice = this.getPricing().max;

                                                if (product.settings && product.settings.value.booking_constrains_time) {
                                                    this.formRoom.controls.booking_constrains_time.setValue(product.settings.value.booking_constrains_time);
                                                }

                                                if (product.base_price && !this.loaded) {
                                                    this.formRoom.controls.daily_price.setValue(product.base_price);
                                                } else if (!this.loaded) {

                                                    this.formRoom.controls.daily_price.setValue(this.minPrice);
                                                }

                                                if (product.currency && !this.loaded) {
                                                    this.formRoom.controls.currency.setValue(product.currency);
                                                } else if (!this.loaded) {
                                                    this.formRoom.controls.currency.setValue(this.authUser.currency);
                                                }

                                                if (product.base_cleaning_fees && !this.loaded) {
                                                    this.formRoom.controls.cleaning_fees.setValue(product.base_cleaning_fees);
                                                } else if (!this.loaded) {
                                                    this.formRoom.controls.cleaning_fees.setValue(0);
                                                }

                                                if (product.settings && product.settings.value.discount_rates) {
                                                    this.formRoom.controls.discount_rates.patchValue(product.settings.value.discount_rates);
                                                }

                                                if (product.settings && product.settings.value.ant_discount_rates) {
                                                    this.formRoom.controls.ant_discount_rates.patchValue(product.settings.value.ant_discount_rates);
                                                }

                                                if (product.settings && product.settings.value.has_ant_discounts) {
                                                    this.formRoom.controls.has_ant_discounts.setValue(product.settings.value.has_ant_discounts);
                                                }

                                                if (product.settings && product.settings.value.has_discounts) {
                                                    this.formRoom.controls.has_discounts.setValue(product.settings.value.has_discounts);
                                                }

                                                if (product.settings && product.settings.value.has_subscribed_insurance) {
                                                    this.formRoom.controls.has_subscribed_insurance.setValue(product.settings.value.has_subscribed_insurance);
                                                } else {
                                                    this.formRoom.controls.has_subscribed_insurance.setValue(false);
                                                }
                                                const currentCurrency = this.appService.getCurrency(config.currency);

                                                if (currentCurrency.min > Number(this.formRoom.get('daily_price').value)) {
                                                    this.formRoom.controls.daily_price.setValue(currentCurrency.min);
                                                }

                                                if (currentCurrency.max < Number(this.formRoom.get('daily_price').value)) {
                                                    this.formRoom.controls.daily_price.setValue(currentCurrency.min);
                                                }

                                                if (currentCurrency.max < Number(this.formRoom.get('cleaning_fees').value)) {
                                                    this.formRoom.controls.cleaning_fees.setValue(currentCurrency.min);
                                                }

                                                this.currency = config.currency;

                                                this.loading = false;
                                                this.schedules = product.unavailable_dates ? product.unavailable_dates : [];

                                                if (!this.loaded && !this.priceRates) {
                                                    this.getPriceRates();
                                                }

                                            }
                                        );

                                        this.appService.emitAppConfig();
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                (error) => {

                                    console.log(error);
                                    if (this.formMode === 'create') {
                                        this.router.navigate(['/rooms/new']);
                                    }

                                    if (this.formMode === 'edit') {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();
        }
    }

    getSliderMessage() {
        let text = '';
        const min = this.formRoom.get('booking_constrains_time').value[0];
        const max = this.formRoom.get('booking_constrains_time').value[1];

        if (min === 0) {
            text += 'Pas de minimun <br><br>';
        } else {
            text += 'Au moins ' + min + (min > 1 ? ' jours' : ' jour') + '<br><br>';
        }

        text += 'Au plus ' + max + (max > 1 ? ' jours' : ' jour');

        return text;
    }


    openSimulationModal(content) {
        this.simulationModal = this.modalService.open(content, { size: 'lg', backdrop: 'static'});
    }

    getPriceRate(name) {
        return this.priceRates[name] ? Number(this.priceRates[name].val) : 0;
    }

    getTransformedPriceRates(withAgent = false): PriceRates {

        const dailyPrice = Number(this.formRoom.get('daily_price').value);
        const cleaningFees = Number(this.formRoom.get('cleaning_fees').value);
        const agentFees = dailyPrice * (this.getPriceRate('fees_agent_rate') / 100);
        const insuranceFees = dailyPrice * (this.getPriceRate('insurance_rate') / 100);
        const bankRates = this.priceRates.bank_fees ? this.priceRates.bank_fees.val : null;
        const fixedBankFees = this.appService.convertPrice(
            bankRates ? Number(bankRates.fixed_fees.internal) : 0,
            this.appService.appConfig.defaultCurrency,
            this.product.currency);
        const bankFees = bankRates ? (dailyPrice * (Number(bankRates.percentage_rates.internal) / 100)) + fixedBankFees : 0;

        const fees = dailyPrice * (this.getPriceRate('fees_host_rate') / 100) + bankFees;

        let gain = dailyPrice - fees;

        if (withAgent) {
            gain -= agentFees;
        }

        if (this.formRoom.controls.has_subscribed_insurance.value) {
            gain -= insuranceFees;
        }

        return {
            dailyPrice,
            cleaningFees,
            fees,
            agentFees,
            insuranceFees,
            gain
        };
    }

    getSimTransformedDiscount(): number {
        const nbrDays = Number(this.getSimDiscountNbrDays('discount_rates'));

        const dailyPrice = Number(this.formRoom.get('daily_price').value);

        const hasDiscounts = this.formRoomSimulation.controls.has_discounts.value;
        const hasAntDiscounts = this.formRoomSimulation.controls.has_ant_discounts.value;

        const rateAntDiscount = hasAntDiscounts ? Number(this.getSimDiscountRate('ant_discount_rates')) : 0;
        const rateDiscount = hasDiscounts ? Number(this.getSimDiscountRate('discount_rates')) : 0;
        const excludedDiscountDays = this.getSimExcludedDiscountDays();

        return dailyPrice * (nbrDays - excludedDiscountDays * (rateDiscount / 100) - nbrDays * (rateAntDiscount / 100));
    }

    getSimExcludedDiscountDays(): number {
        const criteria = this.formRoomSimulation.get('discount_rates').get('sim_discount_criteria').value;
        let excludedDiscountDays = 0;

        switch (criteria)  {
            case '3_7':
                excludedDiscountDays = 3;
                break;
            case '7_21':
                excludedDiscountDays = 5;
                break;
            case '21_X':
                excludedDiscountDays = 7;
                break;
        }
        return excludedDiscountDays;
    }

    getCharge(price: number, chargeName: string): number {
        return price * (this.getPriceRate(chargeName) / 100);
    }

    applyCharges(basePrice: number, withAgent = false) {
        const hasSubscribedInsurance = this.formRoom.controls.has_subscribed_insurance.value;
        const agentFees = withAgent ? this.getCharge(basePrice, 'fees_agent_rate') : 0;
        const insuranceFees = hasSubscribedInsurance ? this.getCharge(basePrice, 'insurance_rate') : 0;
        const bankRates = this.priceRates.bank_fees ? this.priceRates.bank_fees.val : null;
        const fixedBankFees = this.appService.convertPrice(
            bankRates ? Number(bankRates.fixed_fees.internal) : 0,
            this.appService.appConfig.defaultCurrency,
            this.product.currency);
        const bankFees = bankRates ? (basePrice * (Number(bankRates.percentage_rates.internal) / 100)) + fixedBankFees : 0;
        const feesHost = this.getCharge(basePrice, 'fees_host_rate');
        const fees = feesHost + bankFees;
        const total = feesHost + agentFees + bankFees + insuranceFees;

        return {
            fees,
            agentFees,
            insuranceFees,
            total
        };
    }

    getSimTransformedPriceRates(withAgent = false): PriceRates {
        const dailyPrice = Number(this.formRoom.get('daily_price').value);
        const nbrDays = Number(this.getSimDiscountNbrDays('discount_rates'));
        const originalPrice = dailyPrice * nbrDays;

        const basePrice = this.getSimTransformedDiscount();

        const cleaningFees = Number(this.formRoom.get('cleaning_fees').value);

        const charges = this.applyCharges(basePrice, withAgent);

        const gain = basePrice - charges.total + cleaningFees;
        const gainWithoutDiscount = this.getTransformedPriceRates(withAgent).gain * nbrDays;

        const discount = originalPrice - basePrice;

        const discountPercentage = (parseFloat(((discount / originalPrice) * 100).toString()).toFixed(2)).toString().replace('.', ',');

        return {
            dailyPrice,
            cleaningFees,
            fees: charges.fees,
            agentFees: charges.agentFees,
            insuranceFees: charges.insuranceFees,
            gain,
            gainWithoutDiscount,
            discount,
            nbrDays,
            discountPercentage,
            basePrice
        };
    }

    getSimDiscountRate(discountType: string) {
        const criteria = this.formRoomSimulation.get(discountType).get('sim_discount_criteria').value;

        return this.formRoom.get(discountType).get(criteria).value;
    }

    getSimDiscountNbrDays(discountType: string) {
        const criteria = this.formRoomSimulation.get(discountType).get('sim_discount_criteria').value;

        return this.formRoomSimulation.get(discountType).get(criteria).value;
    }

    checkUser() {
        if (this.authUser && this.product && this.product.published) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;
    }

    next() {
        return this.productService.getNextStep();
    }

    getPrice() {
        const price = this.formRoom.get('daily_price').value;

        return this.appService.transformCurrency(
            price ? price : this.minPrice,
            this.product.currency,
            null,
            false,
            this.product.price_unit
        );
    }

    getPriceRates() {
        this.appService.fetchPriceRates().then(
            (rates: any) => {
                this.priceRates = rates.value;
                this.loaded = true;
            }
        );
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    transformCurrency(price) {
        return this.appService.transformCurrency(price, this.product.currency, this.currency, false);
    }

    getPricing(): CurrencyModel {
        return this.appService.getCurrency(this.appService.appConfig.currency);
    }

    editPrice(qty: number) {

        if (this.checkUser()) {
            const currentVal = this.formRoom.get('daily_price').value;
            const increment = this.getPricing().increment;

            if (qty < 0 && Number(currentVal) - this.minPrice < increment) {
                qty = -1;
            }

            if (qty > 0 && this.maxPrice - Number(currentVal) < increment) {
                qty = 1;
            }

            const val: number = currentVal ? parseInt(currentVal.toString(), 10) + qty : qty;

            if (val >= this.getPricing().min && val <= this.getPricing().max) {
                this.formRoom.controls.daily_price.setValue(val);
            }
        }
    }

    editCleaningFees(qty: number) {
        if (this.checkUser()) {
            const currentVal = this.formRoom.get('cleaning_fees').value;
            const increment = this.getPricing().increment;

            if (qty < 0 && Number(currentVal) - this.minPrice < increment) {
                qty = -1;
            }

            if (qty > 0 && this.maxPrice - Number(currentVal) < increment) {
                qty = 1;
            }
            const val: number = currentVal ? parseInt(currentVal.toString(), 10) + qty : qty;

            if (val >= 0 && val <= this.getPricing().max) {
                this.formRoom.controls.cleaning_fees.setValue(val);
            }
        }
    }

    editDiscountRates(name: string, qty: number) {
        if (this.checkUser()) {
            const currentVal = this.formRoom.get('discount_rates').get(name).value;

            const val: number = currentVal ? parseInt(currentVal.toString(), 10) + qty : qty;

            if (val >= 0 && val <= 100) {
                this.formRoom.get('discount_rates').get(name).setValue(val);
            }
        }
    }

    editSimDiscountRates(discountType: string, name: string, qty: number) {
        const currentVal = this.formRoomSimulation.get(discountType).get(name).value;

        const val: number = currentVal ? parseInt(currentVal.toString(), 10) + qty : qty;

        const discount = this.getSimDiscount(discountType, name);
        if (discount && val >= discount.min && val <= discount.max) {
            this.formRoomSimulation.get(discountType).get(name).setValue(val);
            this.formRoomSimulation.get(discountType).get('sim_discount_criteria').setValue(name);
        }
    }

    getSimDiscount(discountType: string, name: string) {
        if (discountType === 'discount_rates') {
            return this.simDiscountsArr.find(
                discount => {
                    return discount.name === name;
                }
            );
        }

        if (discountType === 'ant_discount_rates') {
            return this.simAntDiscountsArr.find(
                discount => {
                    return discount.name === name;
                }
            );
        }

        return null;
    }

    editAntDiscountRates(name: string, qty: number) {
        if (this.checkUser()) {
            const currentVal = this.formRoom.get('ant_discount_rates').get(name).value;

            const val: number = currentVal ? parseInt(currentVal.toString(), 10) + qty : qty;

            if (val >= 0 && val <= 100) {
                this.formRoom.get('ant_discount_rates').get(name).setValue(val);
            }
        }
    }

    doSubmit() {
        this.submitted = true;
        if (this.formRoom.invalid) {
            return;
        }

        this.loading = true;
        const formData = {
            dailly_price: this.formRoom.value.daily_price,
            currency: this.currency,
            cleaning_fees: this.formRoom.value.cleaning_fees,
            has_ant_discounts: !!this.formRoom.value.has_ant_discounts,
            has_discounts: !!this.formRoom.value.has_discounts,
            discounts: this.formRoom.value.discount_rates,
            ant_discounts: this.formRoom.value.ant_discount_rates,
            has_subscribed_insurance: this.formRoom.value.has_subscribed_insurance,
            booking_constrains_time: this.formRoom.value.booking_constrains_time
        };

        this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
            () => {
                this.loading = false;

                if (this.formMode === 'create') {

                    this.router.navigate([this.next()]).then(
                        () => {
                            this.toastr.success('Informations de location modifiées');
                        }
                    );
                }

                if (this.formMode === 'edit') {
                    this.toastr.success('Les modifications on été enregistrées');

                }

            },
            (errors: any) => {
                this.loading = false;
                this.errors = errors.error.error;

            }
        );
    }

    initForm() {
        this.formRoom = this.fb.group({
            daily_price: [1, [Validators.required, Validators.min(1)]],
            currency: ['', [Validators.required]],
            cleaning_fees: ['', [Validators.required, Validators.min(0)]],
            has_ant_discounts: [''],
            has_discounts: [''],
            has_subscribed_insurance: [''],
            discount_rates: this.fb.group({
                '3_7': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
                '7_21': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
                '21_X': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
            }),

            ant_discount_rates: this.fb.group({
                '15_30': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
                '30_60': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
                '60_90': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
                '90_X': [0, [Validators.required, Validators.min(0), Validators.max(100)]],
            }),
            booking_constrains_time: [[0, 90]],
        });

        this.formRoomSimulation = this.fb.group({
            has_discounts: [true],
            has_ant_discounts: [true],
            discount_rates: this.fb.group({
                sim_discount_criteria: ['3_7'],
                '3_7': [4, [Validators.required, Validators.min(4), Validators.max(7)]],
                '7_21': [8, [Validators.required, Validators.min(8), Validators.max(21)]],
                '21_X': [22, [Validators.required, Validators.min(22)]],
            }),

            ant_discount_rates: this.fb.group({
                sim_discount_criteria: ['15_30'],
                '15_30': [15, [Validators.required, Validators.min(15), Validators.max(30)]],
                '30_60': [31, [Validators.required, Validators.min(31), Validators.max(60)]],
                '60_90': [61, [Validators.required, Validators.min(61), Validators.max(90)]],
                '90_X': [91, [Validators.required, Validators.min(91)]],
            }),
        });
    }

    get f() {
        return this.formRoom.controls;
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
