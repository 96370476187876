import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../json/breadcrumb.json';
import { AdminService } from '../services/admin.service';
import { GetAdmin } from '../model/getadmin';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit,GetAdmin {
  breadcrumb = breadcrumb.dashboard;
  dashboard: any;
  admin: any;
  categories: any;
  constructor(public adminService: AdminService) { }

  

  ngOnInit() {
    this.adminService.setTitle('Dashbord');
    this.adminService.getDashboard().then((data:any)=>{
      this.dashboard=data.data;
      console.log('dashbord',this.dashboard);
    },err=>{
        console.log('Erreur',err);
    });
      this.informationAdmin();
  }

  

  informationAdmin(): void {
    this.adminService.$admin.then((data:any)=>{
      this.admin=data;
        this.getAllCategories(this.admin.id);
    },err=>{
      console.log('err',err);
    });
  }

private  getAllCategories(id){
    this.adminService.getAllCategories(id).then((categories:any)=>{
      this.categories=categories.data;
    },err=>{
      console.log('err',err);
    })
  }
}
