import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-room',
  templateUrl: './property-room.component.html',
  styleUrls: ['./property-room.component.css']
})
export class PropertyRoomComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
