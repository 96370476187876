import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {CategoriesService} from '../../services/categories/categories.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';
import {AppService} from '../../services/app/app.service';
import * as countries from 'i18n-iso-countries';
declare var require: any;

declare var $: any;

@Component({
    selector: 'app-welcome-shapart',
    templateUrl: './welcome-shapart.component.html',
    styleUrls: ['./welcome-shapart.component.css']
})
export class WelcomeShapartComponent implements OnInit, OnDestroy {

    categories: any;
    adress: any;
    categoriesSubscription: Subscription;
    tokenExistSubscription: Subscription;
    countries: any;

    constructor(private title: Title,
                private categoriesService: CategoriesService,
                private authService: AuthService,
                private appService: AppService) {}

    ngOnInit() {
        this.title.setTitle('Shapart');

        countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

        this.countries = countries.getNames('fr');

        this.tokenExistSubscription = this.authService.tokenExistSubject.subscribe(
            (tokenExist: boolean) => {

                if (tokenExist) {
                    this.categoriesSubscription = this.categoriesService.forwardedProductByCategoriesSubject.subscribe(
                        (categories: any) => {
                            if (categories) {
                                this.categories = categories;
                            }

                        },
                    );

                    this.categoriesService.getForwardedProductByCategories();
                    this.categoriesService.emitForwardedProductByCategories();

                }
            }
        );

        this.authService.emitTokenExist();
    }

    getCategoryNameByTag(tag) {
        return this.categoriesService.getCategoryNameByTag(tag);
    }

    getPrice(product) {
        return this.appService.transformCurrency(product.dailly_price, product.currency, null, true, product.price_unit);
    }

    ngOnDestroy() {

        if (this.categoriesSubscription) {
            this.categoriesSubscription.unsubscribe();

        }

        if (this.tokenExistSubscription) {
            this.tokenExistSubscription.unsubscribe();
        }
    }

}
