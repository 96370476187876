import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UploadedImage } from 'src/app/models/Image';
import { AdminService } from 'src/app/admin/services/admin.service';
@Component({
    selector: 'app-photo-admin',
    templateUrl: './photo-admin.component.html',
    styleUrls: ['./photo-admin.component.css']
})
export class PhotoAdminComponent implements OnInit {
    user: any;
    breadcrumb=breadcrumb.photo_admin;
    photo: any;
    operations=[];
    fileIsUploading = false;
    userSubscription: Subscription;
    upLoadPhotoFormData: { key: string, value: any }[] = [];
    upLoadedPhoto: UploadedImage;

   



    constructor(private serviceAdmin :AdminService,
                private route: ActivatedRoute){ }

    ngOnInit() {
        let data={
            breadcrumb:this.breadcrumb,
            id:this.route.snapshot.paramMap.get('id'),
            title:"Modifier la photo de l'administrateur",
            tag:"photo"
          }
        setTimeout(() => {
            this.serviceAdmin.sendBreadcrumb(data);
        }, 1000);

        this.getAdmin(this.route.snapshot.paramMap.get('id'))
      

    }

   

  protected  getAdmin(id) {
        this.serviceAdmin.getOneAdmin(id).then((admin: any) => {
                             this.upLoadPhotoFormData = [
                            {
                                key: 'model_name',
                                value: 'admin',
                            },
                            {
                                key: 'file_name',
                                value: 'photo',
                            },
                            {
                                key: 'model_id',
                                value:admin.id
                            }
                        ];
                        if (admin.has_photo) {
                          
                            this.upLoadedPhoto = {
                                link: admin.photo,
                                hasUploaded: true,
                                uploadName: 'photo'
                            };
                           
                            }
        }, err => {
          console.log('erreur', err);
        });
      }


}
