import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-filter-ranking',
  templateUrl: './filter-ranking.component.html',
  styleUrls: ['./filter-ranking.component.css']
})
export class FilterRankingComponent implements OnInit {

  @Input() data: any;

  arrayQuery=[];
  arrayQueryRadio=[];
  filter=[];
  hideRadio=[];
  countChecked=0
  radio: any;


  constructor( private route: ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        console.log('params', params)
       
      });
  }

  changeFilter(event, type){
  
    if (event.target.checked) {
      this.filter.push([type]);
    } else {
      for (var i = 0; i < this.filter.length; i++) {
        if (this.filter[i] == type) {
          this.filter.splice(i, 1);
        }
      }
    } 
 
  }



 // radio  type
  onRadioChange(queryParams,event,data){  
      if(queryParams){
        let t =this.arrayQueryRadio.find(e=>e.key==queryParams);
        if(t){
          t.value=event.target.value;
        }else{
          this.arrayQueryRadio.push({ key: queryParams,value:event.target.value})
        }
      }

      console.log('tt', data);
  }

  // multi type
  onCheckboxChange(data,status, event){
  event.target.checked?this.countChecked=this.countChecked+1:this.countChecked=this.countChecked-1;
  if(data.queryParams){
    // let t =this.arrayQuery.find(e=>e.key==data.queryParams);
    // if(t){
    //   if (event.target.checked) {
    //       t.value.push(status.value);
         
    //   } else {
    //   for (var i = 0; i <  t.value.length; i++) {
    //     if (t.value[i] == status.value) {
    //       t.value.splice(i, 1);
    //     }
    //   }
    // }
    // }else{
    //   this.arrayQuery.push({ key: data.queryParams,value:[status.value]});
    // }
   
    let t =this.arrayQuery.find(e=>e.key==data.queryParams);
        if(t){
          t.value=event.target.value;
        }else{
          this.arrayQuery.push({ key: data.queryParams,value:event.target.value})
        }
  }


  this.data.radio.forEach(element => {
  
    if (event.target.checked) {
      this.radio =element.data.filter(e=>e.hide!==status.value);
    console.log('element',this.radio)
    }else{
      // this.radio.push(element.data.filter(e=>e.hide==status.value))
      console.log(element.data.filter(e=>e.hide==status.value))
    }
       
    

    
  });
   
  
  }



 














  sendFilter() {
    const queryParams: any = {};
 

    if(this.arrayQuery.length){
      this.arrayQuery.forEach(element => {
        if(element.value.length){
          queryParams[element.key]=element.value.toString();
        }
      });
    }

    if(this.arrayQueryRadio.length){
      this.arrayQueryRadio.forEach(element => {
          queryParams[element.key]=element.value.toString();  
      });
    }
   
    const navigationExtras: NavigationExtras = {queryParams};
    this.router.navigate([location.pathname], navigationExtras).then(()=>{
      this.arrayQuery=[];
      this.arrayQueryRadio=[];
      this.filter=[];
    });
  }


 
  Close(){
    const navigationExtras: NavigationExtras = {};
    this.router.navigate([location.pathname], navigationExtras).then(()=>{
   
      this.arrayQuery=[];
      this.arrayQueryRadio=[];
      this.filter=[];
    });
  }


 


  


}
