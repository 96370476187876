import { Component, OnInit, Input } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
declare var $:any;
@Component({
  selector: 'app-items-appart',
  templateUrl: './items-appart.component.html',
  styleUrls: ['./items-appart.component.css']
})
export class ItemsAppartComponent implements OnInit  {
  @Input() name: any;
  @Input() value: any;
  @Input() img:any;
  @Input() currency:any;
  @Input() message:any;
  @Input() title:any;
  @Input() link:any;

  empty: any;
  photo_name: any;
  title_name: string;
  private _album=[];


  constructor(private lightbox: Lightbox) { }

  ngOnInit() {
  
     this.empty= "<em>Aucun(e)</em>";
     this.photo_name="<img width= 80 height=80 class='rounded-circle cursor' src="+this.img+ ">"+ "<span>"+this.value+"</span>";
     this.title_name="<h3>"+this.title+"<h3>";
  }


  onClickImage(e,t){
  this._album.push({src:e,caption:t,thumb:e});
  this.lightbox.open(this._album,0);
  }




 
}
