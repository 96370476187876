import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../models/auth-user.model';
import {AuthService} from '../../../services/auth/auth.service';
import {ProductsService} from '../../../services/products/products.service';
import {BookingService} from '../../../services/bookings/booking.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../../services/app/app.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder} from '@angular/forms';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-booking-payment',
    templateUrl: './booking-payment.component.html',
    styleUrls: ['./booking-payment.component.css']
})
export class BookingPaymentComponent implements OnInit, OnDestroy {
    userSubscription: Subscription;
    authUser: AuthUser;
    bookingId: string | number |null;
    booking: any;
    product: any;
    loaded = false;
    loading: boolean;
    submittedPayment: boolean;
    success: boolean;
    selectionId: string | number | null;
    constructor(private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService,
                private fb: FormBuilder,
                private authService: AuthService,
                private appService: AppService,
                private bookingService: BookingService) { }

    ngOnInit() {
        this.loadStripe();

        this.userSubscription = this.authService.userSubject.subscribe(
            (authUser: AuthUser) => {
                if (authUser) {
                    this.authUser = authUser;
                    this.route.queryParamMap.subscribe(
                        params => {
                            if (params.get('booking_id') && !this.booking) {
                                this.bookingId = params.get('booking_id');
                                this.getUserBooking();
                            } else if (params.get('selection_id')) {
                                this.selectionId = params.get('selection_id');
                                this.getUserSelectionBookingDetails();
                            }
                        }
                    );
                }
            }
        );

        this.authService.emitUser();

    }


    saveBooking(params) {
        this.bookingService.saveClientDemandReservation(this.authUser.id, this.bookingId, params).then(
            (booking: any) => {
                this.loading = false;
                this.submittedPayment = false;
                this.success = true;
            },

            () => {
                this.loading = false;
                this.submittedPayment = false;
            }
        );
    }

    openFormPayment() {
        this.loading = true;

        const handler = (window as any).StripeCheckout.configure({
            key: environment.keyStripe,
            locale: 'fr-FR',
            token: async token => {

                this.submittedPayment = true;
                const queryParams: any = {};
                queryParams.stripeToken = token.id;
                queryParams.email = token.email;
                this.saveBooking(queryParams);

            }
        });

        const convertedTotal = Number(
            this.appService.convertPrice(
                this.booking.total,
                this.product.currency
            )
        );

        const total = Math.round(convertedTotal);

        handler.open({
            name: 'Shapart',
            description: 'Payer ma réservation',
            currency: this.appService.appConfig.currency,
            amount: total,
            closed: () => {
                if (!this.submittedPayment) {
                    this.loading = false;
                }
            }
        });

    }

    getUserSelectionBookingDetails() {
        this.bookingService.getUserSelectionBookingDetails(this.authUser.id, this.selectionId).then(
            (bookingDetails: any) => {
                this.productService.getProduct(bookingDetails.product_id).then(
                    (product) => {
                        if (!bookingDetails.is_available) {
                            this.redirectionFailure();
                            this.appService.setAppTitle('Payer ma réservation');
                        }

                        this.booking = bookingDetails;
                        this.product = product;
                        this.loaded = true;
                    },
                    () => {
                        this.redirectionFailure();
                    }
                );
            }
        );
    }

    getUserBooking() {
        this.bookingService.getUserBooking(this.authUser.id, 'clients', this.bookingId, {}, 'demands').then(
            (booking: any) => {
                this.productService.getProduct(booking.product_id).then(
                    (product) => {

                        if (booking.status !== 'accepted') {
                            this.redirectionFailure();
                        }

                        this.booking = booking;
                        this.product = product;
                        this.loaded = true;
                    },
                    () => {
                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                    }
                );
            },
            () => {
                this.router.navigateByUrl('not-found', {skipLocationChange: true});
            }
        );
    }

    redirectionFailure() {
        this.router.navigate(['/']).then(
            () => {
                this.toastr.error('Votre demande n\'a pas pu aboutir rééssayez plus tard svp');
            }
        );
    }


    loadStripe() {
        if (!window.document.getElementById('stripe-script')) {
            const s = window.document.createElement('script');
            s.id = 'stripe-script';
            s.type = 'text/javascript';
            s.src = 'https://checkout.stripe.com/checkout.js';
            window.document.body.appendChild(s);
        }
    }

    ngOnDestroy(): void {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

}
