import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-amount',
  templateUrl: './filter-amount.component.html',
  styleUrls: ['./filter-amount.component.css']
})
export class FilterAmountComponent implements OnInit {
  amountChange: any;
  SupOrInfOrEquals: string;
  StartEnd=[];
  result:string;
  @Output() SendAmount = new EventEmitter<any>(); 

  constructor() { }

  ngOnInit() {
  }
  selectAmount(e)
  {
    this.amountChange=e;
  }

  ChangeAmount(e,type){
    this.SupOrInfOrEquals=null;
    this.SupOrInfOrEquals=type+','+e
  
    if(this.SupOrInfOrEquals){
      this.result=this.SupOrInfOrEquals;
    }
    this.SendAmount.emit(this.result);
    // console.log('element',this.result);
  }

  ChangeAmountStartOREnd(e,type){
    this.StartEnd[0]='isAmountBetween';
    if(type==='Start'){
      this.StartEnd[1]=e;
    }else if(type==='End'){
      this.StartEnd[2]=e;
    }
    if(this.StartEnd.length==3){
      this.result=this.StartEnd.toString();
    }
    this.SendAmount.emit(this.result);
    // console.log('element',this.result);
  }

}
