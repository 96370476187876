import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import { HeaderShapartComponent } from '../templates/header-shapart/header-shapart.component';
import { FooterShapartComponent } from '../templates/footer-shapart/footer-shapart.component';
import { WelcomeShapartComponent } from './welcome-shapart/welcome-shapart.component';
import { AuthShapartComponent } from './auth-shapart/auth-shapart.component';
import { LoginShapartComponent } from './auth-shapart/login-shapart/login-shapart.component';
import { LoginShapartViewComponent } from './auth-shapart/login-shapart/login-shapart-view/login-shapart-view.component';
import { DetailProductComponent } from './products/detail-product/detail-product.component';
import { RouterModule } from '@angular/router';
import { RegisterShapartComponent } from './auth-shapart/register-shapart/register-shapart.component';
import { RegisterShapartViewComponent } from './auth-shapart/register-shapart/register-shapart-view/register-shapart-view.component';
import { ForgotPasswordShapartComponent } from './auth-shapart/forgot-password-shapart/forgot-password-shapart.component';
import { ForgotPasswordShapartViewComponent } from './auth-shapart/forgot-password-shapart/forgot-password-shapart-view/forgot-password-shapart-view.component';
import { NewPasswordShapartComponent } from './auth-shapart/new-password-shapart/new-password-shapart.component';
import { NewPasswordShapartViewComponent } from './auth-shapart/new-password-shapart/new-password-shapart-view/new-password-shapart-view.component';
import { AgmService } from '../services/agm/agm.service';
import { SearchBarApartComponent } from '../templates/search-bar-apart/search-bar-apart.component';
import { ContactComponent } from './contact/contact.component';
import { AccountComponent } from './account/account.component';
import { HomeAccountComponent } from './account/home-account/home-account.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NgxLoadingModule } from 'ngx-loading';
import { MenuAccountComponent } from './account/menu-account/menu-account.component';
import { CollapseAccountComponent } from './account/collapse-account/collapse-account.component';
import { ProfileAccountComponent } from './account/profile-account/profile-account.component';
import { RoomsListComponent } from './account/rooms-list/rooms-list.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaginationComponent } from '../templates/pagination/pagination.component';
import {WebcamModule} from 'ngx-webcam';
import { ResultSearchWithoutMapComponent } from './search/result-search-without-map/result-search-without-map.component';
import { NewRoomComponent } from './rooms/new-room/new-room.component';
import { AppTemplateComponent } from './app-template/app-template.component';
import { CategoryRoomComponent } from './rooms/category-room/category-room.component';
import { ImagesRoomComponent } from './rooms/images-room/images-room.component';
import { ScheduleRoomComponent } from './rooms/schedule-room/schedule-room.component';
import { ButtonsWizardComponent } from './rooms/buttons-wizard/buttons-wizard.component';
import { TemplateWizardComponent } from './rooms/template-wizard/template-wizard.component';
import { HeaderWizardComponent } from './rooms/header-wizard/header-wizard.component';
import { BedsRoomComponent } from './rooms/beds-room/beds-room.component';
import { OptionsRoomComponent } from './rooms/options-room/options-room.component';
import { LocationRoomComponent } from './rooms/location-room/location-room.component';
import { SettingsAccountComponent } from './account/settings-account/settings-account.component';
import { PasswordForgottenComponent } from './account/settings-account/password-forgotten/password-forgotten.component';
import { VerifyEmailComponent } from './auth-shapart/verify-email/verify-email.component';
import { RoomDetailsComponent } from './account/rooms-list/room-details/room-details.component';
import { RoomEditCategoryComponent } from './account/rooms-list/room-edit-category/room-edit-category.component';
import { NavManagerComponent } from '../templates/nav-manager/nav-manager.component';
import { RoomEditBedroomsComponent } from './account/rooms-list/room-edit-bedrooms/room-edit-bedrooms.component';
import { RoomEditPreviewComponent } from './account/rooms-list/room-edit-preview/room-edit-preview.component';
import { RoomEditOptionsComponent } from './account/rooms-list/room-edit-options/room-edit-options.component';
import { RoomEditLocationComponent } from './account/rooms-list/room-edit-location/room-edit-location.component';
import { RoomEditPropertyComponent } from './account/rooms-list/room-edit-property/room-edit-property.component';
import { RoomEditImagesComponent } from './account/rooms-list/room-edit-images/room-edit-images.component';
import { RoomEditScheduleComponent } from './account/rooms-list/room-edit-schedule/room-edit-schedule.component';
import { CategoryRoomFormComponent } from './rooms/forms/category-room-form/category-room-form.component';
import { RoomEditSettingsComponent } from './account/rooms-list/room-edit-settings/room-edit-settings.component';
import { BedsRoomFormComponent } from './rooms/forms/beds-room-form/beds-room-form.component';
import { OptionsRoomFormComponent } from './rooms/forms/options-room-form/options-room-form.component';
import { LocationRoomFormComponent } from './rooms/forms/location-room-form/location-room-form.component';
import { ImagesRoomFormComponent } from './rooms/forms/images-room-form/images-room-form.component';
import { ScheduleRoomFormComponent } from './rooms/forms/schedule-room-form/schedule-room-form.component';
import { FooterShapartSmallComponent } from '../templates/footer-shapart-small/footer-shapart-small.component';
import { PropertyRoomComponent } from './rooms/property-room/property-room.component';
import { UserRoomComponent } from './rooms/user-room/user-room.component';
import { UserRoomInformationsComponent } from './rooms/user-room/user-room-informations/user-room-informations.component';
import { UserRoomIdentityComponent } from './rooms/user-room/user-room-identity/user-room-identity.component';
import { UserRoomPhotoComponent } from './rooms/user-room/user-room-photo/user-room-photo.component';
import { ProfileAccountFormComponent } from './account/profile-account/profile-account-form/profile-account-form.component';
import { CameraComponent } from '../templates/camera/camera.component';
import { CheckoutRoomComponent } from './rooms/checkout-room/checkout-room.component';
import { PropertyRoomOwnerComponent } from './rooms/property-room/property-room-owner/property-room-owner.component';
import { PropertyRoomDocumentsComponent } from './rooms/property-room/property-room-documents/property-room-documents.component';
import { ContractRoomComponent } from './rooms/contract-room/contract-room.component';
import { ContractRoomOwnerComponent } from './rooms/contract-room/contract-room-owner/contract-room-owner.component';
import { ContractRoomAgentComponent } from './rooms/contract-room/contract-room-agent/contract-room-agent.component';
import { ContractRoomTenantComponent } from './rooms/contract-room/contract-room-tenant/contract-room-tenant.component';
import { UsersSuggestionsComponent } from '../templates/users-suggestions/users-suggestions.component';
import { ContractRoomModelComponent } from './rooms/contract-room/contract-room-model/contract-room-model.component';
import { InvitationRoomComponent } from './rooms/invitation-room/invitation-room.component';
import { InvitationRoomPropertyComponent } from './rooms/invitation-room/invitation-room-property/invitation-room-property.component';
import { InvitationRoomPreviewComponent } from './rooms/invitation-room/invitation-room-preview/invitation-room-preview.component';
import { InvitationRoomContractComponent } from './rooms/invitation-room/invitation-room-contract/invitation-room-contract.component';
import { SelectedUserModelComponent } from './rooms/property-room/property-room-owner/selected-user-model/selected-user-model.component';
import { ProfileAgencyFormComponent } from './account/profile-account/profile-agency-form/profile-agency-form.component';
import { BecomeAgentComponent } from './agents/become-agent/become-agent.component';
import { SharingModule } from '../sharing/sharing.module';
import { RoomStatusBadgeComponent } from './account/rooms-list/partials/room-status-badge/room-status-badge.component';
import { ProfileUserComponent } from './profiles/profile-user/profile-user.component';
import { ProfileModelComponent } from './profiles/profile-model/profile-model.component';
import { ProfileAgencyComponent } from './profiles/profile-agency/profile-agency.component';
import { ROUTES } from './views.routing.module';
import { RoomEditHostComponent } from './account/rooms-list/room-edit-property/partials/room-edit-host/room-edit-host.component';
import { RoomEditIBookingComponent } from './account/rooms-list/room-edit-i-booking/room-edit-i-booking.component';
import { SearchComponent } from './search/search/search.component';
import { BookingTemplateComponent } from './booking/booking-template/booking-template.component';
import { BookingPaymentComponent } from './booking/booking-payment/booking-payment.component';
import { BookingDetailsComponent } from './account/bookings/booking-details/booking-details.component';
import { BookingDemandDetailsComponent } from './account/bookings/booking-demand-details/booking-demand-details.component';
import { BookingsListComponent } from './account/bookings/bookings-list/bookings-list.component';
import { BookingsDemandsListComponent } from './account/bookings/bookings-demands-list/bookings-demands-list.component';
import { BookingListModelComponent } from './account/bookings/booking-list-model/booking-list-model.component';
import { MessagesComponent } from './account/messages/messages.component';
import { ListBookingComponent } from './account/bookings/partials/list-booking/list-booking.component';
import { BookingDetailsModelComponent } from './account/bookings/booking-details-model/booking-details-model.component';
import { ContactHostComponent } from './products/contact-host/contact-host.component';
import { PaymentsComponent } from './account/payments/payments.component';
import { PaymentsPreferenceComponent } from './account/settings-account/payments-preference/payments-preference.component';
import { AccountSettingsComponent } from './account/settings-account/account-settings/account-settings.component';
import { BookingProductIdentityComponent } from './booking/partials/booking-product-identity/booking-product-identity.component';
import { BookingPriceDetailsComponent } from './booking/partials/booking-price-details/booking-price-details.component';
import { RulesRoomComponent } from './rooms/rules-room/rules-room.component';
import { RulesRoomFormComponent } from './rooms/forms/rules-room-form/rules-room-form.component';
import { RoomEditRulesComponent } from './account/rooms-list/room-edit-rules/room-edit-rules.component';
import { BookingCheckoutComponent } from './booking/partials/booking-checkout/booking-checkout.component';
import {ConfirmationDialogService} from '../admin/common/notifications/notification.service';
import { ProfileAccountVerifyComponent } from './account/profile-account/profile-account-verify/profile-account-verify.component';
import { VerifyIdentityComponent } from './account/profile-account/profile-account-verify/verify-identity/verify-identity.component';
import { VerifyUserPhotoComponent } from './account/profile-account/profile-account-verify/verify-user-photo/verify-user-photo.component';
import { VerifyIdentityStatusComponent } from './account/profile-account/profile-account-verify/verify-identity-status/verify-identity-status.component';

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [
        HeaderShapartComponent,
        FooterShapartComponent,
        WelcomeShapartComponent,
        AuthShapartComponent,
        DetailProductComponent,
        LoginShapartComponent,
        LoginShapartViewComponent,
        RegisterShapartComponent,
        RegisterShapartViewComponent,
        ForgotPasswordShapartComponent,
        ForgotPasswordShapartViewComponent,
        NewPasswordShapartComponent,
        NewPasswordShapartViewComponent,
        SearchBarApartComponent,
        ContactComponent,
        AccountComponent,
        HomeAccountComponent,
        MenuAccountComponent,
        CollapseAccountComponent,
        ProfileAccountComponent,
        RoomsListComponent,
        NotFoundComponent,
        PaginationComponent,
        ResultSearchWithoutMapComponent,
        NewRoomComponent,
        AppTemplateComponent,
        CategoryRoomComponent,
        ImagesRoomComponent,
        ScheduleRoomComponent,
        ButtonsWizardComponent,
        TemplateWizardComponent,
        HeaderWizardComponent,
        BedsRoomComponent,
        OptionsRoomComponent,
        LocationRoomComponent,
        SettingsAccountComponent,
        PasswordForgottenComponent,
        VerifyEmailComponent,
        FooterShapartSmallComponent,
        RoomDetailsComponent,
        RoomEditCategoryComponent,
        NavManagerComponent,
        RoomEditBedroomsComponent,
        RoomEditPreviewComponent,
        RoomEditOptionsComponent,
        RoomEditLocationComponent,
        RoomEditPropertyComponent,
        RoomEditImagesComponent,
        RoomEditScheduleComponent,
        CategoryRoomFormComponent,
        RoomEditSettingsComponent,
        BedsRoomFormComponent,
        OptionsRoomFormComponent,
        LocationRoomFormComponent,
        ImagesRoomFormComponent,
        ScheduleRoomFormComponent,
        PropertyRoomComponent,
        UserRoomComponent,
        UserRoomInformationsComponent,
        UserRoomIdentityComponent,
        UserRoomPhotoComponent,
        ProfileAccountFormComponent,
        CameraComponent,
        CheckoutRoomComponent,
        PropertyRoomOwnerComponent,
        PropertyRoomDocumentsComponent,
        ContractRoomComponent,
        ContractRoomOwnerComponent,
        ContractRoomAgentComponent,
        ContractRoomTenantComponent,
        UsersSuggestionsComponent,
        ContractRoomModelComponent,
        InvitationRoomComponent,
        InvitationRoomPropertyComponent,
        InvitationRoomPreviewComponent,
        InvitationRoomContractComponent,
        SelectedUserModelComponent,
        ProfileAgencyFormComponent,
        BecomeAgentComponent,
        ProfileAgencyFormComponent,
        RoomStatusBadgeComponent,
        ProfileUserComponent,
        ProfileModelComponent,
        ProfileAgencyComponent,
        RoomEditHostComponent,
        RoomEditIBookingComponent,
        SearchComponent,
        BookingTemplateComponent,
        BookingPaymentComponent,
        BookingDetailsComponent,
        BookingDemandDetailsComponent,
        BookingsListComponent,
        BookingsDemandsListComponent,
        BookingListModelComponent,
        MessagesComponent,
        ListBookingComponent,
        BookingDetailsModelComponent,
        ContactHostComponent,
        PaymentsComponent,
        PaymentsPreferenceComponent,
        AccountSettingsComponent,
        BookingProductIdentityComponent,
        BookingPriceDetailsComponent,
        RulesRoomComponent,
        RulesRoomFormComponent,
        RoomEditRulesComponent,
        BookingCheckoutComponent,
        ProfileAccountVerifyComponent,
        VerifyIdentityComponent,
        VerifyUserPhotoComponent,
        VerifyIdentityStatusComponent,
    ],
    imports: [
        SharingModule,
        CommonModule,
        AgmJsMarkerClustererModule,
        NgxLoadingModule.forRoot({}),
        WebcamModule,
        RouterModule.forChild(ROUTES)
    ],

    providers: [
        AgmService,
        CurrencyPipe,
        ConfirmationDialogService
    ]

})

export class ViewsModule {
}
