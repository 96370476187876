import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {Lightbox} from 'ngx-lightbox';
declare var $;
@Component({
  selector: 'app-card-appart',
  templateUrl: './card-appart.component.html',
  styleUrls: ['./card-appart.component.css']
})

export class CardAppartComponent implements OnInit {
  type='invisible';
  nbr:any;
  private _album=[] ;
 
  @Input() data:any;
  @Output() openDocs = new EventEmitter<any>();
  url_doc: any;
  url_pdf: any;
  constructor(  private lightbox: Lightbox, private sanitizer:DomSanitizer) { }

  ngOnInit() {
    console.log('documents',this.data);
  }
  

  showDoc(data){
     
     if(data.type=="pdf"){
        this.url_pdf=this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
        this.url_doc==null
        $('#docModal').modal('show');
      }
      else{
        this._album.push({src:data.url,caption:data.description,thumb:data.url});
        this.lightbox.open(this._album,0);
      }
    
    
   
  }

  getExtensionFile(file:string){

    var basename = file.split(/[\\/]/).pop(),  // extract file name from full path ...
                                               // (supports `\\` and `/` separators)
        pos = basename.lastIndexOf('.');       // get last position of `.`

    if (basename === '' || pos < 1)            // if file name is empty or ...
        return "";                             //  `.` not found (-1) or comes first (0)
      // console.log('dddddd', basename.slice(pos + 1));
    return basename.slice(pos + 1);  
  
  }


 
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }
}
