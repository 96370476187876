import {Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AuthUser} from '../../models/auth-user.model';
import {TokenData} from '../../models/user';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppService} from '../../services/app/app.service';
import {UserService} from '../../services/user/user.service';
import {AppConfig, CurrencyModel, Notification} from '../../models/app.model';
import * as moment from 'moment';
import Echo from 'laravel-echo';
declare var $: any;

@Component({
    selector: 'app-header-shapart',
    templateUrl: './header-shapart.component.html',
    styleUrls: ['./header-shapart.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class HeaderShapartComponent implements OnInit, OnDestroy {
    @Input() classHeader: any;

    @Input() sticky: boolean;
    @Input() logo = 'assets/img/logo.png';

    authUser: AuthUser;
    hasLoaded = false;
    formCurrency: FormGroup;
    defaultCurrencies: CurrencyModel[];

    userSubscription: Subscription;
    appConfigSubscription: Subscription;
    currencyModel: any;
    changingCurrency: boolean;
    private echo: Echo;
    notifications: Notification[] = [];
    private loadedNotification = false;
    loadingNotifications: boolean;
    loadingMoreNotifications: boolean;

    constructor(private authService: AuthService,
                private router: Router,
                private toastr: ToastrService,
                private modalService: NgbModal,
                private fb: FormBuilder,
                private appService: AppService,
                private userService: UserService) {
        moment.locale('fr');

    }

    ngOnInit() {

        this.formCurrency = this.fb.group({
            currency: ['', [Validators.required]]
        });

        this.appConfigSubscription = this.appService.appConfigSubject.subscribe(
            (config: AppConfig) => {
                if (config) {
                    this.defaultCurrencies = config.defaultCurrencies;
                    this.formCurrency.controls.currency.setValue(config.currency);
                }
            }
        );

        this.appService.emitAppConfig();

        const currency = localStorage.getItem('currency');

        if (currency && (currency === 'XAF' ||  currency === 'EUR' || currency === 'USD')) {
            this.appService.appConfig.currency = currency;
            this.appService.emitAppConfig();
        }

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                this.authUser = user;

                if (this.authUser && !this.loadedNotification && !this.loadingNotifications) {
                    this.getNotification();
                }

            }
        );

        this.authService.getAuthUser().then(
            () => {
                this.authService.emitUser();
            },
            () => {
                this.authService.emitUser();

            }
        );

        setTimeout(() => {
            this.hasLoaded = true;
        }, 2000);

    }

    hasNotification(id): boolean {
        let hasNotification = false;

        this.notifications.forEach(
            (notification: Notification) => {

                if (notification.id === id) {
                    hasNotification = true;
                }
            }
        );

        return hasNotification;
    }

    getNotificationsQty(): number {
        let qty = 0;

        this.notifications.forEach(
            (notification: Notification) => {
                if (!notification.read_at) {
                    qty++;
                }
            }
        );

        return qty;
    }

    getNotificationDateString(notification: Notification): string {
        return moment(notification.created_at).fromNow();
    }

    readNotification(notification: Notification) {
        this.userService.readUserNotification(this.authUser.id, notification.id);
    }

    markAsReadNotification(data) {

        this.notifications.forEach(
            (notification: Notification) => {
                if (notification.id === data.id) {
                    notification.read_at = data.read_at;
                }
            }
        );
    }

    getNotification() {

        this.loadingNotifications = true;
        this.userService.getUserNotifications(this.authUser.id).then(
            (response: any) => {
                const notifications = response.data ? response.data : [];

                notifications.forEach(
                    (notificationData: any) => {
                        const notification: Notification = {
                            id: notificationData.id,
                            data: notificationData.data.data,
                            picture: notificationData.data.picture,
                            text: notificationData.data.text,
                            type: notificationData.data.data.type,
                            read_at: notificationData.read_at,
                            created_at: notificationData.data.created_at,
                            url: notificationData.data.url
                        };
                        this.notifications.push(notification);
                    }
                );

                this.loadedNotification = true;
                this.authService.broadcast().then(
                    (echo: Echo) => {
                        this.echo = echo;
                        this.loadingNotifications = false;

                        this.echo
                            .private(`App.User.${this.authUser.id}`)
                            .listen('.notification.read', (data) => {

                                this.markAsReadNotification(data.data);
                            })
                            .notification((notification: any) => {
                                if (!this.hasNotification(notification.id)) {
                                    this.notifications.unshift(notification);
                                }

                            });
                    }
                );

            }
        );
    }

    changeCurrency() {

        if (this.authUser) {
            this.changingCurrency = true;

            const formData = {
                _method: 'PUT',
                currency: this.formCurrency.get('currency').value
            };
            this.userService.editProfile(formData, this.authUser.id).then(
                (user: AuthUser) => {
                    this.authUser = user;
                    this.authService.authUser = user;
                    this.authService.emitUser();
                    this.changingCurrency = false;
                    this.currencyModel.close();
                }, err => {
                    this.changingCurrency = false;
                    this.toastr.error('Désolé une erreu s\'est produite');
                    console.log(err);
                }
            );
        } else {

            const currency = this.formCurrency.get('currency').value;
            localStorage.setItem('currency', currency);
            this.appService.appConfig.currency = currency;
            this.appService.emitAppConfig();
            this.currencyModel.close();
        }

    }

    openCurrencyModal(content, event) {
        event.preventDefault();
        this.formCurrency.controls.currency.setValue(this.appService.appConfig.currency);
        this.currencyModel = this.modalService.open(content, { size: 'sm' });
    }

    isSticky() {
        if (this.sticky === false) {
            return true;
        } else {
            return this.hasSticky();
        }
    }

    hasSticky() {
        return $('#header').hasClass('sticky');
    }

    isMapView() {
        return $('#header').hasClass('map_view');
    }

    logout() {
        this.authService.logout().then(
            (data: TokenData) => {
                this.authService.storeValidToken(data, data.grant_type);
                this.router.navigate(['/']).then(
                    () => {
                        this.authService.emitUser();
                        this.toastr.info('Vous êtes a présent déconnecté');
                    }
                );
            }
        );
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.appConfigSubscription) {
            this.appConfigSubscription.unsubscribe();
        }
    }

}
