import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BookingService} from '../../../../services/bookings/booking.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ApiResponse} from '../../../../models/response.model';
import {Message} from '../../../../models/booking.model';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';
import Echo from 'laravel-echo';
import {UserService} from '../../../../services/user/user.service';
declare var $: any;

@Component({
    selector: 'app-booking-details-model',
    templateUrl: './booking-details-model.component.html',
    styleUrls: ['./booking-details-model.component.scss']
})
export class BookingDetailsModelComponent implements OnInit, OnDestroy {
    @Input() mode = 'reservations';

    role: string | null;
    response = new ApiResponse();
    loaded = false;
    loading = false;
    userSubscription: Subscription;
    authUser: AuthUser;
    bookingId: any;
    booking: any;
    messages: Message[][] = [];
    messageArea = new FormControl();
    submittedMessage = false;
    messagesDates = [];
    echo: any;
    unReadMessages = [];
    loadingMessages: boolean;
    readingMessages: boolean;
    loadedMessages: boolean;
    typing;
    private stopingTyping: any;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private bookingService: BookingService,
                private authService: AuthService,
                private userService: UserService) {
        moment.locale('fr');
    }

    ngOnInit() {

        this.messageArea.valueChanges.subscribe(
            val => {
                if (val) {
                    this.submittedMessage = false;
                }
            }
        );

        this.route.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id') && params.hasOwnProperty('role')) {

                    this.bookingId = params.id;
                    this.role = params.role;
                    this.loading = true;

                    this.userSubscription = this.authService.userSubject.subscribe(
                        (user: AuthUser) => {
                            if (user) {
                                this.authUser = user;
                                this.getBooking();
                            }
                        }
                    );
                }
            }
        );
        this.authService.emitUser();

    }

    isOwnerViewing() {
        return this.booking.product.manager_type === 'agent' && this.role === 'host';
    }
    checkUser() {
        return this.authUser.id === this.booking.product.manager.id || this.authUser.id === this.booking.user_id;
    }

    getUser(): AuthUser {
        switch (this.role) {
            case 'agent':
                return this.booking.user;
            case 'tenant':
                return this.booking.product.manager;
            case 'host':
                return this.booking.user;
            default:
                return this.booking.user;
        }
    }

    getApiUserRole() {
        switch (this.role) {
            case 'agent':
                return 'agents';
            case 'tenant':
                return 'clients';
            case 'host':
                return 'hosts';
            default:
                return 'hosts';
        }
    }

    getBooking() {

        this.bookingService.getUserBooking(this.authUser.id, this.getApiUserRole(), this.bookingId, {}, this.mode).then(
            (booking: any) => {
                this.booking = booking;
                if (this.mode === 'reservations') {
                    this.authService.broadcast().then(
                        (echo: Echo) => {
                            this.echo = echo;
                            this.fetchMessages();
                        }
                    );
                } else {
                    this.readMessages();
                    this.readNotification();
                }

                this.loaded = true;
            },
            () => {
                this.router.navigateByUrl('not-found', {skipLocationChange: true});
            }
        );
    }

    subscribeToBroadcastingMessage() {

        this.echo
            .private(`App.User.${this.authUser.id}`)
            .listen('.message.sent', (data) => {

                const formattedMessage = this.getFormattedMessage(data.message);

                if (!this.hasMessage(formattedMessage.code)) {

                    this.fetchMessages();
                    if (!this.hasTodayMessages()) {
                        this.messagesDates.push(moment().calendar().split(' ')[0]);
                        this.messages.push([]);
                    }
                    this.messages[this.messages.length - 1].push(formattedMessage);
                }

                if (!formattedMessage.seen && formattedMessage.receiverId === this.authUser.id) {
                    this.unReadMessages.push(formattedMessage.id);
                    this.readMessages();
                    this.readNotification();
                }

            })
            .listen('.message.read', (data) => {
                this.markMessageAsRead(data.data);
            });

        this.echo
            .private('Chat.Reservation.Typing.' + this.booking.id)
            .listenForWhisper( 'typing', (e) => {
                if (e && e.typing) {
                    this.typing = e;
                } else {
                    this.typing = null;
                }
            });
    }

    hasTodayMessages(): boolean {

        let hasTodayMessages = false;
        this.messagesDates.forEach(
            (date, i) => {
                const messages: any = this.messages[i];
                messages.forEach((message: Message) => {

                    const dateMessage = moment(message.creationDate);
                    const currentDate = moment(moment().format('YYYY-MM-DD'));

                    if (currentDate.isSame(dateMessage)) {
                        hasTodayMessages = true;
                    }

                });
            }
        );

        return hasTodayMessages;
    }

    hasMessage(code): boolean {
        let hasMessage = false;
        this.messagesDates.forEach(
            (date, i) => {
                const messages: any = this.messages[i];
                messages.forEach((message: Message) => {
                    if (message.code === code) {
                        hasMessage = true;
                    }
                });
            }
        );

        return hasMessage;
    }

    handleKeyPress(event) {
        if (event.keyCode === 13) {
            event.preventDefault();

            if (!event.shiftKey) {
                this.sendMessage();
            }

            if (event.shiftKey) {
                const currentMessage = this.messageArea.value ? this.messageArea.value : '';
                this.messageArea.setValue(currentMessage + '\n');
            }
        }

        if (this.stopingTyping) {
            clearTimeout(this.stopingTyping);
        }

        if (this.messageArea.value) {
            this.echo.private('Chat.Reservation.Typing.' + this.booking.id)
                .whisper('typing', {
                    user: this.authUser.lastname,
                    typing: true
                });
        } else {
            this.stopTyping();
        }
    }

    stopTyping() {
        this.stopingTyping = setTimeout( () => {
            this.echo.private('Chat.Reservation.Typing.' + this.booking.id)
                .whisper('typing', {
                    user: this.authUser.lastname,
                    sender: this.booking.user_id,
                    receiver: this.authUser.id,
                    typing: false
                });
            clearTimeout(this.stopingTyping);
        }, 1500);
    }

    readNotification() {
        this.userService.readUserNotifications(this.authUser.id, {
            messageable_id: this.booking.id, messageable_type: 'booking'});
    }

    readMessages() {
        this.readingMessages = true;
        const unReadMessages = this.unReadMessages.slice();

        const messages = {messages: JSON.stringify(unReadMessages)};

        this.bookingService.readBookingMessages(this.authUser.id, this.booking.id, messages).then(
            () => {
                this.readingMessages = false;

                unReadMessages.forEach(
                    (unread: any) => {
                        const indexToRemove = this.unReadMessages.findIndex(
                            (el) => {
                                if (el === unread) {
                                    return true;
                                }
                            }
                        );

                        this.unReadMessages.splice(indexToRemove, 1);
                    }
                );
            }
        );
    }

    markMessageAsRead(data) {
        this.messagesDates.forEach(
            (date, i) => {
                const messages: any = this.messages[i];
                messages.forEach((message: Message) => {
                    if (message.id === data.id) {
                        message.seen = true;
                        message.seenAt = data.seen_at_human;
                    }

                });
            }
        );
    }

    sendMessage() {

        this.submittedMessage = true;

        if (!this.messageArea.value) {
            document.getElementById('msg-text').focus();
            return;
        }
        this.submittedMessage = false;
        const textMessage = this.messageArea.value;
        this.messageArea.setValue('');

        const message: Message = {
            receiverId: this.booking.user_id,
            senderId: this.authUser.id,
            message: textMessage,
            photo: this.authUser.photo,
            lastName: this.authUser.lastname,
            userRole: this.getUserMessageRole(),
            receiverRole: this.booking.product.manager_type,
            code: Date.now().toString(),
            messageableId: this.booking.id,
            creationDate: moment().format('YYYY-MM-DD'),
            creationHour: new Date().getHours() + ':' + new Date().getMinutes(),
            status: 'pending',
            seen: false
        };

        const formData = {
            message: textMessage,
            code: message.code
        };

        if (!this.hasTodayMessages()) {
            this.messagesDates.push(moment().calendar().split(' ')[0]);
            this.messages.push([]);
        }

        const messageData: Message[] = this.messages[this.messages.length - 1];
        const messagePosition = messageData.length;

        messageData.push(message);

        this.bookingService.saveBookingMessage(this.authUser.id, this.booking.id, formData).then(
            (savedMessage: any) => {
                messageData[messagePosition].status = 'saved';
                messageData[messagePosition].creationHour = savedMessage.creation_hour;
                messageData[messagePosition].creationDate = savedMessage.creation_date;
                messageData[messagePosition].creationDateHuman = savedMessage.creation_date_human;
                messageData[messagePosition].id = savedMessage.id;
            }
        );

    }

    getMessagesAtPosition(index) {
        return this.messages[index] ? this.messages[index] : [];
    }


    getUserMessageRole() {
        switch (this.role) {
            case 'agent':
                return 'agent';
            case 'tenant':
                return 'tenant';
            case 'host':
                return 'owner';
            default:
                return 'owner';
        }
    }

    getStatusMessage(message: Message) {

        if (message.status === 'pending') {
            return 'Envoi en cours';
        }

        if ((this.authUser.id === message.senderId || this.isOwnerViewing()) && message.seen) {
            return 'Vu ' + message.seenAt;
        }
    }

    fetchMessages() {
        this.loadingMessages = true;

        this.bookingService.getBookingMessage(this.authUser.id, this.bookingId).then(
            (messages: any) => {
                messages.forEach(
                    (message: any) => {

                        if (!this.hasMessage(message.code)) {
                            if (!this.messagesDates.includes(message.creation_date_human)) {
                                this.messagesDates.push(message.creation_date_human);
                                this.messages.push([]);
                            }

                            const position = this.messagesDates.findIndex(
                                (creationDateHuman: any) => {
                                    return creationDateHuman === message.creation_date_human;
                                }
                            );

                            this.messages[position].push(this.getFormattedMessage(message));

                            if (!message.seen_at && message.receiver_id === this.authUser.id) {
                                this.unReadMessages.push(message.id);
                            }
                        }
                    }
                );

                this.readMessages();

                this.loadedMessages = true;
                this.loadingMessages = true;

                this.route.queryParamMap.subscribe(
                    params => {
                        if (params.get('action') && params.get('action') === 'send-message') {
                            setTimeout(() => {
                                document.getElementById('message-footer').scrollIntoView();
                                document.getElementById('msg-text').focus();
                            }, 1200);
                        }
                    }
                );

                this.subscribeToBroadcastingMessage();

            }
        );
    }

    getFormattedMessage(message: any): Message  {
        return {
            receiverId: message.receiver.id,
            senderId: message.sender.id,
            message: message.message,
            photo: message.sender.photo,
            lastName: message.sender.lastname,
            userRole: message.user_role,
            code: message.code,
            id: message.id,
            messageableId: message.messageable_id,
            creationDate: message.creation_date,
            creationHour: message.creation_hour,
            creationDateHuman: message.creation_date_human,
            status: 'saved',
            seen: !!message.seen_at,
            seenAt: message.seen_at_human,
        };
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

}
