import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import * as  datatable from '../../../json/datatable/datatable_transfer.json';
import { AdminService } from '../../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import * as  filter from '../../../json/filter/filter.json';
import {IPagination} from '../../../model/pagination';
@Component({
  selector: 'app-transfer-pending',
  templateUrl: './transfer-pending.component.html',
  styleUrls: ['./transfer-pending.component.css']
})
export class TransferPendingComponent implements OnInit {
  breadcrumb=breadcrumb.transfer;
  data_json=datatable.transfer;
  paramsPage: IPagination;
  adminId: any;
  loaded: boolean;
  data: any;
  filter=filter.transfer
  constructor(private adminService:AdminService, private route: ActivatedRoute) {
    this.paramsPage={} as IPagination;  
    
   }

  ngOnInit() {
    this.adminService.setTitle('Transfères');
    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id; 
     this.getParams();
    });
    
   }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
        
          if(params){
           
            if(this.adminId){
              this.getAllPayementSuccess( this.adminId,location.search);
            }
          
          }else{
           if(this.adminId){
            this.getAllPayementSuccess(this.adminId);
           }
           
          }
      }); 
  }


  getAllPayementSuccess(AdminId, params?:string){
    this.loaded = false;
    this.adminService.getAllPayementSuccess(AdminId,params).then((response:any)=>{
        this.data=response.data ? response.data : [];
        this.loaded = true;
        this.paramsPage.currentPage=response.data?response.current_page:0;
        this.paramsPage.totalItems=response.data?response.total:0;
        this.paramsPage.currentPage=response.data?response.current_page:0;
        this.paramsPage.itemsPerPage=response.data?response.per_page:0;
        // console.log('list paiemet en attente',this.data);
    },err=>{
     
      console.log('ERREUR',err);
    });
  }

  getPage(page){
 
    this.getAllPayementSuccess(this.adminId,"&page="+page);
  }

}
