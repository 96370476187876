import { Routes } from '@angular/router';
import { AppComponent } from '../app.component';
import { ContactComponent } from './contact/contact.component';
import {AuthGuard} from '../services/auth/auth-guard.service';
import {AnonymousGuardService} from '../services/auth/anonymous-guard.service';
import { DetailProductComponent } from './products/detail-product/detail-product.component';
import {WelcomeShapartComponent} from './welcome-shapart/welcome-shapart.component';
import {LoginShapartViewComponent} from './auth-shapart/login-shapart/login-shapart-view/login-shapart-view.component';
import {RegisterShapartViewComponent} from './auth-shapart/register-shapart/register-shapart-view/register-shapart-view.component';
import {ForgotPasswordShapartViewComponent} from './auth-shapart/forgot-password-shapart/forgot-password-shapart-view/forgot-password-shapart-view.component';
import {NewPasswordShapartViewComponent} from './auth-shapart/new-password-shapart/new-password-shapart-view/new-password-shapart-view.component';
import { ResultSearchWithoutMapComponent } from './search/result-search-without-map/result-search-without-map.component';
import {AccountComponent} from './account/account.component';
import {HomeAccountComponent} from './account/home-account/home-account.component';
import {ProfileAccountComponent} from './account/profile-account/profile-account.component';
import {RoomsListComponent} from './account/rooms-list/rooms-list.component';
import {NewRoomComponent} from './rooms/new-room/new-room.component';
import {CategoryRoomComponent} from './rooms/category-room/category-room.component';
import {TemplateWizardComponent} from './rooms/template-wizard/template-wizard.component';
import {BedsRoomComponent} from './rooms/beds-room/beds-room.component';
import {OptionsRoomComponent} from './rooms/options-room/options-room.component';
import {LocationRoomComponent} from './rooms/location-room/location-room.component';
import {ImagesRoomComponent} from './rooms/images-room/images-room.component';
import {ScheduleRoomComponent} from './rooms/schedule-room/schedule-room.component';
import {SettingsAccountComponent} from './account/settings-account/settings-account.component';
import {PasswordForgottenComponent} from './account/settings-account/password-forgotten/password-forgotten.component';
import {VerifyEmailComponent} from './auth-shapart/verify-email/verify-email.component';
import {RoomDetailsComponent} from './account/rooms-list/room-details/room-details.component';
import {RoomEditCategoryComponent} from './account/rooms-list/room-edit-category/room-edit-category.component';
import {RoomEditPreviewComponent} from './account/rooms-list/room-edit-preview/room-edit-preview.component';
import {RoomEditOptionsComponent} from './account/rooms-list/room-edit-options/room-edit-options.component';
import {RoomEditLocationComponent} from './account/rooms-list/room-edit-location/room-edit-location.component';
import {RoomEditPropertyComponent} from './account/rooms-list/room-edit-property/room-edit-property.component';
import {RoomEditImagesComponent} from './account/rooms-list/room-edit-images/room-edit-images.component';
import {RoomEditScheduleComponent} from './account/rooms-list/room-edit-schedule/room-edit-schedule.component';
import {RoomEditBedroomsComponent} from './account/rooms-list/room-edit-bedrooms/room-edit-bedrooms.component';
import {RoomEditSettingsComponent} from './account/rooms-list/room-edit-settings/room-edit-settings.component';
import {PropertyRoomComponent} from './rooms/property-room/property-room.component';
import {UserRoomComponent} from './rooms/user-room/user-room.component';
import {UserRoomInformationsComponent} from './rooms/user-room/user-room-informations/user-room-informations.component';
import {UserRoomIdentityComponent} from './rooms/user-room/user-room-identity/user-room-identity.component';
import {UserRoomPhotoComponent} from './rooms/user-room/user-room-photo/user-room-photo.component';
import {CheckoutRoomComponent} from './rooms/checkout-room/checkout-room.component';
import {PropertyRoomOwnerComponent} from './rooms/property-room/property-room-owner/property-room-owner.component';
import {PropertyRoomDocumentsComponent} from './rooms/property-room/property-room-documents/property-room-documents.component';
import {ContractRoomComponent} from './rooms/contract-room/contract-room.component';
import {ContractRoomOwnerComponent} from './rooms/contract-room/contract-room-owner/contract-room-owner.component';
import {ContractRoomAgentComponent} from './rooms/contract-room/contract-room-agent/contract-room-agent.component';
import {ContractRoomTenantComponent} from './rooms/contract-room/contract-room-tenant/contract-room-tenant.component';
import {InvitationRoomComponent} from './rooms/invitation-room/invitation-room.component';
import {InvitationRoomPreviewComponent} from './rooms/invitation-room/invitation-room-preview/invitation-room-preview.component';
import {InvitationRoomPropertyComponent} from './rooms/invitation-room/invitation-room-property/invitation-room-property.component';
import {InvitationRoomContractComponent} from './rooms/invitation-room/invitation-room-contract/invitation-room-contract.component';
import {BecomeAgentComponent} from './agents/become-agent/become-agent.component';
import {ProfileUserComponent} from './profiles/profile-user/profile-user.component';
import {ProfileAgencyComponent} from './profiles/profile-agency/profile-agency.component';
import {RoomEditIBookingComponent} from './account/rooms-list/room-edit-i-booking/room-edit-i-booking.component';
import {SearchComponent} from './search/search/search.component';
import {BookingTemplateComponent} from './booking/booking-template/booking-template.component';
import {BookingsListComponent} from './account/bookings/bookings-list/bookings-list.component';
import {BookingsDemandsListComponent} from './account/bookings/bookings-demands-list/bookings-demands-list.component';
import {BookingDetailsComponent} from './account/bookings/booking-details/booking-details.component';
import {BookingDemandDetailsComponent} from './account/bookings/booking-demand-details/booking-demand-details.component';
import {MessagesComponent} from './account/messages/messages.component';
import {BookingPaymentComponent} from './booking/booking-payment/booking-payment.component';
import {ContactHostComponent} from './products/contact-host/contact-host.component';
import {PaymentsComponent} from './account/payments/payments.component';
import {PaymentsPreferenceComponent} from './account/settings-account/payments-preference/payments-preference.component';
import {AccountSettingsComponent} from './account/settings-account/account-settings/account-settings.component';
import {RulesRoomComponent} from './rooms/rules-room/rules-room.component';
import {RoomEditRulesComponent} from './account/rooms-list/room-edit-rules/room-edit-rules.component';
import {ProfileAccountVerifyComponent} from './account/profile-account/profile-account-verify/profile-account-verify.component';

const SITE_ROUTE: Routes = [
    { path: 'login', component: LoginShapartViewComponent, canActivate: [AnonymousGuardService]},
    { path: 'register', component: RegisterShapartViewComponent, canActivate: [AnonymousGuardService] },
    { path: 'password/reset', component: ForgotPasswordShapartViewComponent, canActivate: [AnonymousGuardService] },
    { path: 'password/reset/:token', component: NewPasswordShapartViewComponent},
    { path: 'email/verify', component: VerifyEmailComponent},
    { path: 'contact', component: ContactComponent },
    {path: '', component: WelcomeShapartComponent},
    { path: 'detail-products/:id', component: DetailProductComponent },
    { path: 'search', component: SearchComponent},
    { path: 'search/all', component: ResultSearchWithoutMapComponent },
    { path: 'users/:id', component: ProfileUserComponent},
    { path: 'agencies/:id', component: ProfileAgencyComponent},
];

const DETAILS_ROOM_ROUTES = [
    {path: 'preview', component: RoomEditPreviewComponent},
    {path: 'category', component: RoomEditCategoryComponent},
    {path: 'bedrooms', component: RoomEditBedroomsComponent},
    {path: 'options', component: RoomEditOptionsComponent},
    {path: 'location', component: RoomEditLocationComponent},
    {path: 'house-rules', component: RoomEditRulesComponent},
    {path: 'images', component: RoomEditImagesComponent},
    {path: 'schedule', component: RoomEditScheduleComponent},
    {path: 'property', component: RoomEditPropertyComponent},
    {path: 'instant_booking', component: RoomEditIBookingComponent},
    {path: 'settings', component: RoomEditSettingsComponent},
    {path: '', redirectTo: 'preview', pathMatch: 'full'},
];

const ACCOUNT_ROUTES = [
    {path: '', component: AccountComponent},
    {path: 'profile', component: ProfileAccountComponent},
    {path: 'profile/verify', component: ProfileAccountVerifyComponent},
    {path: 'profile/verify/:step', component: ProfileAccountVerifyComponent},
    {path: 'payments', component: PaymentsComponent},
    {path: 'settings', component: SettingsAccountComponent, children: [
            {
                path: '', component: AccountSettingsComponent
            },
            {
                path: 'payment-preferences', component: PaymentsPreferenceComponent
            },

            {
                path: 'payment-preferences/edit/:subject', component: PaymentsPreferenceComponent
            },
        ]
    },
    {path: 'rooms', component: RoomsListComponent},
    {path: 'rooms/:id', component: RoomDetailsComponent, children: DETAILS_ROOM_ROUTES},
    {path: 'bookings', component: BookingsListComponent},
    {path: 'bookings/:id/:role', component: BookingDetailsComponent},
    {path: 'booking-demands', component: BookingsDemandsListComponent},
    {path: 'booking-demands/:id/:role', component: BookingDemandDetailsComponent}
];

const ROOMS_ROUTES = [
    {path: '', component: NewRoomComponent},
    {path: 'category', component: CategoryRoomComponent},
    {path: 'bedrooms', component: BedsRoomComponent},
    {path: 'options', component: OptionsRoomComponent},
    {path: 'location', component: LocationRoomComponent},
    {path: 'images', component: ImagesRoomComponent},
    {path: 'schedule', component: ScheduleRoomComponent},
    {path: 'house-rules', component: RulesRoomComponent},
    {path: 'user', component: UserRoomComponent, children: [
            {
                path: '', redirectTo: 'informations', pathMatch: 'full',
            },
            {
                path: 'informations', component: UserRoomInformationsComponent,
            },
            {
                path: 'identity', component: UserRoomIdentityComponent,
            },
            {
                path: 'photo', component: UserRoomPhotoComponent,
            },
        ]
    },
    {path: 'property', component: PropertyRoomComponent, children: [
            {
                path: '', redirectTo: 'owner', pathMatch: 'full',
            },
            {
                path: 'owner', component: PropertyRoomOwnerComponent,
            },
            {
                path: 'documents', component: PropertyRoomDocumentsComponent,
            },
        ]
    },
    {path: 'contract', component: ContractRoomComponent, children: [
            {
                path: 'owner', component: ContractRoomOwnerComponent,
            },
            {
                path: 'agent', component: ContractRoomAgentComponent,
            },
            {
                path: 'tenant', component: ContractRoomTenantComponent,
            },
        ]
    },
    {path: 'invitations/:invitation_id', component: InvitationRoomComponent, children: [
            {
                path: '', redirectTo: 'preview', pathMatch: 'full',
            },
            {
                path: 'preview', component: InvitationRoomPreviewComponent,
            },
            {
                path: 'contract', component: InvitationRoomContractComponent,
            },
            {
                path: 'property_room_settings', component: InvitationRoomPropertyComponent,
            },
        ]
    },
    {path: 'checkout', component: CheckoutRoomComponent},
];


export const ROUTES: Routes = [
    {
        path: 'account',
        component: HomeAccountComponent,
        children: ACCOUNT_ROUTES,
        canActivate: [AuthGuard]
    },

    {
        path: 'message/:id/new',
        component: ContactHostComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'messages/:id/:role',
        component: MessagesComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'booking/new',
        component: BookingTemplateComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'booking/payment',
        component: BookingPaymentComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'become-agent',
        component: BecomeAgentComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'rooms/:id',
        component: TemplateWizardComponent,
        children: ROOMS_ROUTES,
        canActivate: [AuthGuard]
    },

    {
        path: '',
        component: AppComponent,
        children: SITE_ROUTE
    },

];



