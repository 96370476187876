import {Component, EventEmitter, Input, OnInit, Output, HostListener} from '@angular/core';
import {ApiResponse} from '../../models/response.model';
import {AuthService} from '../../services/auth/auth.service';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

    @Input() response: ApiResponse;
    @Input() requestUrl: string;
    @Input() loading: boolean;
    @Input() query: any = {};
    @Input() fetchMode = true;
    @Input() url: string;

    @Output() responseChange = new EventEmitter<ApiResponse>();
    @Output() queryChange = new EventEmitter<any>();
    @Output() loadingChange = new EventEmitter<boolean>();

    pages = [];

    public showScrollTop: boolean;
    public topPosToStartShowing = 100;

    @HostListener('window:scroll')
    checkScroll() {

        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (scrollPosition >= this.topPosToStartShowing) {
            this.showScrollTop = true;
        } else {
            this.showScrollTop = false;
        }
    }

    // TODO: Cross browsing
    gotoTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    constructor(private authService: AuthService) { }

    ngOnInit() {
        this.pages = this.getPagesArray(); // [0,1,2,3,4]
    }

    getPagesArray() {
        if (this.response && this.response.last_page) {
            return Array(this.response.last_page).fill(1).map((x, i) => i + 1);
        }
        return [];
    }

    fetchResponse(page) {

        if (this.query.page && this.query.page == page) {
            return;
        }
        this.query.page = page;
        this.queryChange.emit(this.query);

        if (this.fetchMode) {
            this.loadingChange.emit(true);
            this.authService.get(this.requestUrl, this.query).then(
                (response: any) => {
                    this.responseChange.emit(response.data ? response.data : []);
                    this.loadingChange.emit(false);
                }
            );
        }

        this.gotoTop();

    }

}
