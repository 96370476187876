import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Image, UploadedImage} from '../../models/Image';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth/auth.service';
import {Gallery, GalleryItem} from 'ng-gallery';
import {Lightbox} from 'ng-gallery/lightbox';
import {ConfirmationDialogService} from '../../admin/common/notifications/notification.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, Validators} from '@angular/forms';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('thumbTemplate') thumbTemplate: TemplateRef<any>;
    @ViewChild('itemTemplate') itemTemplate: TemplateRef<any>;
    @ViewChild('docViewer') docViewer: TemplateRef<any>;
    @Input() uploadUrl: string;
    @Input() deleteUrl: string;
    @Input() uploadName: string;
    @Input() preview = false;
    @Input() multiple = 'multiple';
    @Input() title: string;
    @Input() class = 'col-12';
    @Input() progressBarStyle = {height: '20px', 'min-width': '300px', width: '95%'};
    @Input() imageStyle = {width: '100%'};
    @Input() upLoadedImages: UploadedImage[] = [];
    @Input() highlightedImage: UploadedImage;
    @Input() formData: { key: string, value: any }[] = [];
    @Input() previewPlaceHolder = 'assets/img/bg-pattern-topo.png';
    @Input() showFileInput = false;
    @Input() showFileStatusVerify = false;
    @Input() disableFileSelection = false;
    @Input() fileHasUploaded = false;
    @Input() acceptedFormats: any = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    @Input() maxFileSize = 5;
    @Input() maxFileQty = 30;
    @Input() fileIsUploading = false;
    @Input() uploadMode = true;
    @Input() isSingleImageUpload = false;

    @Output() upLoadedImagesChange = new EventEmitter<UploadedImage[]>();
    @Output() highlightedImageChange = new EventEmitter<UploadedImage>();
    @Output() fileIsUploadingChange = new EventEmitter<boolean>();

    fileIsLoading = false;
    items: GalleryItem[];
    protected editDescriptionModal: NgbModalRef;
    protected docViewerModal: NgbModalRef;
    editDescriptionInput = new FormControl('', [Validators.required, Validators.maxLength(200)]);
    currentIndex: number = null;
    currentUrl: string;
    fileIsUpdating = false;
    submittedUpdate = false;
    maxDescriptionLenght = 20;

    constructor(private toastr: ToastrService,
                private httpClient: HttpClient,
                private authService: AuthService,
                public gallery: Gallery,
                private lightbox: Lightbox,
                private renderer: Renderer2,
                private confirmDialog: ConfirmationDialogService,
                private modalService: NgbModal,
                @Inject(DOCUMENT) private document: Document) {}


    ngOnInit() {

        this.loadGallery();
        this.lightbox.opened.subscribe(
            () => {
                this.renderer.setStyle(document.getElementById('header'), 'display', 'none');
            }
        );

        this.lightbox.closed.subscribe(
            () => {
                if (!this.document.body.classList.contains('modal-open')) {
                    this.renderer.setStyle(document.getElementById('header'), 'display', 'block');
                }
            }
        );

        this.upLoadedImagesChange.subscribe(
            () => {
                this.loadGallery();
                this.gallery.ref(this.uploadName).setConfig({
                    thumbTemplate: this.thumbTemplate,
                    itemTemplate: this.itemTemplate,
                    counterPosition: 'bottom'
                });
            }
        );

        if (this.isSingleImageUpload) {
            this.uploadUrl = 'images/upload';
            this.deleteUrl = 'images/remove';

            if (!this.highlightedImage) {
                this.setImagePlaceHolder();
            }
        }
    }

    setImagePlaceHolder() {
        this.highlightedImage =  {
            uploadName: this.uploadName,
            isPlaceholder: true,
            isUploading: false,
            hasUploaded: true,
            isDeleting: false,
            percentageLoaded: 0,
            pending: false,
            link: this.previewPlaceHolder,
        };
    }
    openInFullScreen(index: number): void {
        const url = this.upLoadedImages[index].link;
        const img = document.createElement('img');

        img.onerror = () => {
            this.openDocViewer(index);
        };

        img.onload = () => {
            this.lightbox.open(index, this.uploadName);
        };
        img.src = url;
    }

    openDocViewer(index: number): void {
        this.currentUrl = this.upLoadedImages[index].link;
        this.docViewerModal = this.modalService.open(this.docViewer, {size: 'lg'});
    }

    getDescription(uploadedImage: UploadedImage): string {

        let description = '';

        if (!uploadedImage.description) {
            return 'Aucune description';
        }

        description += uploadedImage.description.slice(0, this.maxDescriptionLenght);

        if (uploadedImage.description.length > this.maxDescriptionLenght) {
            description += '...';
        }

        return description;
    }



    loadGallery(): void {
        if (this.gallery.ref(this.uploadName)) {
            this.gallery.ref(this.uploadName).destroy();
        }
        const galleryRef = this.gallery.ref(this.uploadName);

        this.upLoadedImages.forEach(
            (uploadedImage: UploadedImage) => {

                const image: any = {
                    src: uploadedImage.link,
                    title: uploadedImage.description
                };

                if (this.upLoadedImages.length > 1) {
                    image.thumb = uploadedImage.link;
                }
                galleryRef.addImage(image);
            }
        );
    }

    getAcceptedMineTypes() {
        return this.acceptedFormats.join(',') ? this.acceptedFormats.join(',') : 'image/*';
    }

    setHighlightedImage(index) {

        if (this.upLoadedImages[index] && !this.upLoadedImages[index].isUploading && !this.upLoadedImages[index].isDeleting) {
            this.highlightedImage = this.upLoadedImages[index];
            this.highlightedImageChange.emit(this.highlightedImage);
        }
    }

    getHiHighlightedImageIndex() {
        return this.upLoadedImages.findIndex(
            (image: UploadedImage) => {
                return image.link === this.highlightedImage.link;
            }
        );
    }

    selectFile() {
        if (this.getRemainingNumberFiles() > 0) {
            document.getElementById(this.uploadName).click();
        } else {
            this.toastr.info('Vous avez atteint le nombre maximum d\'images');
        }
    }

    getRemainingNumberFiles(): number {
        let qty = 0;

        this.upLoadedImages.forEach(
            (uploadedImage: UploadedImage) => {
                if (!uploadedImage.hasError && !uploadedImage.isPlaceholder) {
                    qty ++;
                }
            }
        );

        return this.maxFileQty - qty;
    }

    openUpdateDescriptionModal(content, index) {
        this.currentIndex = index;
        this.editDescriptionModal = this.modalService.open(content, {centered: true, size: 'md'});

        this.editDescriptionInput.setValue(this.upLoadedImages[index].description);
        this.editDescriptionModal.result.then(
            () => {
                this.currentIndex = null;
            },
            () => {
                this.currentIndex = null;
            }
        );
    }

    onUpdateImage(attr, val) {
        const formData: FormData = this.retrieveFormData();
        formData.append(attr, val);
        if (!this.fileIsUpdating && this.currentIndex !== null) {
            this.updateImage(this.currentIndex, formData);
        }

    }


    updateImage(i, formData: FormData) {
        const uploadedFile: UploadedImage = this.upLoadedImages[i];
        if (uploadedFile && uploadedFile.hasUploaded) {
            formData.append('_method', 'PUT');
            this.fileIsUpdating = true;
            this.submittedUpdate = true;
            const updateUrl = this.authService.baseUri + this.uploadUrl + '/' + uploadedFile.id;

            this.authService.getHeaders().then(
                (headers: HttpHeaders) => {
                    const req = new HttpRequest('POST', updateUrl, formData, {headers, reportProgress: true});
                    this.httpClient.request(req).subscribe(
                        event => {
                            if (event instanceof HttpResponse) {

                                if (this.editDescriptionModal) {
                                    this.editDescriptionModal.close();
                                }
                                const image: Image = (event.body as Image);
                                uploadedFile.description = image.description;
                                uploadedFile.name = image.name;
                                if (image.name === 'front_image') {
                                    this.setHighlightedImage(i);
                                }

                                this.fileIsUpdating = false;
                                this.submittedUpdate = false;
                                this.currentIndex = null;
                                this.upLoadedImagesChange.emit(this.upLoadedImages);

                            }
                        },
                        () => {
                            this.fileIsUpdating = false;
                            this.upLoadedImagesChange.emit(this.upLoadedImages);
                        }
                    );
                },
            );
        }
    }

    onDeleteSingleImage(event) {
        event.stopPropagation();

        this.confirmDialog.confirm(null,
            'Voulez vous supprimer cette image ?',
            'sm', 'Supprimer',
            'Annuler',
            true,
            'btn btn-danger btn-sm').then( response => {
            if (response) {
                this.deleteSingleImage();
            }
        });
    }

    deleteSingleImage() {
        if (this.uploadMode) {
            const deleteUrl = this.deleteUrl;
            const formData: FormData = this.retrieveFormData();
            this.highlightedImage.isDeleting = true;
            this.authService.post(deleteUrl, formData).then(
                () => {
                    this.setImagePlaceHolder();
                },
                () => {
                    this.toastr.error('Impossible de supprimer l\'image');
                }
            );
        } else  {
            this.setImagePlaceHolder();
        }
    }

    onDeleteImage(event, imageId = null, imageIndexToRemove = null) {
        event.stopPropagation();

        this.confirmDialog.confirm(null,
            'Voulez vous supprimer cette image ?',
            'sm', 'Supprimer',
            'Annuler',
            true,
            'btn btn-danger btn-sm').then( response => {
            if (response) {
                this.deleteImage(imageId, imageIndexToRemove);
            }
        });
    }

    deleteImage(imageId, imageIndexToRemove) {
        if (!this.uploadMode) {
            this.highlightedImage = null;
            this.highlightedImageChange.emit(this.highlightedImage);
            return;
        }

        if (this.upLoadedImages[imageIndexToRemove]) {
            this.upLoadedImages[imageIndexToRemove].isDeleting = true;
        }

        const formData: FormData = this.retrieveFormData();

        if (!this.isSingleImageUpload) {
            formData.append('_method', 'DELETE');
        }

        let deleteUrl = this.deleteUrl + '/' + imageId;

        if (this.isSingleImageUpload && this.highlightedImage) {
            this.highlightedImage.isDeleting = true;
            deleteUrl = this.deleteUrl;
        }

        this.authService.post(deleteUrl, formData).then(
            () => {
                this.upLoadedImages.splice(imageIndexToRemove, 1);
                if (this.highlightedImage && this.highlightedImage.id === imageId && this.upLoadedImages.length > 0) {
                    this.highlightedImage = this.upLoadedImages[0];
                    this.highlightedImageChange.emit(this.highlightedImage);
                }
                if (this.upLoadedImages.length === 0) {
                    this.highlightedImage = null;
                    this.highlightedImageChange.emit(this.highlightedImage);
                }
                this.upLoadedImagesChange.emit(this.upLoadedImages);
                if (this.upLoadedImages[imageIndexToRemove]) {
                    this.upLoadedImages[imageIndexToRemove].isDeleting = false;
                }

                if (this.isSingleImageUpload && this.highlightedImage) {
                    this.highlightedImage.isDeleting = false;
                }
            },
            () => {

                if (this.upLoadedImages[imageIndexToRemove]) {
                    this.upLoadedImages[imageIndexToRemove].isDeleting = false;
                }
                if (this.isSingleImageUpload && this.highlightedImage) {
                    this.highlightedImage.isDeleting = false;
                }
            }
        );
    }

    retrieveFormData(): FormData {
        const formData = new FormData();
        if (this.formData.length > 0) {
            this.formData.forEach(
                data => {
                    formData.append(data.key, data.value);
                }
            );
        }
        return formData;
    }

    uploadFile(uploadedFile: UploadedImage, i, update = false) {
        if (!uploadedFile.hasUploaded || update) {
            const formData: FormData = this.retrieveFormData();
            formData.append(this.uploadName, uploadedFile.file);
            let uploadUrl = this.authService.baseUri + this.uploadUrl;

            if (update && !this.isSingleImageUpload) {
                formData.append('_method', 'PUT');
                uploadUrl += '/' + uploadedFile.id;
            }

            uploadedFile.isUploading = true;
            this.authService.getHeaders().then(
                (headers: HttpHeaders) => {
                    const req = new HttpRequest(
                        'POST',
                        uploadUrl,
                        formData, {
                            headers,
                            reportProgress: true
                        });
                    this.httpClient.request(req).subscribe(
                        event => {
                            if (event.type === HttpEventType.UploadProgress) {
                                uploadedFile.percentageLoaded = Math.round(100 * event.loaded / event.total);
                            } else if (event instanceof HttpResponse) {
                                const image: Image = (event.body as Image);
                                uploadedFile.id = image.id;
                                uploadedFile.link = image.url;
                                uploadedFile.isUploading = false;
                                uploadedFile.hasUploaded = true;
                                uploadedFile.hasJustUploaded = true;
                                uploadedFile.status = image.status;
                                this.fileHasUploaded = true;
                                if (!this.highlightedImage || this.highlightedImage.isPlaceholder) {
                                    this.setHighlightedImage(i);
                                }

                                if (update) {
                                    uploadedFile.pending = false;
                                    uploadedFile.isUpdating = true;
                                    this.fileIsUploading = false;
                                    this.fileIsUploadingChange.emit(false);
                                    this.upLoadedImagesChange.emit(this.upLoadedImages);
                                }

                                if (!update && i === this.upLoadedImages.length - 1) {
                                    this.fileIsUploading = false;
                                    this.fileIsUploadingChange.emit(false);
                                    if (!this.showFileInput || this.multiple === 'multiple') {
                                        this.upLoadedImages.forEach(
                                            (upLoadedImage) => {
                                                upLoadedImage.pending = false;
                                            }
                                        );
                                    }
                                    this.upLoadedImagesChange.emit(this.upLoadedImages);
                                }
                            }
                        },
                        () => {
                            this.fileIsUploading = false;
                            this.fileIsUploadingChange.emit(false);
                            uploadedFile.hasError = true;
                            uploadedFile.percentageLoaded = 0;
                            uploadedFile.pending = true;
                            this.upLoadedImagesChange.emit(this.upLoadedImages);
                        }
                    );
                },
            );
        }
    }

    getPercentageLoaded() {
        let percentageLoaded = 0;
        let totalUploadingImages = 0;
        if (this.upLoadedImages.length > 0) {
            this.upLoadedImages.map(
                uploadedImage => {
                    if (!this.fileIsLoading && uploadedImage.pending) {
                        totalUploadingImages++;
                        percentageLoaded += uploadedImage.percentageLoaded;
                    }
                }
            );
            return  totalUploadingImages > 0 ? (percentageLoaded / totalUploadingImages) : 0;
        }
    }

    chooseUpdateImageFile(id) {
        document.getElementById(id).click();
    }

    detectUpdateImageFile(event, index) {

        const images: FileList = event.target.files;
        this.checkImages(images, true).then(
            () => {
                this.fileIsLoading = true;
                const file = images[0];

                const uploadedImage: UploadedImage = this.upLoadedImages[index];
                if (uploadedImage && uploadedImage.hasUploaded) {
                    uploadedImage.uploadName = this.uploadName;
                    uploadedImage.percentageLoaded = 0;
                    uploadedImage.pending = true;
                    uploadedImage.hasUploaded = false;
                    uploadedImage.file = file;
                    uploadedImage.isDeleting = false;
                    uploadedImage.link = 'assets/img/bg-pattern-topo.png';

                    const reader = new FileReader();
                    reader.onload = () => {
                        uploadedImage.link = reader.result;
                    };
                    reader.readAsDataURL(file);

                    this.fileIsUploading = true;
                    this.fileIsLoading = false;
                    this.fileIsUploadingChange.emit(this.fileIsUploading);
                    this.uploadFile(uploadedImage, index, true);
                }
            },
            reject => {
                this.rejectImages(images, reject);
            }
        );
    }

    detectFile(event) {
        const images: FileList = event.target.files;
        this.checkImages(images).then(
            () => {
                this.fileIsLoading = true;
                if (this.multiple !== 'multiple') {
                    this.upLoadedImages = [];
                }
                let currentFilesLength: number = event.target.files.length;
                if (this.upLoadedImages && this.upLoadedImages.length > 0) {
                    currentFilesLength += this.upLoadedImages.length;
                }
                Array.from(images).forEach((file: File) => {
                    const uploadedImage: UploadedImage = {
                        uploadName: this.uploadName,
                        isUploading: false,
                        hasUploaded: !this.uploadMode,
                        isDeleting: false,
                        percentageLoaded: 0,
                        pending: true,
                        name: file.name.slice(0, 50),
                        type: file.type,
                        link: 'assets/img/bg-pattern-topo.png',
                        file
                    };
                    const reader = new FileReader();
                    reader.onload = () => {
                        uploadedImage.link = reader.result;
                    };
                    reader.readAsDataURL(file);

                    if (this.isSingleImageUpload) {
                        this.highlightedImage = uploadedImage;
                        this.highlightedImageChange.emit(this.highlightedImage);
                    }

                    this.upLoadedImages.push(uploadedImage);

                    if (this.upLoadedImages.length === currentFilesLength) {
                        this.fileIsLoading = false;

                        if (this.isSingleImageUpload && this.uploadMode) {
                            this.uploadFile(this.highlightedImage, 0);
                        }

                        if (!this.isSingleImageUpload  && this.uploadMode) {
                            this.fileIsUploading = true;
                            this.fileIsUploadingChange.emit(this.fileIsUploading);
                            this.upLoadedImages.forEach(
                                (uploadedFile: UploadedImage, index) => {
                                    this.uploadFile(uploadedFile, index);
                                }
                            );
                        }
                    }
                });
            },
            reject => {
                this.rejectImages(images, reject);
            }
        );
    }
    isFileImage(url: string): boolean {
        let isImage = true;
        const img = document.createElement('img');
        img.src = url;

        img.onerror = () => {
            isImage = false;
        };

        img.onload = () => {
            isImage = true;
        };

        return isImage;
    }

    rejectImages(images: FileList, reject: string) {
        if (images.length > 1) {
            if (reject === 'type') {
                this.toastr.error('La sélection contient un ou plusieurs fichiers non pris en charge');
            }
            if (reject === 'size') {
                this.toastr.error('La taille d\'un ou de plusieurs fichiers sélecionnés est supérieur à ' +
                    this.maxFileSize + 'mégas');
            }

            if (reject === 'qty') {
                const qty = this.getRemainingNumberFiles();
                let text = 'Le nombre maximum d\'images est de ' + this.maxFileQty;

                if (qty > 0) {
                    text += ' vous pouvez encore ajouter ' + qty + (qty > 1 ? ' images' : ' image');
                }
                this.toastr.info(text);
            }
        } else {
            if (reject === 'type') {
                this.toastr.error('Format du fichier non pris en charge.');
            }
            if (reject === 'size') {
                this.toastr.error('La taille de l\'image ne doit pas dépasser ' + this.maxFileSize + ' mégas');
            }
        }
    }

    checkImages(images: FileList, update = false) {
        return new Promise(
            (resolve, reject) => {
                Array.from(images).forEach(file => {
                    if (!this.checkImageType(file)) {
                        reject('type');
                    }
                    if (!this.checkImageSize(file)) {
                        reject('size');
                    }
                });

                if (!update && images.length > this.getRemainingNumberFiles()) {
                    reject('qty');
                }
                resolve();
            }
        );
    }

    checkImageType(image: File) {
        if (this.acceptedFormats === '*') {
            return true;
        }
        const fileFormatCheck = this.acceptedFormats.find(
            acceptedFormat => {
                return image.type === acceptedFormat;
            }
        );
        if (fileFormatCheck) {
            return true;
        }
        if (!fileFormatCheck) {
            return false;
        }
    }

    checkImageSize(image: File) {
        return image.size <= this.maxFileSize * 1000000;
    }
    ngAfterViewInit(): void {
        this.gallery.ref(this.uploadName).setConfig({
            thumbTemplate: this.thumbTemplate,
            itemTemplate: this.itemTemplate,
            counterPosition: 'bottom'
        });
    }

    ngOnDestroy(): void {
   
        if(document.getElementById('header')){
            this.renderer.setStyle(document.getElementById('header'), 'display', 'block');
            this.gallery.ref(this.uploadName).destroy();
        }
            
       
    }

}
