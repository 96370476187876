import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-category-room',
    templateUrl: './category-room.component.html',
    styleUrls: ['./category-room.component.css']
})

export class CategoryRoomComponent implements OnInit {

    @Input() create = false;

    constructor() { }

    ngOnInit() {}
}
