import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-invitation-room',
    templateUrl: './invitation-room.component.html',
    styleUrls: ['./invitation-room.component.css']
})
export class InvitationRoomComponent implements OnInit {

    constructor() { }

    ngOnInit() {}


}
