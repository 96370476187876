import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../../models/auth-user.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductsService} from '../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-room-edit-i-booking',
    templateUrl: './room-edit-i-booking.component.html',
    styleUrls: ['./room-edit-i-booking.component.css']
})
export class RoomEditIBookingComponent implements OnInit {
    product: any;
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    loaded = false;
    isLoading = false;
    productId;

    constructor(private router: Router,
                private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {
                    if (!this.userSubscription) {

                        this.userSubscription = this.authService.userSubject.subscribe(
                            (user: AuthUser) => {
                                if (user) {
                                    this.authUser = user;
                                    if (!this.userProductSubscription) {
                                        this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                            (product: any) => {
                                                if (product) {
                                                    this.product = product;
                                                    this.loaded = true;
                                                }
                                            }
                                        );

                                        this.productService.getAuthUserProduct(user.id, params.id).then(
                                            () => {
                                                this.productService.emitUserProduct();
                                            },
                                            (error) => {

                                                console.log(error);
                                                this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                            }
                                        );
                                    }
                                }
                            }
                        );

                        this.authService.emitUser();
                    }
                }
            }
        );
    }

    activateOrDesactivateIBooking(isInstantBooking) {
        this.isLoading = true;
        const formData = new FormData();

        formData.append('is_instant_booking', isInstantBooking);
        formData.append('_method', 'PUT');

        this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
            (product: any) => {

                this.isLoading = false;
                this.product = product;
                if (isInstantBooking) {
                    this.toastr.info('La réservation instantannée a été activée avec succès');
                }

                if (!isInstantBooking) {
                    this.toastr.info('La réservation instantannée a été désactivée avec succès');
                }

            }
        );
    }

}
