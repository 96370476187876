import {Component, Input, OnInit} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {ProductsService} from '../../services/products/products.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @Input() schedules = [];
    @Input() userId;
    @Input() productId;
    @Input() disabled;
    @Input() displayMonths;

    hoveredDate: NgbDate;

    fromDate: NgbDate;
    toDate: NgbDate;
    isFocused: boolean;
    loading: boolean = true;
    minDate: NgbDateStruct;
    updatingSchedule: boolean;
    scheduleModal: any;

    constructor(calendar: NgbCalendar,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private modalService: NgbModal,
                public formatter: NgbDateParserFormatter) {}

    ngOnInit() {
        this.minDate = {
            year: moment().year(),
            month: moment().month() + 1,
            day: moment().date()
        };
    }

    cancelCustomSchedule() {
        this.scheduleModal.close();
        this.isFocused = false;

    }

    openCustomScheduleModal(content) {
        this.scheduleModal = this.modalService.open(content, { size: 'sm' });
    }

    onDateSelection(date: NgbDate) {

      if (!this.disabled) {
          if (!this.fromDate && !this.toDate) {
              this.fromDate = date;
              this.isFocused = true;
          } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
              this.isFocused = false;

              this.toDate = date;
          } else if (this.fromDate && !this.toDate && date.equals(this.fromDate)) {
              this.isFocused = false;

              this.toDate = date;
          } else {
              this.isFocused = true;

              this.toDate = null;
              this.fromDate = date;
          }
      }

    }

    isHovered(date: NgbDate) {
        return !this.disabled && this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return !this.disabled && date.after(this.fromDate) && date.before(this.toDate);
    }



    isRange(date: NgbDate) {
        return !this.disabled && date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    isNotAllowed(date: NgbDate) {
        return date ? moment(new Date(this.formatter.format(date))).isBefore(moment().format('YYYY-MM-DD')) : false;

    }

    isUnavailable(date: NgbDate) {
        const newDate = moment(new Date(this.formatter.format(date))).format('YYYY-MM-DD');

        return this.schedules.includes(newDate);
    }

    updateSchedule(status) {
        const fromDate = this.formatter.format(this.fromDate);
        const toDate = this.formatter.format(this.toDate);
        const data = {
            fromDate,
            toDate,
            option: status,
        };
        this.updatingSchedule = true;

        this.productService.updateSchedules(data, this.productId, this.userId).then(
            (schedules: any) => {
                this.fromDate = null;
                this.toDate = null;
                this.schedules = schedules.data;
                this.updatingSchedule = false;

            },

            () => {
                this.fromDate = null;
                this.toDate = null;
                this.updatingSchedule = false;
            }
        );
    }


}
