import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {AppConfig, Country, CurrencyModel} from '../../models/app.model';
import {CurrencyPipe} from '@angular/common';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import * as countriesService from 'i18n-iso-countries';
import {Service} from '../../setting/settings';
declare var require: any;

@Injectable({
    providedIn: 'root'
})
export class AppService {

    title: string;
    appConfig = new AppConfig();
    appConfigSubject = new Subject<any>();
    titleSubject = new Subject<string>();

    constructor(private currencyPipe: CurrencyPipe,
                private authService: AuthService,
                private router: Router) {
        countriesService.registerLocale(require('i18n-iso-countries/langs/fr.json'));

    }
    getCountries(): Country[] {
        const countriesObject = countriesService.getNames('fr');
        const dialsCodes: Country[] = Service.DIAL_CODES;
        const countries: Country[] = [];

        for (const [key, value] of Object.entries(countriesObject)) {

            const dialCode = dialsCodes.find(
                (dial) => {
                    return dial.code === key;
                }
            );

            const country = {
                code: key,
                name: value,
                dial_code: dialCode && dialCode.dial_code ? dialCode.dial_code : null
            };
            countries.push(country);
        }

        return countries;
    }

    setAppTitle(title: string = null) {
        this.title = title;
        this.emitAppTitle();
    }

    emitAppTitle() {
        this.title = this.title ? this.title : environment.appName;
        this.titleSubject.next(this.title);
    }

    getUrlSegments(): Array<string> {
        if (this.router.url.split('?').length > 0) {
            return this.router.url.split('?')[0].split('/');
        }
        return this.router.url.split('/');
    }

    getCurrentUrlLastSegment(): string {
        return this.getUrlSegments()[this.getUrlSegments().length - 1];
    }

    emitAppConfig() {
        this.appConfigSubject.next(this.appConfig);
    }

    getCurrency(currency): CurrencyModel {
        return this.appConfig.defaultCurrencies.find(
            (curr: CurrencyModel) => {
                return curr.currency === currency && curr.activated;
            }
        );
    }

    convertPrice(price, from, to = this.appConfig.currency) {
        const currencyFrom = this.getCurrency(from);
        const currencyTo = this.getCurrency(to);

        if (currencyFrom && currencyTo) {
            const exchangeRateFrom = Number(parseFloat(currencyFrom.exchange_rate.convert.toString()).toFixed(4));
            const exchangeRateTo = Number(parseFloat(currencyTo.exchange_rate.convert.toString()).toFixed(4));
            price = price * exchangeRateFrom;
            return price * (1 / exchangeRateTo);
        } else {
            this.appConfig.currency = from;
            this.emitAppConfig();
        }

        return price;
    }

    getPriceUnitText(unit: string) {
        switch (unit) {
            case 'hour':
                return ' <small class="price-unit">/heure</small>';
            case 'night':
                return ' <small class="price-unit">/nuit</small>';
            case 'day':
                return ' <small class="price-unit">/jour</small>';
            default:
                return '';
        }
    }

    transformCurrency(price: number, from, to = this.appConfig.currency,
                      convert = true, unit = null, digitsInfo = null): any {

        to = to ? to : this.appConfig.currency;

        /* if (to === 'XAF') {
             digitsInfo = digitsInfo ? digitsInfo : '1.0-0';
         }

         if (to === 'EUR' || to === 'USD') {
             digitsInfo = digitsInfo ? digitsInfo : '1.2-2';
         } else {
             digitsInfo = digitsInfo ? digitsInfo : !(price % 1) ? '1.0-0' : '1.2-2';
         }*/

        const convertedPrice = convert ? this.convertPrice(price, from, to) : price;

        return this.currencyPipe.transform(
            convertedPrice.toString(),
            to,
            'symbol',
            '1.2-2',
            'fr-FR'
        ) + this.getPriceUnitText(unit);
    }

    fetchCurrencies() {
        this.authService.get('settings/currencies').then(
            (response: any) => {
                this.appConfig.defaultCurrencies = response.value;
                this.emitAppConfig();
            }
        );
    }

    fetchPriceRates() {
        return this.authService.get('settings/price_rates');
    }

}
