import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-room-tenant',
  templateUrl: './contract-room-tenant.component.html',
  styleUrls: ['./contract-room-tenant.component.css']
})
export class ContractRoomTenantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
