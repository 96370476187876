import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.css']
})
export class IconButtonComponent implements OnInit {
  // donnees du tableau 
  @Input() c: any;
  @Input() d: any;
  constructor() { }

  ngOnInit() {
  }

}
