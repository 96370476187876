import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminAuthService } from '../../services/admin.auth.service';
@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() id:any;
  @Input() checked:any;
  @Input() data:any;
  @Input() simple:boolean;
  @Output() SendValue = new EventEmitter<any>(); 
  constructor(private serviceAuthAdmin:AdminAuthService,
              private toastr: ToastrService) { 
   
  }

  ngOnInit() {
    // console.log('admin',this.data);
    if(this.simple){
      if(this.data==undefined){
        this.data={
          activated:0,
          id:0
        }
      }
    }
  }

  change(e,id?){

    if(this.simple){
   
      this.SendValue.emit({status:e.target.checked,id:id});
    }else{
      if(e.target.checked==true){
        this.serviceAuthAdmin.post(this.data.url+e.target.value,{admin_status:"active",_method:"PUT"}).then((data:any)=>{
          if(data==null){
            this.toastr.error('encours de developpement','Error');
            return;
          }
          this.toastr.success('Activation effectuée','Success');
         
         
        },err=>{
          console.log('erreur',err)
        });
  
      }else if(e.target.checked==false){
  
      
        this.serviceAuthAdmin.post(this.data.url+e.target.value,{admin_status:"inactive",_method:"PUT"}).then((data:any)=>{
          if(data==null){
            this.toastr.error('encours de developpement','Error');
            return;
          }
          this.toastr.success('Déactivation effectuée','Success');
        },err=>{
          
          console.log('erreur',err)
        });
  
      }
    }
   
   
  }

 
}
