import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../services/auth/auth.service';
import {NgForm} from '@angular/forms';
import {FormStep, RoomConfig} from '../../../../models/product.model';
import {Service} from '../../../../setting/settings';

declare var $: any;

@Component({
    selector: 'app-beds-room-form',
    templateUrl: './beds-room-form.component.html',
    styleUrls: ['./beds-room-form.component.css']
})
export class BedsRoomFormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() formMode = 'created';
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    errors: any;
    loading;
    loaded = false;
    product: any;
    params: Params;
    bedTypes =  [
        {
            id: 'sofa',
            title: 'Canapé-lit'
        },
        {
            id: 'couch',
            title: 'Canapé'
        },
        {
            id: 'floor-mattress',
            title: 'Matelas au sol'
        },
        {
            id: 'simple-bed',
            title: 'Lit simple'
        },
        {
            id: 'simple-bed-two',
            title: 'Petit lit deux places'
        },
        {
            id: 'doubled-bed',
            title: 'Lit double'
        },
        {
            id: 'queen-size-bed',
            title: 'Lit « queen size »'
        },
        {
            id: 'kind-size-bed',
            title: 'Lit « king size »'
        },
        {
            id: 'bunk-bed',
            title: 'Lit superposé'
        },
        {
            id: 'inflatable-mattress',
            title: 'Matelas gonflable'
        },
        {
            id: 'baby-crib',
            title: 'Lit pour bébé'
        },
        {
            id: 'children-bed',
            title: 'Lit pour enfant'
        },
        {
            id: 'hammock',
            title: 'Hamac'
        },
        {
            id: 'water-bed',
            title: 'Lit à eau'
        },
    ];
    productId;

    selectableBedTypes = [];

    arrRooms = [
        {
            name: 'number_beds',
            title: 'Nombre de lits',
            beds: []
        }
    ];

    qtyModel = [

        {
            id: 'number_people',
            title: 'Personne',
            heading: 'Nombre de personnes autorisées',
            qty: 0,
            config: []
        },
        {
            id: 'bedrooms',
            title: 'Chambre',
            heading: 'Nombre de chambres disponibles',
            qty: 0,
            config: []

        },
        {
            id: 'bathroom',
            title: 'Salles de bain',
            heading: 'Nombre de salles de bain',
            qty: 0,
            config: []

        },

        {
            id: 'sojourn',
            title: 'Séjour',
            heading: 'Nombre de séjours' ,
            qty: 0,
            config: []

        },

        {
            id: 'kitchen',
            title: 'Cuisine',
            heading: 'Nombre espace cuisine' ,
            qty: 0,
            config: []

        },
        {
            id: 'beds',
            title: 'Lit',
            heading: 'Nombre de lits',
            qty: 0,
            config: []

        },
    ];

    constructor(private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService) { }

    ngOnInit() {

        this.route.parent.params.subscribe(
            (params: Params) => {

                if (params.hasOwnProperty('id')) {

                    this.productId = params.id;
                }
            }
        );

        this.selectableBedTypes = this.bedTypes.slice();

        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user && !this.userProductSubscription) {
                        this.authUser = user;
                        this.userProductSubscription = this.productService.userProductSubject.subscribe(
                            (product: any) => {

                                if (product) {

                                    this.product = product;

                                    if (product.type.parent.tag === 'sport-hall' || product.type.parent.tag === 'offices') {

                                        this.qtyModel = [
                                            {
                                                id: 'number_people',
                                                title: 'Personnes',
                                                heading: 'Nombre de places disponibles',
                                                qty: 1,
                                                config: []
                                            },

                                            {
                                                id: 'surface',
                                                title: 'Surface',
                                                heading: 'Superficie',
                                                qty: 0,
                                                config: []
                                            },
                                        ];
                                    }

                                    if (product.surface) {
                                        this.setNumberItemsById('surface', product.surface);
                                    }

                                    if (product.number_people) {
                                        this.setNumberItemsById('number_people', product.number_people);
                                    }

                                    if (product.number_rooms) {
                                        this.setNumberItemsById('bedrooms', product.number_rooms);
                                    }

                                    if (product.number_beds) {
                                        this.setNumberItemsById('beds', product.number_beds);
                                    }

                                    if (product.number_bathroom) {
                                        this.setNumberItemsById('bathroom', product.number_bathroom);
                                    }

                                    if (product.number_sojourn) {
                                        this.setNumberItemsById('sojourn', product.number_sojourn);
                                    }
                                    if (product.number_kitchen) {
                                        this.setNumberItemsById('kitchen', product.number_kitchen);
                                    }

                                    if (product.rooms && product.rooms.length > 0) {

                                        this.setRoomsConfig(product.rooms);
                                    }

                                    if (product.beds && product.beds.length > 0) {

                                        this.setBedRooms(product.beds);

                                    } else if (product.type.parent.tag !== 'sport-hall' || product.type.parent.tag !== 'offices') {

                                        this.arrRooms[0].beds = [
                                            {
                                                id: 'simple-bed',
                                                title: 'Lit simple',
                                                qty: 0
                                            },
                                            {
                                                id: 'simple-bed-two',
                                                title: 'Petit lit deux places',
                                                qty: 0
                                            },
                                            {
                                                id: 'doubled-bed',
                                                title: 'Lit double',
                                                qty: 0
                                            }
                                        ];
                                    }

                                    this.loaded = true;

                                }
                            }
                        );

                        this.productService.getAuthUserProduct(user.id, this.productId).then(
                            () => {
                                this.productService.emitUserProduct();
                            },

                            (error) => {

                                console.log(error);

                                if (this.formMode === 'create') {

                                    this.router.navigate(['/rooms/new']);

                                }

                                if (this.formMode === 'edit') {

                                    this.router.navigateByUrl('not-found', {skipLocationChange: true});

                                }
                            }
                        );
                    }
                }
            );

            this.authService.emitUser();
        }
    }

    incrementInput(event) {
        event.target.value += 1;
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    doSubmit() {
        this.loading = true;

        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('number_people', this.getNumberItemsById('number_people').toString());

        if (this.product.type.parent.tag === 'sport-hall' || this.product.type.parent.tag === 'offices') {
            formData.append('surface', this.getNumberItemsById('surface').toString());

        } else {
            formData.append('number_rooms', this.getNumberItemsById('bedrooms').toString());
            formData.append('number_beds', this.getNumberItemsById('beds').toString());
            formData.append('number_bathroom', this.getNumberItemsById('bathroom').toString());
            formData.append('number_sojourn', this.getNumberItemsById('sojourn').toString());
            formData.append('number_kitchen', this.getNumberItemsById('kitchen').toString());
            formData.append('bedRooms', JSON.stringify(this.arrRooms));
            formData.append('roomsConfig', JSON.stringify(this.collectRoomsConfig()));

        }


        this.productService.updateUserProduct(this.authUser.id, this.product.id, formData).then(
            (product: any) => {

                this.loading = false;

                if (this.formMode === 'create') {

                    this.router.navigate([this.productService.getNextStep()]).then(
                        () => {
                            this.toastr.success('Informations de couchage modifiées');
                        }
                    );
                }

                if (this.formMode === 'edit') {

                    this.toastr.success('Modifications effectuées');

                }

            },
            (errors: any) => {
                this.loading = false;
                this.errors = errors.error.error;

            }
        );
    }

    setRoomsConfig(roomsConfigs) {

        this.qtyModel.forEach(
            (model) => {

                if (model.qty > 0 &&  model.id !== 'number_people' && model.id !== 'beds' && model.id !== 'surface') {

                    model.config = [];

                    roomsConfigs.map( (roomConfig) => {

                            if (roomConfig.name === model.id) {
                                const config: RoomConfig = {
                                    id: roomConfig.name,
                                    title: 'Superficie ' + model.title.toLowerCase() + ' N°' + ((model.config.length) + 1),
                                    surface: roomConfig.pivot.surface,
                                    isPrivate: roomConfig.pivot.is_private,
                                    batch: model.config.length + 1,
                                    name:  'room_config_' + (model.qty + 1) ,
                                };

                                model.config.push(config);

                            }
                        }
                    );
                }
            }
        );
    }

    setBedRooms(beds) {

        beds.map( (bed) => {

                const existingBed = this.arrRooms[0].beds.find(
                    (cBed) => {

                        return cBed.id === bed.name;
                    }
                );

                if (Number(bed.pivot.batch) === 0 && !existingBed) {

                    const bedRoom = {
                        id: bed.name,
                        title: bed.description,
                        qty: Number(bed.pivot.value)
                    };

                    this.arrRooms[0].beds.push(bedRoom);


                }
            }
        );
    }

    collectRoomsConfig() {

        const configs = [];

        this.qtyModel.map(
            (model) => {

                if (model.id !== 'number_people' && model.id !== 'beds' && model.id !== 'surface') {

                    model.config.map(
                        (config) => {
                            configs.push(config);
                        }
                    );
                }

            }
        );
        return configs;

    }

    getNumberItemsById(id) {
        let qty = 0;

        const item = this.qtyModel.find(
            (model) => {
                return model.id === id;
            }
        );

        if (item) {
            qty = item.qty;
        }

        return qty;
    }

    setNumberItemsById(id, qty) {

        const index = this.qtyModel.findIndex(
            (model) => {
                return model.id === id;
            }
        );

        if (this.qtyModel[index]) {
            this.qtyModel[index].qty = Number(qty);

        }

    }

    editIsPrivateRoomConfig(index, id, isPrivate: boolean) {


    }

    editQtyInput(index, id, qty) {
        if (this.checkUser()) {
            if (id !== 'beds') {

                if (qty < 0 && this.qtyModel[index].qty > 0) {

                    this.qtyModel[index].qty += qty;

                } else if (qty > 0) {

                    this.qtyModel[index].qty += qty;
                }
            }

            if (id !== 'number_people' && id !== 'beds' && id !== 'surface') {
                this.qtyModel.forEach(
                    (model) => {
                        if (model.id === id) {

                            if (qty > 0) {

                                const name: string = 'room_config_' + (model.qty + qty);
                                const  title: string = 'Superficie ' + model.title.toLowerCase() + ' N°' + (model.config.length + 1);
                                const batch: number = model.config.length + 1;
                                const config: RoomConfig = {
                                    id: model.id.toString(),
                                    name,
                                    title,
                                    batch,
                                    surface: 0,
                                    isPrivate: 0
                                };

                                model.config.push(config);
                            }

                            if (qty < 0 && model.config.length > 0) {

                                model.config.splice(model.config.length - 1, 1);

                            }
                        }
                    }
                );
            }
        }


    }

    checkUser() {
        if (this.authUser && this.product && this.product.published) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;

    }

    editQtyBed(indexRoom, indexBed, qty) {

        if (this.checkUser()) {
            if (qty < 0 && this.arrRooms[indexRoom].beds[indexBed].qty > 0) {

                this.arrRooms[indexRoom].beds[indexBed].qty += qty;

            } else if (qty > 0) {

                this.arrRooms[indexRoom].beds[indexBed].qty += qty;
            }
        }

    }

    editRoomConfig(indexModel, indexConfig, qty) {

        if (this.checkUser()) {
            if (qty < 0 && this.qtyModel[indexModel].config[indexConfig].surface > 0) {

                this.qtyModel[indexModel].config[indexConfig].surface += qty;

            } else if (qty > 0) {

                this.qtyModel[indexModel].config[indexConfig].surface += qty;
            }
        }

    }

    setCurrentBed(event, roomIndex, form: NgForm) {

        const currentIndex = event.target.options[event.target.selectedIndex].value;

        const bedType = this.bedTypes.find(
            (type) => {

                return type.id === currentIndex;
            }
        );

        const  bed = {
            id: bedType.id,
            title: bedType.title,
            qty: 1
        };

        this.arrRooms[roomIndex].beds.push(bed);

        const formName = 'bed_option_' + this.arrRooms[roomIndex].name;

        form.controls[formName].setValue('placeholder');

    }

    getNbrBeds(index) {

        let qty = 0;

        this.arrRooms[index].beds.map(
            (bed) => {

                qty += bed.qty;
            }
        );

        return qty;

    }

    getSelectableBedTypes(selectedBeds) {

        const selectableBedTypes = [];

        this.bedTypes.map(
            (bedType) => {

                let des = false;

                selectedBeds.map(
                    (selectedBed) => {
                        if (selectedBed.id === bedType.id) {
                            des = true;
                        }

                    }
                );

                if (!des) {
                    selectableBedTypes.push(bedType);
                }
            }
        );

        return selectableBedTypes;
    }



    ngAfterViewInit() {

        function toggleChevron(e) {
            $(e.target)
                .prev('.card-header')
                .find('i.indicator')
                .toggleClass('ti-angle-down ti-angle-up');
        }
        $('.accordion_2').on('hidden.bs.collapse shown.bs.collapse', toggleChevron);

    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
