import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AppConfig, CurrencyModel} from '../../models/app.model';
import {AppService} from '../../services/app/app.service';
import {FormControl} from '@angular/forms';
import {AuthUser} from '../../models/auth-user.model';
import {AuthService} from '../../services/auth/auth.service';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-footer-shapart',
    templateUrl: './footer-shapart.component.html',
    styleUrls: ['./footer-shapart.component.css']
})
export class FooterShapartComponent implements OnInit {
    @Input() type = 'long';

    public showScrollTop: boolean;
    public topPosToStartShowing = 100;
    appConfigSubscription: Subscription;
    appConfig: AppConfig;
    defaultCurrencies: CurrencyModel[];
    currencyInput = new FormControl();
    userSubscription: Subscription;
    authUser: AuthUser;

    constructor(private appService: AppService,
                private authService: AuthService,
                private userService: UserService,
                private toastr: ToastrService) {}

    ngOnInit() {

        this.currencyInput.valueChanges.subscribe(
            (val) => {
                if (val !== this.appService.appConfig.currency) {
                    this.changeCurrency();
                }
            }
        );

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                this.authUser = user;

            }
        );
        this.appConfigSubscription = this.appService.appConfigSubject.subscribe(
            (config: AppConfig) => {

                if (config) {
                    this.defaultCurrencies = config.defaultCurrencies;
                    this.appConfig = config;
                }
            }
        );

        this.appService.emitAppConfig();

    }

    @HostListener('window:scroll')
    checkScroll() {

        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (scrollPosition >= this.topPosToStartShowing) {
            this.showScrollTop = true;
        } else {
            this.showScrollTop = false;
        }
    }

    // TODO: Cross browsing
    gotoTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    changeCurrency() {

        if (this.authUser) {

            if (this.currencyInput.enabled) {
                this.currencyInput.disable();
            }

            const formData = {
                _method: 'PUT',
                currency: this.currencyInput.value
            };
            this.userService.editProfile(formData, this.authUser.id).then(
                (user: AuthUser) => {
                    this.authUser = user;
                    this.authService.authUser = user;
                    this.authService.emitUser();

                    if (this.currencyInput.disabled) {
                        this.currencyInput.enable();
                    }

                }, err => {
                    if (this.currencyInput.disabled) {
                        this.currencyInput.enable();
                    }
                    this.toastr.error('Désolé une erreu s\'est produite');
                    console.log(err);
                }
            );
        } else {

            const currency =  this.currencyInput.value;
            localStorage.setItem('currency', currency);
            this.appService.appConfig.currency = currency;
            this.appService.emitAppConfig();
        }

    }


}
