import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { routes} from './admin.routing.module';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { AdminTemplateComponent } from './template/admin-template/admin-template.component';
import { HeaderAdminShapartComponent } from './template/header-admin-shapart/header-admin-shapart.component';
import { FooterAdminShapartComponent } from './template/footer-admin-shapart/footer-admin-shapart.component';
import { NavbarAdminShapartComponent } from './template/navbar-admin-shapart/navbar-admin-shapart.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { CustomerComponent } from './customers/customer/customer.component';
import { DatatableComponent } from './common/datatable/datatable.component';
import { FilterComponent } from './common/filter/filter.component';
import {  NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DetailCustomerComponent } from './customers/detail-customer/detail-customer.component';
import { BreadcrumbComponent } from './common/breadcrumb/breadcrumb.component';
import { MenuPopComponent } from './common/menu-pop/menu-pop.component';
import { MenuLinkComponent } from './common/menu-link/menu-link.component';
import { OwnersComponent } from './owners/owners/owners.component';
import { ListAdminComponent } from './administrators/list-admin/list-admin.component';
import { NewAdminComponent } from './administrators/new-admin/new-admin.component';
import { ListProductsComponent } from './products/list-products/list-products.component';
import { EditAdminComponent } from './administrators/edit-admin/edit-admin.component';
import { ProfilAdminComponent } from './administrators/edit-admin/profil-admin/profil-admin.component';
import { PhotoAdminComponent } from './administrators/edit-admin/photo-admin/photo-admin.component';
import { PasswordAdminComponent } from './administrators/edit-admin/password-admin/password-admin.component';
import { EmailAdminComponent } from './administrators/edit-admin/email-admin/email-admin.component';
import { RoleDroitAdminComponent } from './administrators/edit-admin/role-droit-admin/role-droit-admin.component';
import { ToggleSwitchComponent } from './common/toggle-switch/toggle-switch.component';
import { RolesAdminComponent } from './administrators/roles-admin/roles-admin.component';
import { NewRoleComponent } from './administrators/roles-admin/new-role/new-role.component';
import { PermissionAdminComponent } from './administrators/permission-admin/permission-admin.component';
import { ToggleSimpleComponent } from './common/toggle-simple/toggle-simple.component';
import { HideIfUnauthorizedDirective } from './security/directive/hide-if-unauthorized.directive';
import { AuthGuardService } from './security/service/auth-guard.service';
import { AuthorizationService } from './security/service/authorization.service';
import { HideUlIfNotChildenDirective } from './security/directive/hide-ul-if-not-childen.directive';
import { DetailProductComponent } from './products/detail-product/detail-product.component';
import { StatusComponent } from './common/status/status.component';
import { ListAgentComponent } from './agents/list-agent/list-agent.component';
import { DetailAgentComponent } from './agents/detail-agent/detail-agent.component';
import { SharingModule } from '../sharing/sharing.module';
import { AdminGuardService } from './security/service/admin-guard.service';
import { ProductsAgentComponent } from './agents/products-agent/products-agent.component';
import { ListBookingsComponent } from './bookings/list-bookings/list-bookings.component';
import { AdminProfilComponent } from './admin-profil/admin-profil.component';
import { ActivedDeactivedComponent } from './common/actived-deactived/actived-deactived.component';
import { AdminService } from './services/admin.service';
import { AdminAuthService } from './services/admin.auth.service';
import { AdminAccountService } from './services/admin.account.service';
import { RequestStatusComponent } from './requests/request-status/request-status.component';
import { PaymentComponent } from './payment/payment.component';
import { RequestsComponent } from './requests/requests/requests.component';
import { DetailBookingComponent } from './bookings/detail-booking/detail-booking.component';
import { ListAgenciesComponent } from './agencies/list-agencies/list-agencies.component';
import { DetailAgencyComponent } from './agencies/detail-agency/detail-agency.component';
import { VisitorsComponent } from './visitors/visitors.component';
import { CurrentAdminService } from './services/current-admin.service';
import { CurrencyComponent } from './accounting/currency/currency.component';
import { ServiceRateComponent } from './accounting/service-rate/service-rate.component';
import { ActionButtonComponent } from './common/action-button/action-button.component';
import { ItemsAppartComponent } from './common/items-appart/items-appart.component';
import { DetailRequestComponent } from './requests/detail-request/detail-request.component';
import { DetailVisitorComponent } from './visitors/detail-visitor/detail-visitor.component';
import { ListPaymentComponent } from './payment/list-payment/list-payment.component';
import { IconButtonComponent } from './common/icon-button/icon-button.component';
import { MoreDetailProductComponent } from './products/more-detail-product/more-detail-product.component';
import { MoreDetailCustomerComponent } from './customers/more-detail-customer/more-detail-customer.component';
import { AddCurrencyComponent } from './accounting/currency/add-currency/add-currency.component';
import { FilterDateComponent } from './common/filter/filter-date/filter-date.component';
import { FilterAmountComponent } from './common/filter/filter-amount/filter-amount.component';
import { FilterDateTypeComponent } from './common/filter/filter-date-type/filter-date-type.component';
import { ExportFileComponent } from './common/export-file/export-file.component';
import { TravelersComponent } from './travelers/travelers.component';
import { DetailTravelerComponent } from './travelers/detail-traveler/detail-traveler.component';
import { DetailOwnerComponent } from './owners/detail-owner/detail-owner.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ExcelService } from './services/excel.service';
import { ListRefundsComponent } from './payment/refunds/list-refunds/list-refunds.component';
import { DetailRefundComponent } from './payment/refunds/detail-refund/detail-refund.component';
import { DetailTransferComponent } from './payment/transfer/detail-transfer/detail-transfer.component';
import { TransferPendingComponent } from './payment/transfer/transfer-pending/transfer-pending.component';
import { DetailPaymentComponent } from './payment/list-payment/detail-payment/detail-payment.component';
import { CardComponent } from './common/card/card.component';
import { CardButtonComponent } from './common/card/card-button/card-button.component';
import { CardAsideComponent } from './common/card/card-aside/card-aside.component';
import { NotificationsComponent } from './common/notifications/notifications.component';
import { ConfirmationDialogService } from './common/notifications/notification.service';
import { ListDataComponent } from './collection-data/list-data/list-data.component';
import { ListRankingHostComponent } from './rankings/hosts/list-ranking-host/list-ranking-host.component';


import { BanksComponent } from './accounting/banks/banks.component';
import { FilterRankingComponent } from './rankings/filter-ranking/filter-ranking.component';
import { ReductionComponent } from './accounting/reduction/reduction.component';
import { PreferenceTransferComponent } from './payment/preference-transfer/preference-transfer.component';
import { CommonModule } from '@angular/common';
import { ClaimsComponent } from './claims/claims.component';
import { BackButtonComponent } from './common/back-button/back-button.component';
import { HttpAdminService } from './services/http-admin.service';
import { DetailClaimComponent } from './claims/detail-claim/detail-claim.component';
import { LightboxModule } from 'ngx-lightbox';
import { DocumentsCustomerComponent } from './customers/detail-customer/documents-customer/documents-customer.component';
import { CategoriesComponent } from './products/categories/categories.component';
import { SousCategoriesComponent } from './products/categories/sous-categories/sous-categories.component';
import { GoogleanalyticsComponent } from './googleanalytics/googleanalytics.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';





@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  
  declarations: [
    LoginComponent,
    AdminTemplateComponent,
    HeaderAdminShapartComponent, 
    FooterAdminShapartComponent, 
    NavbarAdminShapartComponent, 
    DashboardComponent,
    CustomerComponent,
    DatatableComponent,
    FilterComponent,
    DetailCustomerComponent,
    BreadcrumbComponent,
    MenuPopComponent,
    MenuLinkComponent,
    OwnersComponent,
    ListAdminComponent,
    NewAdminComponent,
    ListProductsComponent,
    EditAdminComponent,
    ProfilAdminComponent,
    PhotoAdminComponent,
    PasswordAdminComponent,
    EmailAdminComponent,
    RoleDroitAdminComponent,
    ToggleSwitchComponent,
    RolesAdminComponent,
    NewRoleComponent,
    PermissionAdminComponent,
    ToggleSimpleComponent,
    HideIfUnauthorizedDirective,
    HideUlIfNotChildenDirective,
    DetailProductComponent,
    StatusComponent,
    ListAgentComponent,
    DetailAgentComponent,
    ProductsAgentComponent,
    ListBookingsComponent,
    AdminProfilComponent,
    ActivedDeactivedComponent,
    RequestStatusComponent,
    PaymentComponent,
    RequestsComponent,
    DetailBookingComponent,
    DetailPaymentComponent,
    ListAgenciesComponent,
    DetailAgencyComponent,
    VisitorsComponent,
    CurrencyComponent,
    ServiceRateComponent,
    ActionButtonComponent,
    ItemsAppartComponent,
    DetailRequestComponent,
    DetailVisitorComponent,
    ListPaymentComponent,
    IconButtonComponent,
    MoreDetailProductComponent,
    MoreDetailCustomerComponent,
    AddCurrencyComponent,
    FilterDateComponent,
    FilterAmountComponent,
    FilterDateTypeComponent,
    ExportFileComponent,
    TravelersComponent,
    DetailTravelerComponent,
    DetailOwnerComponent,
    ListRefundsComponent,
    DetailRefundComponent,
    TransferPendingComponent,
    DetailTransferComponent,
    CardComponent,
    CardButtonComponent,
    CardAsideComponent,
    NotificationsComponent,
    ListDataComponent,
    ListRankingHostComponent,
    BanksComponent,
    FilterRankingComponent,
    ReductionComponent,
    PreferenceTransferComponent,
    ClaimsComponent,
    BackButtonComponent,
    DetailClaimComponent,
    DocumentsCustomerComponent,
    CategoriesComponent,
    SousCategoriesComponent,
    GoogleanalyticsComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    LightboxModule,
    NgbPopoverModule,
    SharingModule,
    NgxPaginationModule,
    CommonModule,
    AutocompleteLibModule
  
  
  ],
  providers: [
    HttpAdminService,
    AdminAuthService,
    AdminAccountService,
    AdminService,
   
    
    AuthGuardService,
    AuthorizationService,
    AdminGuardService,
    CurrentAdminService,
    ExcelService,
    ConfirmationDialogService
  ],
  entryComponents: [NotificationsComponent]
  
})

export class AdminModule { }
