import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserService} from '../../../services/user/user.service';
import {AuthService} from '../../../services/auth/auth.service';
import {ApiResponse} from '../../../models/response.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseData} from '../../../models/response-data.model';
import {ProductsService} from '../../../services/products/products.service';
import {AuthUser} from '../../../models/auth-user.model';
import {FormStep} from '../../../models/product.model';
import {NavLink} from '../../../models/app.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-rooms-list',
    templateUrl: './rooms-list.component.html',
    styleUrls: ['./rooms-list.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class RoomsListComponent implements OnInit, OnDestroy {
    user: AuthUser;
    rooms: any;
    userSubscription: Subscription;
    loaded = false;
    loading = true;
    response: ApiResponse;
    empty;
    queryParams: any = {
        per_page: 6,
        sort_by_desc: 'created_at',
        page: 1
    };
    userRole = null;
    status = null;
    selectedProduct: any;
    deletionModal: any;
    isLoading = false;
    agentExist = true;

    constructor(private userService: UserService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private modalService: NgbModal,
                private toastr: ToastrService) { }

    ngOnInit() {

        this.userSubscription = this.authService.userSubject.subscribe(
            (user) => {
                if (user) {
                    this.user = user;
                    this.route.queryParams.subscribe(
                        params => {
                            this.agentExist = true;

                            if (params.page) {
                                this.queryParams.page = params.page;
                            } else {
                                delete this.queryParams.page;
                            }

                            if (params.user_role) {
                                this.queryParams.user_role = params.user_role;
                                this.userRole = params.user_role;
                            } else {
                                delete this.queryParams.user_role;
                                this.userRole = null;
                            }

                            if (params.status) {
                                this.queryParams.status = params.status;
                                this.status = params.status;

                            } else {
                                delete this.queryParams.status;
                                this.status = null;
                            }

                            if (this.userRole === 'agent' || this.userRole === 'owner') {
                                delete this.queryParams.user_role;

                                if (this.userRole === 'agent' && !this.user.agency) {
                                    this.loading = false;
                                    this.agentExist = false;
                                    return;
                                }
                            }

                            this.getUserRooms();

                        }
                    );
                }
            }
        );

        this.authService.emitUser();

    }

    openDeletionModal(content, selectedProduct) {

        this.selectedProduct = selectedProduct;

        this.deletionModal = this.modalService.open(content, { centered: true, size: 'sm' });
    }

    deleteProduct() {
        this.isLoading = true;

        this.productService.deleteUserProduct(this.user.id, this.selectedProduct.id).then(
            (product: any) => {
                this.isLoading = false;

                this.getUserRooms();

                this.deletionModal.close();

                this.router.navigate(['/account/rooms']).then(
                    () => {
                        this.toastr.info('L\'annonce a été supprimée avec succès');
                    }
                );
            },

            () => {
                this.isLoading = false;
                this.toastr.error('Désolé une erreur s\'est produite');
            }
        );
    }


    filterByStatus(event) {

        const val = event.target.options[event.target.selectedIndex].value;
        const queryParams: any = {};
        delete this.queryParams.page;

        if (this.userRole) {
            queryParams.user_role = this.userRole;
        }

        if (val) {
            queryParams.status = val;
        } else {
            delete this.queryParams.status;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
        });
    }

    getCurrentStepUrl(product) {

        if (this.isProductCompleted(product)) {
            return null;
        }

        const formSteps: FormStep[] = this.productService.getFormWizardStep(product);
        const step: FormStep = formSteps.find(
            (formStep: FormStep) => {
                return formStep.tag === product.current_step;
            }
        );
        return step ? step.url : null;
    }

    isProductSigned(room): boolean {
        return !room.contract || (room.contract && room.contract.signed);
    }

    isProductCompleted(room): boolean {
        return !room.current_step || room.verified || room.published;

    }

    getNavLinks(): NavLink[] {
        const url = this.router.createUrlTree(['/account/rooms']).toString();

        return [
            {
                title: 'Toutes les annonces',
                link: url,
                isActive: this.loaded && !this.userRole,
            },

            {
                title: 'En tant que agent',
                link: url,
                queryParams: {user_role: 'agent'},
                isActive: this.userRole && this.userRole === 'agent',
            },
            {
                title: 'En tant que locataire',
                link: url,
                queryParams: {user_role: 'tenant'},
                isActive: this.userRole && this.userRole === 'tenant',
            },
            {
                title: 'En tant que hôte',
                link: url,
                queryParams: {user_role: 'owner'},
                isActive: this.userRole && this.userRole === 'owner',
            }
        ];
    }

    getUserRole() {
        if (this.userRole === 'owner') {
            return 'owners';
        }

        if (this.userRole === 'agent') {
            return 'agents';
        }

        return 'users';
    }

    getUserRooms() {
        this.loading = true;

        this.userService.getUserAnnounces(this.user.id, this.getUserRole(), this.queryParams).then(
            (response: ResponseData) => {
                this.response = response.data;
                this.loaded = true;
                this.loading = false;
                this.rooms =  response.data ? response.data.data : [];
                this.empty = !response.data;
            },
            err => {
                this.loaded = true;
                this.response = null;
                console.log(err);
            });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }


}
