import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormWizardStep} from '../../../../models/app.model';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../../services/auth/auth.service';
import {FormWizardService} from '../../../../services/form-wizard/form-wizard.service';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../../services/user/user.service';

@Component({
    selector: 'app-profile-account-verify',
    templateUrl: './profile-account-verify.component.html',
    styleUrls: ['./profile-account-verify.component.css']
})
export class ProfileAccountVerifyComponent implements OnInit, OnDestroy {
    authUser: AuthUser;
    userSubscription: Subscription;
    currentStep: any;
    documents: any = [];
    loaded = false;

    constructor(private authService: AuthService,
                private formWizardService: FormWizardService,
                private route: ActivatedRoute,
                private userService: UserService) { }

    ngOnInit(): void {

        this.userSubscription = this.authService.userSubject.subscribe(
            authUser => {
                this.authUser = authUser;
                if (this.authUser) {
                    this.route.params.subscribe(
                        params => {
                            if (params.hasOwnProperty('step')) {
                                this.currentStep = params.step;
                            } else {
                                this.currentStep = null;
                            }

                            if (this.formWizardService.formWizard) {
                                this.formWizardService.updateFormWizardSteps(this.getFormWizardSteps());
                                this.formWizardService.setActiveStep(this.currentStep || this.getFormWizardSteps()[0].tag);
                            }
                        }
                    );
                    this.getUserDocuments();
                }
            }
        );

        this.authService.emitUser();
    }

    getUserDocuments(): void {
        this.userService.getUserDocuments(this.authUser.id, {per_page: '*'}).then(
            (response: any) => {
                this.documents = response.data || response;
                this.formWizardService.createFormWizard('Vérification de l\'identité', this.getFormWizardSteps());
                this.formWizardService.setLoaded(true);
                this.formWizardService.setActiveStep(this.currentStep || this.getFormWizardSteps()[0].tag);
                this.loaded = true;
            }
        );
    }

    getFormWizardSteps(): FormWizardStep[] {

        const steps: FormWizardStep[] = [];

        if (this.formWizardService.findStep('informations') || !this.authUser.completed) {
            steps.push({
                title: 'Compléter mon profil',
                subTitle: 'Pour vérifier votre identité vous devez compléter votre profil',
                url:  'account/profile/verify/identity',
                tag:   'informations',
                isRequired: this.authUser.completed,
                isSubmittable: true,
                isActive: true,
                requiredStep: null,
                icon: null
            });
        }

        steps.push({
            title: 'Pièce d\'identité',
            url:  'account/profile/verify/identity',
            subTitle: 'Pour vérifier votre identité vous devez fornir une photo de votre pièce d\'identité',
            tag:   'identity',
            isRequired: !this.hasIdentityDocument() || !this.getDocument(this.authUser.address_proof_type),
            isSubmittable: true,
            isActive: steps.length === 0,
            requiredStep: null,
            icon: null
        });

        steps.push({
            title: 'Prenez une photo de vous',
            subTitle: 'Pour vérifier votre identité vous devez fornir une photo de vous',
            url:  'account/profile/verify/photo',
            tag:   'photo',
            isRequired: !this.getDocument('user_snapped_photo'),
            isSubmittable: true,
            isActive: false,
            requiredStep: 'identity',
            icon: null
        });

        steps.push({
            title: 'Récapitulatif de vos documents',
            subTitle: 'Vos documents ont bien été enregistrés. Nous les vérifions.',
            url:  'account/profile/verify/checkout',
            tag:   'checkout',
            isRequired: false,
            isSubmittable: true,
            isActive: false,
            requiredStep: 'photo',
            icon: null
        });

        return steps;
    }

    hasIdentityDocument(): boolean {
        if (this.authUser.identity_type === 'id_card') {
            const recto = this.getDocument('user_identity_image_recto');
            const verso = this.getDocument('user_identity_image_verso');
            return !!recto && !!verso;
        }

        if (this.authUser.identity_type === 'passport') {
            return !!this.getDocument('user_passport_image');
        }

        return false;
    }

    getDocument(title: string): any {
        return this.documents.find(document => document.title === title);
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }



}
