import { Component, OnInit, OnDestroy } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  data from '../../json/data.json';
import * as  datatable from '../../json/datatable/datatable_payments.json';
import { Subscription } from 'rxjs';
import { ApiResponse } from 'src/app/models/response.model';
import { AdminAccountService } from '../../services/admin.account.service';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { ResponseData } from 'src/app/models/response-data.model';
import {IPagination} from '../../model/pagination';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-list-payment',
  templateUrl: './list-payment.component.html',
  styleUrls: ['./list-payment.component.css']
})
export class ListPaymentComponent implements OnInit,GetAdmin {

  loaded = false;
  breadcrumb=breadcrumb.requests;
  data_json=datatable.payments;
  response: ApiResponse;
  adminId: any;
  queryParams: any = {
    per_page: 15,
    page: 1
};
  data: any;
  paramsPage: IPagination;
  constructor(private adminService:AdminService, private route: ActivatedRoute)
   { 
    this.paramsPage={} as IPagination;  
   }
  

  ngOnInit() {

    this.informationAdmin();
  }

  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;  
    });
    this.getParams();
   }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
          if (params.page) {
              this.queryParams.page = params.page;
          }
          if(params){
           
            if(this.adminId){
           
              this.getAllRequests( this.adminId,location.search);
            }
          
          }else{
           if(this.adminId){
            this.getAllRequests(this.adminId);
           }
           
          }
      }); 
  } 
  

  getAllRequests(AdminId, params?:string){
    
    this.adminService.getAllReservations(AdminId).then((response:any)=>{

        this.data=response.data ? response.data.data : [];
        this.loaded = true;
        this.paramsPage.currentPage=response.data?response.data.current_page:0;
        this.paramsPage.totalItems=response.data?response.data.total:0;
        this.paramsPage.currentPage=response.data?response.data.current_page:0;
        this.paramsPage.itemsPerPage=response.data?response.data.per_page:0;
        // console.log('list demande',this.data);
    },err=>{
     
      console.log('ERREUR',err);
    });
  }

  getPage(page){
 
    this.getAllRequests(this.adminId,"?page="+page);
  }
}
