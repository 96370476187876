import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoadingSpinnersComponent} from './loading-spinners/loading-spinners.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbDropdownModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { DetailProductViewComponent } from './products/detail-product-view/detail-product-view.component';
import { ProductHostComponent } from './products/partials/product-host/product-host.component';
import { ProductAddressComponent } from './products/partials/product-address/product-address.component';
import { ProductOptionsComponent } from './products/partials/product-options/product-options.component';
import { ProductBookingSidebarComponent } from './products/partials/product-booking-sidebar/product-booking-sidebar.component';
import { ProductImagesPreviewComponent } from './products/partials/product-images-preview/product-images-preview.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TruncatePipesModule } from 'angular-truncate-pipes';
import { AgmCoreModule } from '@agm/core';
import { ProductDocumentsComponent } from './products/partials/product-documents/product-documents.component';
import {LongPressDirective} from '../directives/long-press.directive';
import { CardAppartComponent } from '../admin/common/card-appart/card-appart.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NgxStickySidebarModule } from '@smip/ngx-sticky-sidebar';
import { EmptyRecipientComponent } from './empty-recipient/empty-recipient.component';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { PriceTagComponent } from './price-tag/price-tag.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxPayPalModule } from 'ngx-paypal';
import { NouisliderModule } from 'ng2-nouislider';
import {GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';
import { MatSliderModule } from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TemplateWizardComponent } from './template-wizard/template-wizard.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    declarations: [
        LoadingSpinnersComponent,
        CalendarComponent,
        UploadImageComponent,
        DetailProductViewComponent,
        ProductHostComponent,
        ProductAddressComponent,
        ProductOptionsComponent,
        ProductBookingSidebarComponent,
        ProductImagesPreviewComponent,
        ProductDocumentsComponent,
        LongPressDirective,
        CardAppartComponent,
        DatepickerComponent,
        EmptyRecipientComponent,
        PriceTagComponent,
        TemplateWizardComponent,
    ],
    imports: [
        NgxDocViewerModule,
        MatSlideToggleModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        LightboxModule,
        GalleryModule,
        NouisliderModule,
        MatSliderModule,
        NgbModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TruncatePipesModule,
        NgbPopoverModule,
        NgxStickySidebarModule,
        NgxIntlTelInputModule,
        NgbDropdownModule,
        NgxSkeletonLoaderModule,
        NgxPayPalModule,
        CommonModule,
        AgmCoreModule.forRoot(  {
            apiKey: 'AIzaSyDXr-tBvlC_BApCsY8NvZy4rFFss4nlxok',
            libraries: ['places']
        })
    ],
    exports: [
        NgxDocViewerModule,
        TemplateWizardComponent,
        MatSlideToggleModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        LightboxModule,
        GalleryModule,
        NouisliderModule,
        MatSliderModule,
        LoadingSpinnersComponent,
        CalendarComponent,
        UploadImageComponent,
        DetailProductViewComponent,
        ProductHostComponent,
        ProductAddressComponent,
        ProductOptionsComponent,
        ProductBookingSidebarComponent,
        ProductImagesPreviewComponent,
        RouterModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TruncatePipesModule,
        NgbPopoverModule,
        NgbDropdownModule,
        AgmCoreModule,
        ProductDocumentsComponent,
        LongPressDirective,
        CardAppartComponent,
        DatepickerComponent,
        EmptyRecipientComponent,
        NgxIntlTelInputModule,
        NgxStickySidebarModule,
        PriceTagComponent,
        NgxSkeletonLoaderModule,
        NgxPayPalModule,
    ]
})
export class SharingModule {
    static forRoot(): ModuleWithProviders<SharingModule> {
        return {
            ngModule: SharingModule,
            providers: [SharingModule]
        };
    }
}
