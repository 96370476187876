import {Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {AuthUser} from '../../../models/auth-user.model';
import {AppService} from '../../../services/app/app.service';
import {AppConfig} from '../../../models/app.model';
declare var $: any;
declare var magnificPopup: any;

@Component({
    selector: 'app-detail-product',
    templateUrl: './detail-product.component.html',
    styleUrls: ['./detail-product.component.css']
})

export class DetailProductComponent implements OnInit, OnDestroy {
    userSubscription: Subscription;
    id: number;
    product: any;
    loaded = false;
    authUser: AuthUser;
    appConfigSubscription: Subscription;
    searchs: any = {};
    loading: boolean;
    queryParamsSubscription: Subscription;
    private currency: string;

    constructor(private route: ActivatedRoute,
                private serviceProduct: ProductsService,
                private router: Router,
                private toastr: ToastrService,
                private userService: UserService,
                private authService: AuthService,
                private appService: AppService) {}

    ngOnInit() {
        this.id = this.route.snapshot.params.id;

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                if (user) {
                    this.authUser = user;
                }
            }
        );

        if (!this.queryParamsSubscription) {

            let hasQueryParams = false;
            this.queryParamsSubscription = this.route.queryParamMap.subscribe(
                params => {
                    const query: any = {};

                    if (params.get('latitude')) {
                        query.latitude = params.get('latitude');
                    }

                    if (params.get('longitude')) {
                        query.longitude = params.get('longitude');
                    }

                    if (params.get('dateStart')) {
                        query.dateStart = params.get('dateStart');
                    }

                    if (params.get('dateEnd')) {
                        query.dateEnd = params.get('dateEnd');
                    }

                    if (params.get('adults')) {
                        query.adults = params.get('adults');
                    }

                    if (params.get('children')) {
                        query.children = params.get('children');
                    }

                    if (params.get('babies')) {
                        query.babies = params.get('babies');
                    }

                    this.searchs = query;
                    hasQueryParams = true;

                    this.__getOneProduct();

                }
            );

            if (!this.loading && !this.loaded && !hasQueryParams) {
                this.__getOneProduct();
            }
        }


        this.appConfigSubscription  = this.appService.appConfigSubject.subscribe(
            (config: AppConfig) => {
                this.currency = config.currency;

                if (config.currency && this.loaded && (!this.searchs.currency || this.searchs.currency !== config.currency)) {
                    this.__getOneProduct();
                }
            }
        );


        this.appService.emitAppConfig();

        this.authService.emitUser();


    }

    checkUser(product) {
        if (this.authUser && (!product.verified || !product.activated)) {
            if (product.manager_type === 'tenant' || product.manager_type === 'owner') {
                return product.user_id === this.authUser.id;
            }
            if (product.manager_type === 'agent') {
                return product.agent.id === this.authUser.id || product.owner.id === this.authUser.id;
            }
        }

        return product.verified && product.activated;

    }


    __getOneProduct() {

        this.loading = true;
        this.searchs.currency = this.currency ? this.currency : this.appService.appConfig.defaultCurrency;

        this.serviceProduct.getProduct(this.id, this.searchs).then(
            (data: any) => {

                this.loading = false;

                if (this.checkUser(data)) {

                    if (!this.product) {
                        this.product = data;
                    } else {
                        this.product.pricing = data.pricing;
                    }

                    this.loaded = true;
                } else {
                    this.router.navigateByUrl('not-found', {skipLocationChange: true});
                }
            },

            err => {
                console.log(err);
                this.router.navigateByUrl('not-found', {skipLocationChange: true});
            }
        );
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.appConfigSubscription) {
            this.appConfigSubscription.unsubscribe();
        }

        if (this.queryParamsSubscription) {
            this.queryParamsSubscription.unsubscribe();
        }
    }
}
