import {Component, Input, OnInit} from '@angular/core';
import {AuthUser} from '../../../../../../models/auth-user.model';

@Component({
    selector: 'app-room-edit-host',
    templateUrl: './room-edit-host.component.html',
    styleUrls: ['./room-edit-host.component.css'],
})
export class RoomEditHostComponent implements OnInit {
    @Input() product: any;
    @Input() host: any;
    @Input() contactMode = true;
    @Input() mode = 'details';
    @Input() authUser: AuthUser;
    @Input() userRole = 'owner';
    @Input() viewAs = 'guest';

    constructor() { }

    ngOnInit() {
    }




}
