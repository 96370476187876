import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  security from '../../json/security/security.json';

import * as  datatable from '../../json/datatable/datatable_agency_products.json';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../common/notifications/notification.service';


@Component({
  selector: 'app-detail-agency',
  templateUrl: './detail-agency.component.html',
  styleUrls: ['./detail-agency.component.css']
})
export class DetailAgencyComponent implements OnInit {

  agency: any;
  breadcrumb=breadcrumb.datail_agency;
  status: string[];
  loaded:any;
  data_json=datatable.agency_products;
  list_status=datatable.agency_products.status;
  contracts: any;
  spinner: boolean;
  type:any;
  adminId: void;
  security=security.security.agency;

  reservations: any;
  montant={ca:0, gains:0 ,nbr_refund:0,total_refund:0,nbr_reservation:0};
  demands: any;
  constructor(  private toastr: ToastrService,
    private route: ActivatedRoute,
    private adminService:AdminService,
    public config: NgbModalConfig,
    private dialog :ConfirmationDialogService,
    public router:Router) { 
      this.loaded=false;
      config.backdrop = 'static';
      config.keyboard = false;
    
     
    }


  ngOnInit() {
    
    this.informationAdmin();
  }
  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
     this.adminId = admin.id;
    
     this.getOneAgency(this.adminId,this.route.snapshot.params.id)
    
    });
   }

  getOneAgency(idAdmin,id){
   
    this.adminService.getAgency(idAdmin,id).then((data:any)=>{
    
      this.agency=data;
      this.adminService.setTitle('information sur le l\'agence'+ this.agency.name);
      this.getSatus(this.agency.status);
      this.loaded=true;
      this.getContractAgency(idAdmin,this.agency.id);
      this.getAllReservationsAgency(idAdmin,this.agency.user_id);
      this.getAllDemandsAgency(idAdmin,this.agency.user_id);
    },err=>{
      console.log('erreur',err)
    })
  }




 updateAgency(type){
  this.type=type;
  let data={
      type:type,
      _method:"PUT"
  }
  this.adminService.updateAgencyAdmin(this.adminId,this.route.snapshot.params.id,data).then((data:any)=>{
 
     this.agency=data;
    this.getSatus( this.agency.status);
    if(type==="Activate"){
   
      this.toastr.success('Agence activée avec succès', 'Success');
    }else if(type==="Disabled"){
      this.toastr.success('Agence déactivée avec succès', 'Success');
    }else if(type==="Validate"){
      this.toastr.success('Agence validée avec succès', 'Success');
    }
    this.spinner=false;
  },err=>{
    console.log('Err',err);
  })

 }

  rejectOrApproveAgnecy(type){
    let messages = [{
      type: "Activate",
      title: "Activer l'agence",
      message: 'Voulez vous activer cette agence?'
    },
    {
      type: "Disabled",
      title: "Désactiver l'agence",
      message: 'Voulez vous désactiver cette agence?'
    },
    {
      type: "Validate",
      title: "Valider l'agence",
      message: 'Voulez vous valider cette agence?'
    },
    {
      type: "Rejected",
      title: "Rejetter l'agence",
      message: 'Voulez vous rejeter cette agence?'
    }]
    this.spinner=true;
   
    let m=messages.find(element=> element.type==type);
  
    this.dialog.confirm(m.title,m.message).then(reponse=>{
      if(reponse){
        this.updateAgency(type);
      }
    });
   

  
  }



  getContractAgency(adminId,agencyId){
    this.adminService.getAllContractAgency(adminId,agencyId).then((contracts:any)=>{
   
      this.contracts=contracts?contracts.data:null;
      // console.log('contrat', this.contracts);
     
      
    },err=>{
      console.log('ERREUR',err);
    });
  }

  getAllReservationsAgency(adminId,agencyId){
    this.adminService.getAllReservationsAgency(adminId,agencyId).then((reservations:any)=>{
      this.reservations=reservations.data?reservations.data:null;
    
      if(this.reservations){
          // console.log('reservations',this.reservations);
        this.reservations.forEach(reservation => {
          this.montant.nbr_reservation=this.montant.nbr_reservation+1;
            reservation.payments.forEach(payment => {
              // console.log('payement',payment);
              if(payment.payment_type=="expense" && payment.payment_status=="success" &&  payment.user_role=="tenant" ){
                this.montant.ca=this.montant.ca+parseFloat(payment.total_paid);   
              }

              if(payment.payment_type=="transfer" &&  payment.user_role=="agent" ){
                if(payment.payment_status=="success"|| payment.payment_status=="pending"){
                  // a corriger pour mettre payment.total_paid
                  this.montant.gains=this.montant.gains+parseFloat(payment.total);
                  // console.log('ca', this.montant);
                }
               
              }
            });
          
        });
      }
     
      console.log('montant', this.montant)
    },err=>{
      console.log('ERREUR',err);
    });
  }

  getAllDemandsAgency(adminId,agencyId){
      this.adminService.getAllDemandsAgency(adminId,agencyId).then((demands:any)=>{
        this.demands= demands?demands.data:null;
        // console.log('demands', this.demands);
      },err=>{ console.log('error',err)});
  }




  getSatus(status) {
    this.status = [];
    let s=  this.list_status.find(e=>e.value===status)
      this.status.push(s.class);
      this.status.push(s.text);
  }

  openUrl(id,url,subUrl){
    // console.log('url',url+"/"+id+"/"+subUrl);
    this.router.navigate([url,id,subUrl]);
  }

}
