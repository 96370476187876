import {Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../services/user/user.service';
import {AuthService} from '../../../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import {NavLink} from '../../../models/app.model';
import {AuthUser} from '../../../models/auth-user.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-profile-account',
    templateUrl: './profile-account.component.html',
    styleUrls: ['./profile-account.component.css']
})
export class ProfileAccountComponent implements OnInit, OnDestroy {
    @ViewChild('modalVerifyStatus') modalVerifyStatus: TemplateRef<any>;

    user: AuthUser;
    id: any;
    errors: any;
    loading = false;
    userSubscription: Subscription;
    section = 'personal';
    action = 'preview';
    private availableSections = [
        'personal',
        'agency',
        'verify',
    ];

    private availableActions = [
        'preview',
        'edit',
    ];
    protected verifyStatusModal: NgbModalRef;
    private documents: any;
    loaded: boolean;

    constructor(private toastr: ToastrService,
                private userService: UserService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private modalService: NgbModal,
                private renderer: Renderer2) {
    }

    ngOnInit() {

        this.userSubscription = this.authService.userSubject.subscribe(
            (user: AuthUser) => {
                if (user) {
                    this.user = user;

                    this.route.queryParams.subscribe(
                        (params: Params) => {

                            if (!this.availableSections.includes(params?.section)) {
                                this.section = 'personal';
                            }

                            if (this.availableSections.includes(params?.section)) {
                                this.section = params.section;
                            }

                            if (!this.availableActions.includes(params?.action)) {
                                this.action = 'preview';
                            }

                            if (this.availableActions.includes(params?.action)) {
                                this.action = params.action;
                            }

                            if (!this.loaded) {
                                this.getUserDocuments();
                            }

                        }
                    );

                }

            }
        );

        this.authService.emitUser();
    }

    getUserDocuments(): void {
        this.loading = true;
        this.userService.getUserDocuments(this.user.id, {per_page: '*'}).then(
            (response: any) => {
                this.documents = response.data || response;
                this.loading = false;
                this.loaded = true;
            }
        );
    }

    hasCompletedDocuments(): boolean {
        return this.hasIdentityDocument()
            && !(!this.getDocument(this.user.address_proof_type)
                && !this.getDocument('user_snipped_photo'));
    }

    hasIdentityDocument(): boolean {
        if (this.user.identity_type === 'id_card') {
            const recto = this.getDocument('user_identity_image_recto');
            const verso = this.getDocument('user_identity_image_verso');
            return !(!recto && !verso);
        }

        if (this.user.identity_type === 'passport') {
            return !!this.getDocument('user_passport_image');
        }

        return false;
    }

    getDocument(title: string): any {
        return this.documents?.find(document => document.title === title && document.status !== 'rejected');
    }
    openVerifyStatusModal(): void {
        this.verifyStatusModal = this.modalService.open(this.modalVerifyStatus, {
            size: 'md',
            container: '#container-fluid-profile-account',
            scrollable: true
        });

        this.renderer.setStyle(document.getElementById('header'), 'display', 'none');
        this.verifyStatusModal.result.then(
            () => {
                this.renderer.setStyle(document.getElementById('header'), 'display', 'block');
            }, () => {
                this.renderer.setStyle(document.getElementById('header'), 'display', 'block');
            });
    }

    getNavLinks(): NavLink[] {

        return [
            {
                title: 'Mon profil',
                link: '/account/profile',
                isActive: !this.section || this.section === 'personal' ,
                queryParams: {section: 'personal'}
            },

            {
                title: 'Mon profil agent/agence',
                link: '/account/profile',
                isActive: this.section && this.section === 'agency' ,
                queryParams: {section: 'agency'}
            },

            {
                title: 'Vérification d\'identité',
                link: '/account/profile',
                isActive: this.section && (this.section === 'verify' || this.section === 'verify-status') ,
                queryParams: {section: 'verify'}
            },
        ];

    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }


}
