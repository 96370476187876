export interface Notification {
    id: string;
    data: any;
    picture: string;
    text: any;
    type: string;
    read_at: string;
    created_at: string;
    url: {
        link: string;
        queryParams: any;
    };
}


export interface NavLink {
    title: string;
    link: string;
    queryParams?: any;
    isActive?: boolean;
    lastSegment?: string;
}

export class MapCord {
    latitude?: number = null;
    longitude?: number = null;
    adress?: string = null;
    isLoading?: boolean = null;
    searchControl?: string = null;
    country?: string = null;
    city?: string = null;
    postalCode?: string = null;
    status?: string = null;
}

export interface CurrencyModel {
    currency: string;
    text: string;
    increment: number;
    min: number;
    max: number;
    activated: boolean;
    exchange_rate: {
        rate: number,
        convert: number
    };
}

export class AppConfig {
    defaultCurrency = 'EUR';
    currency = 'EUR';
    defaultCurrencies: CurrencyModel[] = [];
}

export class QtyModel {
    id: string;
    tittle: string;
    qty: number;
    min: number;
    max: number;
}

export interface Country {
    name: string;
    dial_code?: string;
    code: string;
}

export class FormWizard {
    constructor(title: string, steps: FormWizardStep[]) {
        this.title = title;
        this.steps = steps;
    }
    title: string;
    loading: boolean;
    loaded: boolean;
    steps: FormWizardStep[] = [];
}

export interface FormWizardStep {
    title: string;
    subTitle?: string;
    url: string;
    tag: string;
    icon?: string;
    isRequired?: boolean;
    isSubmittable?: boolean;
    requiredStep?: string;
    isActive?: boolean;
}


