import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as  datatable from '../../json/datatable/datatable.agents.json';
import * as  filter from '../../json/filter/filter.json';
import { ApiResponse } from 'src/app/models/response.model';
import { ActivatedRoute } from '@angular/router';
import { ResponseData } from 'src/app/models/response-data.model';
import { AdminService } from '../../services/admin.service';
@Component({
  selector: 'app-list-agent',
  templateUrl: './list-agent.component.html',
  styleUrls: ['./list-agent.component.css']
})
export class ListAgentComponent implements OnInit {
  breadcrumb=breadcrumb.list_agent;
  data_json=datatable.list_agent;
  filter=filter.agencies
  response: ApiResponse;
  queryParams: any = {
    per_page: 10,
    page: 1
};
  loaded = false;
  data: any;
  linkMenuIconFunction: any;
  constructor(public adminService:AdminService,private route: ActivatedRoute) {
    this.response ={} as ApiResponse;
   }

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
          if (params.page) {
              this.queryParams.page = params.page;
          }
          if(params.status){
          
            this.getAllAgents(location.search);
          }else this.getAllAgents();
      });

     
  }

 private getAllAgents(status?:string){
   this.loaded=false;
    this.adminService.getAllAgents(status).then((response:ResponseData)=>{
      this.response = response.data;
        this.data=response.data ? response.data.data : [];
        this.loaded=true;
      // console.log('data',this.data);
    },err=>{
      console.log('erreur ',err);
    })
  }

 
}
