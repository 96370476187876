import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductsService} from '../../../../services/products/products.service';
import {ToastrService} from 'ngx-toastr';
import {AuthUser} from '../../../../models/auth-user.model';
import {Image, UploadedImage} from '../../../../models/Image';

@Component({
    selector: 'app-property-room-documents',
    templateUrl: './property-room-documents.component.html',
    styleUrls: ['./property-room-documents.component.css']
})
export class PropertyRoomDocumentsComponent implements OnInit, OnDestroy {
    @Input() formMode = 'create';
    @Input() isOwnerCompleting = false;
    authUser: AuthUser;
    formData = [
        {
            key: '_method',
            value: 'PUT'
        },
        {
            key: 'has_document',
            value: 'true'
        }
    ];

    userSubscription: Subscription;
    userProductSubscription: Subscription;
    propertyForm: FormGroup;
    loading: boolean;
    loaded: boolean;
    productId: number;
    submitted;
    product: any;
    uploadedPropertyTitle: UploadedImage[] = [];
    uploadedRentalAgreement: UploadedImage[] = [];
    highlightedPropertyTitle: UploadedImage;
    highlightedRentalAgreement: UploadedImage;
    fileIsUploading: boolean;

    constructor(private fb: FormBuilder,
                private productService: ProductsService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService) { }

    ngOnInit() {
        this.productId = this.route.parent.parent.snapshot.params.id;
        this.initForm();

        if (!this.userSubscription) {

            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {

                    if (user) {
                        this.authUser = user;

                        if (!this.userProductSubscription) {

                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;
                                        if (!this.isOwnerCompleting && product.user_role === 'agent') {
                                            this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                        }
                                        this.loading = false;
                                        this.loaded = true;

                                        product.documents.forEach(
                                            (image: Image) => {
                                                const uploadedImage: UploadedImage = {
                                                    id: image.id,
                                                    uploadName: image.title,
                                                    hasUploaded: true,
                                                    pending: false,
                                                    isUploading: false,
                                                    link: image.url
                                                };

                                                if (product.user_role === 'tenant' && image.title === 'rental_agreement') {
                                                    this.highlightedRentalAgreement = uploadedImage;
                                                    this.uploadedRentalAgreement.push(uploadedImage);
                                                }

                                                if ((this.isOwnerCompleting || product.user_role === 'owner') && image.title === 'property_title') {
                                                    this.highlightedPropertyTitle = uploadedImage;
                                                    this.uploadedPropertyTitle.push(uploadedImage);
                                                }
                                            }
                                        );

                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                () => {
                                    this.router.navigate(['/rooms/new']);
                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();
        }

    }

    getAcceptedFormats() {
        return [
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
    }


    checkFiles() {

        if (this.product.user_role === 'tenant' && this.uploadedRentalAgreement.length === 0) {
            return false;
        }

        if (this.product.user_role === 'owner' && this.uploadedPropertyTitle.length === 0) {
            return false;
        }

        if (this.isOwnerCompleting && this.uploadedPropertyTitle.length === 0) {
            return false;
        }

        return true;
    }

    doSubmit() {
        this.submitted = true;

        if (!this.isOwnerCompleting) {
            this.router.navigate([this.next()]).then(
                () => {
                    this.toastr.success('Les informations sur la propriété ont été ajoutées');
                }
            );
        } else {

            this.router.navigate([
                '/account/rooms']).then(
                () => {
                    this.toastr.success('Les informations sur la propriété ont été ajoutées');
                }
            );
        }
    }

    next() {
        return this.productService.getNextStep();
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    get f() {
        return this.propertyForm.controls;
    }


    initForm() {
        this.propertyForm = this.fb.group({});
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
