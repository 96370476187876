import { Component, OnInit } from '@angular/core';
import * as  breadcrumb from '../../json/breadcrumb.json';
import * as filter from '../../json/filter/filter.json';
import * as  datatable from '../../json/datatable/datatable_requests.json';
import { AdminAccountService } from '../../services/admin.account.service';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Request,Datum} from '../../model/request';
import {IPagination} from '../../model/pagination';
import { GetAdmin } from '../../model/getadmin';
@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.css']
})
export class RequestStatusComponent implements OnInit,GetAdmin {
  breadcrumb=breadcrumb.request_status;
  loaded :boolean;
  data: Request ;
  dataTable:Datum[]
  data_json=datatable.requests;

  adminId: any;
 
  
  status:any=null;
  filter=filter.requests;
  paramsPage: IPagination;
  constructor(private router:Router, private authAdmin: AdminAccountService,public adminService:AdminService, private route: ActivatedRoute) {
    this.paramsPage={} as IPagination;
   }



  ngOnInit() {
    this.adminService.setTitle('Demandes');
   this.informationAdmin();

  }
  informationAdmin(): void {
    this.adminService.$admin.then((admin:any)=>{
    
           
      this.getParams();
     });
  }

  getParams(){
    this.route.queryParams.subscribe(
      params => {
          if(params){
           this.status=params['status'];
            if(this.adminId){
              this.getAllRequests(location.search);
            }
          
          }else{
           if(this.adminId){
            this.getAllRequests(this.adminId);
           }
           
          }
      }); 
  } 
  


  getAllRequests(params?:string){
    this.loaded = false;
    this.adminService.getAllRequests(params).then((response:any)=>{
      
      this.data=response ? response.data : [];
      this.loaded = true;
      this.paramsPage.currentPage=response.data?response.current_page:0;
      this.paramsPage.totalItems=response.data?response.total:0;
      this.paramsPage.currentPage=response.data?response.current_page:0;
      this.paramsPage.itemsPerPage=response.data?response.per_page:0;
       
    },err=>{
     
      console.log('ERREUR',err);
    });
  }

  changeStatus(status){
    this.status=status;
    this.loaded=false;
    
    this.router.navigate(['admin/requests/status'], { queryParams: { status: status } }).then(data=>{
     
    });
  }

  getPage(value){
    if(value.params){
      this.getAllRequests(value.params+'&page='+value.page);
     }else{
      this.getAllRequests("?page="+value.page);
     }
  }
}
