import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthUser} from '../../../../models/auth-user.model';
import {Subscription} from 'rxjs';
import {ProductsService} from '../../../../services/products/products.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
export interface OptionModel {
    text: string;
    options: Option[];
    tag: string;
}

export interface Option {
    name: string;
}
@Component({
    selector: 'app-options-room-form',
    templateUrl: './options-room-form.component.html',
    styleUrls: ['./options-room-form.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class OptionsRoomFormComponent implements OnInit, OnDestroy {

    @Input() formMode = 'create';
    authUser: AuthUser;
    userSubscription: Subscription;
    userProductSubscription: Subscription;
    options: any = [];
    formRoom: FormGroup;
    loaded;
    params: Params;
    loading;
    product: any;
    productId;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    optionsModel: OptionModel[] = [];

    constructor(private router: Router,
                private authService: AuthService,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private toastr: ToastrService) {

    }

    add(event: MatChipInputEvent, tag: string): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            this.addOption(value, tag);
        }

        if (input) {
            input.value = '';
        }
    }

    private addOption(value, tag) {
        this.optionsModel.forEach(
            (model: OptionModel) => {
                const index = model.options.findIndex((option: Option) => {
                    return option.name === value.trim();
                });
                if (model.tag === tag && index < 0) {
                    model.options.push({name: value.trim()});
                }
            }
        );
    }

    remove(option: Option, tag: string): void {
        const modelIndex = this.optionsModel.findIndex(
            (model: OptionModel) => {
                return model.tag === tag;
            }
        );

        const index = this.optionsModel[modelIndex].options.indexOf(option);

        if (index >= 0) {
            this.optionsModel[modelIndex].options.splice(index, 1);
        }
    }



    ngOnInit() {

        this.initForm();

        this.route.parent.params.subscribe(
            (params: Params) => {
                if (params.hasOwnProperty('id')) {
                    this.productId = params.id;
                }
            }
        );

        if (!this.userSubscription) {
            this.userSubscription = this.authService.userSubject.subscribe(
                (user: AuthUser) => {
                    if (user) {
                        this.authUser = user;
                        if (!this.userProductSubscription) {
                            this.userProductSubscription = this.productService.userProductSubject.subscribe(
                                (product: any) => {
                                    if (product) {
                                        this.product = product;
                                        if (this.product.category_tag !== 'sports-halls') {
                                            this.options = this.product.type.category.options;
                                        }

                                        if (this.product.category_tag === 'sports-halls') {
                                            this.optionsModel = this.getSportHallsOptionsModel();
                                        }

                                        if (this.product.category_tag === 'offices') {
                                            this.optionsModel = this.getOfficesOptionsModel();
                                        }

                                        if (this.product.custom_options.length > 0) {
                                            this.product.custom_options.forEach(
                                                (customOption: any) => {
                                                    this.addOption(customOption.description, customOption.type);
                                                }
                                            );
                                        }
                                        this.loaded = true;
                                    }
                                }
                            );

                            this.productService.getAuthUserProduct(user.id, this.productId).then(
                                () => {
                                    this.productService.emitUserProduct();
                                },

                                () => {
                                    if (this.formMode === 'create') {
                                        this.router.navigate(['/rooms/new']);
                                    }

                                    if (this.formMode === 'edit') {
                                        this.router.navigateByUrl('not-found', {skipLocationChange: true});
                                    }
                                }
                            );
                        }
                    }
                }
            );

            this.authService.emitUser();

        }

    }

    getSportHallsOptionsModel(): OptionModel[] {
        return [
            {
                text: 'Listez les accessoires fournis (séparés par les virgules)',
                options: [],
                tag: 'provided_options'
            },
            {
                text: 'Listez les accessoires non inclus dans le prix et payable sur place (séparés par les virgules)',
                options: [],
                tag: 'provided_payable_options'
            },
            {
                text: 'Listez les accessoires non inclus dans le prix et non disponible sur place (séparés par les virgules)',
                options: [],
                tag: 'not_provided_options'
            },
        ];
    }

    getOfficesOptionsModel(): OptionModel[] {
        return [
            {
                text: 'Listez d\'autres options à louer sur place (séparés par les virgules)',
                options: [],
                tag: 'provided_payable_options'
            },
        ];
    }
    checkUser() {
        if (this.authUser && this.product) {
            if (this.product.manager_type === 'tenant' && this.product.manager_type === 'owner') {
                return this.product.user_id === this.authUser.id;
            }
            if (this.product.manager_type === 'agent') {
                return this.product.agent.id === this.authUser.id;
            }
        }

        return true;
    }

    previous() {
        return this.productService.getPreviousStep();
    }

    getPayableOptions() {
        return this.options.filter(
            (option) => {
                return !option.free;
            }
        );
    }

    getFreeOptions() {
        return this.options.filter(
            (option) => {
                return option.free;
            }
        );
    }

    getOptionsCeiledLength(free = 1) {
        return Math.ceil((free ? this.getFreeOptions() : this.getPayableOptions()).length / 2);
    }

    doSubmit(form: NgForm, next) {
        console.log(this.optionsModel);

        this.loading = !!next;

        const formData = form.value || {};
        formData.custom_options = this.optionsModel;

        this.productService.saveUserProductEquipments(this.authUser.id, this.product.id, formData).then(
            (product: any) => {
                this.loading = false;

                if (this.formMode === 'create' && next === true) {

                    this.router.navigate([this.productService.getNextStep()]).then(
                        () => {
                            this.toastr.success('Les options ont bien été insérées');
                        }
                    );
                }
            }
        );
    }

    checkOption(id: number): boolean {
        return !!this.product.equipments.find(
            (option: any) => {
                return option.id === id;
            }
        );
    }

    initForm() {
        this.formRoom = this.fb.group({

        });
    }

    ngOnDestroy() {

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.userProductSubscription) {
            this.userProductSubscription.unsubscribe();
        }
    }

}
