import {Component, Input, OnInit} from '@angular/core';
import {AuthUser} from '../../../../models/auth-user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import {BookingService} from '../../../../services/bookings/booking.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-product-host',
    templateUrl: './product-host.component.html',
    styleUrls: ['./product-host.component.css']
})
export class ProductHostComponent implements OnInit {
    @Input() product: any;
    @Input() queryParams: any = {};
    @Input() host;
    @Input() contactMode = true;
    @Input() mode = 'details';
    @Input() authUser: AuthUser;
    @Input() userRole = 'owner';
    @Input() viewAs = 'guest';
    contactHostMode = false;
    inputReason = new FormControl('', [Validators.required]);
    inputMessage = new FormControl('', [Validators.required]);
    submitted: boolean;
    loading: boolean;
    message: any;
    formData: any;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private bookingService: BookingService,
                private toastr: ToastrService) { }

    ngOnInit() {
    }
    showFormContactHost() {
        this.formData = this.queryParams;
        this.contactHostMode = true;
    }

    contactHost() {

        this.submitted = true;

        if (!this.formData.dateStart) {
            document.getElementById('input-date-start-contact-host').focus();
            return;
        }

        if (!this.formData.dateEnd) {
            document.getElementById('input-date-end-contact-host').focus();
            return;
        }

        if (this.authUser) {
            this.sendMessage();
        } else {
            this.router.navigate(['/message/' + this.product.id + '/new'], {
                queryParams: this.formData,
            });
        }
    }

    sendMessage() {
        if (this.inputReason.invalid || this.inputMessage.invalid) {
            return;
        }

        this.loading = true;

        if (this.product.user_id === this.authUser.id || this.product.manager.id === this.authUser.id) {
            this.loading = true;
            setTimeout(() => {
                this.router.navigate(['/search'], {queryParams: this.queryParams});
                return;
            }, 2000);
        } else {
            this.formData.reason = this.inputReason.value;
            this.formData.message = this.inputMessage.value;

            this.inputReason.disable();
            this.inputMessage.disable();

            this.bookingService.saveProductMessage(this.authUser.id, this.product.id, this.formData).then(
                (message: any) => {
                    this.loading = false;
                    this.inputReason.enable();
                    this.inputMessage.enable();
                    this.message = message;
                },
                () => {
                    this.loading = false;
                    this.inputReason.enable();
                    this.inputMessage.enable();
                    this.toastr.error('Désolé, une erreur s\'est produite');
                }
            );
        }
    }
}
