import { Component, OnInit } from '@angular/core';
import {NavLink} from '../../../models/app.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiResponse} from '../../../models/response.model';
import {Subscription} from 'rxjs';
import {AuthUser} from '../../../models/auth-user.model';
import {ProductsService} from '../../../services/products/products.service';
import {UserService} from '../../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../services/auth/auth.service';
import {ResponseData} from '../../../models/response-data.model';
import {AppService} from '../../../services/app/app.service';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
    payments: any;
    userSubscription: Subscription;
    loaded = false;
    loading = true;
    response = new ApiResponse();
    empty;
    queryParams: any = {
        per_page: 6,
        sort_by_desc: 'updated_at',
        page: 1
    };
    role = null;
    status = null;
    isLoading = false;
    authUser: AuthUser;
    paymentStatus: any;
    paymentType: any;
    constructor(private userService: UserService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private productService: ProductsService,
                private router: Router,
                private toastr: ToastrService,
                private appService: AppService) { }

    ngOnInit() {

        this.userSubscription = this.authService.userSubject.subscribe(
            (user) => {
                if (user) {
                    this.authUser = user;
                    this.route.queryParams.subscribe(
                        params => {

                            if (params.page) {
                                this.queryParams.page = params.page;
                            } else {
                                delete this.queryParams.page;
                            }

                            if (params.user_role) {

                                if (params.user_role !== this.role) {
                                    this.loaded = false;
                                }

                                this.queryParams.user_role = params.user_role;
                                this.role = params.user_role;
                            } else {
                                delete this.queryParams.user_role;
                                this.role = null;
                            }

                            if (params.payment_status) {
                                this.queryParams.payment_status = params.payment_status;
                                this.paymentStatus = params.payment_status;
                                delete this.queryParams.payment_type;
                                this.paymentType = null;

                            } else {
                                delete this.queryParams.payment_status;
                                this.paymentStatus = null;
                            }

                            if (params.payment_type) {
                                this.queryParams.payment_type = params.payment_type;
                                this.paymentType = params.payment_type;
                                delete this.queryParams.payment_status;
                                this.paymentStatus = null;

                            } else {
                                delete this.queryParams.payment_type;
                                this.paymentType = null;
                            }

                            if (this.role === 'agent' || this.role === 'host') {
                                delete this.queryParams.user_role;

                                if (this.role === 'agent' && !this.authUser.agency) {
                                    this.loading = false;
                                    this.loaded = true;
                                    this.response = new ApiResponse();
                                    return;
                                }
                            }

                            this.getUserPayments();

                        }
                    );
                }
            }
        );

        this.authService.emitUser();
    }
    filterByStatus(event) {

        const val = event.target.options[event.target.selectedIndex].value;
        const queryParams: any = {};
        delete this.queryParams.page;

        if (this.role) {
            queryParams.user_role = this.role;
        }

        if (val) {
            queryParams.payment_status = val;
        } else {
            delete this.queryParams.payment_status;
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
        });
    }

    transformCurrency(payment) {
        return this.appService.transformCurrency(payment.total, payment.currency);
    }

    getUserPayments() {
        this.loading = true;

        this.userService.getUserPayments(this.authUser.id, this.getApiUserRole(), this.queryParams).then(
            (response: ResponseData) => {
                this.loaded = true;
                this.loading = false;
                this.response = response.data ? response.data : new ApiResponse();
            },
            () => {
                this.loaded = true;
                this.loading = false;
                this.response = new ApiResponse();
            }
        );
    }

    getApiUserRole() {
        switch (this.role) {
            case 'agent':
                return 'agents';
            case 'tenant':
                return 'clients';
            case 'host':
                return 'hosts';
            default:
                return 'hosts';
        }
    }

    getNavLinks(): NavLink[] {
        const url = this.router.createUrlTree(['/account/payments']).toString();

        return [
            {
                title: 'En tant que hôte',
                link: url,
                queryParams: {user_role: 'host'},
                isActive: (this.role && this.role === 'host') || !this.role,
            },
            {
                title: 'En tant que voyageur',
                link: url,
                queryParams: {user_role: 'tenant'},
                isActive: this.role && this.role === 'tenant' ,
            },
            {
                title: 'En tant que agent',
                link: url,
                queryParams: {user_role: 'agent'},
                isActive: this.role && this.role === 'agent',
            },

        ];
    }

}
