import { Component, OnInit, Input } from '@angular/core';
import { AuthorizationService } from '../../security/service/authorization.service';

@Component({
  selector: 'app-menu-pop',
  templateUrl: './menu-pop.component.html',
  styleUrls: ['./menu-pop.component.css']
})
export class MenuPopComponent implements OnInit {

  constructor(public authorizationService:AuthorizationService) { }
  @Input() id: any;
  @Input() linkMenuPop: any;
  ngOnInit() {

  }

}
