import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormWizardStep, FormWizard} from '../../models/app.model';
import {FormWizardService} from '../../services/form-wizard/form-wizard.service';
@Component({
    selector: 'app-template-wizard-1',
    templateUrl: './template-wizard.component.html',
    styleUrls: ['./template-wizard.component.css']
})
export class TemplateWizardComponent implements OnInit {
    @Input() lastStepNextText = 'Envoyer';
    formWizard: FormWizard;
    formWizardSubscription: Subscription;

    constructor(private formWizardService: FormWizardService) { }

    ngOnInit(): void {
        this.formWizardSubscription = this.formWizardService.formWizardSubject.subscribe(
            formWizard => {
                if (formWizard) {
                    this.formWizard = formWizard;
                }
            }
        );

        this.formWizardService.emitFormWizard();
    }

    gotToStep(step: FormWizardStep): void {
        this.formWizardService.setActiveStep(step.tag);
    }
    isLastStep(): boolean {
       return this.getActiveStepIndex() === this.formWizard.steps.length - 1;
    }

    isDisabled(step: FormWizardStep): boolean {
        return !step.isActive && (step.isRequired || !step.isSubmittable);
    }

    getActiveStepIndex(): number {
        return this.formWizardService.getActiveStepIndex();
    }

    getActiveStep(): FormWizardStep {
        return this.formWizardService.getActiveStep();
    }

    getPercentageLoaded(): number {
        return this.formWizardService.getPercentageLoaded();
    }

    next(): void {
        this.formWizardService.submit();
    }

    previous(): void {
        this.formWizardService.previous();
    }
}
