import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as  breadcrumb from '../../../json/breadcrumb.json';
import { AdminService } from 'src/app/admin/services/admin.service';
@Component({
  selector: 'app-new-role',
  templateUrl: './new-role.component.html',
  styleUrls: ['./new-role.component.css']
})
export class NewRoleComponent implements OnInit {
  breadcrumb = breadcrumb.new_role;
  submitted = false;
  angForm: any;
  constructor(private fb: FormBuilder,
    private adminService :AdminService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.initForm();
  }
 

  
  initForm() {
    this.angForm = this.fb.group({
      name: [null, [Validators.required]],
    });
  } 

  get f() {
    return this.angForm.controls;
  }
  saveRole(){
    this.submitted = true;
    if (this.angForm.invalid) {
      return;
    }
    let admin={
      name:this.angForm.value.name
    }
    this.adminService.addRole(admin).then((data:any)=>{
      this.toastr.success('Un nouveau Role a été crée','Success');
      this.router.navigate(['/admin/roles']);
    },err=>{
      console.log('erreur',err);
    })
  }
}
