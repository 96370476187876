import {ResponseData} from './response-data.model';
import {ApiResponse} from './response.model';

export interface Search {
    date_start?:any;
    date_end?:any;
    longitude?:any;
    latitude?:any;
    type?:any;
    adress?:any;
    min_price?:any;
    max_price?:any;
    adults?:any;
    children?:any;
    babies?:any;
    all?:any;
    latest_pub?:any;
    popular?:any;
}

export class SearchModel {
    loading = false;
    loadingMap = false;
    loaded = false;
    loadedMap = false;
    data: ApiResponse;
    mapData: ApiResponse;
}
